import { React, useState, useEffect } from "react";
import { useFormikContext } from "formik";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { es } from "date-fns/locale";
import 'moment-timezone';

const DatePickerField = ({ ...props }) => {

  const { setFieldValue } = useFormikContext();
  const [cDate, setDate] = useState('');
  const [CdateHour, setDateHour] = useState('');
  const actualDate = moment(props.date).tz('America/Hermosillo', true);
  const actualHour = moment(props.hour).tz('America/Hermosillo', true);
  actualDate.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('ha ');
  actualHour.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('ha ');

  const setNewStates = () => {
    setDate(moment(actualDate).format("MM/DD/YYYY"));
    setDateHour(moment(actualHour).format("HH:mm"));
  }

  useEffect(() => {
    setNewStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.date,props.id,props.hour]);

  return (
    <div>
      <div className="mb-5 text-3xl text-gray-550">
        <p className="text-center lg:text-left">Fecha</p>

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <KeyboardDatePicker
            id="date-picker-dialog"
            className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
            inputVariant="outlined"
            inputValue={cDate}
            format="MM/dd/yyyy"
            clearable
            onChange={(value) => {
              setDate(moment(value).format("MM/DD/YYYY"));
              setFieldValue("dateEdit", value);
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            okLabel="Aceptar"
            cancelLabel="Cancelar"
            clearLabel="Limpiar"
          />
        </MuiPickersUtilsProvider>
      </div>
      <div className="mb-9 text-3xl text-gray-550">
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <KeyboardTimePicker
            id="time-picker-dialog"
            className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
            inputVariant="outlined"
            inputValue={CdateHour}
            format="HH:mm"
            clearable
            value={new Date()}
            onChange={(value) => {
              setDateHour(moment(value).format("HH:mm"));
              setFieldValue("hourEdit", value);
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            okLabel="Aceptar"
            cancelLabel="Cancelar"
            clearLabel="Limpiar"
          />
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
};
export default DatePickerField;
