import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Context from "../../../context/AdminUserContext";
import TableLoading from "../../../components/TableLoading";
import TableNoData from "../../../components/TableNoData";
import {
  getColumnsForCoupons,
  customStyles,
  paginationComponentOptions,
} from "../../../utils/tableOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { adminCouponsServices } from "../../../services/api/admin/coupons";

const Index = () => {
  const { currentAdminUser } = useContext(Context);

  const [coupons, setCoupons] = useState([]);
  const [formattedCoupons, setFormattedCoupons] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchCoupons = () => {
    adminCouponsServices
      .getCoupons()
      .then((response) => {
        setCoupons(response.data.coupons);
      })
      .catch((err) => {});
  };

  const formatCouponsData = () => {
    let data = [];

    let typeString = {
      Percent: "Descuento en porcentaje",
      Fixed: "Descuento fijo",
    };

    coupons.forEach((coupon) => {
      let usageLimit = coupon.usage_limit ? coupon.usage_limit : "ꝏ";

      data.push({
        code: <span className="font-bold text-primary-200">{coupon.code}</span>,
        type: typeString[coupon.type],
        amount:
          coupon.type === "Percent"
            ? `${coupon.amount}%`
            : `$${coupon.amount.toLocaleString("en-US", {
                minimumFractionDigits: 2,
              })} MXN`,
        description: coupon.description,
        use: `${coupon.coupon_history_count} / ${usageLimit}`,
        detailsComponent: (
          <Link
            to={`/admin/cupones/${coupon.id}/editar`}
            className="w-full text-primary-200 hover:text-primary-300 text-center text-sm lg:text-base"
          >
            Editar
          </Link>
        ),
      });
    });

    setFormattedCoupons(data);
    setLoading(false);
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchCoupons();
  }, [currentAdminUser]);

  useEffect(() => {
    // if (_.isEmpty(orders)) return;
    formatCouponsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coupons]);

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <p className="text-4xl font-bold">Cupones</p>

        <Link
          to="/admin/cupones/nuevo"
          className="w-full md:w-56 bg-primary-200 text-white text-2xl text-center md:text-left py-3 px-5 disabled:opacity-75 mt-5 md:mt-0"
        >
          <FontAwesomeIcon icon={["fa", "plus"]} size="sm" className="mr-3" />
          Añadir nuevo
        </Link>
      </div>

      <div className="border border-gray-350 mt-12">
        <DataTable
          columns={getColumnsForCoupons}
          data={formattedCoupons}
          noDataComponent={<TableNoData />}
          progressPending={loading}
          progressComponent={<TableLoading />}
          customStyles={customStyles}
          pagination
          paginationComponentOptions={paginationComponentOptions}
        />
      </div>
    </div>
  );
};

export default Index;
