import { api } from "../../../client";

export const adminProfileService = {
  getUser() {
    return api.admin.get("/admin");
  },
  updateUser(model) {
    return api.admin.put("/admin", model);
  },
  updateImage(model) {
    return api.admin.post("/admin", model);
  },
};
