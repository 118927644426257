import { NavLink, Outlet } from "react-router-dom";

const SalesLayout = () => {
  return (
    <>
      <div className="flex flex-row justify-center md:justify-start text-2xl text-gray-700 gap-5">
        <NavLink end to="/mi-escritorio/ventas/productos">
          {({ isActive }) => (
            <span
              className={isActive ? "text-primary-200 font-bold" : undefined}
            >
              Productos
            </span>
          )}
        </NavLink>

        <div className="h-10 border border-[#E3E3E3]" />

        <NavLink end to="/mi-escritorio/ventas/membresias">
          {({ isActive }) => (
            <span
              className={isActive ? "text-primary-200 font-bold" : undefined}
            >
              Membresias
            </span>
          )}
        </NavLink>
      </div>
      <div className="mt-16">
        <Outlet />
      </div>
    </>
  );
};

export default SalesLayout;
