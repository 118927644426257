import { api } from "../../client";

export const newsService = {
  
  getNews() {
    return api.base.get("/new");
  },
  getWithdrawalHistory() {
    return api.base.get("/withdrawal");
  },
 
};
