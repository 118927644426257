import  React  from "react";
import { Field, ErrorMessage } from "formik";

const DatePickerField = ({ ...props }) => {
  return (
    <div className="mb-9 text-xl text-gray-700 space-x-2 text-center md:text-left">
      <p>
        {props.question && props.question}{" "}
        <b>
          {props.link && props.linkTitle && (
            <a href={props.link} target="_blank" rel="noopener noreferrer">
              {props.linkTitle}{" "}
            </a>
          )}{" "}
        </b>
      </p>
      <div className="space-x-5 flex justify-center md:justify-start  mt-2 lg:mb-4">
        <div className="space-x-2 flex items-center">
          <Field
            name={props.name}
            value="1"
            className="t-20 w-5 h-5  bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
            type="radio"
          />
          <span className="">Si</span>
        </div>
        <div className="space-x-2 flex items-center">
          <Field
            name={props.name}
            value="0"
            className="w-5 h-5  bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
            type="radio"
          />
          <span className="">No</span>
        </div>
        <ErrorMessage
          name={props.name}
          component="div"
          className="text-red-400 font-bold text-xl"
        />
      </div>
    </div>
  );
};
export default DatePickerField;
