import { api } from "../../client";

export const mercadoServices = {
  
  setPay(id,model) {
    return api.base.post(`/order/pay/${id}`,model);
  },
  
 
};
