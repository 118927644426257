const desktopNavbarItems = [
    {
        label: "Mis cursos",
        icon: ["far", "book-blank"],
        link: '/mi-escritorio/mis-cursos'
    },
    {
        label: "Calendario",
        icon: ["far", "calendar"],
        link: '/mi-escritorio/calendario'
    },
    {
        label: "Estadisticas",
        icon: ["far", "chart-line"],
        link: '/mi-escritorio/estadisticas'
    },
    {
        label: "Favoritos",
        icon: ["far", "star"],
        link: '/mi-escritorio/favoritos'
    },
    {
        label: "Lista de deseos",
        icon: ["far", "heart"],
        link: '/mi-escritorio/lista-de-deseos'
    },
    {
        label:"Ventas",
        icon:["far", "cart-circle-check"],
        link:'/mi-escritorio/ventas'
    },
    {
        label:"Ordenes",
        icon:["far", "file-invoice"],
        link:'/mi-escritorio/ordenes'
    },
];

export default desktopNavbarItems;