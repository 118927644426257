import { useState } from "react";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { adminCoursesServices } from "../../services/api/admin/courses";
import { notifications } from "../../utils/notifications";

import ReplyQuestionContent from "../Modals/admin/ReplyQuestionContent";
import PublishQCContent from "../Modals/admin/PublishQCContent";

import ReplyQuestionModal from "../Modals/admin/ReplyQuestionModal";
import PublishQCModal from "../Modals/admin/PublishQCModal";

const QuestionComponent = ({
  question,
  answered = false,
  pendings = null,
  setPendings = null,
}) => {
  const [showReply, setShowReply] = useState(false);
  const [showPublish, setShowPublish] = useState(false);

  const [isPublished, setIsPublished] = useState(question.isPublished);

  const replyQuestion = async (answer, resetForm) => {
    await adminCoursesServices
      .answerQuestion({ id: question.id, answer })
      .then((response) => {
        notifications.success(response.data.message);
        resetForm();
        if (setPendings !== null && pendings !== null)
          setPendings(pendings.filter((q) => q.id !== question.id));
        setShowReply(!showReply);
      })
      .catch((err) => {});
  };

  const publishOrHideQuestion = async () => {
    await adminCoursesServices
      .publishQuestion({ id: question.id })
      .then((response) => {
        notifications.success(response.data.message);
        setIsPublished(!isPublished);
        setShowPublish(!showPublish);
      })
      .catch((err) => {});
  };

  return (
    <>
      <div className="flex flex-col md:flex-row justify-between gap-5 lg:gap-10 p-8 border-b border-b-gray-350 w-full">
        <button
          className="flex flex-col md:flex-row gap-y-5 md:gap-y-0"
          onClick={() => setShowReply(true)}
          disabled={answered}
        >
          <div className="mx-auto md:mr-5 md:ml-0">
            <div className="w-10 h-10 lg:w-16 lg:h-16 rounded-full bg-[#D8D8D8]">
              <img
                src={question.user && question.user.image}
                alt=""
                className="rounded-full w-full h-full object-cover"
                style={{ backgroundColor: "#D8D8D8" }}
              />
            </div>
          </div>
          <div className="w-full lg:w-auto  flex flex-col text-xl md:text-2xl text-center md:text-left gap-y-2 lg:gap-y-5 md:gap-y-0">
            <div className="flex flex-col md:flex-row items-center text-[#9F9F9F]">
              <p className="mr-3 text-sm lg:text-xl">{question.user.name}</p>
              <span className="text-lg md:text-xl text-primary-200 text-sm lg:text-xl">
                {question.course.name}
              </span>
            </div>
            <p className="text-sm lg:text-xl">{question.question}</p>
          </div>
        </button>

        <div className="flex justify-between items-center md:flex-col text-[#AEAEAE]">
          <button
            className="hidden md:block"
            onClick={() => setShowPublish(true)}
          >
            <FontAwesomeIcon
              icon="fa-flag"
              className={`${
                isPublished ? "text-primary-200" : "text-[#D8D8D8]"
              }`}
              size="sm"
            />
          </button>
          <p className="text-lg text-center leading-none">
            <Moment format="DD/MM/YYYY">{question.createdAt}</Moment>
          </p>
          <button
            className="block md:hidden"
            onClick={() => setShowPublish(true)}
          >
            <FontAwesomeIcon
              icon="fa-flag"
              className={`${
                question.isPublished ? "text-primary-200" : "text-[#D8D8D8]"
              }`}
              size="sm"
            />
          </button>
        </div>
      </div>
      <ReplyQuestionModal
        visible={showReply}
        setVisible={setShowReply}
        modalContent={
          <ReplyQuestionContent
            handleReply={replyQuestion}
            question={question}
            isQuestion
          />
        }
      />
      <PublishQCModal
        visible={showPublish}
        setVisible={setShowPublish}
        modalContent={
          <PublishQCContent
            handlePublish={publishOrHideQuestion}
            isPublished={isPublished}
            setVisible={setShowPublish}
            isQuestion
          />
        }
      />
    </>
  );
};

export default QuestionComponent;
