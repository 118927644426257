import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field } from "formik";

import "../styles/drop-file-input.css";
import fileDefault from "../assets/images/file-blank-solid-240.png";

import { formatBytes } from "../utils";

const DropFileInput = ({
  multiple,
  onFileChange,
  showFiles = true,
  logoRef,
  activateClean,
}) => {
  const wrapperRef = useRef(null);

  const [fileList, setFileList] = useState([]);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    const newFiles = e.target.files;
    if (newFiles.length > 0) {
      let updatedList = multiple ? [...fileList] : [];
      for (let i = 0; i < newFiles.length; i++) {
        updatedList.push(newFiles[i]);
      }
      setFileList(updatedList);
      onFileChange(updatedList);
    }
  };

  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    onFileChange(updatedList);
  };

  if (activateClean === true) {
    fileList.forEach((file) => {
      const updatedList = [...fileList];
      updatedList.splice(fileList.indexOf(file), 1);
      setFileList(updatedList);
      onFileChange(updatedList);
    });
  }
  return (
    <>
      <div
        ref={wrapperRef}
        className="drop-file-input"
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className="drop-file-input__label">
          <FontAwesomeIcon
            icon={["far", "cloud-arrow-up"]}
            size="3x"
            className="mr-3"
          />
          <p className="text-gray-700 text-2xl font-bold mt-4">
            Arrastra y suelta
          </p>
          <div className="text-xl">
            <span>o </span>
            <button className="text-primary-200">busca</button>
            <span> tu archivo para subir</span>
          </div>
          {multiple && (
            <p className="text-xl">(Puedes subir varias imágenes)</p>
          )}
        </div>
        <Field
          type="file"
          name="image"
          value=""
          onChange={onFileDrop}
          multiple={multiple}
        />
      </div>
      <p className="text-gray-400 my-4 italic text-xl">
        {" "}
        Tamaño de imagen recomendado 1280x384 pixeles.{" "}
      </p>
      <p className="text-gray-400 my-4 italic text-xl">
        Formatos soportados: JPEG,JPG Y PNG
      </p>
      {fileList.length > 0 && showFiles ? (
        <div className="drop-file-preview">
          <p className="drop-file-preview__title">Archivos</p>
          {fileList.map((item, index) => (
            <div key={index} className="drop-file-preview__item">
              <div className="text-center">
                <img src={fileDefault} alt="" />
              </div>
              <div className="drop-file-preview__item__info">
                <p>{item.name}</p>
                <p>{formatBytes(item.size)}</p>
              </div>
              <div className="drop-file-preview__item__del ">
                <button
                  className="flex items-center justify-center text-xl font-bold"
                  onClick={() => fileRemove(item)}
                >
                  X
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

DropFileInput.propTypes = {
  onFileChange: PropTypes.func,
};

export default DropFileInput;
