import { Link } from "react-router-dom";

const NotFoundPage = () => {

  const chooseUrlRedirectHTML = () => {
    let title = "RECURSO"
    let url = "/"
    let urlDescription = ""
    switch (true) {
      case window.location.pathname.includes("curso"):
        title = "curso";
        url = "cursos";
        urlDescription = "Volver a los cursos";
        break;
      case window.location.pathname.includes("producto"):
        title = "producto";
        url = "tienda";
        urlDescription = "Volver a la tienda";
        break;
      default:
        title = "recurso";
        url = "/";
        urlDescription = "Volver al inicio";
        break;
    }
    return (
      <div className="my-20">
        <div className="text-center text-9xl text-primary-200">404</div>
        <p className="pb-2 lg:pb-8 text-2xl lg:text-5xl text-center  text-primary-200 font-bold uppercase ">
          {title}  NO ENCONTRADO
        </p>
        <div>
          <p className="pb-8 text-xl lg:text-3xl text-center">
            {title !== "recurso" ? `Lo sentimos, no pudimos encontrar el ${title} que solicitaste.` : "Lo sentimos, no pudimos encontrar la página que solicitaste."}
          </p>
          <div className=" text-gray-700 text-xl lg:text-2xl text-center my-4">
            <Link
              to={url}
              className="bg-primary-200 py-3 px-12 text-white font-bold border-primary-200 border-2 mt-5"
            >
              {urlDescription}
            </Link>
          </div>
        </div>
      </div>)
  }


  return (
    <main className="container mx-auto flex-1 px-4 md:px-0 mb-16">
     {chooseUrlRedirectHTML()}
    </main>
  );
};

export default NotFoundPage;
