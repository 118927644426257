import basic from "../../assets/images/memberships/plus.png";
import PlusLayout from "../../components/memberships/PlusLayout";
import { useState, React } from "react";
const url = window.location.origin;

const elements = [
  {
    label: "Acceso a inventario de la",
    link: url + "/tienda",
  },

  {
    label: "Más de 700 productos diferentes para envío inmediato.",
  },
  {
    label: "Precio de mayoreo desde una pieza.",
  },
  {
    label: "Garantía por defecto de fábrica en productos.",
  },
  {
    label: "Envíos express.",
  },
  {
    label:
      "Servicio de logística para envíos de ventas en Mercado libre, Amazon, Shopify, redes sociales, etc.",
  },
  {
    label: "Empaque del envío.",
  },

  {
    label:
      "Acceso a toda la plataforma educativa sin costo adicional para las clases como: shopify, Amazon, Mercado Libre, Wordpress, Facebook Ads, Photoshop, ventas, ecommerce, etc.",
  },
  {
    label: "Certificados avalados por Be Global Pro al finalizar cada curso.",
  },
  {
    label: "Actualizaciones constantes.",
  },
  {
    label:
      "Acceso a todas nuestras capacitaciones de zoom para mentorías con nuestros educadores.",
  },

  {
    label: "Acompañamiento por whatsapp con nuestros expertos.",
  },
  {
    label: "Acceso para poder comprar productos americanos originales.",
  },
];

const PlusMembership = () => {
  const [option, setOption] = useState(2);
  function SwitchCase(option) {
    switch (option) {
      // case 1: //Mensual
      //   return (
      //     <PlusLayout
      //       elements={elements}
      //       price={"$10,000 MXN."}
      //       description={"Mensualidad de $6,000 MXN."}
      //     ></PlusLayout>
      //   );
      case 2: //Trimestral
        return (
          <PlusLayout
            elements={elements}
            price={"Pago inicial $17,000 MXN te cubre 3 meses."}
            description={"Mensualidad de $4,500 MXN."}
          ></PlusLayout>
        );
      case 3: //Anual
        return (
          <PlusLayout
            elements={elements}
            price={"Pago inicial $42,000 MXN te cubre 12 meses."}
            description={"Mensualidad de $3,500 MXN."}
          ></PlusLayout>
        );
      case 4: //Semestral
        return (
          <PlusLayout
            elements={elements}
            price={"Pago inicial $27,000 MXN te cubre 6 meses."}
            description={"Mensualidad $3,800 MXN."}
          ></PlusLayout>
        );
      default:
        return (
          <PlusLayout
            elements={elements}
            price={"$8,500 MXN"}
            description={"A partir del segundo mes $5,000 MXN."}
          ></PlusLayout>
        );
    }
  }
  return (
    <div className="bg-white">
      <div className="container mx-auto flex flex-col lg:flex-row text-4xl">
        {/*
         <div className=" text-neutral-50 text-right	w-full lg:w-2/12 flex-auto mb-10	lg:mb-20  ">
          <img alt="" src={basic} className="w-5/12 lg:w-[327px] mx-auto"></img>
        </div>
        */}
        <div className=" text-neutral-50 text-right	w-full lg:w-2/12 flex-auto mb-10	lg:mb-20">
          <img
            alt=""
            src={basic}
            className="object-cover  w-full lg:w-auto lg:object-none h-60  lg:h-auto"
          ></img>
        </div>
        <div className="w-full lg:w-4/6 flex-auto text-gray-700 text-center lg:text-left lg:pl-8">
          <div className="mb-5">
            <h1 className="font-bold text-center lg:text-left lg:mb-5 text-2xl lg:text-4xl">
              Franquicia PLUS
            </h1>
            <div className="flex items-center lg:space-x-12 space-y-2 lg:space-y-0 text-xl lg:text-3xl mb-8">
              {/* <div
                className={`w-full lg:w-32 cursor-pointer ${
                  option === 1
                    ? "font-bold text-primary-200 underline  underline-offset-8"
                    : " "
                } `}
                onClick={() => {
                  setOption(1);
                }}
              >
                Mensual
              </div> */}
              <div
                className={`w-full lg:w-32  cursor-pointer  ${
                  option === 2
                    ? "font-bold text-primary-200 underline  underline-offset-8"
                    : " "
                } `}
                onClick={() => {
                  setOption(2);
                }}
              >
                Trimestral
              </div>
              <div
                className={`w-full lg:w-32  cursor-pointer  ${
                  option === 4
                    ? "font-bold text-primary-200 underline  underline-offset-8"
                    : " "
                } `}
                onClick={() => {
                  setOption(4);
                }}
              >
                Semestral
              </div>
              <div
                className={`w-full lg:w-32 cursor-pointer  ${
                  option === 3
                    ? "font-bold text-primary-200 underline  underline-offset-8"
                    : " "
                } `}
                onClick={() => {
                  setOption(3);
                }}
              >
                Anual
              </div>
            </div>
            {SwitchCase(option)}

            <div className="font-bold">
              <a
                href="/membresias/formulario"
                type="submit"
                className="bg-primary-200 text-white text-xl lg:text-2xl py-3 px-4 disabled:opacity-75 mt-5 w-full md:w-auto text-center"
              >
                Llenar formulario de admisión
              </a>
            </div>
          </div>

          <br></br>
        </div>
      </div>
    </div>
  );
};

export default PlusMembership;
