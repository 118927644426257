import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CarrouselClasse = ({ classe, removeFromFavorites }) => {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 50,
          },
        }}
        navigation={true}
        modules={[Navigation, Pagination]}
        className="mySwiper3"
      >
        {classe.map((favorite, index) => (
          <SwiperSlide id={favorite.classe.id} key={index}>
            <div
              key={favorite.classe.id}
              className="border border-slate-300 h-[130px] flex items-center gap-3 lg:gap-6"
            >
              <div className="w-1/3 h-full">
                <img
                  src={favorite.course.image}
                  className="w-full h-full object-cover"
                  alt=""
                />
              </div>
              <div className="flex flex-col justify-between w-2/3 px-4 py-6">
                <div>
                  <h3 className="text-gray-700 font-bold text-sm lg:text-2xl text-left mb-2">
                    {favorite.course.name}
                  </h3>
                  {favorite.section && (
                    <p className="uppercase lg:text-lg text-left leading-5">
                      {favorite.section.name}
                    </p>
                  )}
                  <p className="lg:text-lg text-left leading-none">
                    {favorite.classe.name}
                  </p>
                </div>

                <div className="flex flex-row justify-between items-center w-full mt-3 md:mt-1">
                  <button
                    onClick={() => removeFromFavorites(favorite.classe.id)}
                  >
                    <FontAwesomeIcon
                      icon="fa-star"
                      className="text-sm text-secondary"
                      size="xl"
                    />
                  </button>
                  <p className="text-gray-500 text-center">
                    {Math.floor(favorite.classe.time / 60) +
                      ":" +
                      (favorite.classe.time % 60)}{" "}
                    min
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default CarrouselClasse;
