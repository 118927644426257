import { useEffect, useState, useContext } from "react";
import Context from "../../context/UserContext";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "react-skeleton-generator";
import { shopifyService } from "../../services/api/shopify";
import { bannersServices } from "../../services/api/banners";
import CategoryCard from "../../components/store/CategoryCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductCard from "../../components/store/ProductCard";
import BounceLoader from "react-spinners/BounceLoader";
import ProductsHistory from "../../components/store/ProductsHistory";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import plusImage from "../../assets/images/memberships/plus.png";
import dropshippingImage from "../../assets/images/memberships/dropshipping.png";

import * as _ from "lodash";
import "../../components/store/CarrouselProducts";
import CarrouselProducts from "../../components/store/CarrouselProducts";

const Index = () => {
  localStorage.removeItem("scrollPosition");

  const navigate = useNavigate();
  const { currentUser } = useContext(Context);
  const [theMostSold, setTheMostSold] = useState([]);
  const [offers, setOffers] = useState([]);
  const [viewedProducts, setViewedProducts] = useState([]);
  const [buyAgain, setBuyAgain] = useState([]);
  const [collections, setCollections] = useState([]);
  const [mainBanner, setMainBanner] = useState({});
  const [productsSearch, setProductsSearch] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLaodedCategories, setIsLoadedCategories] = useState(false);
  const [isLoadedBanner, setIsLoadedBanner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [showSearchContent, setShowSearchContent] = useState(false);

  const [title, setTitle] = useState("");
  const navigateToDetails = (id, store_id) => {
    navigate(`/tienda/${store_id}/producto/${id}`);
  };

  const handleSearchProducts = () => {
    setIsLoading(true);
    setLoadingProducts(true);
    setShowSearchContent(true);
    let params;
    params = {
      title: title,
    };
    shopifyService
      .getAllProductsByTitle(params)
      .then((response) => {
        setProductsSearch(response.data.products);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
        setLoadingProducts(false);
      });
  };

  const fetchProducts = () => {
    shopifyService
      .getAllProducts()
      .then((response) => {
        let products = response.data.products;
        products.sort(function (a, b) {
          return a.sold > b.sold ? -1 : a.sold < b.sold ? 1 : 0;
        });
        products.splice(25);
        setTheMostSold(products);
        setBuyAgain(products.slice(5, 10));
      })
      .catch((err) => {});
  };

  const fetchViewedProducts = () => {
    if (currentUser != null) {
      shopifyService
        .getViewedProducts()
        .then((response) => {
          let products = response.data.products;
          if (products) {
            setViewedProducts(products);
          }
        })
        .catch((err) => {});
    }
  };

  const fetchOfferProducts = () => {
    shopifyService
      .getOfferProducts()
      .then((response) => {
        let products = response.data.products;
        if (products) {
          setOffers(products);
        }
      })
      .catch((err) => {});
  };

  const fetchCollections = () => {
    shopifyService
      .getAllCollections()
      .then((response) => {
        let collectionsFetch = response.data.collects;
        setCollections(collectionsFetch);
      })
      .catch((err) => {})
      .finally(() => setIsLoadedCategories(true));
  };

  const fetchBanners = () => {
    bannersServices
      .getBanners()
      .then((response) => {
        setMainBanner(response.data.bannerMain);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (title.length === 0) setShowSearchContent(false);
  }, [title]);

  const navigateToCategory = (store_id, id) => {
    navigate(`/tienda/${store_id}/categoria/${id}`);
  };

  useEffect(() => {
    fetchBanners();
    fetchCollections();
    fetchProducts();
    fetchOfferProducts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let vars = [theMostSold, mainBanner];
    if (!_.isEmpty(viewedProducts)) {
      vars.push(viewedProducts);
    }
    if (vars.every((element) => !_.isEmpty(element))) {
      setIsLoaded(true);
    }
  }, [theMostSold, buyAgain, collections, viewedProducts]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let vars = [mainBanner];
    if (!_.isEmpty(viewedProducts)) {
      vars.push(viewedProducts);
    }
    if (vars.every((element) => !_.isEmpty(element))) {
      setIsLoadedBanner(true);
    }
  }, [mainBanner]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!currentUser) return;
    fetchViewedProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <div className="font-serif">
      {/* HERO */}
      <div className="container mx-auto w-full px-4 lg:px-0">
        <div className="py-2 lg:py-5">
          {isLoadedBanner ? (
            <Swiper slidesPerView={1} spaceBetween={0} className="mySwiper1">
              <SwiperSlide>
                <div className="w-full h-full pb-2 lg:pb-5">
                  {mainBanner.url ? (
                    <a href={mainBanner.url} target="_blank" rel="noreferrer">
                      <img
                        src={mainBanner.image}
                        alt=""
                        className="md:h-[550px] w-full md:object-cover mx-auto select-none"
                      />
                    </a>
                  ) : (
                    <img
                      src={mainBanner.image}
                      alt=""
                      className="lg:h-96 w-full md:object-cover mx-auto select-none"
                    />
                  )}
                </div>
              </SwiperSlide>
            </Swiper>
          ) : (
            heroSkeleton()
          )}
        </div>
        <div className="flex justify-end space-x-4 lg:space-x-8">
          <label className="relative block w-full md:w-1/2 lg:w-4/12">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <FontAwesomeIcon
                icon={["fa", "magnifying-glass"]}
                className="text-[#A9A9A9]"
                size="sm"
              />
            </span>
            <input
              className="w-full bg-white text-gray-700 placeholder:font-italic border border-slate-300 py-3 pl-12 pr-4 focus:outline-none"
              type="text"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              placeholder="Buscar productos"
              onKeyDown={(event) => {
                if (event.key === "Enter" && title.length > 0) {
                  handleSearchProducts();
                }
              }}
            />
          </label>

          <button
            onClick={() =>
              title.length > 0 && !isLoading && handleSearchProducts()
            }
            className={`py-auto ${
              title.length > 0
                ? "bg-primary-200 cursor-pointer"
                : "bg-gray-600 cursor-not-allowed"
            }  p-3 lg:px-10 text-white font-bold`}
          >
            {isLoading ? (
              <FontAwesomeIcon icon="circle-notch" spin />
            ) : (
              <span title="Escribe un producto y da click en  el botón 'Buscar'">
                Buscar
              </span>
            )}
          </button>
        </div>
      </div>

      {/* CONTENT */}
      <div className="w-full ">
        {showSearchContent && (
          <div className="container mx-auto w-full pt-2 md:pt-6 px-4 md:px-0">
            {productsSearch.length && !loadingProducts > 0 ? (
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 md:gap-5">
                {productsSearch.map((product) => (
                  <ProductCard
                    key={product.id}
                    category_id={product.shopify_store_id}
                    product={product}
                    onClick={navigateToDetails}
                  />
                ))}
              </div>
            ) : (
              <div className="mx-auto container w-full text-2xl my-10">
                {loadingProducts ? (
                  <div className="py-10 ">
                    <p className="text-center ">Buscando productos.</p>
                    <p className="text-center pb-10">Espere un momento...</p>
                    <BounceLoader
                      color={"#63abe6"}
                      loading={true}
                      cssOverride={{
                        display: "block",
                        margin: "0 auto",
                        borderColor: "red",
                        color: "blue",
                      }}
                      size={100}
                      aria-label="BeatLoader"
                      data-testid="BeatLoader"
                    />
                  </div>
                ) : (
                  <div className="mx-auto container w-full text-2xl pb-10">
                    No se encontraron resultados
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {!showSearchContent && (
          <div className="container mx-auto w-full pt-6 md:pt-10 px-10 lg:px-0">
            {/* THE MOST SOLD */}
            {isLoaded && theMostSold.length > 0 && (
              <div className="pb-4 lg:pb-16">
                <p className="text-3xl lg:text-4xl py-1 font-bold py-auto my-4 text-primary-200 text-center">
                  Lo más vendido
                </p>
                {/* PRODUCT LIST */}

                <CarrouselProducts
                  products={theMostSold}
                  productsSkeleton={() => productsSkeleton("theMostSold")}
                ></CarrouselProducts>
              </div>
            )}

            {/* OFFERS */}
            {offers.length > 0 && (
              <>
                <div className="pb-4 lg:pb-16">
                  {/* <p className='mt-10 lg:mt-2 text-gray-700 text-xl lg:text-4xl text-center lg:text-left font-bold pb-6'>
                    Ofertas
                  </p> */}
                  <p className="text-3xl lg:text-4xl py-1 font-bold py-auto my-4 text-primary-200 text-center">
                    Ofertas
                  </p>
                  {/* PRODUCT LIST */}

                  <CarrouselProducts
                    products={offers}
                    productsSkeleton={() => productsSkeleton("offers")}
                  ></CarrouselProducts>
                </div>

                <div className="text-xl grid grid-cols-1 lg:grid-cols-2 gap-5  mb-10 h-50">
                  <div className="bg-gray-200 grid grid-cols-2 pl-5">
                    <div className="my-auto space-y-5 pr-5">
                      <div className="text-center tracking-widest">
                        FRANQUICIAS
                      </div>
                      <div className="text-center font-bold">
                        PLUS SEMESTRAL
                      </div>
                      <a
                        href="https://beglobalpro.org/membresias/plus"
                        target="_blank"
                        className="w-full md:w-1/2 px-8 rounded-md text-center font-bold text-white transition ease-in-out delay-50 bg-primary-200 hover:-translate-y-1 hover:scale-110 hover:bg-primary-300 duration-300 mx-auto flex justify-center"
                      >
                        Ver más
                      </a>
                    </div>
                    <div className=" text-neutral-50 text-right h-60 w-full  flex-auto   ">
                      <img
                        alt=""
                        src={dropshippingImage}
                        className="object-none h-60 w-full"
                      ></img>
                    </div>
                  </div>

                  <div className="bg-gray-200  grid grid-cols-2 pl-5">
                    <div className="my-auto space-y-5 pr-5">
                      <div className="text-center tracking-widest">
                        FRANQUICIAS
                      </div>
                      <div className="text-center font-bold">PLUS ANUAL</div>
                      <a
                        href="https://beglobalpro.org/membresias/plus"
                        target="_blank"
                        c
                        className="w-full md:w-1/2 px-8 rounded-md text-center font-bold text-white transition ease-in-out delay-50 bg-primary-200 hover:-translate-y-1 hover:scale-110 hover:bg-primary-200 duration-300 mx-auto flex justify-center"
                      >
                        Ver más
                      </a>
                    </div>
                    <div className=" text-neutral-50 text-right h-60 w-full  flex-auto   ">
                      <img
                        alt=""
                        src={plusImage}
                        className="object-none 	h-60   w-full"
                      ></img>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* BUY AGAIN */}
            {/* PRODUCT LIST */}
            {currentUser != null && buyAgain && buyAgain.length > 0 ? (
              <>
                <div className="pb-4 lg:pb-16">
                  {/* <p className='text-gray-700 text-center text-xl lg:text-4xl font-bold pb-6'>
                    Comprar de nuevo
                  </p> */}
                  <p className="text-3xl lg:text-4xl py-2 my-4 font-bold py-auto  text-primary-200 text-center">
                    Comprar de nuevo
                  </p>
                  <div className="container mx-auto w-full">
                    {/* PRODUCT VIEWED LIST  */}
                    <CarrouselProducts
                      products={buyAgain}
                      productsSkeleton={() => productsSkeleton("buyAgain")}
                    ></CarrouselProducts>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {/* CATEGORIES */}
            <div className="pb-4 lg:pb-16">
              {isLoaded && collections.length > 0 && (
                <p className="text-3xl lg:text-4xl py-2 font-bold py-auto my-auto text-primary-200 text-center">
                  Proveedores
                </p>
              )}

              {/* CATEGORY LIST */}
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5">
                {!isLaodedCategories && productsSkeleton("categories")}
                {isLaodedCategories &&
                  collections.map((category) => (
                    <CategoryCard
                      key={category.id}
                      category={category}
                      onClick={navigateToCategory}
                    />
                  ))}
              </div>
            </div>

            {/* VIEWED PRODUCTS */}
            {currentUser != null &&
            viewedProducts &&
            viewedProducts.length > 0 ? (
              <>
                <div className="pb-4 lg:pb-16">
                  {/* <p className='text-gray-700 text-center lg:text-left text-xl lg:text-4xl font-bold pb-6'>
                    Articulos vistos
                  </p> */}
                  <p className="text-3xl lg:text-4xl py-2 font-bold py-auto my-4 text-primary-200 text-center">
                    Artículos vistos
                  </p>
                  <div className="container mx-auto w-full py-2">
                    {/* PRODUCT VIEWED LIST  */}
                    <ProductsHistory
                      products={viewedProducts}
                      productsSkeleton={() =>
                        productsSkeleton("viewedProducts")
                      }
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const heroSkeleton = () => {
  return (
    <Skeleton.SkeletonThemeProvider animation="opacity">
      <div className="pb-5">
        <div className="w-full h-96">
          <Skeleton height="100%" borderRadius="0px" />
        </div>
      </div>
    </Skeleton.SkeletonThemeProvider>
  );
};

const productsSkeleton = (section) => {
  return [...Array(5)].map((e, idx) => (
    <Skeleton.SkeletonThemeProvider key={idx} animation="opacity">
      <div key={`${section}-${idx}`} className="h-72">
        <Skeleton height="100%" borderRadius="0px" />
      </div>
    </Skeleton.SkeletonThemeProvider>
  ));
};

export default Index;
