import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Context from "../../../context/AdminUserContext";
import TableLoading from "../../../components/TableLoading";
import TableNoData from "../../../components/TableNoData";
import { adminOrdersServices } from "../../../services/api/admin/orders";
import {
  getColumnsForOrders,
  getFormattedDate,
  customStyles,
  paginationComponentOptions,
} from "../../../utils/tableOptions";

const IndexBGP = () => {
  const { currentAdminUser } = useContext(Context);

  const [orders, setOrders] = useState([]);
  const [formattedOrders, setFormattedOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchOrders = () => {
    adminOrdersServices
      .getAllOrders()
      .then((response) => {
        setOrders(response.data.orders);
      })
      .catch((err) => {});
  };

  const formatOrdersData = () => {
    let data = [];

    orders.forEach((order) => {
      data.push({
        order: <span className="font-bold text-primary-200">{order.id}</span>,
        date: getFormattedDate(order.payment_at),
        client: order.user,
        status: (
          <span className={`font-bold text-primary-200`}>Completado</span>
        ),
        detailsComponent: (
          <Link
            to={`/admin/ordenes/bgp/${order.id}`}
            className="w-full text-primary-200 hover:text-primary-300 text-center text-sm lg:text-base"
          >
            Ver detalles
          </Link>
        ),
      });
    });

    setFormattedOrders(data);
    setLoading(false);
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchOrders();
  }, [currentAdminUser]);

  useEffect(() => {
    // if (_.isEmpty(orders)) return;
    formatOrdersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <p className="text-4xl font-bold">Ordenes</p>
      </div>

      <div className="border border-gray-350 mt-12">
        <DataTable
          columns={getColumnsForOrders.filter(
            (col) => col.name !== "ACCIONES" && col.name !== "ORIGEN"
          )}
          data={formattedOrders}
          noDataComponent={<TableNoData />}
          progressPending={loading}
          progressComponent={<TableLoading />}
          customStyles={customStyles}
          pagination
          paginationComponentOptions={paginationComponentOptions}
        />
      </div>
    </div>
  );
};

export default IndexBGP;
