import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";

import { notifications } from "../../../../utils/notifications";
import { adminCoursesServices } from "../../../../services/api/admin/courses";

const NewSection = () => {
  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
  });

  const navigate = useNavigate();
  const params = useParams();

  const initialValues = {
    name: "",
  };

  const handleSubmit = async (values) => {
    let bodyFormData = new FormData();

    bodyFormData.append("uuid", params.uuid);
    bodyFormData.append("name", values.name);

    await adminCoursesServices
      .createSection(bodyFormData)
      .then((response) => {
        notifications.success(response.data.message);
        navigate(`/admin/cursos/editar/${params.uuid}`);
      })
      .catch((err) => {});
  };

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex-col">
        <button
          className="mr-auto text-primary-200 mb-5"
          onClick={() => navigate(-1)}
        >
          Regresar
        </button>
        <div className="flex flex-col md:flex-row justify-between items-center">
          <p className="text-2xl lg:text-4xl font-bold">Nueva sección</p>
        </div>
      </div>

      <div className="mt-4 lg:mt-12">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-x-10">
                <div className="mb-4 lg:mb-9 text-xl lg:text-3xl text-gray-550 md:col-span-2 text-center lg:text-left">
                  <p>Nombre de la sección</p>
                  <Field
                    name="name"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="w-full">
                  <button
                    type="submit"
                    className="w-full md:w-56 bg-primary-200 text-white text-xl lg:text-2xl py-3 px-5 disabled:opacity-75 md:mt-9"
                  >
                    Agregar
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default NewSection;
