import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import BasicModal from "../../../components/Modals/AdmissionModal";
import { notifications } from "../../../utils/notifications";
import moment from "moment";

import "moment/locale/es";
import { adminAdmissionsService } from "../../../services/api/admin/admissions";
import YNCheckboxEdit from "../../../components/forms/YNCheckboxEdit";
import MultipleCheckboxes from "../../../components/forms/MultipleCheckboxesEdit";
import RateCheckboxesEdit from "../../../components/forms/RateCheckboxesEdit";
const url = window.location.origin;

export let navigate = {
  PREVIOUS: "PREV",
  NEXT: "NEXT",
  TODAY: "TODAY",
  DATE: "DATE",
};
const initialValues = {
  name: "",
  description: "",
  occupation: "Empleado",
};
const formSchema = Yup.object().shape({});

const Application = () => {
  const navigate = useNavigate();
  const [admission, setAdmission] = useState({});
  const params = useParams();
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState();

  const getAnsweredAdmissions = () => {
    adminAdmissionsService
      .getAdmissionApplication(params.id)
      .then((response) => {
        setAdmission(response.data.admission);
      })
      .catch((err) => {});
  };

  const multipleSelectOne = [
    {
      question: "Actualmente tú: (situación sentimental)",
      name: "emotionalSituation",
      check: admission.emotionalSituation,

      checkboxes: [
        {
          text: "Casado",
          value: "1",
        },
        {
          text: "Soltero",
          value: "2",
        },
        {
          text: "Unión libre",
          value: "3",
        },
        {
          text: "Vives con tus padres",
          value: "4",
        },
        {
          text: "Noviazgo",
          value: "5",
        },
      ],
    },
    {
      question: "¿Tienes hijos?",
      name: "hasChildres",
      check: admission.hasChildres,
      checkboxes: [
        {
          text: "No, no tengo",
          value: 1,
        },
        {
          text: "Si, edad preescolar",
          value: 2,
        },
        {
          text: "Si, edad primaria",
          value: 3,
        },
        {
          text: "Si, edad secundaria",
          value: 4,
        },
        {
          text: "Si, edad preparatoria",
          value: 5,
        },
        {
          text: "Si, edad universidad o más",
          value: 6,
        },
      ],
    },

    {
      question: "Selecciona las herramientas que NO sabes usar:",
      name: "userWeakTools",
      check: admission.userWeakTools,
      box: true,
      checkboxes: [
        {
          text: "CANVA",
          value: "1",
        },
        {
          text: "Photoshop",
          value: "2",
        },
        {
          text: "Excel",
          value: "3",
        },
        {
          text: "Tiktok",
          value: "4",
        },
        {
          text: "Facebook",
          value: "5",
        },
        {
          text: "Instagram",
          value: "6",
        },
        {
          text: "Whatsapp",
          value: "7",
        },
      ],
    },
    {
      checkFull: true,
      question: "¿Has vendido por Mercado Libre?",
      name: "hasSoldMercadoLibre",
      check: admission.hasSoldMercadoLibre,

      checkboxes: [
        {
          text: "Si, tengo reputación verde o soy mercado lider",
          value: 1,
        },
        {
          text: "Si, pero he vendido muy poco, no tengo reputación",
          value: 2,
        },
        {
          text: "No, pero me interesa",
          value: 3,
        },
        {
          text: "No, y no me interesa",
          value: 4,
        },
      ],
    },
    {
      checkFull: true,
      question: "¿Has vendido por Amazon",
      name: "hasSoldAmazon",
      check: admission.hasSoldAmazon,
      checkboxes: [
        {
          text: "Si, por FBA",
          value: 1,
        },
        {
          text: "Si, por FBM",
          value: 2,
        },
        {
          text: "No, pero me interesa",
          value: 3,
        },
        {
          text: "No, y no me interesa",
          value: 4,
        },
      ],
    },
    {
      checkFull: true,
      question: "Nivel de estudios que tienes:",
      name: "educationalLevel",
      check: admission.educationalLevel,
      checkboxes: [
        {
          text: "Primaria",
          value: 1,
        },
        {
          text: "Secundaria",
          value: 2,
        },
        {
          text: "Preparatoria",
          value: 3,
        },
        {
          text: "Universidad trunca",
          value: 4,
        },
        {
          text: "Universidad completada",
          value: 5,
        },
        {
          text: "Maestría",
          value: 6,
        },
        {
          text: "Doctorado",
          value: 7,
        },
      ],
    },
    {
      checkFull: true,
      question: "Nivel de conocimientos en computación:",
      name: "computerKnowledge",
      check: admission.computerKnowledge,
      checkboxes: [
        {
          text: "Nulo",
          value: 1,
        },
        {
          text: "Principiante",
          value: 2,
        },
        {
          text: "Intermedio",
          value: 3,
        },
        {
          text: "Avanzado",
          value: 4,
        },
      ],
    },
  ];

  const array = [
    {
      question:
        "Las personas cercanas a ti, ¿Te consideran una persona problemática?",
      name: "problematicPerson",
      check: admission.problematicPerson,
    },
    {
      question:
        "¿Te gustaría participar en un discupulado de la iglesia cristiana donde se enseña sobre la biblia?",
      name: "churchInvitation",
      check: admission.churchInvitation,
    },
    {
      question:
        "¿Estás de acuerdo con nuestra política de privacidad? Aquí las lees: ",
      link: url + "/politicas",
      linkTitle: "Políticas",

      name: "agreePrivacyPolicy",
      check: admission.agreePrivacyPolicy,
    },
    {
      question:
        "¿Estás de acuerdo con nuestros términos y condiciones de la empresa? Aquí los lees: ",
      link: url + "/terminos-y-condiciones",
      linkTitle: "Términos y condiciones",
      name: "agreeTerms",
      check: admission.agreeTerms,
    },
  ];
  useEffect(() => {
    // Este endpoint ya esta listo, solo
    // descomenta lo de abajo cuando ya haya una solicitud para que traiga la informacion
    getAnsweredAdmissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = async (option, id, email) => {
    let bodyFormData = new FormData();
    bodyFormData.append("_method", "PUT");
    bodyFormData.append("status", option);
    bodyFormData.append("email", email);

    await adminAdmissionsService
      .answerAdmissionApplication(id, bodyFormData)
      .then((response) => {
        notifications.success(response.data.message);
        setShowModal(false);
        navigate(-1);
      })
      .catch((err) => {});
  };
  function OpenModal(id, email) {
    setModalContent(
      <div className="px-12 text-2xl text-center" role="dialog">
        <div className="my-10 ml-0 font-bold lg:text-left text-center">
          {" "}
          Responder solicitud{" "}
        </div>
        <textarea
          id="w3review"
          name="w3review"
          rows="4"
          cols="50"
          className="mb-5 w-full h-36 md:h-56 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
          placeholder="Mensaje opcional"
        ></textarea>
        <div className="text-center lg:text-left mb-5 leading-none">
          La respuesta será enviada al correo del candidato.
        </div>
        <div className="flex-wrap flex flex-row justify-between">
          <div className="w-full flex flex-col md:flex-row justify-between">
            <button
              type="submit"
              onClick={() => handleSubmit("Rechazado", id, email)}
              className="md:mb-10 w-full md:w-40 bg-white text-primary-200 border-2 border-primary-200 text-2xl py-3 px-5 disabled:opacity-75 mt-5"
            >
              Rechazar
            </button>
            <button
              type="submit"
              onClick={() => handleSubmit("Aceptado", id, email)}
              className="md:mb-10 w-full md:w-40 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75 mt-5"
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    );
    setShowModal(true);
  }

  return (
    <div className="container mx-auto text-gray-700">
      <BasicModal
        visible={showModal}
        setVisible={setShowModal}
        modalContent={modalContent}
      />
      <div className="text-center lg:text-left flex flex-wrap sm:justify-between">
        <p className="text-4xl font-bold mx-auto sm:mx-0">Solicitud</p>
        {admission.createdAt && (
          <p className="text-4xl mx-auto sm:mx-0">
            {moment(admission.createdAt).format("DD/MM/YYYY HH:mm")}
          </p>
        )}
      </div>

      <div className="mt-12">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={(values) => ""}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-xl text-gray-700">
                  <p>Nombre completo</p>
                  <Field
                    name="name"
                    value={admission.name}
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-9 text-xl text-gray-700">
                  <p>Correo</p>
                  <Field
                    name="email"
                    value={admission.email}
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-xl text-gray-700">
                  <p>Número de Whatsapp</p>
                  <Field
                    name="phone"
                    value={admission.phone}
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-9 text-xl text-gray-700">
                  <p>Edad</p>
                  <Field
                    name="age"
                    value={admission.age}
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="age"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-xl text-gray-700">
                  <p>
                    Correo de la persona que te está atendiendo y envió
                    formulario
                  </p>
                  <Field
                    name="person"
                    value={admission.personAssists}
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="person"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-9 text-xl text-gray-700">
                  <p>País</p>
                  <Field
                    name="country"
                    value={admission.country ? admission.country : "N/A"}
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="country"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-9 text-xl text-gray-700">
                  <p>Estado</p>
                  <Field
                    name="state"
                    value={admission.state}
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="state"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-9 text-xl text-gray-700">
                  <p>Ciudad</p>
                  <Field
                    name="city"
                    value={admission.city}
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="city"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-9 text-xl text-gray-700">
                  <p>Actualmente eres:</p>
                  <Field
                    as="select"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    name="occupation"
                    value={admission.occupation}
                  >
                    <option value="Empleado">Empleado</option>
                    <option value="Emprendedor">Emprendedor</option>
                    <option value="Ama de casa">Ama de casa</option>
                    <option value="Estudiante">Estudiante</option>
                    <option value="Jubilado">Jubilado</option>
                    <option value="Otros">Otros</option>
                  </Field>
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10"></div>

              <div className="mb-9 text-xl text-gray-700">
                <p>Descripción de lo que haces actualmente y horario:</p>
                <Field
                  as="textarea"
                  value={admission.occupationDescription}
                  name="ScheduleDescription"
                  className="w-full h-48 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                />
                <ErrorMessage
                  name="ScheduleDescription"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>
              <YNCheckboxEdit
                question={"¿Has emprendido algo?"}
                name={"hasUndertaken"}
                check={admission.hasUndertaken}
              />
              <MultipleCheckboxes
                question="¿Prefieres resultados a largo o corto plazo?"
                name="results"
                check={admission.results}
                checkboxes={[
                  {
                    text: "Largo plazo",
                    value: "Largo",
                  },
                  {
                    text: "Corto plazo",
                    value: "Corto",
                  },
                ]}
                checkFull={true}
              />
              <YNCheckboxEdit
                question={"¿Te da miedo perder dinero?"}
                name={"hasFearLostMoney"}
                check={admission.hasFearLostMoney}
              />
              <YNCheckboxEdit
                question={"¿Te adaptas bien a los cambios?"}
                name={"acceptChanges"}
                check={admission.acceptChanges}
              />

              <div className="mb-9 text-xl text-gray-700">
                <p>
                  ¿Cuántas horas al día y cuántos días a la semana puedes
                  dedicarle a tu nuevo negocio con el sistema Be Global Pro?
                </p>
                <Field
                  value={admission.timeToInvest}
                  name="scheduleWork"
                  className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none hidden lg:block"
                  type="text"
                />
                <Field
                  as="textarea"
                  value={admission.timeToInvest}
                  name="scheduleWork"
                  className="w-full h-48 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none lg:hidden"
                />
              </div>

              <MultipleCheckboxes
                question="¿Cuánto te gustaría GENERAR DE UTILIDAD EN TUS PRIMEROS 30 DÍAS dentro del sistema Be Global Pro?"
                message={true}
                name="firstMonth"
                check={admission.firstMonth}
                checkboxes={[
                  {
                    text: "Recuperar inversión",
                    value: 1,
                  },
                  {
                    text: "+$5,000",
                    value: 2,
                  },
                  {
                    text: "+$10,000",
                    value: 3,
                  },
                  {
                    text: "+$20,000",
                    value: 4,
                  },
                  {
                    text: "+$40,000",
                    value: 5,
                  },
                  {
                    text: "+$80,000",
                    value: 6,
                  },
                  {
                    text: "Aprendizaje",
                    value: 7,
                  },
                ]}
                checkFull={true}
              />
              <YNCheckboxEdit
                question={"¿Sabes trabajar en equipo?"}
                name={"hasCollaborative"}
                check={admission.hasCollaborative}
              />
              <YNCheckboxEdit
                question={"¿Tienes iniciativa?"}
                name={"hasInitiative"}
                check={admission.hasIniciative}
              />
              <YNCheckboxEdit
                question={"¿Eres perseverante?"}
                name={"hasPerseverance"}
                check={admission.hasPerseverance}
              />
              <YNCheckboxEdit
                question={"¿Te rindes fácilmente?"}
                name={"easyGiveUp"}
                check={admission.easyGiveUp}
              />

              <div className="mb-9 text-xl text-gray-700">
                <p>¿Por qué quieres tener un negocio digital?</p>
                <Field
                  as="textarea"
                  value={admission.whyDigitalBusiness}
                  name="whyDigitalBussiness"
                  className="w-full h-30 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                />
                <ErrorMessage
                  name="whyDigitalBussiness"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>
              <YNCheckboxEdit
                question={"¿Puedes enfrentarte al fracaso?"}
                name={"acceptFailure"}
                check={admission.acceptFailure}
              />
              <YNCheckboxEdit
                question={"¿Piensas comprometerte en este negocio?"}
                name={"hasBussinessCommitment"}
                check={admission.hasBusinessCommitment}
              />
              <YNCheckboxEdit
                question={"¿Estás listo para convertirte en tu propio jefe?"}
                name={"becomeOwnBoss"}
                check={admission.becomeOwnBoss}
              />

              <div className="mb-9 text-xl text-gray-700">
                <p>
                  El negocio lo harías solo/a o con alguien más en equipo?,
                  ¿Quién?
                </p>
                <Field
                  as="textarea"
                  value={admission.businessWith}
                  name="workingWith"
                  className="w-full h-30 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                />
                <ErrorMessage
                  name="workingWith"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>
              {multipleSelectOne.map((value) => (
                <MultipleCheckboxes
                  question={value.question}
                  name={value.name}
                  checkboxes={value.checkboxes}
                  check={value.check}
                  checkFull={true}
                  box={value.box}
                />
              ))}

              <RateCheckboxesEdit
                question="Del 1 al 10 qué tanto sabes de Instagram, siendo 1 nada y 10 experto. "
                name="instagramKnowledge"
                check={admission.instagramKnowledge}
              ></RateCheckboxesEdit>
              <RateCheckboxesEdit
                question="Del 1 al 10 qué tanto sabes de Facebook Ads, siendo 1 nada y 10 experto.(Facebook Ads es donde se hace publicidad pagada) "
                check={admission.facebookAdsKnowledge}
                name="facebookAdsKnowledge"
              ></RateCheckboxesEdit>
              <RateCheckboxesEdit
                question="Del 1 al 10 qué tanto sabes de CANVA siendo 1 nada y 10 experto."
                name="canvasKnowledge"
                check={admission.canvasKnowledge}
              ></RateCheckboxesEdit>
              <YNCheckboxEdit
                question={"¿Has usado WhatsApp business?"}
                name={"hasUsedWhatsAppBussiness"}
                check={admission.hasUsedWhatsAppBusiness}
              />
              <YNCheckboxEdit
                question={"¿Has pagado publicidad en Google?"}
                name={"hasPayedGoogleAds"}
                check={admission.hasPayedGoogleAds}
              />
              <YNCheckboxEdit
                question={"¿Eres creativo?"}
                name={"creativePerson"}
                check={admission.creativePerson}
              />
              <YNCheckboxEdit
                question={"¿Sabes seguir indicaciones?"}
                name={"followIndications"}
                check={admission.knowFollowIndications}
              />

              <div className="mb-9 text-xl text-gray-700">
                <p>
                  Si le preguntara a tu último jefe sobre ti, ¿Qué me diría?
                </p>
                <Field
                  name="opinionLastBoss"
                  value={admission.opinionLastBoss}
                  className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none hidden lg:block"
                  type="text"
                />
                <Field
                  as="textarea"
                  value={admission.opinionLastBoss}
                  name="opinionLastBoss"
                  className="w-full h-48 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none lg:hidden"
                />
              </div>
              <YNCheckboxEdit
                question={
                  "¿Estás consciente que los resultados del negocio dependen 100% de ti?"
                }
                name={"resultsAgree"}
                check={admission.resultsAgree}
              />
              <YNCheckboxEdit
                question={
                  "¿Estás consciente que nosotros te damos la receta y tú tienes que hacerla?"
                }
                name={"recipeAgree"}
                check={admission.recipeAgree}
              />
              <YNCheckboxEdit
                question={
                  "¿Cuentas con la inversión de la membresía en este momento?"
                }
                name={"hasMembershipMoney"}
                check={admission.hasMoneyMembership}
              />
              <MultipleCheckboxes
                question="¿Qué membresía quieres adquirir?"
                name="membership"
                check={admission.membership}
                checkboxes={[
                  {
                    text: "Anual plus de $42,000",
                    value: 5,
                  },
                  {
                    text: "Semestral plus de $27,000",
                    value: 7,
                  },
                  {
                    text: "Trimestral plus de $17,000",
                    value: 4,
                  },
                  {
                    text: "Mensual plus de $10,000",
                    value: 3,
                  },
                  {
                    text: "Dropshipping $15,000",
                    value: 2,
                  },
                  {
                    text: "Emprendedor 6,000",
                    value: 9,
                  },
                ]}
                checkFull={true}
              />
              <YNCheckboxEdit
                question={"¿Estás dado de alta en hacienda?"}
                name={"SatRegistered"}
                check={admission.satRegistered}
              />
              <YNCheckboxEdit
                question={
                  "Si quisieras vender desde el inicio por Mercado libre o Amazon, ¿Tienes la capacidad económica de pagar cada producto que se te vaya vendiendo?"
                }
                name={"sellAllProducts"}
                check={admission.hasCapital}
              />
              <div className="mb-9 text-xl text-gray-700">
                <p>¿Cuál es tu idea y concepto sobre Be Global Pro?</p>
                <Field
                  as="textarea"
                  name="ideaAndConcept"
                  value={admission.ideaAndConcept}
                  className="w-full h-30 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                />
                <ErrorMessage
                  name="ideaAndConcept"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>
              <div className="mb-9 text-xl text-gray-700">
                <p>¿Qué esperas de Be Global Pro?</p>
                <Field
                  as="textarea"
                  value={admission.expectation}
                  name="expectation"
                  className="w-full h-30 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                />
                <ErrorMessage
                  name="expectation"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>
              <YNCheckboxEdit
                question={"¿Eres ordenado?"}
                name={"organizedPerson"}
                check={admission.organizedPerson}
              />
              <YNCheckboxEdit
                question={"¿Hablarías frente a la cámara?"}
                name={"talkToCamera"}
                check={admission.talkToCamera}
              />

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-xl text-gray-700">
                  <p>¿Qué celular tienes? Modelo exacto</p>
                  <Field
                    name="phoneModel"
                    value={admission.phoneModel}
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="phoneModel"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-9 text-xl text-gray-700">
                  <p>¿Cuentas con computadora? ¿Rápida, lenta o normal?</p>
                  <Field
                    as="select"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    name="computer"
                    value={admission.computer}
                  >
                    <option value="1">No, no cuento con una</option>
                    <option value="2">Si, una normal</option>
                    <option value="3">Si, una lenta</option>
                    <option value="4">Si, una rapida</option>
                  </Field>
                  <ErrorMessage
                    name="computer"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>
              <YNCheckboxEdit
                question={"¿Confías en ti mismo?"}
                name={"trustYourself"}
                check={admission.trustYourself}
              />
              <div className="mb-9 text-xl text-gray-700">
                <p>Cuales son tus 3 principales valores como persona:</p>
                <Field
                  as="textarea"
                  value={admission.personValues}
                  name="personVales"
                  className="w-full h-30 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                />
                <ErrorMessage
                  name="personVales"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>

              <div className="mb-9 text-xl text-gray-700">
                <p>
                  Déjanos 4 referencias (2 laborales y 2 personales) en caso de
                  ser necesario les llamaremos o enviaremos WhatsApp para pedir
                  referencia sobre ti.
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-xl text-gray-700 col-end-2">
                  <p className="mb-0 md:mb-7 lg:mb-0">
                    Primera referencia laboral
                  </p>
                  <Field
                    value={admission.workReferenceName1}
                    name="workReferenceName1"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="workReferenceName1"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-9 text-xl text-gray-700">
                  <p>WhatsApp</p>
                  <Field
                    value={admission.workReferencePhone1}
                    name="workReferencePhone1"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="workReferencePhone1"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-xl text-gray-700 col-end-2">
                  <p className="mb-0 md:mb-7 lg:mb-0">
                    Segunda referencia laboral
                  </p>
                  <Field
                    value={admission.workReferenceName2}
                    name="workReferenceName2"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="workReferenceName2"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-9 text-xl text-gray-700">
                  <p>WhatsApp</p>
                  <Field
                    value={admission.workReferencePhone2}
                    name="workReferencePhone2"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="workReferencePhone2"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-xl text-gray-700 col-end-2">
                  <p className="mb-0 md:mb-7 lg:mb-0">
                    Primera referencia personal
                  </p>
                  <Field
                    value={admission.personalReferenceName1}
                    name="personalReferenceName1"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    value={admission.personalReferenceName1}
                    name="personalReferenceName1"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-9 text-xl text-gray-700">
                  <p>WhatsApp</p>
                  <Field
                    value={admission.personalReferencePhone1}
                    name="personalReferencePhone1"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="personalReferencePhone1"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-xl text-gray-700 col-end-2">
                  <p className="mb-0 md:mb-7 lg:mb-0">
                    Segunda referencia personal
                  </p>
                  <Field
                    value={admission.personalReferenceName2}
                    name="personalReferenceName2"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    value={admission.personalReferenceName2}
                    name="personalReferenceName2"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-9 text-xl text-gray-700">
                  <p>WhatsApp</p>
                  <Field
                    value={admission.personalReferencePhone2}
                    name="personalReferencePhone2"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="personalReferencePhone2"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>

              {array.map((value) => (
                <YNCheckboxEdit
                  question={value.question}
                  name={value.name}
                  link={value.link}
                  linkTitle={value.linkTitle}
                  check={value.check}
                />
              ))}

              {/* BUTTONS */}
              {!admission.status && (
                <div className="w-full flex justify-end mt-5">
                  <button
                    type="submit"
                    onClick={() => OpenModal(admission.id, admission.email)}
                    className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75 mt-5"
                  >
                    Responder
                  </button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Application;
