const Prices = ({ text, money, color, sub, add }) => {
  return (
    <div>
      <div
        className={`grid grid-cols-[1fr,1fr,auto,1fr,1fr,auto] gap-1 text-left`}
      >
        <div>{text}: </div>
        <div className={`text-center ${color}`}>
          {sub && "-"} {add && "+"}
        </div>
        <div className={`text-left ${color}`}>$</div>
        <div
          className={`text-right pr-4 md:pr-2 2xl:pr-1 ${color} col-span-2 `}
        >
          {money.toLocaleString("en-US", {
            minimumFractionDigits: 2,
          })}{" "}
        </div>
        <div className={`text-left ${color}`}>MXN</div>
      </div>
    </div>
  );
};

export default Prices;
