/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext, useState } from "react";
 import { useNavigate} from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as _ from "lodash";

import Context from "../../../context/AdminUserContext";

 import { notifications } from "../../../utils/notifications";
 import { locationServices } from "../../../services/api/admin/locations";

const EditLocation = () => {
  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
      country: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
      street_and_number: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
      apartament: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
      state: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
      city: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
      phone: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
      postal_code: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
      description: Yup.string()
      .required("Campo requerido")

  });

  const { currentAdminUser } = useContext(Context);
   const navigate = useNavigate();
const [location, setLocation] = useState()
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    image: null,
  });

   useEffect(() => {
    if (!_.isEmpty(location)) {
       setInitialValues({
         name: location.name || "",
         description: location.description || "",
         country: location.country || "",
         street_and_number: location.street_and_number || "",
         apartament: location.apartament || "",
         state: location.state || "",
         city: location.city || "",
         phone: location.phone || "",
         postal_code: location.postal_code || "",



     });
    }
   }, [location]);

  const fetchCollection = () => {
    locationServices
       .getLocations()
       .then((response) => {
         setLocation(response.data.location)
       })
       .catch((err) => {});
   };

   useEffect(() => {
   if (!currentAdminUser) return;
   fetchCollection();
   }, [currentAdminUser]);


  const handleSubmit = async (values) => {
    let bodyFormData = new FormData();
    bodyFormData.append("_method", "PUT");
    bodyFormData.append("name", values.name)
    bodyFormData.append("description", values.description)
    bodyFormData.append("country", values.country)
    bodyFormData.append("street_and_number", values.street_and_number)
    bodyFormData.append("postal_code", values.postal_code)
    bodyFormData.append("apartament", values.apartament)
    bodyFormData.append("state", values.state)
    bodyFormData.append("city", values.city)
    bodyFormData.append("phone", values.phone)
    await locationServices
      .updateLocation( bodyFormData)
      .then((response) => {
         notifications.success(response.data.message);
        navigate("/admin/locacion");
       })
       .catch((err) => {});
  };

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <p className="text-4xl font-bold">Locación</p>
      </div>

      <div className="mt-12">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-3xl text-gray-550">
                  <p>Nombre</p>
                  <Field
                    name="name"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-1 gap-x-10">
                <div className="mb-9 text-3xl text-gray-550">
                  <p>Descripción</p>
                  <Field
                        as="textarea"
                        name="description"
                        className="w-full h-48 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="text-red-400 font-bold text-xl"
                      />
                </div>
              </div>
              <p className="text-3xl text-gray-700 font-bold mb-5">Dirección</p>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-3xl text-gray-550">
                  <p>Pais/Región</p>
                  <Field
                    name="country"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="country"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-3xl text-gray-550">
                  <p>Calle y número</p>
                  <Field
                    name="street_and_number"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="street_and_number"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-3xl text-gray-550">
                  <p>Apartamento, suite, etc.</p>
                  <Field
                    name="apartament"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="apartament"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-10">
                <div className="mb-9 text-3xl text-gray-550">
                  <p>Código postal</p>
                  <Field
                    name="postal_code"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="number"
                  />
                  <ErrorMessage
                    name="postal_code"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>

                <div className="mb-9 text-3xl text-gray-550">
                  <p>Ciudad</p>
                  <Field
                    name="city"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="city"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>

                <div className="mb-9 text-3xl text-gray-550">
                  <p>Estado</p>
                  <Field
                    name="state"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="state"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-3xl text-gray-550">
                  <p>Teléfono</p>
                  <Field
                    name="phone"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>
            
              <div className="w-full flex justify-end">
                <button
                  type="submit"
                  className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75"
                >
                  Guardar
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditLocation;
