import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";

const ReplyQuestionContent = ({
  isQuestion = false,
  handleReply,
  question,
}) => {
  const formSchema = Yup.object().shape({
    qc_content: Yup.string().required("El campo es requerido"),
  });

  const initialValues = {
    qc_content: "",
  };

  const handleSubmit = ({ qc_content }, resetForm) => {
    handleReply(qc_content, resetForm);
  };

  return (
    <div className="text-gray-700">
      <div className="p-5 lg:p-10 md:px-20 py-7 border-b border-b-gray-350 text-center md:text-left">
        <p className="text-2xl lg:text-3xl font-bold">
          Responder {isQuestion ? "Pregunta" : "Comentario"}
        </p>
      </div>
      <div className="px-10 py-10 md:px-20 ">
        <div className="flex flex-col md:flex-row gap-y-5 md:gap-y-0">
          <div className="mx-auto md:mr-5 md:ml-0">
            <div className="w-16 h-16 rounded-full bg-[#D8D8D8]">
              <img
                src={question.user && question.user.image}
                alt=""
                className="rounded-full w-full h-full object-cover"
                style={{ backgroundColor: "#D8D8D8" }}
              />
            </div>
          </div>
          <div className="flex flex-col text-xl md:text-2xl text-center md:text-left gap-y-2 lg:gap-y-5 md:gap-y-0">
            <div className="flex flex-col md:flex-row items-center text-[#9F9F9F]">
              <p className="mr-3">{question.user.name}</p>
              <span className="text-lg md:text-xl text-primary-200">
                {question.course.name}
              </span>
            </div>
            <p>{question.question}</p>
          </div>
        </div>

        <div className="mt-10">
          <Formik
            initialValues={{
              qc_content: "",
            }}
            validationSchema={formSchema}
            onSubmit={(values, { resetForm }) =>
              handleSubmit(values, () => resetForm(initialValues))
            }
          >
            <Form>
              <Field
                name="qc_content"
                className="w-full h-32 md:h-64 border border-gray-400 text-gray-700 placeholder:text-gray-400 text-lg p-4 resize-none focus:outline-none"
                as="textarea"
                placeholder="Tu mensaje"
              />
              <ErrorMessage
                name="qc_content"
                component="div"
                className="text-red-400 font-bold text-base"
              />
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-primary-200 text-center text-xl lg:text-2xl py-2 px-8 text-white font-bold w-full md:w-auto mt-4"
                >
                  Responder
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ReplyQuestionContent;
