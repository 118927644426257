import React from 'react';
import { useEffect, useContext, useState } from "react";
import Context from "../../../../context/AdminUserContext";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { adminCoursesServices } from "../../../../services/api/admin/courses";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

registerLocale("es", es);
const Statistics = () => {
  const params = useParams();
  const [course, setCourse] = useState(null);
  const [user, setUser] = useState(null);
  const [courseInProgress, setCourseInProgress] = useState(0);
  const [coursesHours, setCoursesHours] = useState(0);
  const [coursesDays, setCoursesDays] = useState([]);
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([])

  const options = {
    scales: {
      x: {
        ticks: {
          font: {
            size: 18,
          },

        }
      },
      y: {
        ticks: {
          font: {
            size: 18,
          },

        }
      }
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
        labels: {
          // This more specific font property overrides the global property
          font: {
            size: 20
          }
        }
      },
      title: {
      },
      fontStyle: "bold",
    },
  };


  const dataDays = {
    labels,
    datasets: [

      {
        label: 'Horas de estudio',
        data: data,
        borderColor: '#63abe6',
        backgroundColor: '#63abe6',
        pointRadius: 8,
      },
    ],
  };

  const fetchStatistics = () => {
    adminCoursesServices
      .getStaticsByCourse(params.uuid, params.id)
      .then((response) => {
        setCourse(response.data.course)
        setCourseInProgress((response.data.progress).toLocaleString("en-US"))

        setUser(response.data.user)
        setCoursesDays(response.data.timePerDay)
      })
      .catch((err) => { });
  };

  const fetchCoursesHours = () => {
    let totalHours = 0;
    if (coursesDays) {
      coursesDays.forEach(element => {
        totalHours = totalHours + parseFloat(element.seconds);
      });
    }
    
    setCoursesHours(parseFloat(((totalHours / 60) / 60).toFixed(1)).toLocaleString("en-US"))
  };

  const changeDate = (option) => {
    var list = [];
    var obj = {};
    var current = "";
    switch (option) {

      case "currentWeek":
        const startDay = moment().startOf('week');
        const endDay = moment().endOf('week');
        const startDay2 = moment().startOf('week').format('YYYY-DD-MM');
        const endDay2 = moment().endOf('week').format('YYYY-DD-MM');
        //Creamos el objeto
        for (current = startDay; current <= endDay; current.add(1, 'd')) {
          list.push(current.format("dddd"))
        }
        let weekArray = moment.weekdays()
        weekArray.forEach(element => {
          obj[element] = 0;

        });
        let resultsDay = [];
        let daysDays = []
        let everyWeek = [];
        coursesDays.forEach(day => {
          let weekDay = moment(day.date).format('YYYY-DD-MM')

          if (weekDay >= startDay2 && weekDay <= endDay2) {
            everyWeek.push(day)
          } else {

          }
        });
        everyWeek.forEach(element => {
          if (obj[moment(element.date).format('dddd')]) {
            obj[moment(element.date).format('dddd')] += parseFloat((element.seconds / 60) / 60);

          } else {
            obj[moment(element.date).format('dddd')] = parseFloat((element.seconds / 60) / 60);
          }

        });


        for (const [key, value] of Object.entries(obj)) {
          resultsDay.push(value)
          daysDays.push(key)
        }



        setLabels(daysDays)
        setData(resultsDay)

        break;
      case "currentMonth":
        const startOfMonth = moment().startOf('month');
        const endOfMonth = moment().endOf('month');
        const startOfMonth2 = moment().startOf('month').format('DD-MM-YYYY');
        const endOfMonth2 = moment().endOf('month').format('DD-MM-YYYY');
        //Creamos el objeto
        for (current = startOfMonth; current <= endOfMonth; current.add(1, 'd')) {
          list.push(current.format("D"))
        }
        list.forEach(element => {
          obj[element] = 0;
        });
        let resultsMonth = [];
        let daysMonth = []
        let everyDay = [];

        coursesDays.forEach(day => {
          let weekDay = moment(day.date).format('DD-MM-YYYY')
          if (weekDay > startOfMonth2 && weekDay < endOfMonth2) {

            everyDay.push(day)
          }
        });


        everyDay.forEach(element => {
          if (obj[moment(element.date).format('D')]) {
            obj[moment(element.date).format('D')] += parseFloat((element.seconds / 60) / 60);

          } else {
            obj[moment(element.date).format('D')] = parseFloat((element.seconds / 60) / 60);
          }
        });



        for (const [key, value] of Object.entries(obj)) {
          daysMonth.push(key)
          resultsMonth.push(value)
        }


        setLabels(daysMonth)
        setData(resultsMonth)

        break;
      case "annual":
        const startYear = moment().startOf('year');
        const endYear = moment().endOf('year');
        const startYear2 = moment().startOf('month').format('DD-MM-YYYY');
        const endYear2 = moment().endOf('month').format('DD-MM-YYYY');
        //Creamos el objeto
        for (current = startYear; current <= endYear; current.add(1, 'd')) {
          list.push(current.format("MMM"))
        }
        list.forEach(element => {
          obj[element] = 0;
        });


        let resultsYear = [];
        let daysYear = []
        let everyYear = [];
        coursesDays.forEach(day => {
          let weekDay = moment(day.date).format('DD-MM-YYYY')
          if (weekDay > startYear2 && weekDay < endYear2) {
            everyYear.push(day)
          }
        });
        everyYear.forEach(element => {
          if (obj[moment(element.date).format('MMM')]) {
            obj[moment(element.date).format('MMM')] += parseFloat((element.seconds / 60) / 60);

          } else {
            obj[moment(element.date).format('MMM')] = parseFloat((element.seconds / 60) / 60);
          }

        });

        for (const [key, value] of Object.entries(obj)) {
          resultsYear.push(value)
          daysYear.push(key)
        }

        setLabels(daysYear)
        setData(resultsYear)
        break;
      default:
        break;
    }
  };

  const { currentAdminUser } = useContext(Context);

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchStatistics();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAdminUser]);

  useEffect(() => {
    if (!currentAdminUser) return;
    if (coursesDays.length > 0) {
      changeDate("currentWeek")

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coursesDays]);

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchCoursesHours()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coursesDays]);
  return (
    <>
      <div >
        <p className="pb-5 my-auto text-center lg:text-left text-xl lg:text-3xl text-gray-700 font-bold">
          Aprendizaje de {user && user.name}
        </p>
        <p className="pb-5 my-auto text-center lg:text-left text-xl lg:text-3xl text-primary-300 font-bold">{course && course.name}</p>

        <div className="flex flex-col md:flex-row gap-10 ">
          <div className='w-full '>


            <div className="px-12  w-full border border-gray-350   flex flex-row flex-wrap py-4 ">
              <div className='mx-auto lg:mx-0'>
                <div className="bg-primary-200 w-12 h-12 rounded-full m-auto mr-4 " >
                  <FontAwesomeIcon
                    className="text-white text-center mx-auto pt-3 flex justify-content"
                    icon={["far", "clock"]}
                    lib="far"
                  />
                </div>
              </div>

              <div className='w-full lg:w-auto my-auto text-primary-700 font-bold flex lg:space-x-4 flex-wrap text-center lg:text-left mx-auto lg:mx-0'>
                <div className='text-center lg:text-left  mx-auto lg:mx-0 flex'>
                  Progreso {Number.isInteger(courseInProgress) ? courseInProgress : parseFloat(courseInProgress).toFixed(2)}% &nbsp; <b className='hidden lg:block'> - </b>
                </div>
                <div>

                  {Number.isInteger(coursesHours) ? coursesHours : parseFloat(coursesHours).toFixed(1)} Horas de estudio
                </div>
              </div>
            </div>


            <div className='w-full mt-20'>
              <div className='flex flex-wrap lg:justify-between mb-10'>
                <p className="w-full lg:w-auto text-center lg:text-left text-xl lg:text-3xl text-gray-700 font-bold ">
                  Estadisticas
                </p>
                <select
                  name="breakdown"
                  onChange={(value) => changeDate(value.target.value)}
                  className="mx-auto lg:mx-1 text-xl lg:text-xl font-bold  bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none"
                >
                  <option value="currentWeek">Semanal</option>
                  <option value="currentMonth">Mensual</option>
                  <option value="annual">Anual</option>

                </select>

              </div>
              <Line options={options} data={dataDays} />

            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Statistics;
