import basic from '../../assets/images/memberships/dropshipping.png'
import PlusLayout from '../../components/memberships/PlusLayout'
import { useState, React } from 'react'

const url = window.location.origin
const elements = [
  {
    label: 'Acceso al inventario de la',
    link: url + '/tienda'
  },
  {
    label: 'Más de 700 productos diferentes para envío inmediato.'
  },
  {
    label: 'Productos importados de China y Estados Unidos.'
  },
  {
    label: 'Productos de marcas 100% originales y reconocidas a nivel internacional.'
  },
  {
    label: 'Productos analizados previamente.'
  },
  {
    label: 'Precio de mayoreo desde una pieza.'
  },
  {
    label: 'Envíos express a cualquier lugar dentro de México.'
  },
  {
    label: 'Empaque del envío.'
  },
  {
    label: 'Garantía en los productos.'
  },
  {
    label:
      'Apoyo por WhatsApp con tus envíos'
  },
]
const DropshippingMembership = () => {
  const [option, setOption] = useState(1)
  function SwitchCase (option) {
    switch (option) {
      case 1: //Mensual
        return (
          <PlusLayout
            elements={elements}
            price={'$15,000 MXN.'}
            description={' $6,000 a partir del segundo mes.'}
          />
        )
    }
  }
  return (
    <div className='bg-white '>
      <div className='container mx-auto px-4 md:px-0  flex flex-col lg:flex-row text-4xl  '>
        <div className=' text-neutral-50 text-right	w-full lg:w-2/12 flex-auto mb-10	lg:mb-20  '>
          <img
            alt=''
            src={basic}
            className='object-cover  w-full lg:w-auto lg:object-none h-60  lg:h-auto'
          ></img>
        </div>
        <div className='  text-zinc-700 text-left	w-full lg:w-4/6 flex-auto    text-gray-700 flex-auto	 text-center lg:text-left'>
          <div className='lg:pl-5  mb-5 '>
            <h1 className='font-bold text-center lg:text-left lg:mb-5 text-xl lg:text-4xl'>
              Básica Dropshipping
            </h1>
            {SwitchCase(option)}
          </div>
          <div className='lg:pl-2 font-bold '>
            <a
              href='/membresias/formulario'
              type='submit'
              className='bg-primary-200 text-white text-xl lg:text-2xl py-3 lg:pl-6 px-2 disabled:opacity-75 mt-5 w-full lg:w-4/12 text-center'
            >
              Llenar formulario de admisión
            </a>
          </div>
          <br></br>
        </div>
      </div>
    </div>
  )
}

export default DropshippingMembership
