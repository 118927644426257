/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notifications } from "../../../utils/notifications";
import { ShopifyAPIService } from "../../../services/api/admin/shopify";

const NewStore = () => {
  const formSchema = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
    access_token: Yup.string().required("Campo requerido"),
    store: Yup.string().required("Campo requerido"),
    location_id: Yup.string().required("Campo requerido"),
    storeFrontToken: Yup.string().required("Campo requerido"),
  });

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const initialValues = {
    name: "",
    access_token: "",
    store: "",
    location_id: "",
    storeFrontToken: "",
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    await ShopifyAPIService.createStore(values)
      .then((response) => {
        notifications.success(response.data.message);
        navigate("/admin/tiendas");
      })
      .catch((err) => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <p className="text-4xl font-bold">Nueva tienda Shopify</p>
      </div>

      <div className="mt-12">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-2xl md:text-3xl text-gray-550">
                  <p>Nombre de la tienda</p>
                  <Field
                    name="name"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-9 text-2xl md:text-3xl text-gray-550">
                  <p>API Rest Token</p>
                  <Field
                    name="access_token"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                  />
                  <ErrorMessage
                    name="access_token"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-2xl md:text-3xl text-gray-550">
                  <p>Url de la tienda</p>
                  <Field
                    name="store"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                  />
                  <ErrorMessage
                    name="store"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>

                <div className="mb-9 text-2xl md:text-3xl text-gray-550">
                  <p>Storefront Token</p>
                  <Field
                    name="storeFrontToken"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                  />
                  <ErrorMessage
                    name="storeFrontToken"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-2xl md:text-3xl text-gray-550">
                  <p>Id de la locacion</p>
                  <Field
                    name="location_id"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                  />
                  <ErrorMessage
                    name="location_id"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>
              <div className="w-full flex justify-end">
                <button
                  type="submit"
                  className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      Sincronizando <FontAwesomeIcon icon="circle-notch" spin />
                    </>
                  ) : (
                    <span>Guardar</span>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default NewStore;
