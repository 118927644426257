import { useNavigate } from "react-router-dom";
import moment from 'moment'

const AdmissionApplicationCard = ({ admission }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col md:flex-row justify-between px-8 py-5 border-b border-b-gray-350 w-full text-xl md:text-2xl">
        <div>
          <p>{admission.name} -{' '}
            {moment(admission.created_at).format('DD/MM/YYYY')}</p>
        </div>

        <div className="flex justify-between items-center md:flex-col text-[#AEAEAE] pt-3 md:pt-0">
          <div className="hidden md:block space-x-4">
            <span
              className={`text-base uppercase ${admission.status === "Aceptado"
                  ? "text-[#32C612]"
                  : "text-[#FF7D7D]"
                }`}
            >
              {admission.status === "Aceptado" ? "Aceptado" : "Rechazado"}
            </span>
            <button
                className="text-primary-200 hover:text-primary-300 text-base uppercase"
                onClick={() => navigate(`/admin/admisiones/${admission.id}`)}
              >
                Revisar
              </button>
          </div>
          <div className="w-full flex md:hidden flex-row justify-end space-x-4">
            <span
              className={`text-base uppercase ${admission.status === "Aceptado"
                  ? "text-[#32C612]"
                  : "text-[#FF7D7D]"
                }`}
            >

              {admission.status === "Aceptado" ? "Aceptado" : "Rechazado"}
             
            </span>
            <button
                className="text-primary-200 hover:text-primary-300 text-base uppercase"
                onClick={() => navigate(`/admin/admisiones/${admission.id}`)}
              >
                Revisar
              </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdmissionApplicationCard;
