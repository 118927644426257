import { MedalIcon } from "../../components/icons";
import ProgressBar from "@ramonak/react-progress-bar";
import CourseButton from "./CourseButton";
const CourseCompleted = ({ course, completed }) => {
  return (
    <div className="shadow-md flex flex-col h-[500px] lg:h[600px] lg:h-full  ">
      <div key={course.uuid} className="shadow-md w-full flex flex-col h-full">
        {completed ? (
          <div className="relative">
            <img
              src={course.image}
              alt=""
              className="w-full h-40 lg:h-60 object-cover"
            />
            <div className="bg-primary-200 opacity-50 absolute top-0 left-0 w-full h-full flex items-center justify-center"></div>
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <MedalIcon styles="h-20 w-20 fill-white opacity-100" />
            </div>
          </div>
        ) : (
          <img src={course.image} alt="" className="w-full h-60 object-cover" />
        )}

        <div className="p-4 flex flex-col flex-grow justify-between">
          <h3 className="font-bold text-gray-700 text-xl lg:text-3xl lg:mb-2 text-center lg:text-left">
            {course.name}
          </h3>

          <div className="text-sm lg:text-xl text-center lg:text-left">
            <p className="text-gray-400">Impartido por</p>
            <p className="text-gray-700">{course.teacher.name}</p>
          </div>

          <div className=" lg:my-8">
            {completed ? (
              <div>
                <p className="text-green-600 text-sm lg:text-xl">
                  ¡Completado!
                </p>
                <div className="completado">
                  <progress max="100" value="100" className="w-full"></progress>
                </div>
              </div>
            ) : (
              <div>
                <p className={"text-gray-400 text-sm lg:text-xl"}>
                  {parseInt(course.progress)}% de progreso
                </p>
                <ProgressBar
                  bgColor="#60a5fa"
                  baseBgColor="#dadada"
                  isLabelVisible={false}
                  height="10px"
                  borderRadius="0px"
                  animateOnRender
                  completed={course.progress}
                  maxCompleted={100}
                />
              </div>
            )}
          </div>
          <CourseButton course={course} />
        </div>
      </div>
    </div>
  );
};

export default CourseCompleted;
