import { Outlet } from "react-router-dom";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

export default function BasicLayout({ theme = "dark" }) {
  return (
    <div className="flex flex-col h-screen justify-between">
      <NavBar />

      <Outlet />

      <Footer theme={theme} />
    </div>
  );
}
