import { api } from "../../../client";

export const adminWithdrawalsService = {
  getPendingWithdrawals() {
    return api.admin.get("/admin/withdrawal/pending");
  },
  getAnsweredWithdrawals() {
    return api.admin.get("/admin/withdrawal/answered");
  },
  setWithdrawal(id,model) {
    return api.admin.post(`/admin/withdrawal/${id}`,model);
  },
  downloadInvoice(id) {
    return api.admin.get(`/admin/withdrawal/invoice/${id}`,{ responseType: "blob" });
  },
};
