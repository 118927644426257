import { useFormikContext } from "formik";
import { React, useEffect, useState } from "react";

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [ arrayValues, setArrayValues ] = useState([]);
  
  const setValues = () => {
    let text =  props.check.replace(/"/g, "");
    setArrayValues(...[text.split(",")]);
  };

  useEffect(() => {
    if (typeof(props.check) === "string") {
      setValues();
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.check]);

  return (
    <div className="mb-9 text-xl text-gray-700 space-x-2 ">
      <p>{props.question}</p>
      {props.message && <p className='text-gray-600'>
        En esta pregunta es super importante que tomes en cuenta que se habla de UTILIDAD, es decir ganancias después de recuperar tu inversión,
        en TUS PRIMEROS 30 DÍAS. Muchas personas se confunden y ponen lo que quieren ganar proyectándose a mediano o largo plazo,
        la pregunta es EN TUS PRIMEROS 30 DÍAS</p> }
      <div className=
        {`flex justify-center lg:justify-start  mt-2 lg:mb-4 ${props.checkFull ? "flex-wrap" : " space-x-5 "}`}
      >

        {props.checkboxes.map((check) => (
          <div className=
            {`space-x-2 flex items-center w-full ${props.checkFull && "w-full mb-2" }`}
          >
            <input
              name={props.name}
              value={check.value}
              checked={props.check ===
                check.value || arrayValues.includes(check.value)}
              onChange={value => {
                setFieldValue(props.name, check.value)
              }}
              type={props.box ? "checkbox" : "radio"}
              className="t-20 w-5 h-5  bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
            />
            <span className="">{check.text}</span>
          </div>
        ))}
      </div>
    </div>
  )
};
export default DatePickerField;
