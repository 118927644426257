import { api } from "../../client";

export const profileService = {
  getUser() {
    return api.base.get("/user");
  },
  updateUser(model) {
    return api.base.put("/user", model);
  },
  updateImage(model) {
    return api.base.post("/user", model);
  },
  getBillingInfo() {
    return api.base.get("/user/billing-information");
  },
  updateBillingInfo(model) {
    return api.base.put("/user/billing-information", model);
  },
  getDiplomas() {
    return api.base.get("/user/diplomas");
  },
  downloadDiploma(id) {
    return api.base.get(`/user/diploma/${id}`, { responseType: "blob" });
  },
  getAddresses() {
    return api.base.get("/user/address");
  },
  getAddress(id) {
    return api.base.get(`/user/address/${id}`);
  },
  addAddress(model) {
    return api.base.post("/user/address", model);
  },
  updateAddress(id, model) {
    return api.base.put(`/user/address/${id}`, model);
  },
  deleteAddress(id) {
    return api.base.delete(`/user/address/${id}`);
  },
  getUserMembershipsSales() {
    return api.base.get("/user/memberships");
  },
  getUserProductsSales() {
    return api.base.get("/user/products");
  },
};
