// eslint-disable-next-line react-hooks/exhaustive-deps
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { api } from "../services/client";
import appStorage from "../utils/appStorage";
import { tokenLocalStorageKey } from "../utils";
import { authService } from "../services/api/auth";
import { notifications } from "../utils/notifications";

const Context = React.createContext({});

export function UserContextProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [jwt, setJWT] = useState(() =>
    appStorage.getItem(tokenLocalStorageKey)
  );
  const [currentUser, setCurrentUser] = useState(null);

  const setDefaultHeaders = (token) => {
    let authorization = token ? `Bearer ${token}` : undefined;
    api.base.defaults.headers.common.Authorization = authorization;
  };

  const verify = useCallback(
    (token) => {
      if (!token) return Promise.resolve(null);

      authService
        .verify(token)
        .then((response) => {
          setCurrentUser(response.data.user);
        })
        .catch((err) => {
          setCurrentUser(null);
          if (err.response.status === 401 || err.response.status === 403) {
            appStorage.removeItem(tokenLocalStorageKey);
            setJWT(null);
            setCurrentUser(null);
          }
        });
    },
    [setCurrentUser]
  );

  useEffect(() => {
    if (location.pathname.includes("admin")) return;

    if (!jwt) {
      if (
        location.pathname.includes("mi-perfil") ||
        location.pathname.includes("mi-escritorio") ||
        location.pathname.includes("carrito-de-compras") ||
        location.pathname.includes("carrito-de-compras/pagar") ||
        location.pathname.includes("cursos/contenido")
      ) {
        notifications.warn(
          "Necesitas iniciar sesión para acceder a esta sección."
        );
        navigate("/");
      }
      return;
    }

    setDefaultHeaders(jwt);
    verify(jwt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt, verify]);

  useEffect(() => {
    window.addEventListener("storage", (e) => {
      if (e.key === tokenLocalStorageKey && e.oldValue && !e.newValue) {
        setCurrentUser(null);
      }
    });

    return () => {
      window.removeEventListener("storage", null);
    };
  }, [navigate]);

  return (
    <Context.Provider
      value={{
        jwt,
        setJWT,
        currentUser,
        setCurrentUser,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default Context;
