import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProductCart = ({
  product,
  handleRemoveItemFromCart,
  handleBuyLaterFromCart,
  handleUpdateProductFromCart,
}) => {
  const [quantity, setQuantity] = useState(11);
  const [isNewQuantity, setIsNewQuantity] = useState(false);
  const [button, setButton] = useState(false);
  const [message, setMessage] = useState("");
  const getPrice = () => {
    if (product.amount > 0) {
      return (product.amount * product.price).toLocaleString(
        "en-US",
        { minimumFractionDigits: 2 }
      );
    }

    return product.price.toLocaleString("en-US");
  };

  const getVariant = (variant_id) => {
    let variant;
    Object.keys(product.variants).forEach(key => {
      if (variant_id === product.variants[key].id) {
        variant = product.variants[key];
      }
    });

    return variant;
  }
  const getCantContent = () => {
    return (<div className="flex items-center ">
      <p className="text-gray-700 font-bold text-sm lg:text-lg leading-none mr-3">
        Cant.
      </p>
      {isNewQuantity ? (
        <div className="space-x-2 flex flex-wrap lg:flex-row">
          <input
            className="w-20 text-center text-sm lg:text-xl bg-white border border-slate-300 focus:outline-none disabled:opacity-75 disabled:cursor-not-allowed"
            defaultValue={product.amount}
            max="1"
            min='2'
            onClick={(event) => {
              if (
                parseInt(getVariant(product.variant_id).inventory_quantity) >
                parseInt(event.target.value)
                || parseInt(event.target.value) === 0
              ) {
                setButton(true);
              } else {
                setButton(false);
                setMessage("Cantidad no disponible");
              }
            }}
            onBlur={(event) => {
              if (
                (parseInt(getVariant(product.variant_id).inventory_quantity) >
                  parseInt(event.target.value))
                && parseInt(event.target.value) !== 0
              ) {
                if (event.target.value === product.amount.toString()) {
                  setButton(false);
                }
              } else {
                setButton(false);
                setMessage("Cantidad no disponible");
              }
            }}
            onChange={(event) => {
              if (
                (parseInt(getVariant(product.variant_id).inventory_quantity) >
                  parseInt(event.target.value))
                && parseInt(event.target.value) !== 0
              ) {
                setQuantity(event.target.value);
                setButton(true);

                setMessage("");
              } else {
                setButton(false);
                setMessage("Cantidad no disponible");
              }
            }}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
          {button ? (
            <button
              onClick={() => {
                setButton(false);
                handleUpdateProductFromCart(product.id, quantity, product.variant_id,product.shopify_store_id);
                setIsNewQuantity(false);
              }}
              className="font-medium text-primary-200 hover:text-primary-300 w-auto"
            >
              Actualizar
            </button>
          ) : (
            <div className="text-sm lg:text-xl text-red-50">{message}</div>
          )}
        </div>
      ) : (
        <select
          name="quantity"
          value={product.amount}
          className="w-full md:w-20 text-xl bg-white border border-slate-300 focus:outline-none disabled:opacity-75 disabled:cursor-not-allowed"
          style={{ textAlignLast: "center" }}
          onChange={(event) => {
            event.target.value === "others"
              ? (setIsNewQuantity(true))
              : handleUpdateProductFromCart(
                product.id,
                event.target.value
                , product.variant_id,product.shopify_store_id
              );
          }}
        >
          {setQuantityOptions()}
          {(product.amount > 10 || product.amount>getVariant(product.variant_id).inventory_quantity) 
            &&
            <option className="text-sm lg:text-xl text-left" key={product.amount} value={product.amount}>
              {product.amount}
            </option>
          }
          {(getVariant(product.variant_id).inventory_quantity > 10 || product.amount >= 10) && (product.amount<getVariant(product.variant_id).inventory_quantity) &&
            <option className="text-sm lg:text-xl text-left" key={"+10"} value={"others"}>
              +{product.amount > 10 ? product.amount : "10"}
            </option>
          }

        </select>
      )}
      {product.amount > getVariant(product.variant_id).inventory_quantity &&
        <div className="w-full text-sm lg:text-base text-red-50 px-2">Cantidad no disponible  </div>}
    </div>)
  }
  const getVariantImage = (image_id) => {
    let image;
    Object.keys(product.images).forEach(key => {
      if (image_id === product.images[key].id) {
        image = product.images[key];
      }
    });
    return image;
  }
  const setQuantityOptions = () => {
    let limit = getVariant(product.variant_id).inventory_quantity;

    if (limit > 10) limit = 10;
    let options = [];
    for (let index = 0; index < limit; index++) {
      options.push(
        <option className="text-left" key={index} value={index + 1}>
          {index + 1}
        </option>
      );
    }
     
    return options;
  };

  return (
    <div className="flex relative pt-8 pb-6 border-t border-gray-300">
      <div className="absolute right-0 top-1">
        <button
          className="text-gray-500 hover:text-gray-600"
          onClick={() => handleRemoveItemFromCart(product.id, "Producto", product.variant_id,product.shopify_store_id)}
        >
          <FontAwesomeIcon icon="fa-xmark" />
        </button>
      </div>
      <div className="flex-shrink-0">
        <img
          src={product.image_id ? getVariantImage(product.image_id).src : product.image.src}
          alt={product.image && product.image.alt}
          className="h-20 w-20 rounded-md object-cover object-center"
        />
      </div>

      <div className="ml-4 flex flex-1 flex-col sm:ml-6">
        <div>
          <div className="flex justify-between">
            <p className="text-gray-700 font-bold text-sm md:text-2xl leading-none">
              {product.title}
            </p>
            <p className="ml-8 font-bold text-sm lg:text-xl text-gray-900 leading-none text-right">
              ${getPrice()} <small> MXN</small>
            </p>
          </div>
        </div>

        <div className="mt-4 flex flex-1 items-end justify-between">

          {getVariant(product.variant_id).inventory_quantity > 0 ?

            getCantContent() :
            <div className=" text-sm lg:text-base text-red-50">Producto no disponible por el momento</div>

          }



          <div className="hidden md:block ml-4">
            <button
              className="font-medium text-primary-200 hover:text-primary-300"
              onClick={() =>
                handleBuyLaterFromCart(
                  product.id,
                  "Producto",
                  getVariant(product.variant_id).save_for_later ? 0 : 1,
                  product.variant_id,
                  product.shopify_store_id
                )
              }
            >
              <div className="flex items-center justify-center">
                <p>
                  {
                    getVariant(product.variant_id).save_for_later
                      ? "Agregar al carrito"
                      : "Guardar para después"}
                </p>
              </div>
            </button>
          </div>
        </div>
        <div className="flex justify-end md:hidden mt-5">
          <button
            className="font-medium text-primary-200 hover:text-primary-300"
            onClick={() =>
              handleBuyLaterFromCart(
                product.id,
                "Producto",
                getVariant(product.variant_id).save_for_later ? 0 : 1
                , product.variant_id,
                product.shopify_store_id
              )
            }
          >
            <div className="flex items-center justify-center">
              <p>
                {getVariant(product.variant_id).save_for_later
                  ? "Agregar al carrito"
                  : "Guardar para después"}
              </p>
            </div>
          </button>
        </div>
      </div>
    </div >
  );
};

export default ProductCart;
