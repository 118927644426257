import { useState } from "react";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { notifications } from "../../utils/notifications";
import { adminCoursesServices } from "../../services/api/admin/courses";

import PublishQCContent from "../Modals/admin/PublishQCContent";
import PublishQCModal from "../Modals/admin/PublishQCModal";

const CommentComponent = ({ comment }) => {
  const [showPublish, setShowPublish] = useState(false);
  const [isPublished, setIsPublished] = useState(comment.isPublished);

  const publishOrHideComment = async () => {
    await adminCoursesServices
      .publishComment({ id: comment.id })
      .then((response) => {
        notifications.success(response.data.message);
        setIsPublished(!isPublished);
        setShowPublish(!showPublish);
      })
      .catch((err) => {});
  };

  return (
    <>
      <div className="flex flex-col md:flex-row justify-between gap-10 p-8 border-b border-b-gray-350 w-full">
        <div className="flex flex-col md:flex-row gap-y-5 md:gap-y-0">
          <div className="mx-auto md:mr-5 md:ml-0">
            <div className="w-16 h-16 rounded-full bg-[#D8D8D8]">
              <img
                src={comment.user && comment.user.image}
                alt=""
                className="rounded-full w-full h-full object-cover"
                style={{ backgroundColor: "#D8D8D8" }}
              />
            </div>
          </div>
          <div className="flex flex-col text-xl md:text-2xl text-center md:text-left gap-y-5 md:gap-y-0">
            <div className="flex flex-col md:flex-row items-center text-[#9F9F9F]">
              <p className="mr-3">{comment.user.name}</p>
              <span className="text-lg md:text-xl text-primary-200">
                {comment.course.name}
              </span>
            </div>
            <p>{comment.comment}</p>
          </div>
        </div>

        <div className="flex justify-between items-center md:flex-col text-[#AEAEAE]">
          <button
            className="hidden md:block"
            onClick={() => setShowPublish(true)}
          >
            <FontAwesomeIcon
              icon="fa-flag"
              className={`${
                isPublished ? "text-primary-200" : "text-[#D8D8D8]"
              }`}
              size="sm"
            />
          </button>
          <p className="text-lg text-center leading-none">
            <Moment format="DD/MM/YYYY">{comment.createdAt}</Moment>
          </p>
          <button
            className="block md:hidden"
            onClick={() => setShowPublish(true)}
          >
            <FontAwesomeIcon
              icon="fa-flag"
              className={`${
                isPublished ? "text-primary-200" : "text-[#D8D8D8]"
              }`}
              size="sm"
            />
          </button>
        </div>
      </div>
      <PublishQCModal
        visible={showPublish}
        setVisible={setShowPublish}
        modalContent={
          <PublishQCContent
            handlePublish={publishOrHideComment}
            isPublished={isPublished}
            setVisible={setShowPublish}
          />
        }
      />
    </>
  );
};

export default CommentComponent;
