import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/es";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { formServices } from "../services/api/admissions";
import * as Yup from "yup";
import YNCheckbox from "../components/forms/YNCheckbox";
import MultipleCheckboxes from "../components/forms/MultipleCheckboxes";
import RateCheckboxes from "../components/forms/RateCheckboxes";
import star from "../assets/images/star-icon.png";
import checkIcon from "../assets/icons/check-icon.svg";
import { Link } from "react-router-dom";
import states from "../utils/states.js";
import cities from "../utils/cities.json";

const url = window.location.origin;
const multipleSelectOne = [
  {
    question: "Actualmente tú: (situación sentimental)",
    name: "emotionalSituation",
    checkboxes: [
      {
        text: "Casado",
        value: "1",
      },
      {
        text: "Soltero",
        value: "2",
      },
      {
        text: "Unión libre",
        value: "3",
      },
      {
        text: "Vives con tus padres",
        value: "4",
      },
      {
        text: "Noviazgo",
        value: "5",
      },
    ],
  },
  {
    question: "¿Tienes hijos?",
    name: "hasChildres",
    checkboxes: [
      {
        text: "No, no tengo",
        value: "1",
      },
      {
        text: "Si, edad preescolar",
        value: "2",
      },
      {
        text: "Si, edad primaria",
        value: "3",
      },
      {
        text: "Si, edad secundaria",
        value: "4",
      },
      {
        text: "Si, edad preparatoria",
        value: "5",
      },
      {
        text: "Si, edad universidad o más",
        value: "6",
      },
    ],
  },

  {
    question: "Selecciona las herramientas que NO sabes usar:",
    name: "userWeakTools",
    box: true,
    checkboxes: [
      {
        text: "CANVA",
        value: "1",
      },
      {
        text: "Photoshop",
        value: "2",
      },
      {
        text: "Excel",
        value: "3",
      },
      {
        text: "Tiktok",
        value: "4",
      },
      {
        text: "Facebook",
        value: "5",
      },
      {
        text: "Instagram",
        value: "6",
      },
      {
        text: "Whatsapp",
        value: "7",
      },
    ],
  },
  {
    checkFull: true,
    question: "¿Has vendido por Mercado Libre?",
    name: "hasSoldMercadoLibre",
    checkboxes: [
      {
        text: "Si, tengo reputación verde o soy mercado lider",
        value: "1",
      },
      {
        text: "Si, pero he vendido muy poco, no tengo reputación",
        value: "2",
      },
      {
        text: "No, pero me interesa",
        value: "3",
      },
      {
        text: "No, y no me interesa",
        value: "4",
      },
    ],
  },
  {
    checkFull: true,
    question: "¿Has vendido por Amazon?",
    name: "hasSoldAmazon",
    checkboxes: [
      {
        text: "Si, por FBA",
        value: "1",
      },
      {
        text: "Si, por FBM",
        value: "2",
      },
      {
        text: "No, pero me interesa",
        value: "3",
      },
      {
        text: "No, y no me interesa",
        value: "4",
      },
    ],
  },
  {
    checkFull: true,
    question: "Nivel de estudios que tienes:",
    name: "educationalLevel",
    checkboxes: [
      {
        text: "Primaria",
        value: "1",
      },
      {
        text: "Secundaria",
        value: "2",
      },
      {
        text: "Preparatoria",
        value: "3",
      },
      {
        text: "Universidad trunca",
        value: "4",
      },
      {
        text: "Universidad completada",
        value: "5",
      },
      {
        text: "Maestría",
        value: "6",
      },
      {
        text: "Doctorado",
        value: "7",
      },
    ],
  },
  {
    checkFull: true,
    question: "Nivel de conocimientos en computación:",
    name: "computerKnowledge",
    checkboxes: [
      {
        text: "Nulo",
        value: "1",
      },
      {
        text: "Principiante",
        value: "2",
      },
      {
        text: "Intermedio",
        value: "3",
      },
      {
        text: "Avanzado",
        value: "4",
      },
    ],
  },
];

export let navigate = {
  PREVIOUS: "PREV",
  NEXT: "NEXT",
  TODAY: "TODAY",
  DATE: "DATE",
};

const array = [
  {
    question:
      "Las personas cercanas a ti, ¿Te consideran una persona problemática?",
    name: "problematicPerson",
  },
  {
    question:
      "¿Te gustaría participar en un discupulado de la iglesia cristiana donde se enseña sobre la biblia?",
    name: "churchInvitation",
  },
  {
    question:
      "¿Estás de acuerdo con nuestra política de privacidad? Aquí las lees: ",
    link: url + "/politicas",
    linkTitle: "Políticas",

    name: "agreePrivacyPolicy",
  },
  {
    question:
      "¿Estás de acuerdo con nuestros términos y condiciones de la empresa? Aquí los lees: ",
    link: url + "/terminos-y-condiciones",
    linkTitle: "Términos y condiciones",

    name: "agreeTerms",
  },
];

moment.locale("es");
const regex = /^(?:\+?\d+\s?)?(?:\(\d+\)|\d+)(?:-?\s?\d+)*$/;

const initialValues = {
  name: "",
  email: "",
  phone: "",
  age: "",
  country: "Mexico",
  city: "",
  timeToInvest: "",
  personAssists: "",
  state: "",
  occupation: "Empleado",
  occupationDescription: "",
  hasChildres: "",
  //userWeakTools: "",
  hasSoldMercadoLibre: "",
  hasSoldAmazon: "",
  instagramKnowledge: "",
  facebookAdsKnowledge: "",
  canvasKnowledge: "",
  membership: "",
  computer: "1",
  hasUndertaken: "",
  results: "",
  hasFearLostMoney: "",
  acceptChanges: "",
  firstMonth: "",
  hasCollaborative: "",
  hasIniciative: "",
  hasPerseverance: "",
  easyGiveUp: "",
  acceptFailure: "",
  hasBusinessCommitment: "",
  becomeOwnBoss: "",
  businessWith: "",
  emotionalSituation: "",
  hasUsedWhatsAppBussiness: "",
  hasPayedGoogleAds: "",
  creativePerson: "",
  knowFollowIndications: "",
  opinionLastBoss: "",
  resultsAgree: "",
  recipeAgree: "",
  hasMoneyMembership: "",
  satRegistered: "",
  hasCapital: "",
  ideaAndConcept: "",
  expectation: "",
  organizedPerson: "",
  talkToCamera: "",
  phoneModel: "",
  trustYourself: "",
  personValues: "",
  workReferenceName1: "",
  workReferencePhone1: "",
  workReferenceName2: "",
  workReferencePhone2: "",
  personalReferenceName1: "",
  personalReferencePhone1: "",
  personalReferenceName2: "",
  personalReferencePhone2: "",
  problematicPerson: "",
  churchInvitation: "",
  agreePrivacyPolicy: "",
  agreeTerms: "",
  whyDigitalBusiness: "",
  educationalLevel: "",
  computerKnowledge: "",
};
const formSchema = Yup.object().shape({
  name: Yup.string()
    .required("Campo requerido")
    .max(255, `Máximo 255 caracteres`),
  email: Yup.string()
    .required("Campo requerido")
    .email("Correo electrónico inválido")
    .max(255, `Máximo 255 caracteres`),
  whyDigitalBusiness: Yup.string()
    .required("Campo requerido")
    .max(255, `Máximo 255 caracteres`),
  timeToInvest: Yup.string()
    .required("Campo requerido")
    .max(255, `Máximo 255 caracteres`),
  phone: Yup.string()
    .required("Campo requerido")
    .max(10, `Máximo 10 caracteres`)
    .matches(new RegExp(regex), "Ingrese un número de teléfono válido"),
  age: Yup.number()
    .required("Campo requerido")
    .typeError("Solo se pueden utilizar numeros enteros")
    .max(100, "Escriba una edad menor a 100 años"),
  personAssists: Yup.string()
    .required("Campo requerido")
    .max(255, `Máximo 255 caracteres`),
  // city: Yup.string()
  //   .required("Campo requerido")
  //   .max(255, `Máximo 255 caracteres`),
  city: Yup.string()
    .required("La ciudad es obligatoria")
    .typeError("La ciudad debe ser un campo de texto")
    .max(255, `Máximo 255 caracteres`),
  country: Yup.string()
    .required("El país es obligatorio")
    .typeError("El país debe ser un campo de texto")
    .max(255, `Máximo 255 caracteres`),
  state: Yup.string()
    .required("La ciudad es obligatoria")
    .typeError("La ciudad debe ser un campo de texto")
    .max(255, `Máximo 255 caracteres`),
  // state: Yup.string()
  //   .required("Campo requerido")
  //   .max(255, `Máximo 255 caracteres`),
  occupationDescription: Yup.string()
    .required("Campo requerido")
    .max(255, `Máximo 255 caracteres`),
  hasSoldMercadoLibre: Yup.number().required("Campo requerido"),
  hasSoldAmazon: Yup.number().required("Campo requerido"),
  instagramKnowledge: Yup.number().required("Campo requerido"),
  facebookAdsKnowledge: Yup.number().required("Campo requerido"),
  canvasKnowledge: Yup.number().required("Campo requerido"),
  membership: Yup.number().required("Campo requerido"),
  computer: Yup.number().required("Campo requerido"),
  hasUndertaken: Yup.number().required("Campo requerido"),
  results: Yup.number().required("Campo requerido"),
  hasFearLostMoney: Yup.number().required("Campo requerido"),
  acceptChanges: Yup.number().required("Campo requerido"),
  firstMonth: Yup.string()
    .required("Campo requerido")
    .max(255, `Máximo 255 caracteres`),
  hasCollaborative: Yup.number().required("Campo requerido"),
  hasIniciative: Yup.number().required("Campo requerido"),
  hasPerseverance: Yup.number().required("Campo requerido"),
  easyGiveUp: Yup.number().required("Campo requerido"),
  acceptFailure: Yup.number().required("Campo requerido"),
  hasBusinessCommitment: Yup.number().required("Campo requerido"),
  becomeOwnBoss: Yup.number().required("Campo requerido"),
  hasUsedWhatsAppBussiness: Yup.number().required("Campo requerido"),
  hasPayedGoogleAds: Yup.number().required("Campo requerido"),
  creativePerson: Yup.number().required("Campo requerido"),
  knowFollowIndications: Yup.number().required("Campo requerido"),
  opinionLastBoss: Yup.string()
    .required("Campo requerido")
    .max(255, `Máximo 255 caracteres`),
  resultsAgree: Yup.number().required("Campo requerido"),
  recipeAgree: Yup.number().required("Campo requerido"),
  hasMoneyMembership: Yup.number().required("Campo requerido"),
  satRegistered: Yup.number().required("Campo requerido"),
  hasCapital: Yup.number().required("Campo requerido"),
  ideaAndConcept: Yup.string()
    .required("Campo requerido")
    .max(255, `Máximo 255 caracteres`),
  expectation: Yup.string()
    .required("Campo requerido")
    .max(255, `Máximo 255 caracteres`),
  organizedPerson: Yup.number().required("Campo requerido"),
  talkToCamera: Yup.number().required("Campo requerido"),
  phoneModel: Yup.string()
    .required("Campo requerido")
    .max(255, `Máximo 255 caracteres`),
  trustYourself: Yup.number().required("Campo requerido"),
  personValues: Yup.string()
    .required("Campo requerido")
    .max(255, `Máximo 255 caracteres`),
  workReferenceName1: Yup.string()
    .required("Campo requerido")
    .max(255, `Máximo 255 caracteres`),
  workReferenceName2: Yup.string()
    .required("Campo requerido")
    .max(255, `Máximo 255 caracteres`),
  personalReferenceName1: Yup.string()
    .required("Campo requerido")
    .max(255, `Máximo 255 caracteres`),
  personalReferenceName2: Yup.string()
    .required("Campo requerido")
    .max(255, `Máximo 255 caracteres`),
  workReferencePhone1: Yup.string()
    .required("Campo requerido")
    .max(10, `Máximo 10 caracteres`)
    .matches(new RegExp(regex), "Ingrese un número de teléfono válido"),
  workReferencePhone2: Yup.string()
    .required("Campo requerido")
    .max(10, `Máximo 10 caracteres`)
    .matches(new RegExp(regex), "Ingrese un número de teléfono válido"),
  personalReferencePhone1: Yup.string()
    .required("Campo requerido")
    .max(10, `Máximo 10 caracteres`)
    .matches(new RegExp(regex), "Ingrese un número de teléfono válido"),
  personalReferencePhone2: Yup.string()
    .required("Campo requerido")
    .max(10, `Máximo 10 caracteres`)
    .matches(new RegExp(regex), "Ingrese un número de teléfono válido"),
  problematicPerson: Yup.number().required("Campo requerido"),
  churchInvitation: Yup.number().required("Campo requerido"),
  agreePrivacyPolicy: Yup.number().required("Campo requerido"),
  agreeTerms: Yup.number().required("Campo requerido"),
  businessWith: Yup.string()
    .required("Campo requerido")
    .max(255, `Máximo 255 caracteres`),
  emotionalSituation: Yup.number().required("Campo requerido"),
  hasChildres: Yup.number().required("Campo requerido"),
  educationalLevel: Yup.number().required("Campo requerido"),
  computerKnowledge: Yup.number().required("Campo requerido"),
});
export default function Index() {
  const [loading, setLoading] = useState(false);
  const [correctSubmit, setCorrectSubmit] = useState(false);
  const [citiesPerState, setCitiesPerState] = useState([]);
  const [anotherCountry, setAnotherCountry] = useState(false);

  const handleSubmit = async (values, resetForm) => {
    setLoading(true);
    let bodyFormData = new FormData();
    bodyFormData.append("name", values.name);
    bodyFormData.append("email", values.email);
    bodyFormData.append("phone", values.phone);
    bodyFormData.append("age", values.age);
    bodyFormData.append("personAssists", values.personAssists);
    bodyFormData.append("city", values.city);
    bodyFormData.append("state", values.state);
    bodyFormData.append("occupation", values.occupation);
    bodyFormData.append("occupationDescription", values.occupationDescription);
    bodyFormData.append("hasUndertaken", values.hasUndertaken);
    bodyFormData.append("results", values.results);
    bodyFormData.append("hasFearLostMoney", values.hasFearLostMoney);
    bodyFormData.append("acceptChanges", values.acceptChanges);
    bodyFormData.append("timeToInvest", values.timeToInvest);
    bodyFormData.append("firstMonth", values.firstMonth);
    bodyFormData.append("hasCollaborative", values.hasCollaborative);
    bodyFormData.append("hasIniciative", values.hasIniciative);
    bodyFormData.append("hasPerseverance", values.hasPerseverance);
    bodyFormData.append("easyGiveUp", values.easyGiveUp);
    bodyFormData.append("whyDigitalBusiness", values.whyDigitalBusiness);
    bodyFormData.append("acceptFailure", values.acceptFailure);
    bodyFormData.append("hasBusinessCommitment", values.hasBusinessCommitment);
    bodyFormData.append("becomeOwnBoss", values.becomeOwnBoss);
    bodyFormData.append("businessWith", values.businessWith);
    bodyFormData.append("emotionalSituation", values.emotionalSituation);
    bodyFormData.append("hasChildres", values.hasChildres);
    bodyFormData.append("country", values.country);
    bodyFormData.append(
      "userWeakTools",
      values.userWeakTools.length === 0 ? undefined : values.userWeakTools
    );
    bodyFormData.append("hasSoldMercadoLibre", values.hasSoldMercadoLibre);
    bodyFormData.append("hasSoldAmazon", values.hasSoldAmazon);
    bodyFormData.append("instagramKnowledge", values.instagramKnowledge);
    bodyFormData.append("facebookAdsKnowledge", values.facebookAdsKnowledge);
    bodyFormData.append("canvasKnowledge", values.canvasKnowledge);
    bodyFormData.append(
      "hasUsedWhatsAppBusiness",
      values.hasUsedWhatsAppBussiness
    );
    bodyFormData.append("hasPayedGoogleAds", values.hasPayedGoogleAds);
    bodyFormData.append("creativePerson", values.creativePerson);
    bodyFormData.append("knowFollowIndications", values.knowFollowIndications);
    bodyFormData.append("opinionLastBoss", values.opinionLastBoss);
    bodyFormData.append("resultsAgree", values.resultsAgree);
    bodyFormData.append("recipeAgree", values.recipeAgree);
    bodyFormData.append("hasMoneyMembership", values.hasMoneyMembership);
    bodyFormData.append("membership", values.membership);
    bodyFormData.append("satRegistered", values.satRegistered);
    bodyFormData.append("hasCapital", values.hasCapital);
    bodyFormData.append("ideaAndConcept", values.ideaAndConcept);
    bodyFormData.append("expectation", values.expectation);
    bodyFormData.append("organizedPerson", values.organizedPerson);
    bodyFormData.append("talkToCamera", values.talkToCamera);
    bodyFormData.append("phoneModel", values.phoneModel);
    bodyFormData.append("computer", values.computer);
    bodyFormData.append("trustYourself", values.trustYourself);
    bodyFormData.append("personValues", values.personValues);
    bodyFormData.append("workReferenceName1", values.workReferenceName1);
    bodyFormData.append("workReferencePhone1", values.workReferencePhone1);
    bodyFormData.append("workReferenceName2", values.workReferenceName2);
    bodyFormData.append("workReferencePhone2", values.workReferencePhone2);
    bodyFormData.append(
      "personalReferenceName1",
      values.personalReferenceName1
    );
    bodyFormData.append(
      "personalReferencePhone1",
      values.personalReferencePhone1
    );
    bodyFormData.append(
      "personalReferenceName2",
      values.personalReferenceName2
    );
    bodyFormData.append(
      "personalReferencePhone2",
      values.personalReferencePhone2
    );
    bodyFormData.append("problematicPerson", values.problematicPerson);
    bodyFormData.append("churchInvitation", values.churchInvitation);
    bodyFormData.append("agreePrivacyPolicy", values.agreePrivacyPolicy);
    bodyFormData.append("agreeTerms", values.agreeTerms);
    bodyFormData.append("educationalLevel", values.educationalLevel);
    bodyFormData.append("computerKnowledge", values.computerKnowledge);

    await formServices
      .sendForm(bodyFormData)
      .then((response) => {
        setCorrectSubmit(true);
        resetForm();
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const returnCities = (state, setFieldValue) => {
    setFieldValue("city", "");
    if (state === "Seleccione un estado") {
      setFieldValue("state", "");
      setCitiesPerState([]);
    } else {
      setCitiesPerState(cities[state]);
    }
  };

  const changeCountry = (country, setFieldValue) => {
    if (country === "Mexico") {
      setAnotherCountry(false);
      setFieldValue("country", "Mexico");
    } else {
      setAnotherCountry(true);
      setFieldValue("city", "");
      setFieldValue("country", "");
      setFieldValue("state", "");
      setFieldValue("country", "");
    }
  };

  return (
    <div>
      <div
        className={`container mx-auto relative ${
          correctSubmit ? "hidden" : "block"
        }`}
      >
        <div className="mt-20 px-10 text-center lg:text-justify">
          <p className="text-3xl lg:text-5xl text-gray-700 font-bold mb-5">
            Formulario de admisión al sistema Be Global Pro
          </p>
          <div className="text-gray-700 text-xl ">
            <div>
              <p className="mb-5">
                No llenes este formulario si tienes dudas, no cuentas con la
                inversión o no conoces el sistema. Solo personas 100% listas
                para iniciar. <b>Lee detenidamente.</b>
              </p>
              <p className="mb-5">
                <b>Be Global Pro</b> es una empresa donde lo más importante es
                Dios, Jesucristo es la cabeza de nuestra empresa y nos movemos
                por indicación del Espíritu Santo, por lo tanto no encontrarás
                cosas que ofendan a Dios como productos tipo maskings, sex shop
                o que fomenten el consumo de alcohol, etc.
              </p>
              <p className="mb-3 font-bold">Propósitos de la empresa:</p>
            </div>

            <ul className="list-none mb-5 space-y-2">
              <li className="flex flex-row text-left">
                <img alt="" src={star} className="pr-2 h-6"></img> Respetar a
                Dios sobre todas las cosas.
              </li>
              <li className="flex flex-row text-left">
                <img alt="" src={star} className="pr-2 h-6"></img> La familia: a
                través de este sistema esperamos que logres tener libertad de
                tiempo que te permita gozar de los que más amas.
              </li>
              <li className="flex flex-row text-left">
                <img alt="" src={star} className="pr-2 h-6"></img> Tu
                crecimiento personal: que encuentres el rumbo de tu vida y tu
                crecimiento personal sea intencional.
              </li>
              <li className="flex flex-row text-left">
                <img alt="" src={star} className="pr-2 h-6"></img> Tu
                crecimiento profesional: que entiendas cómo funciona el comercio
                electrónico y puedas hacer crecer todas las empresas que quieras
                a través de metodologías comprobadas que funcionan.
              </li>
              <li className="flex flex-row text-left">
                <img alt="" src={star} className="pr-2 h-6"></img> Tu
                crecimiento espiritual: estar bien con Dios es estar bien
                contigo mismo, tu paz y tranquilidad lo más importante.
              </li>
              <li className="flex flex-row text-left">
                <img alt="" src={star} className="pr-2 h-6"></img> Crecimiento
                financiero: para tener resultados económicos y éxito en
                cualquier cosa que te propongas es necesario estar bien contigo
                mismo ya que logres esto, lograrás tener finanzas sanas en tu
                vida que te permitirán vivir con tranquilidad y paz.
              </li>
            </ul>

            <p className="mb-5">
              {" "}
              Este formulario tiene como finalidad ver si cumples con el perfil
              para ser parte del sistema de Be Global Pro,<b> NO </b> nos
              gustaría que inviertas tu dinero y que no lo recuperes.
            </p>

            <p className="mb-5">
              Nos interesa que tengas resultados, no queremos mentirte diciendo
              que esto es para todos porque realmente no es así, hay personas
              que simplemente el emprendimiento no se le da, por eso queremos
              cuidar que no hagas una inversión en vano. Una vez revisado el
              formulario si está aprobado, tienes 3 hrs para realizar el pago de
              la membresía, de lo contrario, ya no tendrías acceso al lugar ni
              al sistema, pierdes acceso de manera definitiva, buscamos trabajar
              con personas serias.
            </p>

            <p className="mb-5">
              Si no cuentas con la inversión o tienes dudas no realices el
              formulario.{" "}
            </p>
            <p className="mb-5 font-bold underline">
              En caso de requerir factura avisar antes de pagar porque la cuenta
              podría cambiar.{" "}
            </p>
            <p>
              Link de video informativo por si no conoces la info:{" "}
              <a
                href="https://youtu.be/Eah2wYmEMBY"
                className="font-italic font-bold"
              >
                https://youtu.be/Eah2wYmEMBY
              </a>{" "}
              (Cuando termines de verlo puedes contactarnos para resolver
              dudas).
            </p>
          </div>
          <div>
            <div className="container mx-auto text-gray-700">
              <div className="mt-12">
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={formSchema}
                  onSubmit={(values, { resetForm }) =>
                    handleSubmit(values, () => resetForm(initialValues))
                  }
                >
                  {({ setFieldValue, errors }) => (
                    <Form>
                      <div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                          <div className="mb-9 text-xl text-gray-700">
                            <p className="text-center md:text-left">
                              Nombre completo
                            </p>
                            <Field
                              name="name"
                              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                              type="text"
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="text-red-400 font-bold text-xl"
                            />
                          </div>
                          <div className="mb-9 text-xl text-gray-700">
                            <p className="text-center md:text-left">Correo</p>
                            <Field
                              name="email"
                              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                              type="text"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-red-400 font-bold text-xl"
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                          <div className="mb-9 text-xl text-gray-700">
                            <p className="text-center md:text-left">
                              Número de Whatsapp
                            </p>
                            <Field
                              name="phone"
                              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                              type="text"
                            />
                            <ErrorMessage
                              name="phone"
                              component="div"
                              className="text-red-400 font-bold text-xl"
                            />
                          </div>
                          <div className="mb-9 text-xl text-gray-700">
                            <p className="text-center md:text-left">Edad</p>
                            <Field
                              name="age"
                              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                              type="text"
                            />
                            <ErrorMessage
                              name="age"
                              component="div"
                              className="text-red-400 font-bold text-xl"
                            />
                          </div>
                          <div className="mb-9 text-xl text-gray-700">
                            <p className="text-center md:text-left">
                              Correo de la persona que te está atendiendo y
                              envió formulario
                            </p>
                            <Field
                              name="personAssists"
                              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                              type="text"
                            />
                            <ErrorMessage
                              name="personAssists"
                              component="div"
                              className="text-red-400 font-bold text-xl"
                            />
                          </div>
                          <div className="mb-9 text-xl text-gray-700 mt-auto">
                            <p className="text-center md:text-left">País:</p>
                            <Field
                              as="select"
                              onChange={(e) => {
                                changeCountry(e.target.value, setFieldValue);
                              }}
                              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                              name="chooseCountry"
                            >
                              <option value={"Mexico"}>México</option>
                              <option value={""}>Otro</option>
                            </Field>
                            <ErrorMessage
                              name="chooseCountry"
                              component="div"
                              className="text-red-400 font-bold text-xl"
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols- md:grid-cols-2 gap-x-10">
                          {!anotherCountry ? (
                            <div className="mb-9 text-xl text-gray-700">
                              <p className="text-center md:text-left">
                                Estado:
                              </p>
                              <Field
                                as="select"
                                onChange={(e) => {
                                  returnCities(e.target.value, setFieldValue);
                                  setFieldValue("state", e.target.value);
                                }}
                                className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                name="state"
                              >
                                <option value={null}>
                                  Seleccione un estado
                                </option>
                                {states.map(({ nombre }, index) => (
                                  <option value={nombre}>{nombre}</option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="state"
                                component="div"
                                className="text-red-400 font-bold text-xl"
                              />
                            </div>
                          ) : (
                            <div className="mb-9 text-xl text-gray-700">
                              <p className="text-center md:text-left">
                                Escribe tu pais
                              </p>
                              <Field
                                name="country"
                                className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                type="text"
                              />
                              <ErrorMessage
                                name="country"
                                component="div"
                                className="text-red-400 font-bold text-xl"
                              />
                            </div>
                          )}

                          {!anotherCountry ? (
                            <div className="mb-9 text-xl text-gray-700">
                              <p className="text-center md:text-left">
                                Ciudad:
                              </p>
                              <Field
                                as="select"
                                className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                name="city"
                              >
                                <option value={null}>
                                  Seleccione su ciudad
                                </option>
                                {citiesPerState &&
                                  citiesPerState.map((value, index) => (
                                    <option value={value}>{value}</option>
                                  ))}
                              </Field>
                              <ErrorMessage
                                name="city"
                                component="div"
                                className="text-red-400 font-bold text-xl"
                              />
                            </div>
                          ) : (
                            <div className="mb-9 text-xl text-gray-700">
                              <p className="text-center md:text-left">Estado</p>
                              <Field
                                name="state"
                                className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                type="text"
                              />
                              <ErrorMessage
                                name="state"
                                component="div"
                                className="text-red-400 font-bold text-xl"
                              />
                            </div>
                          )}

                          <div className="mb-9 text-xl text-gray-700">
                            {anotherCountry && (
                              <div className="mb-9 text-xl text-gray-700">
                                <p className="text-center md:text-left">
                                  Ciudad
                                </p>
                                <Field
                                  name="city"
                                  className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                  type="text"
                                />
                                <ErrorMessage
                                  name="city"
                                  component="div"
                                  className="text-red-400 font-bold text-xl"
                                />
                              </div>
                            )}
                            <p className="text-center md:text-left">
                              Actualmente eres:
                            </p>
                            <Field
                              as="select"
                              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                              name="occupation"
                            >
                              <option value="1">Empleado</option>
                              <option value="2">Emprendedor</option>
                              <option value="3">Ama de casa</option>
                              <option value="4">Estudiante</option>
                              <option value="5">Jubilado</option>
                              <option value="6">Otros</option>
                            </Field>
                            <ErrorMessage
                              name="occupation"
                              component="div"
                              className="text-red-400 font-bold text-xl"
                            />
                          </div>
                        </div>
                        <div className="mb-9 text-xl text-gray-700">
                          <p className="text-center md:text-left">
                            Descripción de lo que haces actualmente y horario:
                          </p>
                          <Field
                            as="textarea"
                            name="occupationDescription"
                            className="w-full h-48 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                          />
                          <ErrorMessage
                            name="occupationDescription"
                            component="div"
                            className="text-red-400 font-bold text-xl"
                          />
                        </div>
                        <YNCheckbox
                          question={"¿Has emprendido algo?"}
                          name={"hasUndertaken"}
                        />
                        <MultipleCheckboxes
                          question="¿Prefieres resultados a largo o corto plazo?"
                          name="results"
                          checkboxes={[
                            {
                              text: "Largo plazo",
                              value: "1",
                            },
                            {
                              text: "Corto plazo",
                              value: "2",
                            },
                          ]}
                          checkFull={true}
                        />
                        <YNCheckbox
                          question={"¿Te da miedo perder dinero?"}
                          name={"hasFearLostMoney"}
                        />
                        <YNCheckbox
                          question={"¿Te adaptas bien a los cambios?"}
                          name={"acceptChanges"}
                        />

                        <div className="mb-9 text-xl text-gray-700">
                          <p className="text-center md:text-left mb-1">
                            ¿Cuántas horas al día y cuántos días a la semana
                            puedes dedicarle a tu nuevo negocio con el sistema
                            Be Global Pro?
                          </p>
                          <Field
                            as="textarea"
                            name="timeToInvest"
                            className="w-full h-48 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                            type="text"
                          />
                          <ErrorMessage
                            name="timeToInvest"
                            component="div"
                            className="text-red-400 font-bold text-xl"
                          />
                        </div>

                        <MultipleCheckboxes
                          question="¿Cuánto te gustaría GENERAR DE UTILIDAD EN TUS PRIMEROS 30 DÍAS dentro del sistema Be Global Pro?"
                          message={true}
                          name="firstMonth"
                          checkboxes={[
                            {
                              text: "Recuperar inversión",
                              value: "1",
                            },
                            {
                              text: "+$5,000",
                              value: "2",
                            },
                            {
                              text: "+$10,000",
                              value: "3",
                            },
                            {
                              text: "+$20,000",
                              value: "4",
                            },
                            {
                              text: "+$40,000",
                              value: "5",
                            },
                            {
                              text: "+$80,000",
                              value: "6",
                            },
                            {
                              text: "Aprendizaje",
                              value: "7",
                            },
                          ]}
                          checkFull={true}
                        />
                        <YNCheckbox
                          question={"¿Sabes trabajar en equipo?"}
                          name={"hasCollaborative"}
                        />
                        <YNCheckbox
                          question={"¿Tienes iniciativa?"}
                          name={"hasIniciative"}
                        />
                        <YNCheckbox
                          question={"¿Eres perseverante?"}
                          name={"hasPerseverance"}
                        />
                        <YNCheckbox
                          question={"¿Te rindes fácilmente?"}
                          name={"easyGiveUp"}
                        />
                        <div className="mb-9 text-xl text-gray-700">
                          <p className="text-center md:text-left">
                            ¿Por qué quieres tener un negocio digital?
                          </p>
                          <Field
                            as="textarea"
                            name="whyDigitalBusiness"
                            className="w-full h-30 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                          />
                          <ErrorMessage
                            name="whyDigitalBusiness"
                            component="div"
                            className="text-red-400 font-bold text-xl"
                          />
                        </div>
                        <YNCheckbox
                          question={"¿Puedes enfrentarte al fracaso?"}
                          name={"acceptFailure"}
                        />
                        <YNCheckbox
                          question={"¿Piensas comprometerte en este negocio?"}
                          name={"hasBusinessCommitment"}
                        />
                        <YNCheckbox
                          question={
                            "¿Estás listo para convertirte en tu propio jefe?"
                          }
                          name={"becomeOwnBoss"}
                        />

                        <div className="mb-9 text-xl text-gray-700">
                          <p className="text-center md:text-left">
                            El negocio lo harías solo/a o con alguien más en
                            equipo?, ¿Quién?
                          </p>
                          <Field
                            as="textarea"
                            name="businessWith"
                            className="w-full h-30 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                          />
                          <ErrorMessage
                            name="businessWith"
                            component="div"
                            className="text-red-400 font-bold text-xl"
                          />
                        </div>
                        {multipleSelectOne.map((value, index) => (
                          <MultipleCheckboxes
                            key={index}
                            question={value.question}
                            name={value.name}
                            checkboxes={value.checkboxes}
                            checkFull={true}
                            box={value.box}
                          />
                        ))}

                        <RateCheckboxes
                          question="Del 1 al 10 qué tanto sabes de Instagram, siendo 1 nada y 10 experto. "
                          name="instagramKnowledge"
                        ></RateCheckboxes>
                        <RateCheckboxes
                          question="Del 1 al 10 qué tanto sabes de Facebook Ads, siendo 1 nada y 10 experto.(Facebook Ads es donde se hace publicidad pagada) "
                          name="facebookAdsKnowledge"
                        ></RateCheckboxes>
                        <RateCheckboxes
                          question="Del 1 al 10 qué tanto sabes de CANVA siendo 1 nada y 10 experto."
                          name="canvasKnowledge"
                        ></RateCheckboxes>
                        <YNCheckbox
                          question={"¿Has usado WhatsApp business?"}
                          name={"hasUsedWhatsAppBussiness"}
                        />
                        <YNCheckbox
                          question={"¿Has pagado publicidad en Google?"}
                          name={"hasPayedGoogleAds"}
                        />
                        <YNCheckbox
                          question={"¿Eres creativo?"}
                          name={"creativePerson"}
                        />
                        <YNCheckbox
                          question={"¿Sabes seguir indicaciones?"}
                          name={"knowFollowIndications"}
                        />

                        <div className="mb-9 text-xl text-gray-700">
                          <p className="text-center md:text-left">
                            Si le preguntara a tu último jefe sobre ti, ¿Qué me
                            diría?
                          </p>
                          <Field
                            as="textarea"
                            name="opinionLastBoss"
                            className="w-full h-48 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                            type="text"
                          />
                          <ErrorMessage
                            name="opinionLastBoss"
                            component="div"
                            className="text-red-400 font-bold text-xl"
                          />
                        </div>
                        <YNCheckbox
                          question={
                            "¿Estás consciente que los resultados del negocio dependen 100% de ti?"
                          }
                          name={"resultsAgree"}
                        />
                        <YNCheckbox
                          question={
                            "¿Estás consciente que nosotros te damos la receta y tú tienes que hacerla?"
                          }
                          name={"recipeAgree"}
                        />
                        <YNCheckbox
                          question={
                            "¿Cuentas con la inversión de la membresía en este momento?"
                          }
                          name={"hasMoneyMembership"}
                        />
                        <MultipleCheckboxes
                          question="¿Qué membresía quieres adquirir?"
                          name="membership"
                          checkboxes={[
                            {
                              text: "Anual plus de $42,000",
                              value: "5",
                            },
                            {
                              text: "Semestral plus de $27,000",
                              value: "7",
                            },
                            {
                              text: "Trimestral plus de $17,000",
                              value: "4",
                            },
                            // {
                            //   text: "Mensual plus de $10,000",
                            //   value: "3",
                            // },
                            // {
                            //   text: "Emprendedor 6,000",
                            //   value: "9",
                            // },
                            // {
                            //   text: "Básica Educación $6,000",
                            //   value: "1",
                            // },

                            // {
                            //   text: "Dropshipping $15,000",
                            //   value: "2",
                            // },
                          ]}
                          checkFull={true}
                        />
                        <YNCheckbox
                          question={"¿Estás dado de alta en hacienda?"}
                          name={"satRegistered"}
                        />
                        <YNCheckbox
                          question={
                            "Si quisieras vender desde el inicio por Mercado libre o Amazon, ¿Tienes la capacidad económica de pagar cada producto que se te vaya vendiendo?"
                          }
                          name={"hasCapital"}
                        />
                        <div className="mb-9 text-xl text-gray-700">
                          <p className="text-center md:text-left">
                            ¿Cuál es tu idea y concepto sobre Be Global Pro?
                          </p>
                          <Field
                            as="textarea"
                            name="ideaAndConcept"
                            className="w-full h-30 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                          />
                          <ErrorMessage
                            name="ideaAndConcept"
                            component="div"
                            className="text-red-400 font-bold text-xl"
                          />
                        </div>
                        <div className="mb-9 text-xl text-gray-700">
                          <p className="text-center md:text-left">
                            ¿Qué esperas de Be Global Pro?
                          </p>
                          <Field
                            as="textarea"
                            name="expectation"
                            className="w-full h-30 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                          />
                          <ErrorMessage
                            name="expectation"
                            component="div"
                            className="text-red-400 font-bold text-xl"
                          />
                        </div>
                        <YNCheckbox
                          question={"¿Eres ordenado?"}
                          name={"organizedPerson"}
                        />
                        <YNCheckbox
                          question={"¿Hablarías frente a la cámara?"}
                          name={"talkToCamera"}
                        />

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                          <div className="mb-9 text-xl text-gray-700 col-end-2">
                            <p className="mb-0 md:mb-7 lg:mb-0 text-center md:text-left">
                              ¿Qué celular tienes? Modelo exacto
                            </p>
                            <Field
                              name="phoneModel"
                              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                              type="text"
                            />
                            <ErrorMessage
                              name="phoneModel"
                              component="div"
                              className="text-red-400 font-bold text-xl"
                            />
                          </div>
                          <div className="mb-9 text-xl text-gray-700">
                            <p className="text-center md:text-left">
                              ¿Cuentas con computadora? ¿Rápida, lenta o normal?
                            </p>
                            <Field
                              as="select"
                              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                              name="computer"
                            >
                              <option value="1">No, no cuento con una</option>
                              <option value="2">Si, una normal</option>
                              <option value="3">Si, una lenta</option>
                              <option value="4">Si, una rapida</option>
                            </Field>
                            <ErrorMessage
                              name="computer"
                              component="div"
                              className="text-red-400 font-bold text-xl"
                            />
                          </div>
                        </div>
                        <YNCheckbox
                          question={"¿Confías en ti mismo?"}
                          name={"trustYourself"}
                        />
                        <div className="mb-9 text-xl text-gray-700">
                          <p className="text-center md:text-left">
                            Cuales son tus 3 principales valores como persona:
                          </p>
                          <Field
                            as="textarea"
                            name="personValues"
                            className="w-full h-30 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                          />
                          <ErrorMessage
                            name="personValues"
                            component="div"
                            className="text-red-400 font-bold text-xl"
                          />
                        </div>
                        <div className="mb-9 text-xl text-gray-700">
                          <p className="text-center md:text-left">
                            Déjanos 4 referencias (2 laborales y 2 personales)
                            en caso de ser necesario les llamaremos o enviaremos
                            WhatsApp para pedir referencia sobre ti.
                          </p>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                          <div className="mb-9 text-xl text-gray-700 col-end-2">
                            <p className="text-center md:text-left">
                              Primera referencia laboral
                            </p>
                            <Field
                              name="workReferenceName1"
                              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                              type="text"
                            />
                            <ErrorMessage
                              name="workReferenceName1"
                              component="div"
                              className="text-red-400 font-bold text-xl"
                            />
                          </div>
                          <div className="mb-9 text-xl text-gray-700">
                            <p className="text-center md:text-left">WhatsApp</p>
                            <Field
                              name="workReferencePhone1"
                              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                              type="text"
                            />
                            <ErrorMessage
                              name="workReferencePhone1"
                              component="div"
                              className="text-red-400 font-bold text-xl"
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                          <div className="mb-9 text-xl text-gray-700 col-end-2">
                            <p className="text-center md:text-left">
                              Segunda referencia laboral
                            </p>
                            <Field
                              name="workReferenceName2"
                              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                              type="text"
                            />
                            <ErrorMessage
                              name="workReferenceName2"
                              component="div"
                              className="text-red-400 font-bold text-xl"
                            />
                          </div>
                          <div className="mb-9 text-xl text-gray-700">
                            <p className="text-center md:text-left">WhatsApp</p>
                            <Field
                              name="workReferencePhone2"
                              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                              type="text"
                            />
                            <ErrorMessage
                              name="workReferencePhone2"
                              component="div"
                              className="text-red-400 font-bold text-xl"
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                          <div className="mb-9 text-xl text-gray-700 col-end-2">
                            <p className="text-center md:text-left">
                              Primera referencia personal
                            </p>
                            <Field
                              name="personalReferenceName1"
                              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                              type="text"
                            />
                            <ErrorMessage
                              name="personalReferenceName1"
                              component="div"
                              className="text-red-400 font-bold text-xl"
                            />
                          </div>
                          <div className="mb-9 text-xl text-gray-700">
                            <p className="text-center md:text-left">WhatsApp</p>
                            <Field
                              name="personalReferencePhone1"
                              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                              type="text"
                            />
                            <ErrorMessage
                              name="personalReferencePhone1"
                              component="div"
                              className="text-red-400 font-bold text-xl"
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                          <div className="mb-9 text-xl text-gray-700 col-end-2">
                            <p className="text-center md:text-left">
                              Segunda referencia personal
                            </p>
                            <Field
                              name="personalReferenceName2"
                              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                              type="text"
                            />
                            <ErrorMessage
                              name="personalReferenceName2"
                              component="div"
                              className="text-red-400 font-bold text-xl"
                            />
                          </div>
                          <div className="mb-9 text-xl text-gray-700">
                            <p className="text-center md:text-left">WhatsApp</p>
                            <Field
                              name="personalReferencePhone2"
                              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                              type="text"
                            />
                            <ErrorMessage
                              name="personalReferencePhone2"
                              component="div"
                              className="text-red-400 font-bold text-xl"
                            />
                          </div>
                        </div>
                        {array.map((value, index) => (
                          <YNCheckbox
                            key={index}
                            question={value.question}
                            name={value.name}
                            link={value.link}
                            linkTitle={value.linkTitle}
                          />
                        ))}

                        {/* BUTTONS */}
                        <div className="w-full flex justify-end mt-5 mb-10 lg:mt-10">
                          {loading ? (
                            <div
                              className={`text-center bg-primary-200 w-full md:w-56  text-white text-2xl py-3 px-5 disabled:opacity-75 mt-5`}
                            >
                              <FontAwesomeIcon icon="circle-notch" spin />{" "}
                              Enviando...
                            </div>
                          ) : (
                            <div className="flex flex-col items-end">
                              {Object.keys(errors).length > 0 && (
                                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                                  <span className="block">
                                    Asegúrese de llenar todos los campos del
                                    formulario
                                  </span>
                                </div>
                              )}
                              <button
                                type="submit"
                                className="bg-primary-200  w-44 md:w-56  text-white text-2xl py-3 px-5 disabled:opacity-75 mt-5"
                              >
                                Enviar
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`my-40 lg:my-10 container mx-auto relative ${
          correctSubmit ? "block" : "hidden"
        }`}
      >
        <div className="space-y-5">
          <div className="bg-green-50 border-green-50 border-4 rounded-full w-12 h-12 lg:w-24 lg:h-24 mx-auto flex items-center justify-center ">
            <img src={checkIcon} alt="" />
          </div>

          <p className=" text-2xl lg:text-5xl text-center  text-green-50 font-bold">
            Formulario enviado.
          </p>
          <p className=" text-xl lg:text-3xl text-center">
            El formulario se ha enviado correctamente.
          </p>
          <div className=" text-gray-700 text-xl lg:text-2xl text-center">
            <Link
              to="/"
              className="bg-primary-200 py-3 px-12 text-white font-bold border-primary-200 border-2 mt-5 rounded-lg"
            >
              Volver a inicio
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
