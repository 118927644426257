/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useContext } from "react";
import Context from "../context/UserContext";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/es";
import BasicModal from "../components/Modals/BasicModalEvent";
import { eventServices } from "../services/api/events";
import { notifications } from "../utils/notifications";
import CalendarComponent, {
  changeEventFormat,
} from "../components/Calendar/CalendarComponent";
import ScheduleEventComponent from "../components/Calendar/ScheduleEventComponent";
import RegistrationEventForm from "../components/Calendar/RegistrationEventForm";

export let navigate = {
  PREVIOUS: "PREV",
  NEXT: "NEXT",
  TODAY: "TODAY",
  DATE: "DATE",
};
moment.locale("es");

export default function Index() {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [eventsCalendar, setEventsCalendar] = useState([]);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useContext(Context);

  const fetchEvents = () => {
    if (currentUser) {
      eventServices
        .getUserEvents()
        .then((response) => {
          setEventsCalendar(response.data.events);
        })
        .catch((err) => {});
    } else {
      eventServices
        .getEvents()
        .then((response) => {
          setEventsCalendar(response.data.events);
        })
        .catch((err) => {});
    }
  };

  const handleSubmit = async (values, id) => {
    setLoading(true);
    let bodyFormData = new FormData();
    bodyFormData.append("name", values.name);
    bodyFormData.append("email", values.email);
    bodyFormData.append("phone", values.phone);
    bodyFormData.append("id", id);
    await eventServices
      .setEventGuest(bodyFormData)
      .then((response) => {
        notifications.success(response.data.message);
        setShowModal(false);
        fetchEvents();
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const clickEventInfo = (event) => {
    const handleSubmit = async (id) => {
      let bodyFormData = new FormData();
      bodyFormData.append("id", id);
      await eventServices
        .SetEvent(bodyFormData)
        .then((response) => {
          notifications.success(response.data.message);
          setShowModal(false);
          fetchEvents();
        })
        .catch((err) => {});
    };

    setModalContent(
      <ScheduleEventComponent
        event={event}
        handleSubmit={(id) => handleSubmit(id)}
        RegistrationForm={(event) => openRegistrationForm(event)}
      ></ScheduleEventComponent>
    );
    setShowModal(true);
  };

  const openRegistrationForm = (event) => {
    setModalContent(
      <RegistrationEventForm
        clickEventInfo={(event) => clickEventInfo(event)}
        event={event}
        handleSubmit={(values, id) => handleSubmit(values, id)}
        loading={loading}
      ></RegistrationEventForm>
    );
    setShowModal(true);
  };

  useEffect(() => {
    fetchEvents();
  }, [currentUser]);

  return (
    <div>
      {eventsCalendar ? eventsCalendar.forEach(changeEventFormat) : ""}
      <BasicModal
        visible={showModal}
        setVisible={setShowModal}
        modalContent={modalContent}
      />
      <div
        className="container mx-auto my-20 capitalize px-4"
        style={{ height: "500pt" }}
      >
        <div className="text-gray-650 normal-case text-center lg:text-left text-2xl mb-5">
          Te explicamos paso a paso como funciona nuestro sistema para que no
          quede ninguna duda, selecciona una fecha para agendar.
        </div>
        <CalendarComponent
          eventsCalendar={eventsCalendar}
          getEvent={clickEventInfo}
        />
      </div>
    </div>
  );
}
