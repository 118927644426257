import { React } from "react";
import List from "../../components/memberships/CheckList";

const PlusLayout = ({ ...props }) => {
  return (
    <div>
      {props.newPrice ? (
        <p className="text-xl lg:text-3xl font-bold text-primary-200">
          <b className="line-through text-gray-650">{props.price}</b>
          <b className="text-green-50 w-auto">
            {" "}
            ¡Promoción por tiempo limitado!: {props.newPrice}
          </b>
        </p>
      ) : (
        <p className="text-xl lg:text-4xl font-bold text-primary-200">
          {props.price}
        </p>
      )}
      {props.newDescription ? (
        <p className="text-xl lg:text-2xl  text-gray-650 mb-4">
          <b className="line-through">{props.description}</b>
          <b className="text-green-50"> {props.newDescription}</b>
        </p>
      ) : (
        <p className="text-xl lg:text-2xl  text-gray-650 mb-4">
          {props.description}
        </p>
      )}

      <div className="text-xl lg:text-2xl text-gray-650">
        <p className="mb-2 lg:mb-5 font-bold lg:text-3xl">Incluye:</p>
        <List elements={props.elements}></List>
      </div>
    </div>
  );
};
export default PlusLayout;
