import { useCallback, useContext, useState } from "react";
import { notifications } from "../utils/notifications";

import Context from "../context/UserContext";
import { profileService } from "../services/api/profile";

export default function useUser() {
  const { setCurrentUser } = useContext(Context);
  const [_state, setState] = useState({
    loading: false,
    error: false,
    success: false,
  });
  const [errors, setErrors] = useState([]);

  const [billingInformation, setBillingInformation] = useState({
    rfc: "",
    business_name: "",
    address: "",
    phone: "",
    email: "",
  });

  const getUser = useCallback(async () => {
    await profileService
      .getUser()
      .then((response) => {
        setCurrentUser(response.data.user);
      })
      .catch((err) => {
        setErrors(err.response.data.errors);
      });
  }, [setCurrentUser]);

  const updateUser = useCallback(
    async ({ name, phone, address, city, state, country }) => {
      setState({ loading: true, error: false, success: false });
      await profileService
        .updateUser({ name, phone, address, city, state, country })
        .then((response) => {
          setState({ loading: false, error: false, success: true });
          notifications.success(response.data.message);
          getUser();
        })
        .catch((err) => {
          setState({ loading: false, error: true, success: false });
          setErrors(err.response.data.errors);
        });
    },
    [getUser]
  );

 

  const getBillingInfo = useCallback(async () => {
    await profileService
      .getBillingInfo()
      .then((response) => {
        setBillingInformation(response.data.billingInformation);
      })
      .catch((err) => {
        setErrors(err.response.data.errors);
      });
  }, []);

  const updateBillingInfo = useCallback(
    async ({ rfc, business_name, address, phone, email }) => {
      setState({ loading: true, error: false, success: false });

      await profileService
        .updateBillingInfo({ rfc, business_name, address, phone, email })
        .then((response) => {
          setState({ loading: false, error: false, success: true });
          notifications.success(response.data.message);
        })
        .catch((err) => {
          setState({ loading: false, error: true, success: false });
          setErrors(err.response.data.errors);
        });
    },
    []
  );

  return {
    isLoading: _state.loading,
    hasError: _state.error,
    onSucess: _state.success,
    getUser,
    updateUser,
    getBillingInfo,
    updateBillingInfo,
    billingInfo: billingInformation,
    errors,
  };
}
