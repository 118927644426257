import { api } from "../../client";

export const authService = {
  login(model) {
    return api.base.post("/auth/login", model);
  },
  verify() {
    return api.base.get("/user");
  },
  register(model) {
    return api.base.post("/user", model);
  },
  verifyAccount(model) {
    return api.base.post("/user/verify", model);
  },
  resendVerificationCode(model) {
    return api.base.post("/user/resend-verification", model);
  },
  sendPasswordReset(model) {
    return api.base.post("/user/send-reset-password", model);
  },
  passwordReset(model) {
    return api.base.post("/user/reset-password", model);
  },
  logout() {
    return api.base.post("/auth/logout");
  },
};
