import { useEffect, useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as _ from "lodash";

import ChangeImageProfileModal from "../../../components/Modals/ChangeImageProfileModal";
import Context from "../../../context/AdminUserContext";
import { notifications } from "../../../utils/notifications";
import { adminProfileService } from "../../../services/api/admin/profile";

const Index = () => {
  const [user, SetUser] = useState();
  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    title: Yup.string().max(255, `Máximo 255 caracteres`),
    semblance: Yup.string().max(255, `Máximo 255 caracteres`),
  });

  const formSchemaReviewer = Yup.object().shape({
    name: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    title: Yup.string().max(255, `Máximo 255 caracteres`),
  });

  const formSchemaSells = Yup.object().shape({
    name: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
  });

  const formSchemaProvideer = Yup.object().shape({
    name: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    title: Yup.string().max(255, `Máximo 255 caracteres`),
    semblance: Yup.string(),
    payment_card_holder: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    payment_reference: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    payment_bank: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    payment_card: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    payment_information: Yup.string().required("Campo requerido"),
  });

  const { currentAdminUser, setCurrentAdminUser } = useContext(Context);

  const [initialValues, setInitialValues] = useState({
    name: "",
    title: "",
    semblance: "",
    payment_reference: "",
    payment_bank: "",
    payment_card: "",
    payment_card_holder: "",
    payment_information: "",
  });
  const [showChangeImageModal, setShowChangeImageModal] = useState(false);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    if (!currentAdminUser) return;
    adminProfileService
      .getUser()
      .then((response) => {
        let user = response.data.admin;
        SetUser(user);
        setInitialValues({
          name: user.name || "",
          title: user.title || "",
          semblance: user.semblance || "",
          payment_reference: user.payment_reference || "",
          payment_card_holder: user.payment_card_holder || "",
          payment_card: user.payment_card || "",
          payment_bank: user.payment_bank || "",
          payment_information: user.payment_information || "",
        });
        setCourses(user.courses);
      })
      .catch((err) => {});
  }, [currentAdminUser]);

  const handleSubmit = async (values) => {
    let body = values;
    switch (currentAdminUser.type) {
      case "Proveedor":
        delete body.title;
        delete body.semblance;
        break;
      case "Profesor":
        body = {
          name: body.name,
          title: body.title,
          semblance: body.semblance,
        };
      case "Revisor":
        body = {
          name: body.name,
          title: body.title,
        };
        break;
      case "Ventas":
        body = {
          name: body.name,
        };
        break;
      default:
        break;
    }

    await adminProfileService
      .updateUser(body)
      .then((response) => {
        setCurrentAdminUser(response.data.admin);
        notifications.success(response.data.message);
      })
      .catch((err) => {});
  };
  const returnSchema = (type) => {
    switch (type) {
      case "Proveedor":
        return formSchemaProvideer;
      case "Revisor":
        return formSchemaReviewer;
      case "Ventas":
        return formSchemaSells;
      default:
        return formSchema;
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={user && returnSchema(user.type)}
        onSubmit={(values) => handleSubmit(values)}
      >
        <Form>
          <div className="flex flex-col md:flex-row gap-x-10">
            <div className="w-auto mx-auto mb-10 md:mb-0">
              <div className="w-52 h-52">
                <button
                  className="rounded-full border-4 border-primary-200 w-full h-full hover:shadow-2xl"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowChangeImageModal(true);
                  }}
                >
                  <img
                    src={currentAdminUser && currentAdminUser.image}
                    alt=""
                    className="rounded-full w-full h-full object-cover"
                    style={{ backgroundColor: "#D8D8D8" }}
                  />
                </button>
              </div>
            </div>
            <div className="w-full">
              <div className="mb-9 text-2xl text-gray-550">
                <p>Nombre completo</p>
                <Field
                  name="name"
                  className="w-full bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none"
                  type="text"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>

              {currentAdminUser.type !== "Proveedor" &&
                currentAdminUser.type !== "Ventas" && (
                  <div className="mb-9 text-2xl text-gray-550">
                    <p>Titulo</p>
                    <Field
                      name="title"
                      className="w-full bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none"
                      type="text"
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="text-red-400 font-bold text-xl"
                    />
                  </div>
                )}
            </div>
          </div>

          {currentAdminUser.type !== "Proveedor" &&
            currentAdminUser.type !== "Revisor" &&
            currentAdminUser.type !== "Ventas" && (
              <div className="mb-9 text-2xl text-gray-550">
                <p>Semblanza</p>
                <Field
                  as="textarea"
                  name="semblance"
                  className="w-full h-24 md:h-32 lg:h-44 border border-slate-300 text-gray-700 placeholder:text-gray-700 py-3 px-4 resize-none focus:outline-none"
                />
                <ErrorMessage
                  name="semblance"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>
            )}

          {currentAdminUser.type !== "Profesor" &&
            currentAdminUser.type !== "Revisor" &&
            currentAdminUser.type !== "Ventas" && (
              <div className="mb-9 text-2xl text-gray-550 mt-9">
                <p>Información de Pagos</p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                  <div className="mb-9 text-xl text-gray-700">
                    <p>CLABE</p>
                    <Field
                      name="payment_reference"
                      className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                      type="text"
                    />
                    <ErrorMessage
                      name="payment_reference"
                      component="div"
                      className="text-red-400 font-bold text-xl"
                    />
                  </div>
                  <div className="mb-9 text-xl text-gray-700">
                    <p>BANCO</p>
                    <Field
                      name="payment_bank"
                      className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                      type="text"
                    />
                    <ErrorMessage
                      name="payment_bank"
                      component="div"
                      className="text-red-400 font-bold text-xl"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                  <div className="mb-9 text-xl text-gray-700">
                    <p>Titular</p>
                    <Field
                      name="payment_card_holder"
                      className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                      type="text"
                    />
                    <ErrorMessage
                      name="payment_card_holder"
                      component="div"
                      className="text-red-400 font-bold text-xl"
                    />
                  </div>
                  <div className="mb-9 text-xl text-gray-700">
                    <p>Numero de tarjeta</p>
                    <Field
                      name="payment_card"
                      className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                      type="text"
                    />
                    <ErrorMessage
                      name="payment_card"
                      component="div"
                      className="text-red-400 font-bold text-xl"
                    />
                  </div>
                </div>

                <div className="mb-9 text-xl text-gray-700">
                  <p>Información adicional</p>

                  <Field
                    as="textarea"
                    name="payment_information"
                    className="w-full h-24 md:h-32 lg:h-44 border border-slate-300 text-gray-700 placeholder:text-gray-700 py-3 px-4 resize-none focus:outline-none"
                  />
                  <ErrorMessage
                    name="payment_information"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>
            )}

          <div className="w-full flex justify-end">
            <button
              type="submit"
              className="w-full md:w-1/3 bg-primary-200 text-white font-bold text-2xl py-3 disabled:opacity-75"
              // disabled={isLoading}
            >
              {false ? (
                <FontAwesomeIcon icon="circle-notch" spin />
              ) : (
                <span>Actualizar</span>
              )}
            </button>
          </div>
        </Form>
      </Formik>
      {currentAdminUser.type === "Profesor" && (
        <div className="mt-16 text-gray-700">
          <p className="text-3xl font-bold">Cursos asignados</p>
          {currentAdminUser &&
            (!_.isEmpty(courses) ? (
              <div className="border border-gray-350 mt-5">
                {courses.map((course) => (
                  <div
                    key={course.id}
                    className="flex flex-col md:flex-row justify-between px-8 py-5 border-b border-b-gray-350 w-full"
                  >
                    <div>
                      <p>{course.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="bg-gray-300 border-l-4 border-l-primary-200 p-4 mt-5">
                <p className="text-lg lg:text-xl text-gray-700 font-bold">
                  Aun no cuenta con cursos asignados
                </p>
              </div>
            ))}
        </div>
      )}
      <ChangeImageProfileModal
        visible={showChangeImageModal}
        setVisible={setShowChangeImageModal}
      />
    </div>
  );
};

export default Index;
