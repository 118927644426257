import { api } from "../../client";

export const coursesServices = {
  getCourses() {
    return api.base.get("/course");
  },
  getBestCourses() {
    return api.base.get("/course/best");
  },
  courseDetails(uuid) {
    return api.base.get(`/course/${uuid}`);
  },
  addCourseMembership(uuid) {
    return api.base.post(`/course/${uuid}`);
  },
  getCourseView(uuid) {
    return api.base.get(`/course/${uuid}/view`);
  },
  getCourseContent(uuid, id) {
    return api.base.get(`/course/${uuid}/${id}`);
  },
  saveLessonProgress(model) {
    return api.base.post("/course/progress", model);
  },
  qualifyCourse(model) {
    return api.base.post("/course/qualify", model);
  },
  sendQuestion(model) {
    return api.base.post("/course/question", model);
  },
  sendComment(model) {
    return api.base.post("/course/comment", model);
  },
  updateCourseWishList(model) {
    return api.base.post("/course/wish-list", model);
  },
  updateClassFavorites(model) {
    return api.base.post("/course/classe", model);
  },
  getUserCourses() {
    return api.base.get("/user/courses");
  },
  getUserWishList() {
    return api.base.get("/user/wish-list");
  },
  getUserFavorites() {
    return api.base.get("/user/favorites");
  },
  saveNote(model) {
    return api.base.post("/course/note", model);
  },
  deleteNote(id) {
    return api.base.delete(`/course/note/${id}`);
  },
  generateDiploma(model) {
    return api.base.post("/course/diploma", model, { responseType: "blob" });
  },
};
