import * as _ from "lodash";
import { MEMBERSHIPS_DICTIONARY } from "../../../utils/MembershipsDictionary";

const MembershipsGridsSales = ({ salesTotalChart }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-7y gap-5">
      {Object.entries(MEMBERSHIPS_DICTIONARY).map(
        ([key, membership]) =>
          membership.label !== "" && (
            <div key={key}>
              <div className="text-center font-bold text-2xl text-[#9F9F9F] border border-gray-300 rounded-lg shadow-md p-4">
                <p className="text-2xl leading-none text-gray-700">
                  {membership.label} <br /> <br />
                </p>
                <div className="mt-2">
                  <p className="text-3xl text-primary-200">
                    $
                    {(
                      salesTotalChart[
                        membership.label === "Cursos"
                          ? Object.keys(MEMBERSHIPS_DICTIONARY).length - 1
                          : key - 1
                      ] || 0
                    ).toLocaleString("en-US")}
                  </p>
                  <p className="text-xl">MXN</p>
                </div>
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default MembershipsGridsSales;
