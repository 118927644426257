import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import CourseCompleted from "./CourseCompleted";

const CarrouselCoursesCompleted = ({ courses, completed }) => {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 50,
          },
        }}
        navigation={true}
        modules={[Navigation, Pagination]}
        className="mySwiper3"
      >
        {courses.map((course, index) => (
          <SwiperSlide id={course.id} key={index}>
            <CourseCompleted
              course={course}
              completed={completed}
            ></CourseCompleted>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default CarrouselCoursesCompleted;
