import { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import * as _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Context from "../../context/UserContext";
import { ordersService } from "../../services/api/orders";
import TableLoading from "../../components/TableLoading";

import TableNoData from "../../components/TableNoData";
import {
  getColumnsForOrdersDesktop,
  getFormattedDate,
  customStyles,
  paginationComponentOptions,
} from "../../utils/tableOptions";
import { Link } from "react-router-dom";

const Orders = () => {
  const { currentUser } = useContext(Context);

  const [orders, setOrders] = useState();
  const [formattedOrders, setFormattedOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchOrders = () => {
    setIsLoading(true);
    ordersService
      .getAllOrders()
      .then((response) => {
        setOrders(response.data.orders);
      })
      .catch((err) => {})
      .finally(() => setIsLoading(false));
  };

  const formatOrdersData = () => {
    let data = [];

    let dataColorsStatus = {
      Checkout: {
        class: "text-gray-700",
        text: "Sin confirmar",
      },
      AwaitingPayment: {
        class: "text-gray-700",
        text: "Pendiente de pago",
      },
      Payment: {
        class: "text-secondary",
        text: "Pagado",
      },
      ConfirmPayment: {
        class: "text-green-50",
        text: "Pago confirmado",
      },
      DeniedPayment: {
        class: "text-tertiary",
        text: "Pago rechazado",
      },
      Send: {
        class: "text-gray-700",
        text: "Enviado",
      },
      Finish: {
        class: "text-primary-200",
        text: "Completado", // Finalizado
      },
    };

    orders.forEach((order, idx) => {
      data.push({
        order: <span className="font-bold text-primary-200">{order.id}</span>,
        date: getFormattedDate(order.created_at),
        total: (
          <p className="font-bold">
            $
            {order.total.toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })}{" "}
            <small>MXN</small>
          </p>
        ),
        status: (
          <span
            className={`font-bold	 ${
              order.status && dataColorsStatus[order.status]
                ? dataColorsStatus[order.status].class
                : "text-gray-700"
            }`}
          >
            {order.status && dataColorsStatus[order.status]
              ? dataColorsStatus[order.status].text
              : "---"}
          </span>
        ),
        actionComponent:
          order.status !== "Checkout" ? (
            <Link
              to={`/mi-escritorio/orden/${order.id}`}
              className="w-full text-primary-200 hover:text-primary-300 text-center text-sm lg:text-base"
            >
              Ver detalles
            </Link>
          ) : (
            <FontAwesomeIcon
              icon={["fa", "ban"]}
              size="1x"
              className="text-gray-700"
            />
          ),
      });
    });

    setFormattedOrders(data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!currentUser) return;
    fetchOrders();
  }, [currentUser]);

  useEffect(() => {
    if (_.isEmpty(orders)) return;
    formatOrdersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  return (
    <>
      <div>
        <div className="bg-white p-8 rounded-md w-full">
          <h1 className="text-gray-700 font-bold text-xl lg:text-3xl mb-6 text-center md:text-left">
            Mis Ordenes
          </h1>

          <div className="border border-gray-350 mt-12">
            <DataTable
              columns={getColumnsForOrdersDesktop}
              data={formattedOrders}
              noDataComponent={<TableNoData />}
              progressPending={isLoading}
              progressComponent={<TableLoading size="5x" />}
              customStyles={customStyles}
              pagination
              paginationComponentOptions={paginationComponentOptions}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;
