import { api } from "../../../client";

export const adminShippingsServices = {
  getShippings() {
    return api.admin.get(`/admin/shipping`);
  },
  getShipping(id) {
    return api.admin.get(`/admin/shipping/${id}`);
  },
  createShipping(model) {
    return api.admin.post(`/admin/shipping`, model);
  },
  updateShipping(id, model) {
    return api.admin.put(`/admin/shipping/${id}`, model);
  },
  deleteShipping(id) {
    return api.admin.delete(`/admin/shipping/${id}`);
  },
};
