import { api } from "../../../client";

export const adminNewsService = {
  getNews() {
    return api.admin.get("/admin/new");
  },
  uploadNew(model) {
    return api.admin.post("/admin/new", model, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  updateNew(uuid, model) {
    return api.admin.post(`/admin/new/${uuid}`, model, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  deleteNew(id) {
    return api.admin.delete(`/admin/new/${id}`);
  },
};
