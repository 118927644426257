
const NotFoundPage = () => {
  return (
    <main className="container mx-auto flex-1 px-4 md:px-0 mb-16">
      <div className="my-20">
        <div className="text-center text-9xl text-primary-200">404</div>

        <p className="pb-2 lg:pb-8 text-2xl lg:text-5xl text-center  text-primary-200 font-bold">
          RECURSO NO ENCONTRADO
        </p>
        <p className="pb-8 text-xl lg:text-3xl text-center">
          Lo sentimos, no pudimos encontrar la sección que solicitaste.
        </p>
        
      </div>
    </main>
  );
};

export default NotFoundPage;
