import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Context from "../../../context/AdminUserContext";
import TableLoading from "../../../components/TableLoading";
import TableNoData from "../../../components/TableNoData";
import {
  getColumnsForStores,
  customStyles,
  paginationComponentOptions,
} from "../../../utils/tableOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ShopifyAPIService } from "../../../services/api/admin/shopify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { notifications } from "../../../utils/notifications";

const Index = () => {
  const MySwal = withReactContent(Swal);

  const { currentAdminUser } = useContext(Context);
  const [stores, setStores] = useState([]);
  const [formattedStores, setFormattedStores] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchStores = () => {
    ShopifyAPIService.getStores()
      .then((response) => {
        setStores(response.data.stores);
      })
      .catch((err) => {});
  };

  const formatCouponsData = () => {
    const handleDelete = (id) => {
      MySwal.fire({
        icon: "error",
        title: "¿Deseas eliminar esta tienda?",
        text: "Se eliminará todo el contenido asociado. Esta acción es irreversible",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#42a819",
        confirmButtonText: "Eliminar",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await ShopifyAPIService.deleteStore(id)
            .then((response) => {
              notifications.success(response.data.message);
              fetchStores();
            })
            .catch((err) => {});
        }
      });
    };

    let data = [];

    stores.forEach((store) => {
      data.push({
        name: <span className="font-bold text-primary-200">{store.name}</span>,
        store: store.store,
        token: store.access_token,
        location_id: store.location_id,

        detailsComponent: (
          <button
            onClick={() => handleDelete(store.id)}
            to={`/admin/cupones/${store.id}/editar`}
            className="w-full text-red-50 hover:text-red-500 text-center text-sm lg:text-base"
          >
            Eliminar
          </button>
        ),
      });
    });

    setFormattedStores(data);
    setLoading(false);
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchStores();
  }, [currentAdminUser]);

  useEffect(() => {
    // if (_.isEmpty(orders)) return;
    formatCouponsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores]);

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <p className="text-3xl md:text-4xl font-bold mb-5 md:mb-0">
          Tiendas Shopify
        </p>

        <Link
          to="/admin/tiendas/nueva"
          className="w-full md:w-56 bg-primary-200 text-white text-2xl text-center md:text-left py-3 px-5 disabled:opacity-75"
        >
          <FontAwesomeIcon icon={["fa", "plus"]} size="sm" className="mr-3" />
          Añadir nueva
        </Link>
      </div>

      <div className="border border-gray-350 mt-12">
        {loading ? (
          <TableLoading></TableLoading>
        ) : (
          <DataTable
            columns={getColumnsForStores}
            data={formattedStores}
            noDataComponent={<TableNoData />}
            progressPending={loading}
            progressComponent={<TableLoading />}
            customStyles={customStyles}
            pagination
            paginationComponentOptions={paginationComponentOptions}
          />
        )}
      </div>
    </div>
  );
};

export default Index;
