/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as _ from "lodash";

import Context from "../../../../context/AdminUserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilesDragAndDrop from "../../../../components/FilesDragAndDrop";

import { notifications } from "../../../../utils/notifications";
import { adminCoursesServices } from "../../../../services/api/admin/courses";

const SUPPORTED_FORMATS = [
  "video/mp4",
  "video/avi",
  "video/3gpp",
  "video/quicktime",
];

const NewLesson = () => {
  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    course_section_id: Yup.string()
      .notRequired()
      .max(255, `Máximo 255 caracteres`),
    video: Yup.mixed()
      .required("El video es requerido")
      .test(
        "fileFormat",
        "Formato no soportado",
        (value) =>
          value === null || (value && SUPPORTED_FORMATS.includes(value.type))
      ),
  });

  const { currentAdminUser } = useContext(Context);
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [course, setCourse] = useState({});

  const initialValues = {
    name: "",
    course_section_id: "sin_seccion",
    video: null,
  };

  const fetchCourse = () => {
    adminCoursesServices
      .courseDetails(params.uuid)
      .then((response) => {
        setCourse(response.data.course);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchCourse();
  }, [currentAdminUser]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    let bodyFormData = new FormData();
    bodyFormData.append("uuid", params.uuid);
    if (values.course_section_id !== "sin_seccion") {
      bodyFormData.append("section_id", values.course_section_id);
    }
    bodyFormData.append("name", values.name);
    bodyFormData.append("video", values.video);

    await adminCoursesServices
      .uploadLesson(bodyFormData)
      .then((response) => {
        notifications.success(response.data.message);
        setIsLoading(false);
        navigate(`/admin/cursos/editar/${params.uuid}`);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const onFileChange = (files, setFieldValue) => {
    if (!_.isEmpty(files)) {
      setFieldValue("video", files[0]);
    }
  };

  return (
    <div className="container mx-auto text-gray-700">
      <div>
        <button
          className="mr-auto text-primary-200 mb-5"
          onClick={() => navigate(-1)}
        >
          Regresar
        </button>
        <p className="text-center lg:text-left text-2xl lg:text-4xl font-bold">
          {course.name}
        </p>
        <p className="text-center lg:text-left text-xl lg:text-3xl text-[#A2A2A2] mt-1 lg:mt-5">
          Nueva lección
        </p>
      </div>

      <div className="mt-4 lg:mt-12">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="text-center lg:text-left mb-4 lg:mb-9 text-xl lg:text-3xl text-gray-550">
                  <p>Nombre de la lección</p>
                  <Field
                    name="name"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="text-center lg:text-left mb-4 lg:mb-9 text-xl lg:text-3xl text-gray-550">
                  <p>Sección</p>
                  <Field
                    as="select"
                    name="course_section_id"
                    className="w-full bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none"
                  >
                    <option value="sin_seccion">Sin sección</option>
                    {!_.isEmpty(course) &&
                      course.sections.map((section) => (
                        <option key={section.id} value={section.id}>
                          {section.name}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage
                    name="course_section_id"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>

              <div className="text-center lg:text-left mb-4 lg:mb-9 text-xl lg:text-3xl text-gray-550">
                <p className="mb-5">Subir video de la lección</p>
                <div>
                  <FilesDragAndDrop
                    onFileChange={(files) => onFileChange(files, setFieldValue)}
                    multiple={false}
                  />
                </div>
                <ErrorMessage
                  name="video"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>

              {/* BUTTONS */}
              <div className="w-full flex justify-end mt-5">
                {isLoading ? (
                  <FontAwesomeIcon icon="circle-notch" spin />
                ) : (
                  <button
                    type="submit"
                    className="w-full md:w-56 bg-primary-200 text-white text-xl lg:text-2xl py-3 px-5 disabled:opacity-75 mt-5"
                  >
                    Agregar
                  </button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default NewLesson;
