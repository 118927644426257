const appConfig = {
  api: {
    base: {
      development: {
        url: process.env.REACT_APP_BACKEND_SERVER,
        blog: process.env.REACT_APP_BACKEND_BLOG_SERVER,
      },
    },
  },
  localStorage: {
    namespace: "bgp",
  },
};

export default appConfig;
