import { api } from "../../client";

export const ordersService = {
  getAllOrders() {
    return api.base.get("/order");
  },
  getOrder(id) {
    return api.base.get(`/order/${id}`);
  },
  getCheckout() {
    return api.base.get("/order/checkout");
  },
  updateOrder(id, model) {
    return api.base.post(`/order/${id}`, model);
  },
  sendOrderCostumerMail(id) {
    return api.base.post(`/order/${id}/email`);
  },
};
