import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";

import BGPLogo from "../../assets/images/logo-bgp.svg";

import Context from "../../context/AdminUserContext";
import useUserAdmin from "../../hooks/useUserAdmin";

const Login = () => {
  const navigate = useNavigate();
  const { currentAdminUser } = useContext(Context);

  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required("Campo requerido")
      .email("Correo electronico invalido")
      .max(255, `Máximo 255 caracteres`),
    password: Yup.string()
      .required("Campo requerido")
      .min(8, `Mínimo 8 caracteres`),
  });

  const { isLoading, hasError, errors, login } = useUserAdmin();

  const handleSubmit = ({ email, password }) => {
    login({ email, password });
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    navigate("/admin");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAdminUser]);

  return (
    <div className="w-full h-screen bg-[#F2F2F2]">
      <div className="max-w-3xl h-full flex items-center justify-center mx-auto">
        <div className="flex flex-col md:flex-row w-full h-full md:h-auto shadow-lg">
          <div className="flex flex-col justify-between w-full md:w-1/2 h-2/3 md:h-full px-14 pt-16 pb-24 bg-white">
            <div className="flex flex-col">
              <img
                src={BGPLogo}
                alt="BeGlobalPro"
                className="object-contain h-auto"
              />
              <div>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={formSchema}
                  onSubmit={(values) => handleSubmit(values)}
                >
                  <Form>
                    <p className="text-gray-700 font-bold text-2xl pt-10 pb-5">
                      Iniciar Sesión
                    </p>
                    <p className="text-gray-700 font-bold text-xl">
                      Correo Electronico
                    </p>
                    <label className="relative block">
                      <span className="absolute inset-y-0 left-0 flex items-center pl-4 text-gray-550">
                        <FontAwesomeIcon icon={["far", "user"]} />
                      </span>
                      <Field
                        name="email"
                        className="w-full bg-white text-gray-550 placeholder:font-italitc border border-slate-300 py-2 pl-10 pr-4 focus:outline-none"
                        placeholder="Usuario"
                        type="email"
                      />
                    </label>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-400 font-bold font-xs"
                    />

                    <p className="text-gray-700 font-bold text-xl mt-5">
                      Contraseña
                    </p>
                    <label className="relative block">
                      <span className="absolute inset-y-0 left-0 flex items-center pl-4 text-gray-550">
                        <FontAwesomeIcon
                          className="mr-2"
                          icon={["far", "lock-keyhole"]}
                        />
                      </span>
                      <Field
                        name="password"
                        className="w-full bg-white text-gray-550 placeholder:font-italitc border border-slate-300 py-2 pl-10 pr-4 focus:outline-none"
                        placeholder="Contraseña"
                        type="password"
                        autoComplete="off"
                      />
                    </label>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-400 font-bold font-xs"
                    />

                    {hasError && (
                      <div className="mt-2 bg-gray-300 border-l-4 border-l-tertiary p-2">
                        <ul className="text-gray-700 font-bold text-xs">
                          {errors.map((err, i) => (
                            <li key={i}>{err}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                    <button
                      type="submit"
                      className="w-full bg-primary-200 text-white font-bold text-lg py-2 disabled:opacity-75 mt-3"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <FontAwesomeIcon icon="circle-notch" spin />
                      ) : (
                        <span>Entrar</span>
                      )}
                    </button>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>

          <div className="w-full md:w-1/2 h-1/3 md:h-auto flex justify-center items-center bg-primary-200">
            <p className=" text-white font-bold text-5xl p-8">
              Bienvenido <br />
              al admin center <br />
              ___
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
