import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as _ from "lodash";

import { notifications } from "../../../utils/notifications";
import { adminUsersAdministratorService } from "../../../services/api/admin/usersAdministrator";
import { ShopifyAPIService } from "../../../services/api/admin/shopify";
import Context from "../../../context/AdminUserContext";

const NewUser = () => {
  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    email: Yup.string()
      .email("No es un correo válido")
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    type: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
  });

  const vendorFormSchema = Yup.object().shape({
    name: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    email: Yup.string()
      .email("No es un correo válido")
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    type: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    vendor: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    shopify_store_id: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
  });

  const navigate = useNavigate();
  const { currentAdminUser } = useContext(Context);

  const [initialValues] = useState({
    name: "",
    email: "",
    type: "Admin",
    vendor: "",
    shopify_store_id: 1,
  });

  const [type, setType] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const [showVendors, setShowVendors] = useState(false);
  const [showSemblance, setShowSemblance] = useState(false);
  const [showStores, setShowStores] = useState(false);

  const fetchStores = () => {
    ShopifyAPIService.getStores()
      .then((response) => {
        setStores(response.data.stores);
      })
      .catch((err) => {});
  };

  const handleSubmit = async (values) => {
    let bodyFormData = new FormData();
    for (const property in values) {
      bodyFormData.set(property, values[property]);
    }
    !showVendors && bodyFormData.delete("vendor");

    setIsLoading(true);
    await adminUsersAdministratorService
      .createUser(bodyFormData)
      .then((response) => {
        notifications.success(response.data.message);
        navigate("/admin/usuarios/administradores");
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchStores();
  }, [currentAdminUser]);

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <p className="text-4xl font-bold">Nuevo usuario</p>
      </div>

      <div className="mt-12">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={showVendors ? vendorFormSchema : formSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-3xl text-gray-550">
                  <p>Nombre</p>
                  <Field
                    name="name"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-9 text-3xl text-gray-550">
                  <p>Correo</p>
                  <Field
                    name="email"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-3xl text-gray-550">
                  <p>Tipo de Usuario</p>
                  <Field
                    as="select"
                    name="type"
                    value={type}
                    onChange={(event) => {
                      setShowSemblance(false);
                      setShowVendors(false);
                      setShowStores(false);
                      switch (event.target.value) {
                        case "Proveedor":
                          setShowVendors(true);
                          setShowStores(true);
                          break;
                        case "Profesor":
                          setShowSemblance(true);
                          break;
                        default:
                          break;
                      }

                      setType(event.target.value);
                      setFieldValue("type", event.target.value);
                    }}
                    className="w-full bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none"
                  >
                    <option value="Admin">Administrador</option>
                    <option value="Profesor">Profesor</option>
                    <option value="Proveedor">Proveedor</option>
                    <option value="Revisor">Revisor</option>
                    <option value="Ventas">Ventas</option>
                  </Field>
                  <ErrorMessage
                    name="type"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                {showStores && (
                  <div className="mb-4 lg:mb-9 text-xl lg:text-3xl text-gray-550">
                    <p>Tienda Shopify</p>
                    <Field
                      as="select"
                      name="shopify_store_id"
                      className="w-full bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none"
                    >
                      {!_.isEmpty(stores) &&
                        stores.map((store) => (
                          <option key={store.id} value={store.id}>
                            {store.name}
                          </option>
                        ))}
                    </Field>
                    <ErrorMessage
                      name="shopify_store_id"
                      component="div"
                      className="text-red-400 font-bold text-xl"
                    />
                  </div>
                )}

                {showSemblance ? (
                  <div className="mb-9 text-3xl text-gray-550">
                    <p>Título</p>
                    <Field
                      name="title"
                      className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                      type="text"
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="text-red-400 font-bold text-xl"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              {showVendors ? (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                  <div className="mb-9 text-3xl text-gray-550">
                    <p>Nombre de la tienda</p>
                    <Field
                      name="vendor"
                      className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                      type="text"
                    />
                    <ErrorMessage
                      name="vendor"
                      component="div"
                      className="text-red-400 font-bold text-xl"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {showSemblance ? (
                <div className="mb-9 text-3xl text-gray-550">
                  <p>Semblanza</p>
                  <Field
                    as="textarea"
                    name="semblance"
                    className="w-full h-24 md:h-32 lg:h-44 border border-slate-300 text-gray-700 placeholder:text-gray-700 py-3 px-4 resize-none focus:outline-none"
                  />
                  <ErrorMessage
                    name="semblance"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              ) : (
                ""
              )}

              {/* BUTTONS */}
              <div className="w-full flex justify-end">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75"
                >
                  {isLoading ? (
                    <FontAwesomeIcon icon="circle-notch" spin />
                  ) : (
                    "Guardar"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default NewUser;
