import { useState, useEffect } from "react";
import * as _ from "lodash";

import CommentComponent from "../../../components/admin/CommentComponent";
import { adminCoursesServices } from "../../../services/api/admin/courses";

const Comments = () => {
  const [comments, setComments] = useState([]);

  const getComments = () => {
    adminCoursesServices
      .getComments()
      .then((response) => {
        setComments(response.data.comments);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getComments();
  }, []);

  return (
    <div className="container mx-auto text-gray-700">
      <p className="text-4xl font-bold">Todos los comentarios</p>

      <div className="border border-gray-350 mt-12">
        {/* CARD */}
        <>
          {!_.isEmpty(comments) ? (
            comments.map((comment) => (
              <CommentComponent key={comment.id} comment={comment} />
            ))
          ) : (
            <div className="bg-gray-300 border-l-4 border-l-primary-200 p-4 m-8">
              <p className="text-lg lg:text-xl text-gray-700 font-bold">
                No hay comentarios.
              </p>
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default Comments;
