import appConfig from "../../app.config.js";
import axios from "axios";
import { notifications } from "../../utils/notifications";

const client = axios.create({
  baseURL: appConfig.api.base.development.blog,
});

client.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 400) {
      notifications.error(error.response.data.message);
    }

    if (error.response.status === 401) {
      window.location.replace("/");
    }

    if (error.response.status === 403) {
      notifications.error(error.response.data.message);
    }

    if (error.response.status === 404) {
      if (error.response.data.message) {
        notifications.error(error.response.data.message);
      } else {
        notifications.error("No se ha encontrado el recurso especificado");
      }
    }

    if (error.response.status === 422) {
      let errors = error.response.data.errors;

      errors.forEach((error) => {
        notifications.error(error);
      });
    }

    if (error.response.status === 500) {
      notifications.error(
        "Estamos experimentando fallas técnicas, intenta de nuevo más tarde"
      );
    }

    return Promise.reject(error);
  }
);

export default client;
