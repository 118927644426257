import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as _ from "lodash";

import Context from "../../../context/AdminUserContext";

import FilesDragAndDrop from "../../../components/FilesDragAndDrop";

import { notifications } from "../../../utils/notifications";
import { adminCoursesServices } from "../../../services/api/admin/courses";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const NewCourse = () => {
  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    description: Yup.string().required("Campo requerido"),
    cost: Yup.number().required("Campo requerido"),
    image: Yup.mixed()
      .required("La imagen es requerida")
      .test(
        "fileFormat",
        "Formato no soportado",
        (value) =>
          !value || ((value) => value && SUPPORTED_FORMATS.includes(value.type))
      ),
    level: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    teacher_id: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
  });

  const { currentAdminUser } = useContext(Context);
  const navigate = useNavigate();

  const [teachers, setTeachers] = useState([]);

  const initialValues = {
    name: "",
    description: "",
    cost: 0,
    image: null,
    level: "Principiante",
    teacher_id: currentAdminUser.type === "Profesor" ? currentAdminUser.id : 1,
    exclusive_members: 0,
  };

  const fetchTeachers = () => {
    adminCoursesServices
      .getTeachers()
      .then((response) => {
        setTeachers(response.data.teachers);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchTeachers();
  }, [currentAdminUser]);

  const handleSubmit = async (values) => {
    let bodyFormData = new FormData();

    bodyFormData.append("name", values.name);
    bodyFormData.append("exclusive_members", values.exclusive_members);
    bodyFormData.append("description", values.description);
    bodyFormData.append("cost", values.cost);
    bodyFormData.append("image", values.image);
    bodyFormData.append("level", values.level);
    if (values.teacher_id !== "0")
      bodyFormData.append("teacher_id", values.teacher_id);

    await adminCoursesServices
      .uploadCourse(bodyFormData)
      .then((response) => {
        notifications.success(response.data.message);
        navigate("/admin/cursos");
      })
      .catch((err) => {});
  };

  const onFileChange = (files, setFieldValue) => {
    if (!_.isEmpty(files)) {
      setFieldValue("image", files[0]);
    }
  };

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <p className="text-2xl lg:text-4xl font-bold">Nuevo curso</p>
      </div>

      <div className="mt-4 lg:mt-12">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-4 lg:mb-9 text-xl lg:text-3xl text-gray-550">
                  <p>Nombre del curso</p>
                  <Field
                    name="name"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-4 lg:mb-9 text-xl lg:text-3xl text-gray-550">
                  <p>Profesor</p>
                  <Field
                    as="select"
                    name="teacher_id"
                    className="w-full bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none"
                    disabled={currentAdminUser.type === "Profesor"}
                    defaultValue={currentAdminUser.id}
                  >
                    <option value={"0"}>Seleccione un maestro</option>
                    {!_.isEmpty(teachers) &&
                      teachers.map((teacher) => (
                        <option key={teacher.id} value={teacher.id}>
                          {teacher.name}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage
                    name="teacher_id"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>

              <div className="mb-4 lg:mb-9 text-xl lg:text-3xl text-gray-550">
                <p>Descripción del curso</p>
                <Field
                  as="textarea"
                  name="description"
                  className="w-full h-48 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>

              <div className="mb-6 lg:mb-9 text-xl lg:text-3xl text-gray-550">
                <p className="mb-5">Imagen del curso</p>
                <div>
                  <FilesDragAndDrop
                    text={true}
                    onFileChange={(files) => onFileChange(files, setFieldValue)}
                    multiple={false}
                  />
                </div>
                <ErrorMessage
                  name="image"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-4 lg:mb-9 text-xl lg:text-3xl text-gray-550">
                  <p>Nivel</p>
                  <Field
                    as="select"
                    name="level"
                    className="w-full bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none"
                  >
                    <option value="Principiante">Principiante</option>
                    <option value="Intermedio">Intermedio</option>
                    <option value="Experto">Experto</option>
                  </Field>
                  <ErrorMessage
                    name="level"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-4 lg:mb-9 text-xl lg:text-3xl text-gray-550">
                  <p>Costo</p>
                  <Field
                    name="cost"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="number"
                    min="0"
                  />
                  <ErrorMessage
                    name="cost"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>

                <div className="mb-4 lg:mb-9 text-xl lg:text-3xl text-gray-550">
                  <p>Exclusivad para miembros</p>
                  <Field
                    as="select"
                    name="exclusive_members"
                    className="w-full bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none"
                  >
                    <option value="0">Ninguna</option>
                    <option value="1">Membresia</option>
                    <option value="2">Dropshipping</option>
                    <option value="6">Membresia premium</option>
                  </Field>
                  <ErrorMessage
                    name="level"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>

              <div className="w-full flex justify-end mt-5">
                <button
                  type="submit"
                  className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75 mt-5"
                >
                  Guardar
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default NewCourse;
