import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const OrderNotFound = () => {
  return (
    <main className="container mx-auto flex-1 px-4 md:px-0 mb-16">
      

      <div className="">
        <div className="bg-primary-200 border-primary-200 border-4 rounded-full w-16 lg:w-24 h-16 lg:h-24 mx-auto flex items-center justify-center mb-5 lg:mb-10">
          <FontAwesomeIcon
            icon={["far", "fa-cart-circle-xmark"]}
            className="fill-red-50   text-white text-3xl lg:text-5xl"
          />
        </div>

        <p className="pb-2 lg:pb-8 text-2xl lg:text-5xl text-center  text-primary-200 font-bold">
          Orden no encontrada
        </p>
        <p className="pb-2 lg:pb-8 text-xl lg:text-3xl text-center">
          La orden que estás buscando no existe
        </p>
        <div className="text-xl lg:text-2xl text-center mt-5">
          <Link
            to="/mi-escritorio/ordenes"
            className="bg-white py-3 px-12 text-primary-200 font-bold border-primary-200 border-2"
          >
            Volver a mis órdenes
          </Link>
        </div>
      </div>
    </main>
  );
};

export default OrderNotFound;
