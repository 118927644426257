import { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import fileDownload from 'js-file-download'
import Detail from '../../../components/orders/Details'
import ServicesDetail from '../../../components/orders/ServicesDetails'
import Context from '../../../context/AdminUserContext'
import { adminOrdersServices } from '../../../services/api/admin/orders'
import TableLoading from '../../../components/TableLoading'

const Details = () => {
  const { currentAdminUser } = useContext(Context)
  const params = useParams()
  const navigate = useNavigate()

  const [ordersShopify, setOrdersShopify] = useState()
  const [orders, setOrders] = useState([])
  const [products, setProducts] = useState([])
  const [services, setServices] = useState([])
  const [courses, setCourses] = useState([])
  const [memberships, setMemberships] = useState([])
  const [orderTotal, setOrderTotal] = useState(0)
  const [orderTotalCoupon, setOrderTotalCoupon] = useState(0)
  const [coupons, setCoupons] = useState([])
  const [orderShipping, setOrderShipping] = useState(0)

  const [loading, setLoading] = useState(true)

  const fetchOrder = () => {
    adminOrdersServices
      .getOrder(params.id)
      .then(response => {
        setServices(response.data.order.services)
        setCourses(response.data.order.courses)
        setMemberships(response.data.order.memberships)

        setOrders(response.data.order)
        setOrderShipping(response.data.order.shipping_cost)
        setCoupons(response.data.order.coupons)

        if (response.data.order.orderShopify) {
          setProducts(response.data.order.orderShopify.order.line_items)
          setOrdersShopify(response.data.order.orderShopify.order)
        }
      })
      .catch(err => {
        if (err.response.status === 403) {
          navigate('/admin/ordenes')
        }
      })
      .finally(() => setLoading(false))
  }

  const getOrderTotal = () => {
    let total = 0
    let totalCoupons = 0

    courses.forEach(course => {
      total += course.cost
    })

    services.forEach(service => {
      total += service.cost
    })

    memberships.forEach(membership => {
      total += membership.cost
    })

    products.forEach(product => {
      total += product.quantity * product.price
    })
    coupons.forEach(cupon => {
      totalCoupons += cupon.discount_amount
    })

    setOrderTotal(total)
    setOrderTotalCoupon(totalCoupons)
  }

  useEffect(() => {
    if (!currentAdminUser) return
    fetchOrder()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAdminUser])

  useEffect(() => {
    if (!orders) return
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getOrderTotal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders])

  const formatTotals = total => {
    return total.toLocaleString('en-US', {
      minimumFractionDigits: 2
    })
  }

  const downloadProofOfPayment = id => {
    let extension = ''
    adminOrdersServices
      .downloadPayment(id)
      .then(response => {
        response.data.type === 'application/pdf'
          ? (extension = 'pdf')
          : (extension = 'jpg')
        fileDownload(response.data, `Comprobante de pago - ${id}.${extension}`)
      })
      .catch(err => {})
  }

  return (
    <div className='container mx-auto text-gray-700'>
      <div className='flex flex-col md:flex-row justify-between items-center'>
        <p className='text-2xl lg:text-4xl font-bold'>Orden #{params.id}</p>
      </div>

      {loading ? (
        <div className='w-full flex justify-center mt-12'>
          <TableLoading size='3x' />
        </div>
      ) : (
        <div className='mt-4 xl:mt-12'>
          <main className='mx-auto max-w-7xl px-2 xl:px-4 py-4 xl:py-16 sm:px-6 sm:py-2 xl:px-8 '>
            <h1 className='text-center md:text-left text-xl xl:text-3xl font-bold tracking-tight text-gray-900'>
              Detalles de la orden
            </h1>
            <div className='mt-2 pb-2 sm:flex sm:justify-between text-lg'>
              <dl className='flex'>
                <dd className='font-medium text-gray-900  sm:text-left'>
                  <p>
                    Usuario:{' '}
                    <span className='text-primary-200 font-bold'>
                      {orders.user.name}
                    </span>
                  </p>
                  <time dateTime='2021-03-22'>
                    {' '}
                    {orders
                      ? moment(orders.created_at).format('D').toString() +
                        ' de ' +
                        moment(orders.created_at).format('MMMM').toString() +
                        ' de ' +
                        moment(orders.created_at).format('Y').toString()
                      : ''}
                  </time>
                  <p className=''>
                    {/* El cliente aun no ha subido el comprobante de pago. */}
                    {orders.status !== 'Checkout' &&
                    orders.status !== 'AwaitingPayment' ? (
                      <>
                        <FontAwesomeIcon
                          className='mr-2 text-primary-200'
                          icon={['fa', 'download']}
                          size='sm'
                        />
                        <span className='font-bold text-left  '>
                          El cliente ha subido el comprobante de pago,{' '}
                        </span>
                        <div className='xl:hidden'>
                          <br></br>
                        </div>
                        <button
                          onClick={() => downloadProofOfPayment(orders.id)}
                          className='text-primary-200 hover:text-primary-300 cursor-pointer '
                        >
                          {' '}
                          haz click aquí para descargar
                        </button>
                      </>
                    ) : (
                      <span className='font-bold'>
                        El cliente aún no ha subido el comprobante de pago.
                      </span>
                    )}
                  </p>
                </dd>
              </dl>
            </div>
            {orders && !orders.has_membership && (
              <div className='pb-3 text-yellow-1200 flex items-center'>
                <FontAwesomeIcon
                  icon={["fa", "tags"]}
                  size={'sm'}
                  className="mr-2"
                />
                <span className='font-bold text-lg'>
                  Los productos de esta orden fueron adquiridos sin membresía
                </span>
              </div>
            )}

            <section
              aria-labelledby='products-heading'
              className='mt-2 flex flex-row flex-wrap'
            >
              <div className='space-y-2 w-full lg:w-1/2 overflow-y-auto h-auto lg:rounded-l-lg'>
                {products &&
                  products.map((product, index) => (
                    <Detail key={index} product={product}></Detail>
                  ))}
                {services &&
                  services.map((service, index) => (
                    <ServicesDetail
                      key={index}
                      service={service}
                      sub={'Servicio'}
                    />
                  ))}
                {memberships &&
                  memberships.map((service, index) => (
                    <ServicesDetail
                      key={index}
                      service={service}
                      sub={'Membresia'}
                    />
                  ))}

                {courses &&
                  courses.map((service, index) => (
                    <ServicesDetail
                      key={index}
                      service={service}
                      sub={'Curso'}
                    />
                  ))}
              </div>
              <div className='w-full lg:w-1/2 xl:rounded-r-lg bg-gray-50 py-6 px-6 xl:grid xl:grid-cols-12 lg:gap-x-8 xl:px-0 xl:py-8  '>
                {products && ordersShopify && (
                  <dl className='grid grid-cols-1 gap-6 text-lg md:gap-x-8 lg:col-span-6 xl:pl-8 text-xl  '>
                    <div className='xl:pl-10'>
                      <dt className='font-bold text-gray-900 text-center xl:text-left'>
                        Dirección de envío
                      </dt>
                      {ordersShopify && ordersShopify.shipping_address ? (
                        <dd className='mt-3 text-gray-700'>
                          <span className='block'>
                            {ordersShopify.shipping_address.address1}
                          </span>
                          <span className='block'>
                            {ordersShopify.shipping_address.zip}{' '}
                          </span>
                          <span className='block'>
                            {ordersShopify.shipping_address.city},{' '}
                            {ordersShopify.shipping_address.province}.{' '}
                            {ordersShopify.shipping_address.country}{' '}
                          </span>
                        </dd>
                      ) : (
                        'No disponible'
                      )}
                    </div>
                    {orders.shipping_name && (
                      <div className='lg:pl-10'>
                        <dt className='text-center lg:text-left font-bold text-gray-900'>
                          Tipo de envío
                        </dt>
                        <dd className='lg:text-left text-center text-gray-700 mb-10 lg:mb-1'>
                          <span className='block'>
                            {orders.shipping_name} - {orders.days}{' '}
                            {orders.days === 1 ? ' dia' : ' dias'}
                          </span>
                        </dd>
                      </div>
                    )}
                  </dl>
                )}

                <dl className='grid grid-cols-1 gap-6 text-lg md:gap-x-8 lg:col-span-6 lg:pl-8'>
                  <div>
                    <dt className='text-center lg:text-left font-bold text-gray-900 mb-2'>
                      Información de pago
                    </dt>
                    <div className='flex items-center justify-between  lg:pr-10'>
                      <dt className='text-gray-700'>Subtotal</dt>
                      <dd
                        className={`font-medium ${
                          orderTotalCoupon > 0
                            ? 'line-through text-red-50'
                            : 'text-gray-900'
                        } `}
                      >
                        ${formatTotals(orderTotal)}
                      </dd>
                    </div>
                    {coupons.length > 0 && (
                      <div className='flex items-center justify-between  lg:pr-10'>
                        <dt className='text-gray-700'>Cupones</dt>
                        <dd className='font-medium text-green-50'>
                          - ${formatTotals(orderTotalCoupon)}
                        </dd>
                      </div>
                    )}

                    {products && ordersShopify && (
                      <div className='flex items-center justify-between  lg:pr-10'>
                        <dt className='text-gray-700'>Envío</dt>
                        <dd className='font-medium text-gray-900'>
                          ${formatTotals(orderShipping)}
                        </dd>
                      </div>
                    )}

                    <div className='flex items-center justify-between lg:pr-10'>
                      <dt className='text-gray-900'>Total</dt>
                      <dd className='font-bold text-primary-200'>
                        $
                        {formatTotals(
                          orderTotal + orderShipping - orderTotalCoupon
                        )}
                      </dd>
                    </div>
                  </div>
                  {products && ordersShopify && (
                    <div>
                      <dt className='text-center lg:text-left font-bold text-gray-900'>
                        Persona que recibe el pedido:
                      </dt>
                      <div className='flex items-center justify-center lg:justify-between '>
                        {ordersShopify && ordersShopify.shipping_address ? (
                          <dd className='lg:text-left text-center text-gray-700 mb-10 lg:mb-1'>
                            <span className='block'>
                              {ordersShopify.shipping_address.name}
                            </span>
                          </dd>
                        ) : (
                          products.length>0 && 'No disponible'
                        )}
                      </div>
                    </div>
                  )}

                  {products && ordersShopify &&
                    ordersShopify.shipping_address &&
                    ordersShopify.shipping_address.phone && (
                      <div>
                        {ordersShopify && (
                          <dt className='text-center lg:text-left font-bold text-gray-900'>
                            Teléfono
                          </dt>
                        )}

                        <div className='flex items-center justify-center lg:justify-between '>
                          {ordersShopify ? (
                            <dd className='lg:text-left text-center text-gray-700 mb-10 lg:mb-1'>
                              <span className='block'>
                                {ordersShopify.shipping_address.phone}
                              </span>
                            </dd>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    )}
                </dl>

                {products && ordersShopify && (
                  <dl className='grid grid-cols-1 gap-6 text-lg md:gap-x-8 lg:col-span-12 lg:pl-8 mt-5'>
                    <div className='pl-10'>
                      <dt className='font-bold text-gray-900'>
                        Información de rastreo
                      </dt>
                      {orders && orders.status === 'Send' ? (
                        <dd className='mt-3 text-gray-700'>
                          <span className='block text-gray-700 '>
                            <b>Compañía: </b> {orders.shippingCarrier}
                          </span>
                          <span className='block'>
                            <b>Numero de rastreo:</b> {orders.trackingNumber}{' '}
                          </span>
                        </dd>
                      ) : (
                        <div className=''>Preparando envío</div>
                      )}
                    </div>
                  </dl>
                )}
              </div>
            </section>
          </main>
        </div>
      )}
    </div>
  )
}

export default Details
