import Rodal from "rodal";
import "rodal/lib/rodal.css";

import image from "../../assets/icons/felicidades-curso-completado.svg";

const CompletedCourseModal = ({ visible, setVisible }) => {
  return (
    <div>
      <div>
        <Rodal
          visible={visible}
          onClose={() => setVisible(false)}
          customStyles={{
            width: "95%",
            maxWidth: 700,
            height: "85%",
            maxHeight: 600,
            padding: "0",
            overflowY: "scroll",
          }}
        >
          <div className="flex flex-col items-center justify-center h-full px-8">
            <img src={image} alt="Curso completado" className="mx-auto" />
            <h2 className="text-center font-bold text-gray-700 text-5xl mb-4">
              ¡Felicidades!
            </h2>
            <p className="text-center text-gray-700 text-xl">Tu puntuación</p>
            <p className="text-center font-bold text-gray-700 text-5xl mb-4">
              100%
            </p>
            <p className="text-center font-bold text-gray-700 text-lg mb-4 px-10 pb-4 border-b-4 border-primary-200">
              Haz completado tu curso correctamente
            </p>

            {/* <a
              href="!#"
              className="block bg-primary-200 text-center p-4 text-white font-bold text-lg"
            >
              <div className="flex items-center justify-center">
                <DiplomaIcon styles="h-8 w-8" />
                <p className="pl-2 text-lg">Obtener Certificado</p>
              </div>
            </a> */}
          </div>
        </Rodal>
      </div>
    </div>
  );
};

export default CompletedCourseModal;
