import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LessonCard = ({ uuid, lesson, index, moveListItem }) => {
  const navigate = useNavigate();

  const [{ isDragging }, dragRef] = useDrag({
    type: "item",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "item",
    hover: (item, monitor) => {
      if (!dragDropRef.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;

      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

      moveListItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const ref = useRef(null);
  const dragDropRef = dragRef(drop(ref));

  return (
    <>
      <div
        ref={dragDropRef}
        className={`flex flex-row justify-between items-center pl-4 pr-8 py-2 lg:py-5 border-b border-b-gray-350 w-full cursor-pointer ${
          isDragging ? "opacity-50" : "opacity-100"
        }`}
      >
        <div className="flex flex-row">
          <FontAwesomeIcon
            icon={["far", "bars"]}
            className="text-lg lg:text-xl pr-4 text-gray-600"
          />
          <p className="text-sm lg:text-2xl font-bold">{lesson.name}</p>
        </div>

        <div className="flex justify-between items-center md:flex-col text-[#AEAEAE]  md:pt-0">
          <button
            className="hidden md:block"
            onClick={() =>
              navigate(`/admin/cursos/${uuid}/leccion/${lesson.id}/editar`)
            }
          >
            <FontAwesomeIcon
              icon={["far", "pencil"]}
              className={`${true ? "text-primary-200" : "text-[#D8D8D8]"}`}
              size="sm"
            />
          </button>
          <button
            className="block md:hidden ml-auto"
            onClick={() =>
              navigate(`/admin/cursos/${uuid}/leccion/${lesson.id}/editar`)
            }
          >
            <FontAwesomeIcon
              icon={["far", "pencil"]}
              className={`text-sm lg:text-xl ${
                true ? "text-primary-200" : "text-[#D8D8D8]"
              }`}
            />
          </button>
        </div>
      </div>
    </>
  );
};

export default LessonCard;
