import React from "react";
import { useEffect, useContext, useState } from "react";
import Context from "../../context/UserContext";
import moment from "moment";
import LearningCard from "../../components/statics/LearningCard";
import { StatisticsService } from "../../services/api/statistics";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import CoursesInProgress from "../../components/statics/CoursesInProgress";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

registerLocale("es", es);
const Statistics = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [coursesFinished, setCoursesFinished] = useState([]);
  const [coursesInProgress, setCoursesInProgress] = useState([]);
  const [coursesHours, setCoursesHours] = useState([]);
  const [coursesDays, setCoursesDays] = useState([]);
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);

  const options = {
    scales: {
      x: {
        ticks: {
          font: {
            size: 18,
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 18,
          },
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
        labels: {
          // This more specific font property overrides the global property
          font: {
            size: 20,
          },
        },
      },
      title: {},
      fontStyle: "bold",
    },
  };

  const dataDays = {
    labels,
    datasets: [
      {
        label: "Horas de estudio",
        data: data,
        borderColor: "#63abe6",
        backgroundColor: "#63abe6",
        pointRadius: 8,
      },
    ],
  };

  const learningArray = [
    {
      icon: "medal",
      title: "Cursos completados",
      count: coursesFinished,
    },
    {
      icon: ["far", "loader"],
      title: "Cursos en progreso",
      count: coursesInProgress,
    },
    {
      icon: ["far", "clock"],
      title: "Horas de estudio",
      count: coursesHours,
    },
  ];
  const fetchStatistics = () => {
    StatisticsService.getStatistics()
      .then((response) => {
        setCourses(response.data.courses);
        setCoursesFinished(response.data.coursesFinished);
        setCoursesInProgress(response.data.coursesInProgress);
        setCoursesDays(response.data.timePerDay);
      })
      .catch((err) => {})
      .finally(() => setIsLoading(false));
  };

  const fetchCoursesHours = () => {
    let totalHours = 0;
    if (coursesDays) {
      coursesDays.forEach((element) => {
        totalHours = totalHours + parseFloat(element.seconds);
      });
    }
    setCoursesHours(
      parseFloat((totalHours / 60 / 60).toFixed(1)).toLocaleString("en-US")
    );
  };

  const changeDate = (option) => {
    var list = [];
    var obj = {};
    var current = "";
    switch (option) {
      case "currentWeek":
        const startDay = moment().startOf("week");
        const endDay = moment().endOf("week");
        const startDay2 = moment().startOf("week").format("YYYY-DD-MM");
        const endDay2 = moment().endOf("week").format("YYYY-DD-MM");
        //Creamos el objeto
        for (current = startDay; current <= endDay; current.add(1, "d")) {
          list.push(current.format("dddd"));
        }
        let weekArray = moment.weekdays();
        weekArray.forEach((element) => {
          obj[element] = 0;
        });
        let resultsDay = [];
        let daysDays = [];
        let everyWeek = [];
        coursesDays.forEach((day) => {
          let weekDay = moment(day.date).format("YYYY-DD-MM");

          if (weekDay >= startDay2 && weekDay <= endDay2) {
            everyWeek.push(day);
          } else {
          }
        });
        everyWeek.forEach((element) => {
          if (obj[moment(element.date).format("dddd")]) {
            obj[moment(element.date).format("dddd")] += parseFloat(
              element.seconds / 60 / 60
            );
          } else {
            obj[moment(element.date).format("dddd")] = parseFloat(
              element.seconds / 60 / 60
            );
          }
        });

        for (const [key, value] of Object.entries(obj)) {
          resultsDay.push(value);
          daysDays.push(key);
        }

        setLabels(daysDays);
        setData(resultsDay);

        break;
      case "currentMonth":
        const startOfMonth = moment().startOf("month");
        const endOfMonth = moment().endOf("month");
        const startOfMonth2 = moment().startOf("month").format("DD-MM-YYYY");
        const endOfMonth2 = moment().endOf("month").format("DD-MM-YYYY");
        //Creamos el objeto
        for (
          current = startOfMonth;
          current <= endOfMonth;
          current.add(1, "d")
        ) {
          list.push(current.format("D"));
        }
        list.forEach((element) => {
          obj[element] = 0;
        });
        let resultsMonth = [];
        let daysMonth = [];
        let everyDay = [];

        coursesDays.forEach((day) => {
          let weekDay = moment(day.date).format("DD-MM-YYYY");
          if (weekDay > startOfMonth2 && weekDay < endOfMonth2) {
            everyDay.push(day);
          }
        });

        everyDay.forEach((element) => {
          if (obj[moment(element.date).format("D")]) {
            obj[moment(element.date).format("D")] += parseFloat(
              element.seconds / 60 / 60
            );
          } else {
            obj[moment(element.date).format("D")] = parseFloat(
              element.seconds / 60 / 60
            );
          }
        });

        for (const [key, value] of Object.entries(obj)) {
          daysMonth.push(key);
          resultsMonth.push(value);
        }

        setLabels(daysMonth);
        setData(resultsMonth);

        break;
      case "annual":
        const startYear = moment().startOf("year");
        const endYear = moment().endOf("year");
        const startYear2 = moment().startOf("month").format("DD-MM-YYYY");
        const endYear2 = moment().endOf("month").format("DD-MM-YYYY");
        //Creamos el objeto
        for (current = startYear; current <= endYear; current.add(1, "d")) {
          list.push(current.format("MMM"));
        }
        list.forEach((element) => {
          obj[element] = 0;
        });

        let resultsYear = [];
        let daysYear = [];
        let everyYear = [];
        coursesDays.forEach((day) => {
          let weekDay = moment(day.date).format("DD-MM-YYYY");
          if (weekDay > startYear2 && weekDay < endYear2) {
            everyYear.push(day);
          }
        });
        everyYear.forEach((element) => {
          if (obj[moment(element.date).format("MMM")]) {
            obj[moment(element.date).format("MMM")] += parseFloat(
              element.seconds / 60 / 60
            );
          } else {
            obj[moment(element.date).format("MMM")] = parseFloat(
              element.seconds / 60 / 60
            );
          }
        });

        for (const [key, value] of Object.entries(obj)) {
          resultsYear.push(value);
          daysYear.push(key);
        }

        setLabels(daysYear);
        setData(resultsYear);
        break;
      default:
        break;
    }
  };

  const { currentUser } = useContext(Context);
  useEffect(() => {
    if (!currentUser) return;
    fetchStatistics();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser) return;
    if (coursesDays.length > 0) {
      changeDate("currentWeek");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coursesDays]);

  useEffect(() => {
    if (!currentUser) return;
    fetchCoursesHours();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coursesDays]);
  return (
    <>
      <div>
        <p className="text-center lg:text-left text-xl lg:text-3xl text-gray-700 font-bold pb-5">
          Mi aprendizaje
        </p>
        <div className="flex flex-col md:flex-row gap-10 ">
          <div className="w-full lg:w-8/12 ">
            <div className="lg:px-auto w-full border border-gray-350 px-8 py-4 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
              {learningArray.map((item, key) => (
                <LearningCard key={key} item={item} desktop></LearningCard>
              ))}
            </div>
            <div className="w-full mt-20">
              <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-10">
                <p className="w-full lg:w-auto text-center md:text-left text-2xl lg:text-3xl text-gray-700 font-bold mb-5 md:mb-0">
                  Tus estadisticas
                </p>
                <select
                  name="breakdown"
                  onChange={(value) => changeDate(value.target.value)}
                  className="mx-auto lg:mx-1 text-xl lg:text-xl font-bold  bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none w-full md:w-auto"
                >
                  <option value="currentWeek">Semanal</option>
                  <option value="currentMonth">Mensual</option>
                  <option value="annual">Anual</option>
                </select>
              </div>
              <Line options={options} data={dataDays} />
            </div>
          </div>
          <div className="w-full lg:w-4/12   border border-gray-350  ">
            <CoursesInProgress
              isLoading={isLoading}
              courses={courses}
              label={"Mi progreso"}
            ></CoursesInProgress>
          </div>
        </div>
      </div>
    </>
  );
};

export default Statistics;
