import { useState, useEffect, useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

import Context from "../../../context/AdminUserContext";
import * as _ from "lodash";

import TableLoading from "../../../components/TableLoading";
import TableNoData from "../../../components/TableNoData";
import { adminUsersService } from "../../../services/api/admin/users";

import {
  getColumnsForMembershipsProductsSales,
  customStyles,
  paginationComponentOptions,
} from "../../../utils/tableOptions";
const Index = () => {
  const navigate = useNavigate();
  const { currentAdminUser } = useContext(Context);
  const firstRender = useRef(true);

  const [loading, setLoading] = useState(true);
  const [formattedUsersData, setFormattedUsersData] = useState([]);

  const [users, setUsers] = useState([]);
  const [querySearch, setQuerySearch] = useState("");

  const inputHandler = (e) => setQuerySearch(e.target.value.toLowerCase());

  const formatTopUserData = () => {
    let data = [];

    users.forEach((user, idx) => {
      data.push({
        photoComponent: (
          <div className="w-10 h-10 mx-2">
            <img
              src={user.image}
              alt=""
              className="rounded-full w-full h-full object-cover"
              style={{ backgroundColor: "#D8D8D8" }}
            />
          </div>
        ),

        userComponent: (
          <div className="">
            <a
              href={`/admin/usuarios/miembros/${user.id}/ventas-membresias`}
              className="text-primary-200"
            >
              {" "}
              {user.name}
            </a>
          </div>
        ),
        name: user.name,
        email: user.email,
        memberships_total: user.products_total,
        memberships_count: user.products_count,
        actionComponent: (
          <div className="text-primary-200 hover:text-primary-300 text-lg leading-none">
            <button
              onClick={() =>
                navigate(
                  `/admin/usuarios/miembros/productos/${user.id}/ventas-productos`
                )
              }
            >
              Más Detalles
            </button>
          </div>
        ),
      });
    });

    let dataDesc = _.orderBy(data, ["memberships_total"], ["desc"]);

    setFormattedUsersData(dataDesc);
    setLoading(false);
  };
  const filteredData = formattedUsersData.filter((user) => {
    if (querySearch === "") {
      return user;
    } else {
      return (
        user.name.toLowerCase().includes(querySearch) ||
        user.email.toLowerCase().includes(querySearch)
      );
    }
  });

  const fetchUsers = () => {
    adminUsersService
      .getUsersProducts()
      .then((response) => {
        setUsers(response.data.users);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchUsers();
  }, [currentAdminUser]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (_.isEmpty(users)) {
      setLoading(false);
      return;
    }
    formatTopUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);
  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <p className="text-4xl font-bold">Ventas de Productos</p>
        <div className="flex flex-col md:flex-row items-center gap-5 mt-5 md:mt-0">
          <label className="relative block">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <FontAwesomeIcon
                icon={["fa", "magnifying-glass"]}
                className="text-[#A9A9A9]"
                size="sm"
              />
            </span>
            <input
              className="w-full bg-white text-gray-700 placeholder:font-italic border border-slate-300 py-3 pl-12 pr-4 focus:outline-none"
              type="text"
              placeholder="Buscar"
              onChange={inputHandler}
            />
          </label>
        </div>
      </div>
      <div className="border border-gray-350 mt-12">
        <DataTable
          columns={getColumnsForMembershipsProductsSales}
          data={filteredData}
          noDataComponent={<TableNoData />}
          progressPending={loading}
          progressComponent={<TableLoading />}
          customStyles={customStyles}
          pagination
          paginationComponentOptions={paginationComponentOptions}
          defaultSortAsc={false}
        />
      </div>
    </div>
  );
};

export default Index;
