import { api } from "../../client";

export const shopifyService = {
  getAllProducts() {
    return api.base.get("/shopify/product");
  },

  getAllProductsByTitle(params) {
    return api.base.get('/shopify/all-products-title', {
      params: params
    });
  },
  getViewedProducts() {
    return api.base.get("/shopify/product-view");
  },
  getOfferProducts() {
    return api.base.get("/shopify/product-offers");
  },
  getAllCollections() {
    return api.base.get("/shopify/collection");
  },
  getProduct(id,store_id) {
    return api.base.get(`/shopify/product/${id}/${store_id}`);
  },
  getCollection(id) {
    return api.base.get(`/shopify/collection/${id}`);
  },
  getCategories() {
    return api.base.get("shopify/collection");
  },

  getBanners() {
    return api.admin.get("admin/shopify/banner");
  },
  uploadView(model) {
    return api.base.post("shopify/product-view", model);
  },
  uploadBanner(model) {
 
    return api.admin.post("admin/shopify/banner", model);
  },
  uploadBannerAdvertising(model) {
 
    return api.admin.post("admin/shopify/banner/advertising", model);
  },
  updateBannerAdvertising(id,model) {
 
    return api.admin.post(`/admin/shopify/banner/advertising/${id}`, model);
  },
  uploadCollection(model) {

    return api.admin.post("admin/shopify/banner/collection", model, {
      headers: {
        Accept: "application/json",
        "Content-type": "multipart/form-data",
      },
    });
  },

  deleteCollection(model) {
    return api.admin.post("/admin/shopify/banner/collection/delete",model);
  },
  deleteAdvertising(id,model) {
    return api.admin.delete(`/admin/shopify/banner/advertising/${id}`,model);
  },

  
 
};
