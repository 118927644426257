import Rodal from "rodal";
import "rodal/lib/rodal.css";

export default function App({
  visible,
  setVisible,
  modalContent,
  resetForm = null,
}) {
  return (
    visible && (
      <div>
        <div>
          <Rodal
            visible={visible}
            onClose={() => {
              setVisible(false);
              if (resetForm) {
                resetForm();
              }
            }}
            customStyles={{
              width: "95%",
              maxWidth: 700,
              height: "85%",
              padding: "0",
              overflowY: "scroll",
            }}
          >
            {modalContent}
          </Rodal>
        </div>
      </div>
    )
  );
}
