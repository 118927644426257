import { api } from "../../../client";

export const adminUsersService = {
  getUsers() {
    return api.admin.get("/admin/user/membership");
  },
  getUser(id) {
    return api.admin.get(`/admin/user/membership/${id}`);
  },
  getUserTop() {
    return api.admin.get(`/admin/user/top`);
  },
  getUsersProducts() {
    return api.admin.get("/admin/user/product");
  },
  getUserProduct(id) {
    return api.admin.get(`/admin/user/product/${id}`);
  },
  getUsersAll() {
    return api.admin.get("/admin/user");
  },
  getUserById(id) {
    return api.admin.get(`/admin/user/${id}`);
  },
  updateUser(id, model) {
    return api.admin.put(`/admin/user/${id}`, model);
  },
};
