import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import ServicesDetail from "../../../components/orders/ServicesDetails";
import Context from "../../../context/AdminUserContext";
import { adminOrdersServices } from "../../../services/api/admin/orders";
import TableLoading from "../../../components/TableLoading";

const DetailsBGP = () => {
  const { currentAdminUser } = useContext(Context);
  const params = useParams();

  const [orders, setOrders] = useState([]);
  const [services, setServices] = useState([]);
  const [courses, setCourses] = useState([]);
  const [memberships, setMemberships] = useState([]);
  const [orderTotal, setOrderTotal] = useState(0);
  const [orderTotalCoupon, setOrderTotalCoupon] = useState(0);
  const [coupons, setCoupons] = useState([]);

  const [loading, setLoading] = useState(true);

  const fetchOrder = () => {
    adminOrdersServices
      .getOrder(params.id)
      .then((response) => {
        setServices(response.data.order.services);
        setCourses(response.data.order.courses);
        setMemberships(response.data.order.memberships);

        setOrders(response.data.order);
        setCoupons(response.data.order.coupons);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const getOrderTotal = () => {
    let total = 0;
    let totalCoupons = 0;

    courses.forEach((course) => {
      total += course.cost;
    });

    services.forEach((service) => {
      total += service.cost;
    });

    memberships.forEach((membership) => {
      total += membership.cost;
    });

    coupons.forEach((cupon) => {
      totalCoupons += cupon.discount_amount;
    });

    setOrderTotal(total);
    setOrderTotalCoupon(totalCoupons);
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAdminUser]);

  useEffect(() => {
    if (!orders) return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getOrderTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  const formatTotals = (total) => {
    return total.toLocaleString("en-US", {
      minimumFractionDigits: 2,
    });
  };

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <p className="text-2xl lg:text-4xl font-bold">Orden #{params.id}</p>
      </div>

      {loading ? (
        <div className="w-full flex justify-center mt-12">
          <TableLoading size="3x" />
        </div>
      ) : (
        <div className="mt-4 xl:mt-8">
          <main className="mx-auto max-w-7xl py-4 xl:py-16">
            <h1 className="text-center md:text-left text-xl xl:text-3xl font-bold tracking-tight text-gray-900">
              Detalles de la orden
            </h1>
            <div className="mt-2 pb-5 sm:flex sm:justify-between text-lg">
              <dl className="flex">
                <dd className="font-medium text-gray-900  sm:text-left">
                  <p>
                    Usuario:{" "}
                    <span className="text-primary-200 font-bold">
                      {orders.user.name}
                    </span>
                  </p>
                  <time dateTime="2021-03-22">
                    {" "}
                    {orders
                      ? moment(orders.created_at).format("D").toString() +
                        " de " +
                        moment(orders.created_at).format("MMMM").toString() +
                        " de " +
                        moment(orders.created_at).format("Y").toString()
                      : ""}
                  </time>
                </dd>
              </dl>
            </div>

            <section
              aria-labelledby="products-heading"
              className="mt-2 flex flex-row flex-wrap"
            >
              <div className="space-y-2 w-full lg:w-1/2 overflow-y-auto h-auto lg:rounded-l-lg">
                {services &&
                  services.map((service, index) => (
                    <ServicesDetail
                      key={index}
                      service={service}
                      sub={"Servicio"}
                    />
                  ))}
                {memberships &&
                  memberships.map((service, index) => (
                    <ServicesDetail
                      key={index}
                      service={service}
                      sub={"Membresia"}
                    />
                  ))}

                {courses &&
                  courses.map((service, index) => (
                    <ServicesDetail
                      key={index}
                      service={service}
                      sub={"Curso"}
                    />
                  ))}
              </div>
              <div className="w-full lg:w-1/2 xl:rounded-r-lg bg-gray-50 py-6 px-6 xl:grid xl:grid-cols-12 lg:gap-x-8 xl:px-0 xl:py-8 mt-5">
                <dl className="grid grid-cols-1 gap-6 text-lg md:gap-x-8 lg:col-span-6 lg:pl-8">
                  <div>
                    <dt className="text-center lg:text-left font-bold text-gray-900 mb-2">
                      Información de pago
                    </dt>
                    <div className="flex items-center justify-between  lg:pr-10">
                      <dt className="text-gray-700">Subtotal</dt>
                      <dd
                        className={`font-medium ${
                          orderTotalCoupon > 0
                            ? "line-through text-red-50"
                            : "text-gray-900"
                        } `}
                      >
                        ${formatTotals(orderTotal)}
                      </dd>
                    </div>
                    {coupons.length > 0 && (
                      <div className="flex items-center justify-between  lg:pr-10">
                        <dt className="text-gray-700">Cupones</dt>
                        <dd className="font-medium text-green-50">
                          - ${formatTotals(orderTotalCoupon)}
                        </dd>
                      </div>
                    )}

                    <div className="flex items-center justify-between lg:pr-10">
                      <dt className="text-gray-900">Total</dt>
                      <dd className="font-bold text-primary-200">
                        ${formatTotals(orderTotal - orderTotalCoupon)}
                      </dd>
                    </div>
                  </div>
                </dl>
              </div>
            </section>
          </main>
        </div>
      )}
    </div>
  );
};

export default DetailsBGP;
