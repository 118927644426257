import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function StepsSelling({
  isFirst,
  isLast,
  label,
  icon,
  iconType,
}) {
  return (
    <div className="w-full mb-4 lg:my-20 flex flex-col relative">
      <div className="relative h-20 lg:h-40 flex justify-center items-center ">
        <div
          className={`absolute lg:border-2 bg-primary-200 border-primary-200 w-full z-10 ${
            isFirst ? "left-1/2" : " "
          } ${isLast ? "right-1/2" : " "} `}
        ></div>
        <div className=" text-gray-650 bg-zinc-50 border-primary-200 border-4 rounded-full w-16 h-16 lg:w-32 lg:h-32 mx-auto flex items-center justify-center z-20">
          <FontAwesomeIcon
            icon={[iconType, icon]}
            className="fill-blue-500  text-primary-200 text-3xl lg:text-6xl"
          />
        </div>
      </div>
      <div className="text-gray-650 text-base lg:text-xl px-8">{label}</div>
    </div>
  );
}
