import { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as _ from "lodash";

import Context from "../../../context/AdminUserContext";
import AdmissionPendingCard from "../../../components/admin/AdmissionPendingCard";
import AdmissionApplicationCard from "../../../components/admin/AdmissionApplicationCard";
import { adminAdmissionsService } from "../../../services/api/admin/admissions";

const Index = () => {
  const { currentAdminUser } = useContext(Context);

  const [menuContent, setMenuContent] = useState("pending");
  const [pendings, setPendings] = useState([]);
  const [answered, setAnswered] = useState([]);
  const [querySearch, setQuerySearch] = useState("");

  const getPendingAdmissions = () => {
    adminAdmissionsService
      .getPendings()
      .then((response) => {
        setPendings(response.data.admissions);
      })
      .catch((err) => {});
  };

  const getAnsweredAdmissions = () => {
    adminAdmissionsService
      .getAnswered()
      .then((response) => {
        setAnswered(response.data.admissions);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    getPendingAdmissions();
    getAnsweredAdmissions();
  }, [currentAdminUser]);

  useEffect(() => {
    setQuerySearch("");
  }, [menuContent]);

  const inputHandler = (e) => setQuerySearch(e.target.value.toLowerCase());

  const filteredData = () => {
    let dataToFilter = pendings;
    if (menuContent === "answered") dataToFilter = answered;

    return dataToFilter.filter((admission) => {
      if (querySearch === "") {
        return admission;
      } else {
        return admission.name.toLowerCase().includes(querySearch);
      }
    });
  };

  return (
    <div className="container mx-auto text-gray-700">
      <p className="text-4xl font-bold text-center md:text-left">
        Todas las solicitudes
      </p>

      {/* MENU */}
      <div className="flex flex-col md:flex-row justify-center md:justify-between items-center gap-8 py-3 mt-6 border-b border-b-gray-350">
        <div className="flex flex-row">
          <button
            className="text-2xl mr-8"
            onClick={() => setMenuContent("pending")}
          >
            <p
              className={`${
                menuContent === "pending"
                  ? "text-primary-200 font-bold"
                  : "text-gray-700"
              }`}
            >
              Pendientes
            </p>
          </button>

          <button
            className="text-2xl"
            onClick={() => setMenuContent("answered")}
          >
            <p
              className={`${
                menuContent === "answered"
                  ? "text-primary-200 font-bold"
                  : "text-gray-700"
              }`}
            >
              Respondidas
            </p>
          </button>
        </div>

        <div className="flex flex-end">
          <label className="relative block">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <FontAwesomeIcon
                icon={["fa", "magnifying-glass"]}
                className="text-[#A9A9A9]"
                size="sm"
              />
            </span>
            <input
              className="w-full md:w-52 lg:w-64 bg-white text-gray-700 placeholder:font-italic border border-slate-300 py-3 pl-12 pr-4 focus:outline-none"
              type="text"
              placeholder="Buscar"
              value={querySearch}
              onChange={inputHandler}
            />
          </label>
        </div>
      </div>

      <div className="border border-gray-350 mt-12">
        {/* CARD */}
        {menuContent === "pending" ? (
          <Pending admissions={filteredData()} />
        ) : (
          <Answered admissions={filteredData()} />
        )}
      </div>
    </div>
  );
};

export default Index;

const Pending = ({ admissions }) => {
  return (
    <>
      {!_.isEmpty(admissions) ? (
        admissions.map((admission) => (
          <AdmissionPendingCard key={admission.id} admission={admission} />
        ))
      ) : (
        <div className="bg-gray-300 border-l-4 border-l-primary-200 p-4 m-8">
          <p className="text-lg lg:text-xl text-gray-700 font-bold">
            No hay solicitudes pendientes.
          </p>
        </div>
      )}
    </>
  );
};

const Answered = ({ admissions }) => {
  return (
    <>
      {!_.isEmpty(admissions) ? (
        admissions.map((admission) => (
          <AdmissionApplicationCard key={admission.id} admission={admission} />
        ))
      ) : (
        <div className="bg-gray-300 border-l-4 border-l-primary-200 p-4 m-8">
          <p className="text-lg lg:text-xl text-gray-700 font-bold">
            No hay solicitudes respondidas.
          </p>
        </div>
      )}
    </>
  );
};
