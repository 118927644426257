import { useState, useEffect } from "react";
import * as _ from "lodash";

import QuestionComponent from "../../../components/admin/QuestionComponent";
import { adminCoursesServices } from "../../../services/api/admin/courses";

const Questions = () => {
  const [menuContent, setMenuContent] = useState("pending");
  const [pendings, setPendings] = useState([]);
  const [answered, setAnswered] = useState([]);

  const getPendingQuestions = () => {
    adminCoursesServices
      .getPendingQuestions()
      .then((response) => {
        setPendings(response.data.questions);
      })
      .catch((err) => {});
  };

  const getAnsweredQuestions = () => {
    adminCoursesServices
      .getAnsweredQuestions()
      .then((response) => {
        setAnswered(response.data.questions);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getPendingQuestions();
    getAnsweredQuestions();
  }, [pendings.length]);

  return (
    <div className="container mx-auto text-gray-700">
      <p className="text-center lg:text-left text-2xl lg:text-4xl font-bold">Todas las preguntas</p>

      {/* MENU */}
      <div className="flex justify-center md:justify-start items-center gap-8 px-8 py-3 mt-6 border-b border-b-gray-350">
        <button className="text-xl lg:text-2xl" onClick={() => setMenuContent("pending")}>
          <p
            className={`${
              menuContent === "pending"
                ? "text-primary-200 font-bold"
                : "text-gray-700"
            }`}
          >
            Pendientes
          </p>
        </button>

        <button className="text-xl lg:text-2xl" onClick={() => setMenuContent("answered")}>
          <p
            className={`${
              menuContent === "answered"
                ? "text-primary-200 font-bold"
                : "text-gray-700"
            }`}
          >
            Respondidas
          </p>
        </button>
      </div>

      <div className="border border-gray-350 mt-12">
        {/* CARD */}
        {menuContent === "pending" ? (
          <Pending questions={pendings} setPendings={setPendings} />
        ) : (
          <Answered questions={answered} />
        )}
      </div>
    </div>
  );
};

export default Questions;

const Pending = ({ questions, setPendings }) => {
  return (
    <>
      {!_.isEmpty(questions) ? (
        questions.map((question) => (
          <QuestionComponent
            key={question.id}
            question={question}
            pendings={questions}
            setPendings={setPendings}
          />
        ))
      ) : (
        <div className="bg-gray-300 border-l-4 border-l-primary-200 p-4 m-8">
          <p className="text-center lg:text-left text-lg lg:text-xl text-gray-700 font-bold">
            No hay preguntas pendientes.
          </p>
        </div>
      )}
    </>
  );
};

const Answered = ({ questions }) => {
  return (
    <>
      {!_.isEmpty(questions) ? (
        questions.map((question) => (
          <QuestionComponent key={question.id} question={question} answered />
        ))
      ) : (
        <div className="bg-gray-300 border-l-4 border-l-primary-200 p-4 m-8">
          <p className="text-center lg:text-left text-lg lg:text-xl text-gray-700 font-bold">
            No hay preguntas respondidas.
          </p>
        </div>
      )}
    </>
  );
};
