import { useNavigate } from "react-router-dom";

export default function NotFoundComponent({ label, url, buttonText, label2 }) {
  const navigate = useNavigate();

  return (
    <div className="space-y-5 md:space-y-10 bg-white py-24 shadow-xl flex flex-col items-center justify-center container px-auto mx-auto text-center text-4xl w-11/12 md:w-full h-full">
      <div className="mt-4 text-2xl md:text-4xl text-primary-300">
        {label}
        {label2 && <b className="font-bold"> {label2}</b>}
      </div>{" "}
      <button
        type="button"
        className="bg-primary-200 hover:bg-primary-300 text-white text-sm md:text-xl   mx-auto lg:m-0 p-3 px-6 font-bold  hover:bounce transition transform hover:scale-105 flex-col"
        onClick={() => navigate(url)}
      >
        Volver al {buttonText}
      </button>
    </div>
  );
}
