import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BenefitComponent({ icon, br, br2, br3 }) {
  return (
    <div className="w-full my-3 lg:my-10 text-xl lg:text-2xl leading-none">
      <div className="mb-5 bg-primary-50 rounded-full w-12 h-12 lg:w-20 lg:h-20 mx-auto flex items-center justify-center">
        <FontAwesomeIcon
          icon={icon}
          className="text-primary-200 text-2xl lg:text-5xl"
        />
      </div>
      {br} <br />
      {br2 && (
        <>
          {br2}
          <br />{" "}
        </>
      )}
      {br3}
    </div>
  );
}
