import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

import * as _ from "lodash";
import DataTable from "react-data-table-component";
import {
  columnsMembershipsSales,
  paginationComponentOptions,
} from "../../../utils/tableOptions";
import TableNoData from "../../TableNoData";

const CommissionsHistory = ({
  startDate,
  endDate,
  setDateRange,
  commissions,
}) => {
  registerLocale("es", es);

  return (
    <>
      <div className="flex mt-16 md:mt-24 lg:mt-32 mb-10">
        <p className="text-3xl font-bold text-gray-700">Historial de Ventas</p>
      </div>

      <div className="border border-gray-350 p-5">
        <div>
          <div className="w-full lg:w-1/3 ml-auto text-lg mb-5">
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              dateFormat="dd/MM/yyyy"
              isClearable={true}
              className="text-gray-700 placeholder:text-gray-700 border border-slate-300 focus:outline-none py-1 px-3 w-full"
              placeholderText="Buscar por rango de fechas..."
              locale="es"
            />
          </div>
          <DataTable
            columns={columnsMembershipsSales}
            data={commissions}
            noDataComponent={<TableNoData />}
            pagination
            paginationComponentOptions={paginationComponentOptions}
          />
          <div></div>
        </div>
      </div>
    </>
  );
};

export default CommissionsHistory;
