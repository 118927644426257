import { useState } from "react";
import { adminOrdersServices } from "../../services/api/admin/orders";
import BasicModal from "../../components/Modals/TrackingModal";

import { notifications } from "../../utils/notifications";

const OrderStatusComponent = ({ order, fetchOrders }) => {
  const [showSelect, setShowSelect] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleAddTracking = (values) => {
      adminOrdersServices
      .updateOrderStatus(order.id, { status: 'Send',shippingCarrier:values.shippingCarrier,trackingNumber:values.trackingNumber })
      .then((response) => {
        fetchOrders();
        notifications.success("El estado de la orden se actualizó con éxito.");
        setShowSelect(false);
        setShowModal(false);
      })
      .catch((err) => {});

  }

  const handleChangeStatus = (event) => {
    let value = event.target.value;
    if (value === "default" || value === order.status) {
      setShowSelect(false);
      return;
    }
    if (value === "Send") {setShowModal(true);
    } else {
      adminOrdersServices
        .updateOrderStatus(order.id, { status: event.target.value })
        .then((response) => {
          fetchOrders();
          notifications.success("El estado de la orden se actualizó con éxito.");
          setShowSelect(false);
        })
        .catch((err) => {});
    }

  };

  return (
    <>
      <div>
        <BasicModal
          visible={showModal}
          setVisible={setShowModal}
          handleAddTracking={handleAddTracking}
        />
        {showSelect ? (
          <div className="flex flex-col lg:flex-row">
            <select
              className="w-full bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none"
              onChange={handleChangeStatus}
              value="default"
            >
              <option value="default" disabled="disabled">
                Seleccione una opción
              </option>
              <option value="ConfirmPayment">Pago confirmado</option>
              <option value="DeniedPayment">Pago rechazado</option>
              <option value="Send">Enviado</option>
            </select>
            <button
              onClick={() => setShowSelect(false)}
              className="w-auto bg-tertiary text-white text-center py-2 px-3 mt-2 lg:mt-0 lg:ml-2"
            >
              X
            </button>
          </div>
        ) : (
          <button
            onClick={() => setShowSelect(true)}
            className="w-full bg-primary-200 text-white text-center py-2 px-3 text-sm lg:text-base"
          >
            Cambiar estatus
          </button>
        )}
      </div>
    </>
  );
};

export default OrderStatusComponent;
