/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as _ from "lodash";
import ImageViewer from "react-simple-image-viewer";
import { Skeleton } from "react-skeleton-generator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserContext from "../../context/UserContext";
import CarrouselProducts from "../../components/store/CarrouselProducts";
import noImageProduct from "../../assets/images/store/no-image-product.jpg";
import { shopifyService } from "../../services/api/shopify";
import { bannersServices } from "../../services/api/banners";
import { cartServices } from "../../services/api/cart";
import { notifications } from "../../utils/notifications";
import PublishCartModal from "../../components/Modals/PublishCartModal";
import CartModal from "../../components/Modals/CartModal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { validationPrice } from "../../utils";

const ProductSingleView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { currentUser } = useContext(UserContext);

  const [itMayInterestYou, setItMayInterestYou] = useState([]);
  const [product, setProduct] = useState({});
  const [variantOption1, setVariantOption1] = useState({});
  const [variantOption2, setVariantOption2] = useState({});
  const [variantOption3, setVariantOption3] = useState({});
  const [variant, setVariant] = useState({});
  const [variantImage, setVariantImage] = useState({});
  const [variantOptionsSelected, setVariantOptionsSelected] = useState({
    option1: "",
    option2: "",
    option3: "",
  });

  const [productStock, setProductStock] = useState(0);
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [advertisingBanner, setAdvertisingBanner] = useState({});

  const [isLoaded, setIsLoaded] = useState(false);

  const [showPublish, setShowPublish] = useState(false);

  const [currentPrice, setCurrentPrice] = useState();
  const [currentPrices, setCurrentPrices] = useState({
    price: null,
    compare_at_price: null,
  });

  const handleShoppingCart = async (
    id,
    quantity,
    variants,
    shopify_store_id
  ) => {
    if (quantity === 0) {
      quantity = 1;
    }

    let variantId = variants[0].id;
    if (variants.length > 1) {
      variants.forEach((variant) => {
        let name = variantOptionsSelected.option1;
        if (
          variantOptionsSelected.option2 !== null &&
          variantOptionsSelected.option2 !== ""
        ) {
          name = name + " / " + variantOptionsSelected.option2;
        }
        if (
          variantOptionsSelected.option3 !== null &&
          variantOptionsSelected.option3 !== ""
        ) {
          name = name + " / " + variantOptionsSelected.option3;
        }

        if (variant.title === name) {
          variantId = variant.id;
        }
      });
    }

    await cartServices
      .addProductToCart({
        product_id: id,
        amount: quantity,
        variant_id: variantId,
        shopify_store_id: shopify_store_id,
      })
      .then((response) => {
        notifications.success(response.data.message);
        setShowPublish(true);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          notifications.warn(
            <div>
              {" "}
              <a
                onClick={() =>
                  navigate({ pathname: "", search: "?login=true" })
                }
                className="text-primary-200"
              >
                Inicie sesión{" "}
              </a>
              para adquirir un producto
            </div>
          );
        }
      });
  };

  const getProductImage = (product, variantImage) => {
    if (product.variants.length > 0 && variantImage && variantImage.src) {
      return variantImage.src;
    }
    if (product.image && product.image.src) {
      return product.image.src;
    }

    return noImageProduct;
  };

  const fetchProducts = () => {
    shopifyService
      .getAllProducts()
      .then((response) => {
        let products = response.data.products;
        setItMayInterestYou(products.slice(10, 15));
      })
      .catch((err) => {});
  };

  const fetchProductById = () => {
    shopifyService
      .getProduct(parseInt(params.id), parseInt(params.store_id))
      .then((response) => {
        let product = response.data.product;
        setVariantImage({});
        setProduct(product);
        setCurrentPrice(
          showPrice()
            ? product.variants[0].price
            : product.variants[0].compare_at_price > 0
            ? product.variants[0].compare_at_price
            : product.variants[0].price
        );
        setCurrentPrices({
          price: product.variants[0].price,
          compare_at_price: product.variants[0].compare_at_price,
        });
        if (product.options[0]) {
          setVariantOption1(product.options[0]);
          setVariantOptionsSelected((state) => ({
            ...state,
            option1: product.options[0].values[0],
          }));
        }
        if (product.options[1]) {
          setVariantOption2(product.options[1]);
          setVariantOptionsSelected((state) => ({
            ...state,
            option2: product.options[1].values[0],
          }));
        }
        if (product.options[2]) {
          setVariantOption3(product.options[2]);
          setVariantOptionsSelected((state) => ({
            ...state,
            option3: product.options[2].values[0],
          }));
        }
      })
      .catch((err) => {
        err.response.status === 404 && navigate("producto-no-encontrado");
        err.response.status === 400 && navigate("/tienda");
      });
  };

  const fetchBanners = () => {
    bannersServices
      .getBanners()
      .then((response) => {
        setAdvertisingBanner(response.data.bannerAdvertising);
      })
      .catch((err) => {});
  };

  const changeVariant = (variantSelected) => {
    if (product.variants.length > 1) {
      product.variants.forEach((variant) => {
        let name = variantOptionsSelected.option1;
        if (
          variantOptionsSelected.option2 !== null &&
          variantOptionsSelected.option2 !== ""
        ) {
          name = name + " / " + variantOptionsSelected.option2;
        }
        if (
          variantOptionsSelected.option3 !== null &&
          variantOptionsSelected.option3 !== ""
        ) {
          name = name + " / " + variantOptionsSelected.option3;
        }
        if (variantSelected === variant.title) {
          setCurrentPrice(
            showPrice()
              ? variant.price
              : variant.compare_at_price > 0
              ? variant.compare_at_price
              : variant.price
          );
          setCurrentPrices({
            price: variant.price,
            compare_at_price: variant.compare_at_price,
          });
        }
      });
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchBanners();
  }, []);

  useEffect(() => {
    fetchProductById();
  }, [params.id]);

  useEffect(() => {
    if (!currentUser) return;
    let finalPrice = showPrice()
      ? currentPrices.price
      : currentPrices.compare_at_price > 0
      ? currentPrices.compare_at_price
      : currentPrices.price;
    setCurrentPrice(finalPrice);
  }, [currentUser, currentPrices]);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const setQuantityOptions = () => {
    let limit = productStock;
    let options = [];
    for (let index = 0; index < limit; index++) {
      options.push(
        <option key={index} value={index + 1}>
          {index + 1}
        </option>
      );
    }
    return options;
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getStock = () => {
    let variant = null;

    let opt1 =
      variantOptionsSelected.option1 !== ""
        ? variantOptionsSelected.option1
        : null;
    let opt2 =
      variantOptionsSelected.option2 !== ""
        ? variantOptionsSelected.option2
        : null;
    let opt3 =
      variantOptionsSelected.option3 !== ""
        ? variantOptionsSelected.option3
        : null;

    // BY DEFAULT RETURN SUM OF ALL STOCK VARIANTS BY DEFAULT RETURN SUM OF ALL STOCK VARIANTS
    if (
      Object.values(variantOptionsSelected).every((option) => option === "")
    ) {
      let stocks = product.variants.map(
        (variant) => variant.inventory_quantity
      );
      return stocks.reduce(function (previousValue, currentValue) {
        return previousValue + currentValue;
      });
    }

    // IF VARIANT IS MATCH, RETURN ITS STOCK
    product.variants.forEach((_variant) => {
      if (
        _variant.option1 === opt1 &&
        _variant.option2 === opt2 &&
        _variant.option3 === opt3
      ) {
        variant = _variant;
        return true;
      }
    });

    if (!variant) return 0;

    setVariant(variant);

    return variant.inventory_quantity;
  };

  const getImage = () => {
    let imageMatch = null;

    if (
      product.variants.length === 1 &&
      product.variants[0].title === "Default Title" &&
      product.image &&
      product.image.src
    )
      return product.image.src;

    product.images.forEach((image) => {
      if (image.variant_ids.includes(variant.id)) {
        imageMatch = image;
        return true;
      }
    });

    setVariantImage(imageMatch);
  };

  const showPrice = () => {
    return validationPrice(currentUser, product.vendor);
  };

  useEffect(() => {
    if (_.isEmpty(product)) return;
    setProductStock(getStock());
  }, [variantOptionsSelected, product]);

  useEffect(() => {
    if (productStock < 1) setSelectedQuantity(0);
  }, [productStock]);

  useEffect(() => {
    if (_.isEmpty(variant)) return;
    getImage();
  }, [variant]);

  useEffect(() => {
    let vars = [advertisingBanner, product];
    if (vars.every((element) => !_.isEmpty(element))) {
      setIsLoaded(true);
    }
  }, [itMayInterestYou, advertisingBanner, product]);

  useEffect(() => {
    if (product && product.variants) {
      setCurrentPrices({
        price: product.variants[0].price,
        compare_at_price: product.variants[0].compare_at_price,
      });
      setCurrentPrice(
        showPrice()
          ? product.variants[0].price
          : product.variants[0].compare_at_price > 0
          ? product.variants[0].compare_at_price
          : product.variants[0].price
      );
    }
  }, [product]);

  return (
    <div className="mx-2">
      {/* PRODUCT */}
      {/* {!_.isEmpty(product) && ( */}
      <div className="w-full ">
        <div className="container mx-auto w-full pt-12 md:pt-24 px-10 md:px-0">
          {/* epale */}
          {isLoaded ? (
            <div className=" w-full grid grid-cols-1 md:grid-cols-2 gap-5">
              <div className="">
                <div className="w-full  md:px-5 lg:px-10">
                  <img
                    src={getProductImage(product, variantImage)}
                    alt=""
                    className="w-full max-h-[200px] md:max-h-[380px] lg:max-h-[550px] object-contain"
                  />
                </div>

                <div className="block overflow-x-auto  h-7/12">
                  <div className="flex m-5">
                    {product.images.map((image, index) => (
                      <img
                        key={index}
                        src={image.src}
                        alt=""
                        className="m-1 cursor-pointer w-20"
                        onClick={() => openImageViewer(index)}
                      />
                    ))}
                  </div>
                </div>
              </div>
              {/* 
              info del producto 
               */}
              <div className="mx-5">
                <p className="text-sm text-[#A1A1A1] :text-left">
                  {productStock < 1
                    ? "No disponible."
                    : `Disponible en ${product && product.vendor}`}
                </p>
                {product.isMostSold && (
                  <div className="bg-secondary w-32 uppercase md:mt-2 text-sm lg:text-xl text-white font-bold px-3 py-1 lg:py-2 text-center">
                    Más vendido
                  </div>
                )}
                <p className="md:mt-2 text-xl lg:text-4xl font-bold text-gray-700">
                  {product.title}
                </p>

                {showPrice() && (
                  <p className="text-base lg:text-xl text-green-50 mt-5">
                    <FontAwesomeIcon
                      className="text-green-50 mr-1"
                      icon={["fa", "tags"]}
                      size="sm"
                    />
                    Precio exclusivo para miembros
                  </p>
                )}
                <p
                  className={`text-sm md:text-3xl text-primary-200 ${
                    !showPrice() ? " mt-5" : ""
                  }`}
                >
                  ${currentPrice} MXN.
                </p>
                {!showPrice() && currentPrices.compare_at_price > 0 && (
                  <a
                    href="/membresias/dropshipping"
                    className="text-base lg:text-xl text-green-50"
                  >
                    <FontAwesomeIcon
                      className="text-green-50 mr-1"
                      icon={["fa", "tags"]}
                      size="sm"
                    />
                    Precio a miembros: ${currentPrices.price}
                  </a>
                )}
                <p className="text-sm md:text-xl  text-[#A1A1A1]">
                  Impuesto incluido.
                </p>
                {/* VARIANTS */}
                {product.options[0].values.length > 1 && (
                  <>
                    {!_.isEmpty(variantOption1) && (
                      <>
                        <p className="mt-5 mb-2 text-sm lg:text-2xl text-[#636363]">
                          {variantOption1.name}
                        </p>
                        <select
                          name="variant-option-1"
                          value={variantOptionsSelected.option1}
                          className=" w-5/12 lg:w-1/2 bg-white text-[#C0C0C0] text-sm lg:text-xl border border-slate-300 py-3 px-4 focus:outline-none"
                          onChange={(event) => {
                            setVariantOptionsSelected((state) => ({
                              ...state,
                              option1: event.target.value,
                            }));
                            changeVariant(event.target.value);
                          }}
                        >
                          {variantOption1.values.map((option, idx) => (
                            <option
                              key={`${variantOption1.name}-opt-${idx}`}
                              value={option}
                            >
                              {capitalizeFirstLetter(option)}
                            </option>
                          ))}
                        </select>
                      </>
                    )}
                    {!_.isEmpty(variantOption2) && (
                      <>
                        <p className="mt-5 mb-2 text-sm lg:text-2xl text-[#636363]">
                          {variantOption2.name}
                        </p>
                        <select
                          name="variant-option-2"
                          value={variantOptionsSelected.option2}
                          className=" w-5/12 lg:w-1/2 bg-white text-[#C0C0C0] text-sm lg:text-xl border border-slate-300 py-3 px-4 focus:outline-none"
                          onChange={(event) => {
                            setVariantOptionsSelected((state) => ({
                              ...state,
                              option2: event.target.value,
                            }));
                            changeVariant(event.target.value);
                          }}
                        >
                          {variantOption2.values.map((option, idx) => (
                            <option
                              key={`${variantOption2.name}-opt-${idx}`}
                              value={option}
                            >
                              {capitalizeFirstLetter(option)}
                            </option>
                          ))}
                        </select>
                      </>
                    )}
                    {!_.isEmpty(variantOption3) && (
                      <>
                        <p className="mt-5 mb-2 text-sm lg:text-2xl text-[#636363]">
                          {variantOption3.name}
                        </p>
                        <select
                          name="variant-option-3"
                          value={variantOptionsSelected.option3}
                          className=" w-5/12 lg:w-1/2 bg-white text-[#C0C0C0] text-sm lg:text-xl border border-slate-300 py-3 px-4 focus:outline-none"
                          onChange={(event) => {
                            setVariantOptionsSelected((state) => ({
                              ...state,
                              option3: event.target.value,
                            }));
                            changeVariant(event.target.value);
                          }}
                        >
                          {variantOption3.values.map((option, idx) => (
                            <option
                              key={`${variantOption3.name}-opt-${idx}`}
                              value={option}
                            >
                              {capitalizeFirstLetter(option)}
                            </option>
                          ))}
                        </select>
                      </>
                    )}
                  </>
                )}
                <div className="w-full lg:w-7/12">
                  <div className="flex space-x-2 mt-5 mb-2">
                    <p className=" text-center lg:text-left text-sm lg:text-2xl text-[#636363]">
                      Cantidad
                    </p>
                    <p
                      className={`my-auto text-center lg:text-left text-sm lg:text-2xl ${
                        productStock < 1 ? "text-[#FF2828]" : "text-[#1AA51D]"
                      } `}
                    >
                      {productStock < 1
                        ? "(Sin existencia en tienda.)"
                        : `(Stock ${productStock} disponible.)`}
                    </p>
                  </div>

                  <select
                    name="quantity"
                    value={selectedQuantity}
                    className="  w-5/12 lg:w-1/2 bg-white text-[#C0C0C0] text-sm lg:text-xl border border-slate-300 py-3 px-4 focus:outline-none disabled:opacity-75 disabled:cursor-not-allowed"
                    disabled={productStock === 0}
                    onChange={(event) =>
                      setSelectedQuantity(event.target.value)
                    }
                  >
                    <option value={0} disabled>
                      Seleccionar
                    </option>
                    {setQuantityOptions()}
                  </select>
                </div>
                <div className="order-3 md:col-span-4 xl:col-span-3">
                  <div className="w-full md:w-1/2 h-auto ">
                    <button
                      onClick={() =>
                        handleShoppingCart(
                          product.id,
                          selectedQuantity,
                          product.variants,
                          product.shopify_store_id
                        )
                      }
                      className="w-full block bg-primary-200 text-white text-center font-bold py-2 lg:py-3 text-lg mt-5 disabled:opacity-75 disabled:cursor-not-allowed"
                      disabled={productStock < 1}
                    >
                      <div className="flex items-center justify-center">
                        <p className="pl-2 text-sm lg:text-xl">
                          Agregar al carrito
                        </p>
                      </div>
                    </button>
                  </div>
                  <div className="py-5">
                    <p className="text-xl md:text-2xl lg:text-3xl"></p>
                    <div
                      className="text-sm md:text-lg lg:text-xl text-[#A1A1A1]"
                      dangerouslySetInnerHTML={{ __html: product.body_html }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            heroSkeleton()
          )}

          {/* YOU MAY ALSO LIKE */}
          <div className="pb-12 lg:pb-24">{/* CONTENT */}</div>
        </div>
      </div>
      {/* )} */}

      {/* PRODUCTS LIST */}
      <div className="w-full md:px-8 lg:px-0">
        {isLoaded ? (
          itMayInterestYou.length > 0 && (
            <div className="container mx-auto w-full pt-6 md:pt-12 px-10 md:px-0">
              {/* YOU MAY ALSO LIKE */}

              <div className="pb-12 md:pb-16">
                <p className="text-gray-700 text-center lg:text-left text-xl lg:text-4xl font-bold pb-6">
                  También te puede interesar
                </p>
                {/* PRODUCT LIST */}
                <CarrouselProducts
                  products={itMayInterestYou}
                  productsSkeleton={() => productsSkeleton("theMostSold")}
                ></CarrouselProducts>
              </div>
            </div>
          )
        ) : (
          <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-5">
            {productsSkeleton()}
          </div>
        )}
      </div>

      {/* BANNER */}
      <div className="w-full">
        <div className="container mx-auto w-full pt-12 md:pt-24 px-10 md:px-0">
          <div className="pb-12 md:pb-24">
            {isLoaded ? (
              <div className="w-full h-full">
                {advertisingBanner.url ? (
                  <a
                    href={advertisingBanner.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={advertisingBanner.image}
                      alt=""
                      className="md:h-96 w-full md:object-cover"
                    />
                  </a>
                ) : (
                  <img
                    src={advertisingBanner.image}
                    alt=""
                    className="md:h-96 w-full md:object-cover select-none"
                  />
                )}
              </div>
            ) : (
              heroSkeleton()
            )}
          </div>
        </div>
      </div>

      <PublishCartModal
        visible={showPublish}
        setVisible={setShowPublish}
        modalContent={<CartModal setVisible={setShowPublish} isQuestion />}
      />

      {isViewerOpen && (
        <ImageViewer
          src={product.images.map((image) => image.src)}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={true}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
            zIndex: 50,
          }}
          closeOnClickOutside
        />
      )}
    </div>
  );
};

const heroSkeleton = () => {
  return (
    <Skeleton.SkeletonThemeProvider animation="opacity">
      <div className="w-full h-96">
        <Skeleton height="100%" borderRadius="0px" />
      </div>
    </Skeleton.SkeletonThemeProvider>
  );
};

const productsSkeleton = (section) => {
  return [...Array(5)].map((e, idx) => (
    <Skeleton.SkeletonThemeProvider key={idx} animation="opacity">
      <div key={`${section}-${idx}`} className="h-72">
        <Skeleton height="100%" borderRadius="0px" />
      </div>
    </Skeleton.SkeletonThemeProvider>
  ));
};

export default ProductSingleView;
