import { useFormikContext } from "formik";
import { useState, React, useEffect } from "react";
import { adminCoursesServices } from "../../services/api/admin/courses";

const Combo = ({ ...props }) => {
    const [courses, setCourses] = useState([]);
    const [selected, setSelected] = useState(props.uuid);
    selected !== props.uuid && setSelected(props.uuid)

    useEffect(() => {
        adminCoursesServices
            .getCourses()
            .then((response) => {
            setCourses(response.data.courses);
            })
            .catch((err) => {});
    }, []);
    const { setFieldValue } = useFormikContext();
    return (
        <div>
            { }
            <select
                className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                name="coursesEdit"
                defaultValue={selected}
                onChange={value => {
                    setFieldValue("courseEdit", value.target.value)
                    setSelected(value.target.value)
                }}
            >
                <option value="ninguno" selected={selected === "ninguno"}>Ninguno</option>
                {

                    courses ? courses.map((value) => (
                        <option value={value.uuid} selected={selected === value.uuid}>{value.name}</option>
                    )) : ""
                }


            </select>
        </div>
    )

};
export default Combo;
