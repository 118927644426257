import { useContext } from "react";
import AdminContext from "../../context/AdminUserContext";

const Index = () => {
  const { currentAdminUser } = useContext(AdminContext);

  return (
    <div>
      <p className="text-xl lg:text-4xl text-center">
        ¡Bienvenido{" "}
        <b className="text-primary-200 font-bold">
          {currentAdminUser && currentAdminUser.name}
        </b>
        !
      </p>
    </div>
  );
};

export default Index;
