import { api } from "../../../client";

export const adminOrdersServices = {
  getAllOrders() {
    return api.admin.get(`/admin/order`);
  },
  getOrder(id) {
    return api.admin.get(`/admin/order/${id}`);
  },
  updateOrderStatus(id, model) {
    return api.admin.put(`/admin/order/${id}`, model);
  },
  downloadPayment(id) {
    return api.admin.get(`/admin/order/payment/${id}`, {
      responseType: "blob",
    });
  },
};
