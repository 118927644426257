import { api } from "../../../client";

export const adminCouponsServices = {
  getCoupons() {
    return api.admin.get("/admin/coupon");
  },
  getCoupon(id) {
    return api.admin.get(`/admin/coupon/${id}`);
  },
  createCoupon(model) {
    return api.admin.post("/admin/coupon", model, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  updateCoupon(id, model) {
    return api.admin.put(`/admin/coupon/${id}`, model, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  deleteCoupon(id) {
    return api.admin.delete(`/admin/coupon/${id}`);
  },

  getCouponsItems() {
    return api.admin.get("/admin/coupon/items");
  },
};
