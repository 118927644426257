/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const List = ({ ...props }) => {
  return (
    <ul className="flex flex-col justify-center mr-0 lg:mr-20">
      {props.elements.map((value, index) => (
        <>
          <li
            className="w-full flex flex-row mb-1 lg:mb-2 text-left"
            key={index}
          >
            <FontAwesomeIcon
              icon={value.wrong ? "fa-xmark" : "fa-check"}
              className={
                value.wrong
                  ? "text-primary-200 mr-4"
                  : "text-primary-200 mr-2 pt-1"
              }
            />
            <div className="">
              {value.label}
              {value.link && (
                <a
                  tcurrentAdminUserarget="_blank"
                  className="font-bold text-primary-200"
                  href={value.link}
                >
                  {" "}
                  Tienda de Be Global Pro
                </a>
              )}
            </div>
          </li>
          {value.options &&
            value.options.map((text, index) => (
              <li
                className="text-xl lg:text-2xl ml-8 w-full flex flex-row items-center mb-1 lg:mb-2 text-left"
                key={index}
              >
                <FontAwesomeIcon
                  icon={["fa", "circle"]}
                  className={
                    "w-[7px] lg:w-[10px] lg:h-auto] text-primary-200 mr-2"
                  }
                />
                <div className="">{text}</div>
              </li>
            ))}
        </>
      ))}
    </ul>
  );
};
export default List;
