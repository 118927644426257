import premium from "../../assets/images/memberships/premium.jpg";
import List from "../../components/memberships/CheckList";
import { cartServices } from "../../services/api/cart";
import { notifications } from "../../utils/notifications";
import { useNavigate } from "react-router-dom";

const elementsCourses = [
  { label: "Facebook Ads principiantes" },
  { label: "Facebook Ads teórico" },
  { label: "Facebook Ads avanzado" },
  { label: "DROPSHIPPING manager" },
  { label: "Chat selling" },
  { label: "Aprende a crear tu tienda en línea desde cero" },
  {
    label:
      "Cursos de Actualización en Vivo: Maximiza tu Conocimiento en Todos los Cursos",
  },
  { label: "Embudo o funnel de ventas" },
  { label: "Crea tu propio contenido para redes sociales" },
];

const elementsSuppliers = [
  { label: "Bodega Global" },
  { label: "Bodega Puebla" },
  { label: "Bodega PlayMaket" },
  { label: "Bodega Ciudad de México" },
  { label: "Bodega Guadalajara" },
];

const EntrepreneurMembership = () => {
  const navigate = useNavigate();
  const addEntrepeneurToCart = () => {
    cartServices
      .insertMembership()
      .then((response) => {
        notifications.success(response.data.message);
        navigate("/carrito-de-compras");
      })
      .catch((err) => {
        if (err.response.status === 401) {
          notifications.warn(
            <div>
              {" "}
              <button
                onClick={() =>
                  navigate({ pathname: "", search: "?login=true" })
                }
                className="text-primary-200"
              >
                {" "}
                Inicie sesión{" "}
              </button>{" "}
              para adquirir esta membresía
            </div>
          );
        }
      })
      .finally(() => {});
  };
  return (
    <div className="bg-white ">
      <div className="container mx-auto px-4 md:px-0  flex flex-col lg:flex-row text-xl lg:text-4xl  ">
        <div className="text-neutral-50 text-right	 w-full lg:w-2/12 flex-auto mb-10	lg:mb-20 ">
          <img
            alt=""
            src={premium}
            className="mx-auto object-cover   w-full lg:w-auto lg:object-fill h-60  lg:h-[690px]"
          ></img>
        </div>
        <div className="w-full lg:w-4/6  text-gray-700 flex-auto	 text-center lg:text-left lg:pl-8">
          <div className="mb-5">
            <h1 className="font-bold text-center lg:text-left mb-5 text-2xl lg:text-4xl">
              Franquicia Emprendedor
            </h1>
            <p className="font-bold text-primary-200">$6,000 MXN</p>
            <p className="text-xl lg:text-2xl text-gray-650">
              A partir del cuarto mes $2,500 MXN
            </p>
          </div>

          <div className="">
            <div className="text-xl lg:text-2xl text-gray-650">
              <p className="mb-4 font-bold">
                Incluye acceso únicamente a <br className="md:hidden" /> los
                siguientes cursos:
              </p>
              <List elements={elementsCourses}></List>
            </div>
            <br />
            <div className="text-xl lg:text-2xl text-gray-650">
              <p className="mb-4 font-bold">
                PROVEEDORES ÚNICAMENTE <br className="md:hidden" />
                ACCESO CON:
              </p>
              <List elements={elementsSuppliers}></List>
            </div>
          </div>
          <div className="font-bold">
            <button
              onClick={() => addEntrepeneurToCart()}
              href="/membresias/formulario"
              type="submit"
              className="bg-primary-200 text-white text-xl lg:text-2xl py-3 px-4 disabled:opacity-75 mt-5 w-full md:w-1/3 text-center"
            >
              Adquirirla
            </button>
          </div>
          <br></br>
        </div>
      </div>
    </div>
  );
};

export default EntrepreneurMembership;
