import { useNavigate } from "react-router-dom";
import posterBackup from "../../assets/images/imagen-contenido-curso.png";
import moment from "moment";

export default function PostComponent({
  id,
  title,
  body,
  publish_date,
  featured_image,
  author,
  slug,
  hideAuthor,
}) {
  const replaceWithBr = (text) => {
    return text ? text.replace(/\n/g, "<br />") : "";
  };
  const navigate = useNavigate();

  const maxLength = 450;
  const truncatedBody =
    body.length > maxLength ? body.substring(0, maxLength) + "..." : body;
  return (
    <div
      onClick={() => navigate(`/blog/${slug}`)}
      title="Haz click para ver más."
      className="hover:cursor-pointer hover:bounce w-full h-50 bg-white border-4 border-white shadow-lg flex text-xl text-gray-700 rounded-lg transition transform hover:scale-105 flex-col"
    >
      <div className="flex flex-col h-full">
        <img
          alt=""
          src={featured_image ? featured_image : posterBackup}
          className="h-40 w-full object-cover"
        />
        <div className="flex-grow p-5">
          <h1 className="text-xl md:text-3xl text-primary-200 font-bold text-left pb-4">
            {title}
          </h1>
          <p
            dangerouslySetInnerHTML={{
              __html: replaceWithBr(truncatedBody),
            }}
          />
        </div>
        {!hideAuthor && (
          <div className="flex justify-between p-5 border-t border-gray-200">
            <div className="flex space-x-2">
              <div className="h-12 w-12 bg-gray-500 rounded-full overflow-hidden">
                <img
                  alt=""
                  src={author.avatar}
                  className="h-full w-full object-cover"
                />
              </div>
              <p className="my-auto">{author.name}</p>
            </div>
            <div className="my-auto">
              {moment(publish_date).format("D [de] MMMM [del] YYYY")}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
