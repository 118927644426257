const profileNavbarItems = [
    {
        label: "General",
        link: "/mi-perfil"
    },
    {
        label: "Facturacion",
        link: "/mi-perfil/facturacion"
    },
    {
        label: "Mis diplomas",
        link: "/mi-perfil/diplomas"
    },
    {
        label: "Direcciones de envío",
        link: "/mi-perfil/direcciones"
    },

];

export default profileNavbarItems;