import fileDownload from "js-file-download";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { profileService } from "../services/api/profile";

const Diploma = ({ diploma, index, last }) => {
  const handleDownloadeDiploma = async () => {
    await profileService
      .downloadDiploma(diploma.id)
      .then((response) => {
        fileDownload(response.data, `${diploma.name} - Diploma.pdf`);
      })
      .catch((err) => {});
  };

  return (
    <div
      className={`flex flex-col lg:flex-row justify-between items-start lg:items-center border-gray-350 ${
        index === 0 ? "pb-8" : "py-8"
      } ${!last ? "border-b" : "pb-0"}`}
    >
      <div className="flex items-center w-full">
        <FontAwesomeIcon
          className="text-primary-200"
          icon="medal"
          lib="far"
          size="2xl"
        />
        <div className="pl-3">
          <p className="text-gray-550 text-xl leading-none">Diploma</p>
          <p className="text-gray-700 font-bold text-xl xl:text-2xl leading-none">
            {diploma.name}
          </p>
        </div>
      </div>
      <button
        type="button"
        className="text-primary-200 text-xl pt-5 lg:pt-0 mx-auto lg:m-0"
        onClick={() => handleDownloadeDiploma()}
      >
        <FontAwesomeIcon className="pr-2" icon="download" lib="far" />
        Descargar
      </button>
    </div>
  );
};

export default Diploma;
