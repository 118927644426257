/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const Details = ({ product,admin }) => {
  const [image, setImage] = useState('')
  const getPrice = () => {
    if (product.price > 0) {
      return (product.price * product.quantity).toLocaleString('en-US', {
        minimumFractionDigits: 2
      })
    }

    return product.price.toLocaleString('en-US')
  }

  const getImage = async () => {
    for (const image of product.images) {
      if (
        image.variant_ids.length > 0 &&
        image.variant_ids[0] === product.variant_id
      ) {
        setImage(image.src)
        return
      }
    }

    if (product.images.length > 0) {
      setImage(product.images[0].src)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await getImage()
    }
    fetchData()
  }, [product.images, product.variant_id])

  return (
    <div className='flex relative py-4 px-5 border-b border-gray-300'>
      <div className='flex-shrink-0'>
        {product.product_exists ? (
          <img
            src={image}
            alt=''
            className='h-20 w-20 rounded-md object-cover object-center'
          />
        ) : (
          <span title='Este producto ha sido eliminado de la tienda.'>
            <FontAwesomeIcon
              icon='fa-solid fa-cart-circle-exclamation'
              size={`${admin?"4x":"2x" }`}
            />
          </span>
        )}
      </div>
      <div className='ml-5 md:ml-3 flex flex-1 flex-col'>
        <div className='flex justify-between'>
          <p
            className={`${
              product.product_exists ? 'text-gray-700' : 'text-yellow-700'
            } font-bold text-base lg:text-xl text-left leading-none`}
          >
            {product.title}{' '}
          </p>
          <div>
            <p className='ml-8 font-bold text-base lg:text-xl text-gray-900 leading-none text-right'>
              ${getPrice()} <br /> <small>MXN</small>
            </p>
            <p className='text-gray-700 font-bold text-sm lg:text-lg leading-none text-right mt-3'>
              Cant. {product.quantity}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Details
