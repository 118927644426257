import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Context from "../../../context/AdminUserContext";
import TableLoading from "../../../components/TableLoading";
import TableNoData from "../../../components/TableNoData";
import {
  getColumnsForShippings,
  customStyles,
  paginationComponentOptions,
} from "../../../utils/tableOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { adminShippingsServices } from "../../../services/api/admin/shippings";

const Index = () => {
  const { currentAdminUser } = useContext(Context);
  const [shippings, setShippings] = useState([]);
  const [formattedShippings, setFormattedShippings] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchShippings = () => {
    adminShippingsServices
      .getShippings(currentAdminUser.id)
      .then((response) => {
        setShippings(response.data.shippings);
      })
      .catch((err) => {});
  };

  const formatShippingsData = () => {
    let data = [];

    shippings.forEach((shipping, idx) => {
      data.push({
        name: (
          <span className="font-bold text-primary-200">{shipping.name}</span>
        ),
        cost: "$" + parseFloat(shipping.cost).toFixed(2) + " MXN",
        days: shipping.days,
        detailsComponent: (
          <Link
            to={`/admin/envios/${shipping.id}/editar`}
            className="w-full text-primary-200 hover:text-primary-300 text-center text-sm lg:text-base"
          >
            Editar
          </Link>
        ),
      });
    });

    setFormattedShippings(data);
    setLoading(false);
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchShippings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAdminUser]);

  useEffect(() => {
    // if (_.isEmpty(orders)) return;
    formatShippingsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippings]);

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <p className="text-4xl font-bold">Envios</p>
        <Link
          to="/admin/envios/nuevo"
          className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 mt-5 text-center md:text-left disabled:opacity-75"
        >
          <FontAwesomeIcon icon={["fa", "plus"]} size="sm" className="mr-3" />
          Añadir nuevo
        </Link>
      </div>

      <div className="border border-gray-350 mt-12">
        <DataTable
          columns={getColumnsForShippings}
          data={formattedShippings}
          noDataComponent={<TableNoData />}
          progressPending={loading}
          progressComponent={<TableLoading />}
          customStyles={customStyles}
          pagination
          paginationComponentOptions={paginationComponentOptions}
        />
      </div>
    </div>
  );
};

export default Index;
