import { useFormikContext } from "formik";
import { useState, React } from "react";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { es } from "date-fns/locale";

function disablePrevDates(startDate) {
  const startSeconds = Date.parse(startDate);
  return (date) => {
    return Date.parse(date) < startSeconds;
  };
}
const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [Cdate, setDate] = useState(moment(new Date()).format("MM/DD/YYYY"));
  const [CdateHour, setDateHour] = useState(moment(new Date()).format("HH:mm"));

  return (
    <div>
      <div className="mb-5 text-3xl text-gray-550">
        <p className="">Fecha</p>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <KeyboardDatePicker
            id="date-picker-dialog"
            className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
            inputVariant="outlined"
            inputValue={Cdate}
            format="MM/dd/yyyy"
            clearable
            shouldDisableDate={disablePrevDates(Cdate)}
            onChange={(value) => {
              setDate(moment(value).format("MM/DD/YYYY"));
              setFieldValue("date", value);
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            okLabel="Aceptar"
            cancelLabel="Cancelar"
            clearLabel="Limpiar"
          />
        </MuiPickersUtilsProvider>
      </div>
      <div className="mb-9 text-3xl text-gray-550">
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <KeyboardTimePicker
            id="time-picker-dialog"
            className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
            inputVariant="outlined"
            inputValue={CdateHour}
            format="HH:mm"
            clearable
            value={new Date()}
            onChange={(value) => {
              setDateHour(moment(value).format("HH:mm"));
              setFieldValue("hour", value);
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            okLabel="Aceptar"
            cancelLabel="Cancelar"
            clearLabel="Limpiar"
          />
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
};
export default DatePickerField;
