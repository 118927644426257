import { api } from "../../../client";

export const locationServices = {
  getLocations() {
    return api.admin.get("/admin/location");
  },
  updateLocation(model) {
    return api.admin.post("/admin/location", model, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  deleteLocation(id) {
    return api.admin.delete(`/admin/location/`);
  },
};
