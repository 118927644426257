import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TableLoading = ({ size = "3x" }) => {
  return (
    <div className="py-10">
      <FontAwesomeIcon
        icon={["fa", "circle-notch"]}
        size={size}
        className="text-primary-200"
        spin
      />
    </div>
  );
};

export default TableLoading;
