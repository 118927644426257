import { Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import React, { useState } from "react";
import logo from "../assets/images/logo-globe-gbp.png";
import desktopNavbarItems from "../utils/desktopNavbarItems";
import NavBarItem from "../components/desktopLayout/NavBarItem";
const DesktopLayout = () => {
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <div className="flex flex-col h-screen justify-between ">
      <div className="">
        <NavBar />
        <main className="">
          <div className="border-b border-gray-400 px-5">
            <div className="container mx-auto h-20 flex items-center px-5 md:px-0">
              <img src={logo} alt="BeGlobalPro" className="h-6 mr-2" />
              <p className=" text-gray-700 ">
                <FontAwesomeIcon icon="chevron-right" /> Mi Escritorio
              </p>
            </div>
          </div>
          <div className="w-full h-full relative">
            <div className="w-full">
              <div className=" bg-gray-100 py-5 lg:py-10 px-5">
                <div className="container mx-auto ">
                  <h1
                    onClick={() => setOpenMenu(!openMenu)}
                    className={`block lg:hidden cursor-pointer  lg:cursor-default text-gray-700 font-bold text-xl lg:text-4xl  ${
                      openMenu ? "mb-6" : "md:mb-6"
                    }  text-center md:text-left`}
                  >
                    <FontAwesomeIcon
                      icon={
                        openMenu
                          ? "fa-solid fa-chevron-down"
                          : "fa-solid fa-chevron-right"
                      }
                      size="sm"
                      className="md:hidden  mr-2"
                    />
                    Mi Escritorio
                  </h1>
                  <div className="hidden lg:block">
                    <h1 className="cursor-pointer  lg:cursor-default text-gray-700 font-bold text-4xl mb-6 text-center md:text-left">
                      <FontAwesomeIcon
                        icon={
                          openMenu
                            ? "fa-solid fa-chevron-down"
                            : "fa-solid fa-chevron-right"
                        }
                        size="sm"
                        className="md:hidden  mr-2"
                      />
                      Mi Escritorio
                    </h1>
                  </div>
                  {/* NAV*/}
                  {/* md:block flex items-center flex-col md:flex-row items-center justify-center lg:justify-start gap-5 lg:gap-10 text-2xl text-gray-600 */}
                  <div className="md:hidden">
                    <div
                      className={
                        openMenu
                          ? " md:block flex px-20 flex-col lg:justify-start gap-5 lg:gap-10 text-sm text-gray-600 "
                          : "hidden md:flex flex-col md:flex-row items-center justify-center lg:justify-start gap-5 lg:gap-10 text-2xl text-gray-600"
                      }
                    >
                      {desktopNavbarItems.map((item, idx) => (
                        <NavBarItem
                          key={idx}
                          item={item}
                          idx={idx}
                        ></NavBarItem>
                      ))}
                    </div>
                  </div>
                  <div className="hidden md:flex flex-col md:flex-row items-center justify-center lg:justify-start gap-5 lg:gap-10 text-2xl text-gray-600">
                    {desktopNavbarItems.map((item, idx) => (
                      <NavBarItem key={idx} item={item}></NavBarItem>
                    ))}
                  </div>
                </div>
              </div>
              <div className="bg-white w-full container mx-auto py-10 px-5">
                <Outlet />
              </div>
            </div>
          </div>
        </main>
      </div>

      <Footer />
    </div>
  );
};

export default DesktopLayout;
