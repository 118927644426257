import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Context from "../../../context/AdminUserContext";
import { adminCoursesServices } from "../../../services/api/admin/courses";
import CourseCard from "../../../components/admin/CourseCard.js";
import TableNoData from "../../../components/TableNoData";

const Index = () => {
  const { currentAdminUser } = useContext(Context);

  const [courses, setCourses] = useState([]);
  const [querySearch, setQuerySearch] = useState("");

  const inputHandler = (e) => setQuerySearch(e.target.value.toLowerCase());

  const filteredData = courses.filter((course) => {
    if (querySearch === "") {
      return course;
    } else {
      return course.name.toLowerCase().includes(querySearch);
    }
  });

  const fetchCourses = () => {
    adminCoursesServices
      .getCourses()
      .then((response) => {
        setCourses(response.data.courses);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchCourses();
  }, [currentAdminUser]);

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <p className="text-2xl lg:text-4xl font-bold">Todos los cursos</p>
        <div className="flex flex-col md:flex-row items-center gap-5 mt-5 md:mt-0">
          <label className="relative block">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <FontAwesomeIcon
                icon={["fa", "magnifying-glass"]}
                className="text-[#A9A9A9]"
                size="sm"
              />
            </span>
            <input
              className="w-full md:w-52 lg:w-64 bg-white text-gray-700 placeholder:font-italic border border-slate-300 py-3 pl-12 pr-4 focus:outline-none"
              type="text"
              placeholder="Buscar"
              onChange={inputHandler}
            />
          </label>
          <Link
            to="/admin/cursos/nuevo"
            className="w-full md:w-56 bg-primary-200 text-white text-xl lg:text-2xl text-center lg:text-left py-3 px-5 disabled:opacity-75"
          >
            <FontAwesomeIcon icon={["fa", "plus"]} size="sm" className="mr-3" />
            Añadir nuevo
          </Link>
        </div>
      </div>

      {courses.length > 0 ? (
        <div className="border border-gray-350 mt-4 lg:mt-12">
          {filteredData.length > 0 ? (
            filteredData.map((course) => (
              <CourseCard key={course.uuid} course={course} />
            ))
          ) : (
            <div className="w-full flex justify-center">
              <TableNoData />
            </div>
          )}
        </div>
      ) : (
        <div className="bg-gray-300 border-l-4 border-l-primary-200 p-4 mt-8">
          <p className="text-lg lg:text-xl text-gray-700 font-bold">
            No hay cursos registrados.
          </p>
        </div>
      )}
    </div>
  );
};

export default Index;
