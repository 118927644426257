import React, { useContext, useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import AuthModal from "./AuthModal";
import logo from "../assets/images/logo-bgp.svg";
import useUser from "../hooks/useUser";
import UserContext from "../context/UserContext";
import Dropdown from "./Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function NavBar() {
  const { currentUser } = useContext(UserContext);
  const [showLogin, setShowLogin] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const keyValues = window.location.search;
  const urlParams = new URLSearchParams(keyValues);
  const { logout } = useUser();
  const location = useLocation();

  const onLogout = async () => {
    await logout();
    setOpenMenu(false);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    urlParams.get("login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (urlParams.get("login") === "true") {
      setShowLogin(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <>
      <nav className=" w-full h-16 bg-white shadow-md flex items-center z-40 fixed px-5">
        <div className="container mx-auto flex justify-between">
          {/* Logo BeGlobalPro */}
          <Link to="/" onClick={() => setOpenMenu(false)}>
            <img
              src={logo}
              alt="logo de BeGlobal Pro"
              className="w-44 md:w-40 lg:w-44 px-5 md:px-0"
            />
          </Link>
          {/* Menú Móvil */}
          <div
            className="flex items-center md:hidden cursor-pointer "
            onClick={() => setOpenMenu(!openMenu)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </div>

          {/* Menu Pc */}
          <div className="md:flex items-center hidden">
            <NavLink
              to="/blog"
              className="mr-3 lg:mr-6"
              onClick={() => setOpenMenu(false)}
            >
              {({ isActive }) => (
                <span
                  className={
                    isActive
                      ? "bg-primary-200 px-3 py-2 text-white font-bold"
                      : undefined
                  }
                >
                  Blog
                </span>
              )}
            </NavLink>
            <NavLink
              to="/membresias"
              className="mr-3 lg:mr-6"
              onClick={() => setOpenMenu(false)}
            >
              {({ isActive }) => (
                <span
                  className={
                    isActive
                      ? "bg-primary-200 px-3 py-2 text-white font-bold"
                      : undefined
                  }
                >
                  Membresías
                </span>
              )}
            </NavLink>
            {/* <NavLink
              to="/servicios"
              className="mr-3 lg:mr-6"
              onClick={() => setOpenMenu(false)}
            >
              {({ isActive }) => (
                <span
                  className={
                    isActive
                      ? "bg-primary-200 px-3 py-2 text-white font-bold"
                      : undefined
                  }
                >
                  Servicios
                </span>
              )}
            </NavLink> */}
            <NavLink
              to="/tienda"
              className="mr-3 lg:mr-6"
              onClick={() => setOpenMenu(false)}
            >
              {({ isActive }) => (
                <span
                  className={
                    isActive
                      ? "bg-primary-200 px-3 py-2 text-white font-bold"
                      : undefined
                  }
                >
                  Tienda
                </span>
              )}
            </NavLink>
            <NavLink
              to="/calendario"
              className="mr-3 lg:mr-6"
              onClick={() => setOpenMenu(false)}
            >
              {({ isActive }) => (
                <span
                  className={
                    isActive
                      ? "bg-primary-200 px-3 py-2 text-white font-bold"
                      : undefined
                  }
                >
                  Calendario
                </span>
              )}
            </NavLink>
            <NavLink
              to="/cursos"
              className="mr-3 lg:mr-6"
              onClick={() => setOpenMenu(false)}
            >
              {({ isActive }) => (
                <span
                  className={
                    isActive
                      ? "bg-primary-200 px-3 py-2 text-white font-bold"
                      : undefined
                  }
                >
                  Cursos
                </span>
              )}
            </NavLink>

            {currentUser ? (
              <Dropdown name={currentUser.name} />
            ) : (
              <button
                className=" border border-primary-200 px-6 py-2  text-primary-200"
                onClick={() => (currentUser ? false : setShowLogin(true))}
              >
                Iniciar Sesión
              </button>
            )}
            {currentUser && (
              <NavLink to="/carrito-de-compras" className="ml-3">
                <span>
                  <FontAwesomeIcon icon={["far", "cart-shopping"]} size="lg" />
                </span>
              </NavLink>
            )}
          </div>
        </div>

        {/* Menú Móvil */}
        {openMenu && (
          <div className="absolute top-16 md:hidden w-full flex flex-col  items-left   text-left bg-white shadow-md py-4 z-0 text-xl">
            <NavLink
              to="/blog"
              className="mb-2 px-6 text-gray-700 font-bold"
              onClick={() => setOpenMenu(false)}
            >
              {({ isActive }) => (
                <div className=" space-x-4 flex flex-row">
                  <span className="text-center w-1/6 pl-3">
                    <FontAwesomeIcon
                      icon={["fa", "blog"]}
                      size="lg"
                      color={isActive ? "#63abe6" : undefined}
                    />
                  </span>
                  <span
                    className={
                      isActive ? "text-primary-200 font-bold" : undefined
                    }
                  >
                    Blog
                  </span>
                </div>
              )}
            </NavLink>
            <NavLink
              to="/membresias"
              className="mb-2 px-6 text-gray-700 font-bold"
              onClick={() => setOpenMenu(false)}
            >
              {({ isActive }) => (
                <div className=" space-x-4 flex flex-row">
                  <span className="text-center w-1/6">
                    <FontAwesomeIcon
                      icon={["far", "users"]}
                      size="lg"
                      color={isActive ? "#63abe6" : undefined}
                    />
                  </span>
                  <span
                    className={
                      isActive ? "text-primary-200 font-bold" : undefined
                    }
                  >
                    Membresías
                  </span>
                </div>
              )}
            </NavLink>
            {/* <NavLink
              to="/servicios"
              className="mb-2 px-6 text-gray-700 font-bold"
              onClick={() => setOpenMenu(false)}
            >
              {({ isActive }) => (
                <div className=" space-x-4 flex flex-row">
                  <span className="text-center w-1/6">
                    <FontAwesomeIcon icon={["far", "briefcase"]} size="lg" />
                  </span>
                  <span
                    className={
                      isActive
                        ? "bg-primary-200 px-6 text-white font-bold"
                        : undefined
                    }
                  >
                    Servicios
                  </span>
                </div>
              )}
            </NavLink> */}
            <NavLink
              to="/tienda"
              className="mb-2 px-6 text-gray-700 font-bold"
              onClick={() => setOpenMenu(false)}
            >
              {({ isActive }) => (
                <div className=" space-x-4 flex flex-row">
                  <span className="text-center w-1/6">
                    <FontAwesomeIcon
                      icon={["far", "store"]}
                      size="lg"
                      color={isActive ? "#63abe6" : undefined}
                    />
                  </span>
                  <span
                    className={
                      isActive ? "text-primary-200 font-bold" : undefined
                    }
                  >
                    Tienda
                  </span>
                </div>
              )}
            </NavLink>
            <NavLink
              to="/calendario"
              className="mb-2 px-6 text-gray-700 font-bold"
              onClick={() => setOpenMenu(false)}
            >
              {({ isActive }) => (
                <div className=" space-x-4 flex flex-row">
                  <span className="text-center w-1/6">
                    <FontAwesomeIcon
                      icon={["far", "calendar"]}
                      size="lg"
                      color={isActive ? "#63abe6" : undefined}
                    />
                  </span>
                  <span
                    className={
                      isActive ? "text-primary-200 font-bold" : undefined
                    }
                  >
                    Calendario
                  </span>
                </div>
              )}
            </NavLink>
            <NavLink
              to="/cursos"
              className="mb-2 px-6 text-gray-700 font-bold"
              onClick={() => setOpenMenu(false)}
            >
              {({ isActive }) => (
                <div className=" space-x-4 flex flex-row">
                  <span className="text-center w-1/6">
                    <FontAwesomeIcon
                      icon={["far", "book"]}
                      size="lg"
                      color={isActive ? "#63abe6" : undefined}
                    />
                  </span>
                  <span
                    className={
                      isActive ? "text-primary-200 font-bold" : undefined
                    }
                  >
                    Cursos
                  </span>
                </div>
              )}
            </NavLink>

            <NavLink
              to="/carrito-de-compras"
              className="mb-2 px-6 text-gray-700 font-bold"
              onClick={() => setOpenMenu(false)}
            >
              {({ isActive }) => (
                <div className=" space-x-4 flex flex-row">
                  <span className=" w-1/6 text-center">
                    <FontAwesomeIcon
                      icon={["far", "cart-shopping"]}
                      size="lg"
                      color={isActive ? "#63abe6" : undefined}
                    />
                  </span>
                  <span
                    className={
                      isActive ? "text-primary-200 font-bold" : undefined
                    }
                  >
                    Carrito de compras
                  </span>
                </div>
              )}
            </NavLink>
            {currentUser ? (
              <div className="text-gray-900 flex flex-col ">
                <Link
                  to="/mi-perfil"
                  className="mb-2 px-6  font-bold"
                  onClick={() => setOpenMenu(false)}
                >
                  <div className=" space-x-4 flex flex-row">
                    <span className=" w-1/6 text-center">
                      <FontAwesomeIcon icon={["far", "user"]} size="lg" />
                    </span>
                    <span>Mi Perfil</span>
                  </div>
                </Link>
                <Link
                  to="/mi-escritorio"
                  className="mb-2 px-6  font-bold"
                  onClick={() => setOpenMenu(false)}
                >
                  <div className=" space-x-4 flex flex-row">
                    <span className=" w-1/6 text-center">
                      <FontAwesomeIcon
                        icon={["far", "laptop-code"]}
                        size="lg"
                      />
                    </span>
                    <span>Mi Escritorio</span>
                  </div>
                </Link>
                <hr className="border-gray-300 my-2 pb-2" />
                <div
                  type="button"
                  className="mb-2 px-6 font-bold"
                  onClick={() => onLogout()}
                >
                  <div className=" space-x-4 flex flex-row">
                    <span className=" w-1/6 text-center">
                      <FontAwesomeIcon
                        icon={["far", "right-from-bracket"]}
                        size="lg"
                      />
                    </span>
                    <span>Cerrar Sesión</span>
                  </div>
                </div>
              </div>
            ) : (
              <div
                type="button"
                className="mb-2 px-6 font-bold text-primary-200"
                onClick={() => {
                  if (!currentUser) {
                    setShowLogin(true);
                    setOpenMenu(false);
                  }
                }}
              >
                <div className=" space-x-4 flex flex-row">
                  <span className=" w-1/6 text-center">
                    <FontAwesomeIcon
                      icon={["far", "right-to-bracket"]}
                      size="lg"
                    />
                  </span>
                  <span>Iniciar Sesión</span>
                </div>
              </div>
            )}
          </div>
        )}
      </nav>
      <AuthModal isModalOpen={showLogin} setIsModalOpen={setShowLogin} />
      <div className="pt-16 relative"></div>
    </>
  );
}
