import { useState } from "react";
import StepsSelling from "../components/StepsSelling";
import { useParams } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import "./styles.css"; // Tell webpack that Button.js uses these styles
import ConceptComponent from "../components/Home/ConceptComponent";
import BenefitComponent from "../components/Home/BenefitComponent";
import backgroundImg from "../assets/images/back-image.png";
import banner from "../assets/images/home/banner.png";
import home1 from "../assets/images/home/home-1.png";
import home2 from "../assets/images/home/home-2.png";

import concept1 from "../assets/images/home/concepts/concept-1.png";
import concept2 from "../assets/images/home/concepts/concept-2.png";
import concept3 from "../assets/images/home/concepts/concept-3.png";

import social1 from "../assets/images/home/socials/amazon.png";
import social2 from "../assets/images/home/socials/facebook.png";
import social3 from "../assets/images/home/socials/Shopify.png";
import social4 from "../assets/images/home/socials/instagram.png";
import social5 from "../assets/images/home/socials/wordpress.png";
import social6 from "../assets/images/home/socials/whatsapp.png";
import social7 from "../assets/images/home/socials/mercadolibre.png";

import socialComplete1 from "../assets/images/home/socials/social-icon-1.png";
import socialComplete2 from "../assets/images/home/socials/social-icon-2.png";
import socialComplete3 from "../assets/images/home/socials/social-icon-3.png";
import socialComplete4 from "../assets/images/home/socials/social-icon-4.png";
import socialComplete5 from "../assets/images/home/socials/social-icon-5.png";
import socialComplete6 from "../assets/images/home/socials/social-icon-6.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthModal from "../components/AuthModal";

export default function Index() {
  const params = useParams();
  let propBool = false;
  if (params.login === "true") {
    propBool = true;
  }
  const [showLogin, setShowLogin] = useState(propBool);
  const stepsSelling = [
    {
      icon: "amazon",
      iconType: "fab",
      label:
        "Activa tu canal de venta ya sea amazon,    mercado libre, shopify   redes sociales, etc.",
    },
    {
      icon: "store",
      iconType: "far",
      label:
        "Subes los productos de la tienda BeGlobal.com aumentando tus ganancias.",
    },
    {
      icon: "cart-circle-check",
      iconType: "far",
      label:
        "Tu cliente entra a tu tienda, elige sus productos, hace el pago y Be Global Pro recibe  la orden de compra.",
    },
    {
      icon: "truck-clock",
      iconType: "far",
      label:
        " Be Global Pro se encarga de realizar el  envío y tu solo pagas lo  que previamente te  pagaron.",
    },
    {
      icon: "money-bill-1-wave",
      iconType: "far",
      label: "Disfruta de tus ganancias con ventas 100% seguras.",
    },
  ];

  return (
    <section className="mx-0">
      <AuthModal isModalOpen={showLogin} setIsModalOpen={setShowLogin} />
      <div className="bg-white">
        <div className="container mx-auto px-4 md:px-6 3xl:px-4 flex flex-col lg:flex-row text-4xl  ">
          <div className="text-left	w-full lg:w-4/6 flex-auto	text-6xl text-gray-700 my-10 lg:my-40">
            <h1 className="font-bold text-center lg:text-left  mb-5 text-xl lg:text-4xl">
              Sé uno de los elegidos para ser parte del único sistema de
              dropshipping express en México.
            </h1>
            <p className="text-xl lg:text-3xl text-center lg:text-left text-gray-650  mb-5">
              Todo lo que necesitas para ser un experto en el comercio
              electrónico, vender en línea sin invertir en stock de productos,
              almacenamiento y envíos.
            </p>
            <a href="#dropshippingDef">
              <input
                type="button"
                value="Conoce más"
                className="w-full xl:w-1/2 text-center bg-primary-200 py-3 px-4 text-white font-bold  cursor-pointer text-xl lg:text-2xl"
              />
            </a>
            <br></br>
          </div>
          <div className="text-neutral-50 text-right	w-full flex-auto	mb-20 lg:my-20">
            <img alt="" src={banner} className="lg:w-full"></img>
          </div>
        </div>
      </div>
      <div className="" id="dropshippingDef">
        <div className="pb-10 lg:pb-0 container mx-auto md:px-0 flex flex-col lg:flex-row text-4xl text-white bg-primary-300">
          <div className="w-full">
            <img alt="" src={home1} className="h-100 w-full object-cover"></img>
          </div>
          <div className="w-full lg: mr-0">
            <h1 className=" w-5/6 lg:text-right text-center justify-center  mx-auto text-xl lg:text-5xl  my-4 lg:mt-20">
              ¿Qué es el <b>dropshipping?</b>
            </h1>
            <div className="text-xl w-5/6 lg:text-left text-center justify-left mx-auto text-x lg:text-2xl ">
              El dropshipping es la manera más sencilla de empezar un negocio
              digital porque no requieres de inversión en stock de productos.
              Con el dropshipping no compras stock, no almacenas productos y no
              te arriesgas a tener productos estancados porque no se venden. Es
              todo lo contrario: en el dropshipping solo vas pagando lo que a ti
              previamente ya te pagaron los clientes a través de tu tienda en
              línea.
              <br></br>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-100 relative">
        <div className="py-10 lg:py-20 flex-wrap container mx-auto px-4 md:px-0  flex flex-row   text-center text-gray-700 text-base ">
          <div className="w-full  text-xl lg:text-4xl mb-10 lg:my-10 font-bold text-gray-650">
            ¿Cómo vender haciendo dropshipping con Be Global Pro?
          </div>
          <div className="w-full flex flex-col lg:flex-row text-3xl relative lg:px-20">
            {stepsSelling.map(({ icon, iconType, label }, index) => (
              <StepsSelling
                key={index}
                icon={icon}
                iconType={iconType}
                label={label}
                isFirst={index === 0}
                isLast={index === 4}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="">
        <div className="container flex flex-col lg:flex-row  bg-primary-200 mx-auto text-5xl">
          <div className="pt-10 lg:my-auto lg:mt-20 lg:pt-2 w-full  text-white text-left	lg:text-justify  lg:py-32  px-8 lg:px-24 pb-10 text-xl lg:text-3xl justify-left">
            En Be Global Pro contamos con más de <b>700 productos</b>
            diferentes ya puestos en México con precios de china y envíos
            express el mismo día que podrás vender en cualquier canal de venta
            ya sea: Amazon, Mercado Libre, shopify, redes sociales , etc.. Y
            solo vas pagando lo que se te va vendiendo, nosotros nos encargamos
            de empacar el producto y enviarlo por ti.
            <br></br>
            <br></br>
            <a
              className="bg-primary-200 text-center py-3 px-6 text-white font-bold w-11/12 md:w-auto cursor-pointer text-xl lg:text-2xl border border-white-300hover:text-zinc-100"
              href="/tienda"
              target="_blank"
              rel="noopener noreferrer"
            >
              Conoce los productos
            </a>
          </div>
          <div className="w-full text-neutral-50  flex-auto	 ">
            <img
              alt=""
              src={home2}
              className="ml-0 h-full md:h-[500px] lg:h-full w-full object-cover"
            ></img>
          </div>
        </div>
      </div>
      <div className="">
        <div className="flex-col-reverse lg:flex-row container mx-auto px-4 md:px-8 lg:px-0 bg-primary-300 flex text-4xl">
          <div className="w-full lg:w-1/2 text-neutral-50	my-auto mx-auto justify-center lg:pl-10 pb-10 lg:pb-2">
            <iframe
              className="w-full aspect-video"
              title="myFrame"
              src="https://www.youtube.com/embed/LWcBJgnPikU?si=2sftcOuIFzm18zMp"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="w-full lg:w-1/2 text-neutral-50 mt-10 lg:mt-20  lg:px-24">
            <h1 className="font-bold text-xl lg:text-5xl  text-center lg:text-right mb-5">
              Conoce nuestro sistema exclusivo Be Global Pro
            </h1>
            <p className="lg:text-right text-xl lg:text-4xl  text-center px-2 pb-10 lg:pb-20">
              Descubre reproduciendo el video como puedes formar parte del único
              sistema de ecommerce y dropshipping express en Mexico y que puedas
              iniciar un negocio digital altamente rentable.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className=" flex flex-col lg:flex-row lg:flex-wrap container mx-auto px-4 md:px-0 text-center text-gray-700 text-base py-10 lg:py-20">
          <div className="px-4 lg:px-20 mx-auto w-full text-xl lg:text-5xl my-5 font-bold text-gray-650 text-center">
            Canales de venta donde te enseñamos a vender como profesional
          </div>
          <div className="px-20 mx-auto w-full h-40 my-5 lg:my-20">
            <Swiper
              slidesPerView={2}
              spaceBetween={0}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 0,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 0,
                },
              }}
              modules={[Pagination]}
              className="mySwiper1"
            >
              <SwiperSlide>
                <img alt="" className="mx-auto" src={social1}></img>
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" className="mx-auto" src={social2}></img>
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" className="mx-auto" src={social3}></img>
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" className="mx-auto " src={social4}></img>
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" className="mx-auto " src={social5}></img>
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" className="mx-auto " src={social6}></img>
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" className="mx-auto " src={social7}></img>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="flex flex-wrap container mx-auto px-4 md:px-0 flex-row   text-center text-gray-700 text-base lg:text-lg ">
          <div className="w-full  text-xl lg:text-4xl my-10 font-bold">
            Nuestro concepto
          </div>
          <div className="flex-col md:flex-row w-full flex mb-10 text-gray-600 md:mx-4 lg:mx-0">
            <ConceptComponent
              concept={concept1}
              label={
                "Be Global Pro es una empresa donde las personas podrán encontrar educación para tener un negocio digital 100% rentable, atacar todos los canales de venta que ofrece el internet como son: Amazon, mercado libre, sitio web, redes sociales, etc."
              }
            ></ConceptComponent>
            <ConceptComponent
              concept={concept2}
              label={
                "Manejamos un almacén donde albergamos los productos más vendidos de cada proveedor en tendencia en todo el mundo y con los cuales el miembro puede iniciar su negocio sin necesidad de invertir en stock, podrá generar ventas y nuestro sistema de distribución se encarga de entregarle a tu cliente final."
              }
            ></ConceptComponent>
            <ConceptComponent
              concept={concept3}
              label={
                "Hemos desarrollado el sistema de membresías para ofrecerle a nuestros miembros un negocio único y diferenciado en el ámbito del comercio electrónico desarrollando un negocio altamente rentable con ingresos residuales y en constante innovación para mantenernos siempre como la empresa número 1 a nivel mundial."
              }
            ></ConceptComponent>
          </div>
        </div>
      </div>
      <div className="bg-zinc-50  py-10">
        <div className="flex-col container mx-auto px-4 md:px-0  flex flex-row  text-center text-gray-700 text-base ">
          <div className="w-full text-xl lg:text-4xl lg:my-10 font-bold mb-5 lg:mb-0">
            Beneficios de ser parte de Be Global Pro
          </div>
          <div className=" grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 font-bold">
            <BenefitComponent
              icon={["far", "tag"]}
              br={"Precios en productos"}
              br3={"directos de china"}
            />
            <BenefitComponent
              icon={["fas", "medal"]}
              br={" Diploma oficial"}
              br3={"al finalizar cada curso"}
            />
            <BenefitComponent
              icon={["fab", "whatsapp"]}
              br={" Acompañamiento via"}
              br3={" WhatsApp personal"}
            />
            <BenefitComponent
              icon={["far", "circle-check"]}
              br={" Todos nuestros precios"}
              br3={"incluyen IVA"}
            />

            <BenefitComponent
              icon={["far", "video"]}
              br={" Clases en vivo para"}
              br3={"dudas y actualizaciones"}
            />
            <BenefitComponent
              icon={["far", "store"]}
              br={"Más de 700"}
              br3={"productos diferentes"}
            />
            <BenefitComponent
              icon={["far", "location-smile"]}
              br={"Ubicación flexible hazlo "}
              br2={"desde cualquier parte"}
              br3={"del mundo"}
            />
            <BenefitComponent
              icon={["far", "play"]}
              br={"Clases pregrabadas "}
              br2={"para que las veas a tus"}
              br3={"tiempos"}
            />

            <BenefitComponent
              icon={["fab", "shopify"]}
              br={"App de vinculación"}
              br2={"de inventario en tiempo real"}
            />
            <BenefitComponent
              icon={["far", "file-certificate"]}
              br={"Certificación ecommerce "}
              br3={"manager validez con la SEP"}
            />
            <BenefitComponent
              icon={["far", "box-circle-check"]}
              br={"Productos Americanos"}
              br2={"100% originales"}
            />
          </div>
        </div>
      </div>
      <div className="container mx-auto bg-white">
        <div className="w-full  bg-gradient-to-r from-primary-100 to-primary-300  text-xl py-10 lg:py-20 text-center text-white">
          <p className="font-bold text-xl lg:text-5xl">
            ¡Contáctanos por{" "}
            <a
              className="text-white"
              href="https://api.whatsapp.com/send?phone=+526622873165&text=%C2%A1Quiero%20saber%20m%C3%A1s%20informaci%C3%B3n%20de%20Be%20Global%20Pro!"
              target="_blank"
              rel="noopener noreferrer"
            >
              WhatsApp
            </a>
            !
          </p>
          <div className="flex flex-col lg:flex-row  justify-center">
            <div className="w-full lg:w-2/12 font-bold text-7xl ">
              <a
                className="text-zinc-300"
                href="https://api.whatsapp.com/send?phone=+526622873165&text=%C2%A1Quiero%20saber%20m%C3%A1s%20informaci%C3%B3n%20de%20Be%20Global%20Pro!"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={["fab", "whatsapp"]}
                  className="fill-green-500  text-white text-6xl lg:text-9xl"
                />
              </a>
            </div>
            <div className="w-full lg:w-6/12  text-xl lg:text-3xl lg: text-center lg:text-left px-2 lg:px-0 mt-4 lg:mt-10">
              {" "}
              ¡Y obtén mucha más información acerca de nuestros servicios!
            </div>
          </div>
        </div>
      </div>
      <div className="w-full ">
        <div className="container mx-auto relative  lg:h-full">
          <img
            alt=""
            src={backgroundImg}
            className="object-cover w-full h-96 lg:h-full"
          />
          <div className="bg-primary-300 absolute top-0 w-full h-full opacity-[.60]" />
          <div className="lg:absolute absolute top-0 lg:top-1/2 left-0 lg:left-1/2 transform lg:-translate-x-1/2 lg:-translate-y-1/2">
            <div className="w-full  text-xl lg:text-5xl  text-neutral-50 font-bold text-center mt-20 px-4 md:px-0">
              ¡Aprende todos los días gratis!
              <div></div>
              <p className="text-1xl lg:text-2xl">
                Síguenos en nuestras redes sociales y aprende todos los días
                algo nuevo sobre el comercio electrónico
              </p>
              <div className="flex flex-row justify-center py-5">
                <a
                  className="mr-8"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/beglobalpro"
                >
                  <img
                    alt=""
                    className="mx-auto"
                    href=""
                    src={socialComplete1}
                  ></img>{" "}
                </a>
                <a
                  className="mr-8"
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/Beglobalpromx?t=xM7S-RIKKw2d9IZ1KoD50Q&s=09"
                >
                  <img
                    alt=""
                    className="mx-auto"
                    href=""
                    src={socialComplete2}
                  ></img>{" "}
                </a>
                <a
                  className="mr-8"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/beglobalpro/"
                >
                  <img
                    alt=""
                    className="mx-auto"
                    href=""
                    src={socialComplete3}
                  ></img>{" "}
                </a>
                <a
                  className="mr-8"
                  target="_blank"
                  rel="noreferrer"
                  href="https://api.whatsapp.com/send?phone=+526622873165&text=%C2%A1Quiero%20saber%20m%C3%A1s%20informaci%C3%B3n%20de%20Be%20Global%20Pro!"
                >
                  <img
                    alt=""
                    className="mx-auto"
                    href=""
                    src={socialComplete4}
                  ></img>{" "}
                </a>
                <a
                  className="mr-8"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.tiktok.com/@beglobalpro.org?_t=8jHiy47Rrny&_r"
                >
                  <img
                    alt=""
                    className="mx-auto"
                    href=""
                    src={socialComplete5}
                  ></img>{" "}
                </a>
                <a target="_blank" href="https://t.me/beglobalproinfo">
                  <img
                    alt=""
                    className="mx-auto"
                    href=""
                    src={socialComplete6}
                  ></img>{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
