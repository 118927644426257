import React from "react";

const MainItemCheckout = ({ item, type }) => {

  return (
    <>
      <div className="flex relative py-4 px-5 border-b border-gray-300">
        <div className="flex-shrink-0">
          <img
            src={item.image ? item.image : null}
            alt=""
            className="h-20 w-20 rounded-md object-cover object-center"
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col sm:ml-6">
          <div>
            <div className="flex justify-between">
              <div>
                <p className="text-primary-200 font-bold text-base lg:text-xl text-left italic leading-none">
                  {type}
                </p>
                <p className="text-gray-700 font-bold text-base lg:text-xl text-left leading-none">
                  {item.name}
                </p>
                {item.couponsCodes &&
                  item.couponsCodes.map((code) => (
                    <p className="text-green-50 font-bold text-base lg:text-xl text-left leading-none">
                      Cupon <b className="italic">{code}</b> aplicado.
                    </p>
                  ))
                }

                {/*
                   item.coupon &&
                  <p className="text-green-50 font-bold text-base lg:text-xl text-left leading-none">
                    Cupon <b className="italic">{item.coupon}</b> aplicado.
                  </p>
                */

                }

              </div>
              <div className={` ml-8 font-bold text-base lg:text-xl text-gray-900 leading-none text-right`}>


                <>
                  <p className={`${item.old_cost && item.old_cost !== item.cost ? "line-through text-red-50" : ""}`}>
                    $
                    {(item.old_cost ? item.old_cost : item.cost).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                    })}{" "}
                    <small> MXN</small> </p>

                </>
                {item.couponsCosts &&
                  item.couponsCosts.map((cost) => (
                    <p className={`text-green-50`}>
                    -  $
                      {(cost).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      })}{" "}
                      <small> MXN</small> </p>
                  ))
                }

                { item.old_cost && item.old_cost!==item.cost &&
                  <p className={`${item.old_cost !== item.cost ? "text-primary-200 " : ""}`}>
                    $
                    {item.cost.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                    })}{" "}
                    <small> MXN</small> </p>
                }

              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainItemCheckout;
