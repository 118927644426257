import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../styles/drop-file-input-payment.css";
import fileDefault from "../assets/images/file-blank-solid-240.png";

import { formatBytes } from "../utils";

const DropFileInput = ({ multiple, onFileChange, showFiles = true, text }) => {
  const wrapperRef = useRef(null);

  const [fileList, setFileList] = useState([]);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      let updatedList = [];
      if (multiple) {
        updatedList = [...fileList, newFile];
      } else {
        updatedList = [newFile];
      }
      setFileList(updatedList);
      onFileChange(updatedList);
    }
  };

  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    onFileChange(updatedList);
  };

  return (
    <>
      <div
        ref={wrapperRef}
        className="drop-file-input-pay flex justify-center mx-auto"
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className="drop-file-input__label">
          <FontAwesomeIcon
            icon={["far", "cloud-arrow-up"]}
            className="lg:mr-3 text-3xl lg:text-6xl"
            style={{ color: "#CDCDCD" }}
          />
          <p className="text-gray-700 text-sm lg:text-2xl font-bold mt-4">
            Arrastra y suelta
          </p>
          <div className="text-sm lg:text-xl">
            <span>o </span>
            <button className="text-primary-200">busca</button>
            <span> tu archivo para subir</span>
          </div>
          {multiple && (
            <p className="text-xl">(Puedes subir varias imágenes)</p>
          )}
        </div>
        <input
          type="file"
          name="image"
          value=""
          onChange={onFileDrop}
          multiple={multiple}
          
        />
      </div>
      {text ? (
        <div>
          <p className="text-gray-400 my-4 italic">
            {" "}
            Tamaño de imagen recomendado 1280x715 pixeles.{" "}
          </p>
          <p className="text-gray-400 my-4 italic">
            Formatos soportados: JPEG,JPG Y PNG
          </p>
        </div>
      ) : (
        ""
      )}

      {fileList.length > 0 && showFiles ? (
        <div className="drop-file-preview">
          <p className="drop-file-preview__title">Archivos</p>
          {fileList.map((item, index) => (
            <div key={index} className="drop-file-preview__item">
              <div className="text-center">
                <img src={fileDefault} alt="" />
              </div>
              <div className="drop-file-preview__item__info">
                <p>{item.name}</p>
                <p>{formatBytes(item.size)}</p>
              </div>
              <div className="drop-file-preview__item__del ">
                <button
                  className="flex items-center justify-center text-xl font-bold"
                  onClick={() => fileRemove(item)}
                >
                  X
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

DropFileInput.propTypes = {
  onFileChange: PropTypes.func,
};

export default DropFileInput;
