import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import Context from "../context/AdminUserContext";
import appStorage from "../utils/appStorage";
import { adminTokenLocalStorageKey } from "../utils";
import { adminAuthService } from "../services/api/admin/auth";

export default function useUser() {
  const navigate = useNavigate();

  const { jwt, setJWT, setCurrentAdminUser } = useContext(Context);
  const [state, setState] = useState({
    loading: false,
    error: false,
    success: false,
  });
  const [errors, setErrors] = useState([]);

  const login = useCallback(
    ({ email, password }) => {
      setState({ loading: true, error: false, success: false });

      adminAuthService
        .login({ email, password })
        .then((response) => {
          setState({ loading: false, error: false, success: true });
          let token = response.data.token;
          appStorage.setItem(adminTokenLocalStorageKey, token);
          setJWT(token);
        })
        .catch((err) => {
          setState({ loading: false, error: true, success: false });
          appStorage.removeItem(adminTokenLocalStorageKey);
          if (err.response.data.error) {
            setErrors([err.response.data.error]);
          }
          if (err.response.data.message) {
            setErrors([err.response.data.message]);
          }
        });
    },
    [setJWT]
  );

  const logout = useCallback(async () => {
    await adminAuthService
      .logout()
      .then((response) => {
        appStorage.removeItem(adminTokenLocalStorageKey);
        setJWT(null);
        setCurrentAdminUser(null);
        navigate("/admin/login", { replace: true });
      })
      .catch((err) => {
        if (err.response.data.error) {
          setErrors([err.response.data.error]);
        }
      });
  }, [setJWT, setCurrentAdminUser, navigate]);

  return {
    isLogged: Boolean(jwt),
    isLoading: state.loading,
    hasError: state.error,
    onSucess: state.success,
    login,
    logout,
    errors,
  };
}
