import { api } from "../../client";

export const WithdrawalService = {
  
  withdrawal(model) {
    return api.base.post("/withdrawal", model);
  },
  getWithdrawalHistory() {
    return api.base.get("/withdrawal");
  },
  getBankInfo() {
    return api.base.get("/withdrawal/withdrawal-information");
  },
  sendBankInfo(model) {
    return api.base.post("/withdrawal/withdrawal-information",model);
  },
 
};
