import { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";



import Context from "../../../context/AdminUserContext";
import { notifications } from "../../../utils/notifications";
import { adminProfileService } from "../../../services/api/admin/profile";

const Index = () => {
  const formSchema = Yup.object().shape({
    pass: Yup.string()
      .min(8, 'La contraseña debe tener al menos 8 caracteres')
      .required('Contraseña requerida'),

    confirmPass: Yup.string()
      .required('Confirmar contraseña requerida')
      .when('pass', {
        is: (pass) =>
          pass && pass.length > 0 ? true : 'Contraseña requerida',
        then: Yup.string().oneOf(
          [Yup.ref('pass')],
          'Las contraseñas deben coincidir'
        ),
      }),



  });


  const {  setCurrentAdminUser } = useContext(Context);

  const [initialValues] = useState({
    pass: "",
    confirmPass: "",
  });


  const handleSubmit = async (values) => {
    let body = values;

      body = {
        password: body.pass,

      };
    await adminProfileService
      .updateUser(body)
      .then((response) => {
        setCurrentAdminUser(response.data.admin);
        notifications.success(response.data.message);
      })
      .catch((err) => {
      });
  };

  return (
    <div>
      <div className="my-10 flex flex-col md:flex-row justify-between items-center">
        <p className="text-4xl font-bold">Cambiar contraseña</p>
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={(values, { resetForm }) => { handleSubmit(values); resetForm(); }}
        resetOnSubmit
      >
        {({ setFieldValue }) => (
          <Form>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">

              <div className="mb-9 text-xl text-gray-700">
                <p>Contraseña</p>
                <Field
                  name="pass"
                  className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                  type="password"

                />
                <ErrorMessage
                  name="pass"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>
              <div className="mb-9 text-xl text-gray-700">
                <p>Confirmar contraseña</p>
                <Field
                  name="confirmPass"
                  className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                  type="password"
                />
                <ErrorMessage
                  name="confirmPass"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>
            </div>


            <div className="w-full flex justify-end">
              <button
                type="submit"
                className="w-full md:w-1/3 bg-primary-200 text-white font-bold text-2xl py-3 disabled:opacity-75"
              // disabled={isLoading}
              >
                {false ? (
                  <FontAwesomeIcon icon="circle-notch" spin />
                ) : (
                  <span>Actualizar</span>
                )}
              </button>
            </div>
          </Form>


        )}

      </Formik>

    </div>
  );
};

export default Index;
