import Moment from "react-moment";
import {
  FacebookIcon,
  InstagramIcon,
  TelegramIcon,
  TiktokIcon,
  TumblrIcon,
  TwitterIcon,
  WhatsappIcon,
} from "./icons";
import logo from "../assets/images/logo-bgp.svg";

export default function Footer({ theme = "dark" }) {
  return (
    <footer className={`${theme === "dark" ? "bg-gray-100" : "bg-white"} px-5`}>
      <div className="border-b border-gray-400">
        <div className="container mx-auto py-4 lg:py-16 flex flex-col text-center md:text-left md:flex-row gap-y-10 lg:gap-y-12 md:gap-x-16">
          <div className="md:w-3/5 ">
            <h3 className="text-gray-600 text-xl font-bold mb-4">
              INFORMACIÓN
            </h3>
            <div className="flex flex-col lg:flex-row lg:space-x-8 lg:gap-y-1 text-gray-700 text-xl">
              <a href="/">Nosotros</a>
              <a href="/membresias">Membresias</a>
              <a href="/cursos">Cursos</a>
              <a href="/tienda">Tienda</a>
              {/* <a href="/servicios">Servicios</a> */}
            </div>
          </div>

          <div className="md:w-2/5 flex items-center justify-center">
            <div>
              <h3 className="text-gray-600 text-xl text-center lg:text-left font-bold mb-4 lg:mb-0">
                SIGUENOS
              </h3>

              <div className="flex flex-col lg:flex-row gap-4 md:gap-4 items-center">
                <div className="flex gap-4 justify-center">
                  <a
                    href="https://www.facebook.com/beglobalpro"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center w-10 h-10 border-2 border-gray-400 rounded-full"
                  >
                    <FacebookIcon styles="h-5 w-5 fill-gray-400" />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://twitter.com/Beglobalpromx?t=xM7S-RIKKw2d9IZ1KoD50Q&s=09"
                    className="flex items-center justify-center w-10 h-10 border-2 border-gray-400 rounded-full"
                  >
                    <TwitterIcon styles="h-5 w-5 fill-gray-400" />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/beglobalpro/"
                    className="flex items-center justify-center w-10 h-10 border-2 border-gray-400 rounded-full"
                  >
                    <InstagramIcon styles="h-6 w-6 fill-gray-400" />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://t.me/beglobalproinfo"
                    className="flex items-center justify-center w-10 h-10 border-2 border-gray-400 rounded-full"
                  >
                    <TelegramIcon styles="h-8 w-8 text-gray-400" />
                  </a>
                </div>

                <div className="flex gap-4 justify-center">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="!#"
                    className="flex items-center justify-center w-10 h-10 border-2 border-gray-400 rounded-full"
                  >
                    <TumblrIcon styles="h-5 w-5 fill-gray-400" />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://api.whatsapp.com/send?phone=+526622873165&text=%C2%A1Quiero%20saber%20m%C3%A1s%20informaci%C3%B3n%20de%20Be%20Global%20Pro!"
                    className="flex items-center justify-center w-10 h-10 border-2 border-gray-400 rounded-full"
                  >
                    <WhatsappIcon styles="h-6 w-6 fill-gray-400" />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.tiktok.com/@academiaecommerce?is_from_webapp=1&sender_device=pc"
                    className="flex items-center justify-center w-10 h-10 border-2 border-gray-400 rounded-full"
                  >
                    <TiktokIcon styles="h-5 w-5 fill-gray-400" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-8 flex flex-col items-center">
        <img src={logo} alt="logo be global pro" className="mb-4 w-44" />

        <p className="text-gray-600 text-sm lg:text-xl text-center px-4 md:px-0">
          Todos los derechos reservados -{" "}
          <Moment format="YYYY">{new Date()}</Moment> |{" "}
          <a href="/politicas">Politicas de privacidad</a>
        </p>
      </div>
    </footer>
  );
}
