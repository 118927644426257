import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ReviewerSidebarLayout = () => {
  const UrlPath = window.location.pathname;

  const validateAdmissionsUrl = (isActive) => {
    if (isActive || UrlPath.includes("admisiones")) return true;
  };
  return (
    <NavLink end to="/admin/admisiones">
      {({ isActive }) => (
        <div
          className={`flex items-center font-bold py-3 px-10 border-l-4 ${
            validateAdmissionsUrl(isActive)
              ? "text-primary-200 border-primary-200"
              : "border-gray-200"
          }`}
        >
          <div className="flex w-full">
            <div className="w-1/6">
              <FontAwesomeIcon icon={["far", "memo-pad"]} size="sm" />
            </div>
            <div className="w-full">
              <p className="leading-none">Solicitudes de admisión</p>
            </div>
          </div>
        </div>
      )}
    </NavLink>
  );
  <NavLink end to="/admin/admisiones">
    {({ isActive }) => (
      <div
        className={`flex items-center font-bold py-3 px-5 border-l-4 ${
          validateAdmissionsUrl(isActive)
            ? "text-primary-200 border-primary-200"
            : "border-gray-200"
        }`}
      >
        <div className="flex w-full">
          <div className="w-1/6">
            <FontAwesomeIcon icon={["far", "memo-pad"]} size="sm" />
          </div>
          <div className="w-full">
            <p className="leading-none">Solicitudes de admisión</p>
          </div>
        </div>
      </div>
    )}
  </NavLink>;
};

export default ReviewerSidebarLayout;
