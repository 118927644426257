import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import * as _ from "lodash";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Context from "../../context/UserContext";
import { ordersService } from "../../services/api/orders";
import Detail from "../../components/orders/Details";
import ServicesDetail from "../../components/orders/ServicesDetails";
import { notifications } from "../../utils/notifications";
import FilesDragAndDropPayments from "../../components/FilesDragAndDropPayments";
import TableLoading from "../../components/TableLoading";
import { useNavigate } from "react-router-dom";

export default function OrderDetail() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingEvidence, setLoadingEvidence] = useState(false);
  const { currentUser } = useContext(Context);
  const params = useParams();

  const [orders, setOrders] = useState();
  const [ordersShopify, setOrdersShopify] = useState();
  const [fillment, setFillment] = useState();
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [courses, setCourses] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [memberships, setMemberships] = useState([]);
  const [orderTotal, setOrderTotal] = useState(0);
  const [orderTotalCoupon, setOrderTotalCoupon] = useState(0);
  const [orderShipping, setOrderShipping] = useState(0);
  const [emailSent, setEmailSent] = useState(false);
  const [emailMessage, setEmailMessage] = useState({});
  const [loadingMail, setLoadingMail] = useState(false);

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "file/pdf",
  ];

  function setBar(value) {
    switch (value) {
      case "AwaitingPayment":
        return 0;
      case "Payment":
      case "ConfirmPayment":
        return 0;
      case "Send":
        return 1;
      case "Finish":
        return 3;

      default:
        return 0;
    }
  }
  const onFileChange = (files, setFieldValue) => {
    if (!_.isEmpty(files)) {
      setFieldValue("file", files[0]);
    }
  };

  const getOrderTotal = () => {
    let total = 0;
    let totalCoupons = 0;

    courses.forEach((course) => {
      total += course.cost;
    });

    services.forEach((service) => {
      total += service.cost;
    });

    memberships.forEach((membership) => {
      total += membership.cost;
    });

    products.forEach((product) => {
      total += product.quantity * product.price;
    });
    coupons.forEach((cupon) => {
      totalCoupons += cupon.discount_amount;
    });

    setOrderTotal(total);
    setOrderTotalCoupon(totalCoupons);
  };

  const handleStatus = async () => {
    let bodyFormData = new FormData();
    bodyFormData.append("_method", "PUT");
    bodyFormData.append("status", "Finish");

    await ordersService
      .updateOrder(params.id, bodyFormData)
      .then((response) => {
        fetchOrders();
        notifications.success(response.data.message);
      })
      .catch((err) => {});
  };

  const uploadEvidence = async (values) => {
    setLoadingEvidence(true);
    let bodyFormData = new FormData();
    bodyFormData.append("_method", "PUT");
    bodyFormData.append("payment", values.file);
    bodyFormData.append("status", "Payment");

    await ordersService
      .updateOrder(params.id, bodyFormData)
      .then((response) => {
        fetchOrders();

        notifications.success(response.data[0]);
      })
      .catch((err) => {})
      .finally(() => setLoadingEvidence(false));
  };

  const fetchOrders = () => {
    ordersService
      .getOrder(params.id)
      .then((response) => {
        setServices(response.data.order.services);
        setCourses(response.data.order.courses);
        setMemberships(response.data.order.memberships);
        setOrders(response.data.order);
        setOrderShipping(response.data.order.shipping_cost);
        setCoupons(response.data.order.coupons);

        if (response.data.order.orderShopify) {
          setProducts(response.data.order.orderShopify.order.line_items);
          setFillment(response.data.order.orderShopify.order.fulfillments);
          setOrdersShopify(response.data.order.orderShopify.order);
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate(`/mi-escritorio/orden/no-encontrada`);
        }
      })
      .finally(() => setLoading(false));
  };

  const sendMail = () => {
    setLoadingMail(true);
    ordersService
      .sendOrderCostumerMail(params.id)
      .then((response) => {
        notifications.success(response.data.message);
        setEmailMessage({ message: response.data.message, status: "success" });
      })
      .catch((err) => {
        setEmailMessage({
          message: err.response.data.message,
          status: "error",
        });
      })
      .finally(() => {
        setLoadingMail(false);
        setEmailSent(true);
      });
  };

  useEffect(() => {
    if (!currentUser) return;
    fetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    if (!orders) return;
    getOrderTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const formSchema = Yup.object().shape({
    file: Yup.mixed()
      .required("La imagen es requerida")
      .test(
        "fileFormat",
        "Formato no soportado",
        (value) =>
          !value || ((value) => value && SUPPORTED_FORMATS.includes(value.type))
      ),
  });

  const formatTotals = (total) => {
    if (total > 0) {
      return total.toLocaleString("en-US", {
        minimumFractionDigits: 2,
      });
    }

    return 0;
  };

  const replaceWithBr = (text) => {
    return text ? text.replace(/\n/g, "<br />") : "";
  };

  return (
    <div className="container mx-auto text-gray-700">
      <main className="mx-auto max-w-7xl px-4 py-16 sm:px-6 sm:py-2 lg:px-8 ">
        <h1 className="text-center md:text-left text-xl lg:text-3xl font-bold tracking-tight text-gray-900">
          Detalles de la orden
        </h1>

        {!emailSent && products && ordersShopify ? (
          <p>
            ¿No recibiste el correo de Pago?, {"  "}
            {!loadingMail ? (
              <b
                onClick={() => sendMail()}
                className="text-primary-200 font-bold cursor-pointer"
              >
                Haz Click aquí para reenviarlo.
              </b>
            ) : (
              <b className="font-bold">Enviando correo...</b>
            )}
          </p>
        ) : (
          <p
            className={`${
              emailMessage.status === "success"
                ? "text-green-50"
                : "text-red-500"
            } font-bold`}
          >
            {emailMessage.message}
          </p>
        )}

        <div className=" mt-2 pb-2 flex justify-between text-sm lg:text-lg ">
          <dl className="flex mx-auto lg:mx-0">
            <dt className="text-gray-500"># Orden&nbsp;</dt>
            <dd className="font-medium text-gray-900">{params.id}</dd>
            <dt>
              <span className="sr-only">Date</span>
              <span className="mx-2 text-gray-400" aria-hidden="true">
                &middot;
              </span>
            </dt>
            <dd className="font-medium text-gray-900">
              <time dateTime="2021-03-22">
                {" "}
                {orders
                  ? moment(orders.created_at).format("D").toString() +
                    " de " +
                    moment(orders.created_at).format("MMMM").toString() +
                    " de " +
                    moment(orders.created_at).format("Y").toString()
                  : ""}
              </time>
            </dd>
            {orders && orders.status === "DeniedPayment" && (
              <dd className="pl-2 font-medium text-gray-500">
                {" "}
                &middot;
                <span className="pl-2 text-tertiary font-bold">
                  Pago rechazado
                </span>
              </dd>
            )}
          </dl>
        </div>
        {orders && !orders.has_membership && (
          <div className="pb-3 text-yellow-1200 ">
            <FontAwesomeIcon
              icon={["fa", "tags"]}
              size={"lg"}
              className="mr-2"
            />
            <span className="font-bold text-lg">
              Los productos de esta orden fueron adquiridos sin membresía
            </span>
          </div>
        )}

        {loading ? (
          <div className="w-full flex justify-center mt-4">
            <TableLoading size="5x" />
          </div>
        ) : (
          <>
            <section
              aria-labelledby="products-heading"
              className="mt-2 flex flex-row flex-wrap"
            >
              <div className="space-y-2 w-full lg:w-1/2 overflow-y-auto h-auto lg:rounded-l-lg">
                {products &&
                  products.map((product, index) => (
                    <Detail key={index} product={product} admin />
                  ))}

                {services &&
                  services.map((service, index) => (
                    <ServicesDetail
                      key={index}
                      service={service}
                      sub={"Servicio"}
                    />
                  ))}

                {memberships &&
                  memberships.map((service, index) => (
                    <ServicesDetail
                      key={index}
                      service={service}
                      sub={"Membresia"}
                    />
                  ))}

                {courses &&
                  courses.map((service, index) => (
                    <ServicesDetail
                      key={index}
                      service={service}
                      sub={"Curso"}
                    />
                  ))}
              </div>

              <div className="w-full lg:w-1/2 lg:rounded-r-lg bg-gray-50 py-3 px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-0 lg:py-8">
                {products.length > 0 && (
                  <dl className="grid grid-cols-1 gap-6 text-lg md:gap-x-8 lg:col-span-6 lg:pl-8">
                    <div className="lg:pl-10">
                      <dt className="text-center lg:text-left font-bold text-gray-900">
                        Dirección de envío
                      </dt>
                      {ordersShopify && ordersShopify.shipping_address ? (
                        <dd className="lg:text-left text-center mt-3 text-gray-700 mb-10 lg:mb-1">
                          <span className="block">
                            {ordersShopify.shipping_address.address1}
                          </span>
                          <span className="block">
                            {ordersShopify.shipping_address.zip}{" "}
                          </span>
                          <span className="block">
                            {ordersShopify.shipping_address.city},{" "}
                            {ordersShopify.shipping_address.province}.{" "}
                            {ordersShopify.shipping_address.country}{" "}
                          </span>
                        </dd>
                      ) : (
                        "No disponible"
                      )}
                    </div>
                    {orders.shipping_name && (
                      <div className="lg:pl-10">
                        <dt className="text-center lg:text-left font-bold text-gray-900">
                          Tipo de envío
                        </dt>
                        <dd className="lg:text-left text-center text-gray-700 mb-10 lg:mb-1">
                          <span className="block">
                            {orders.shipping_name} - {orders.days}{" "}
                            {orders.days === 1 ? " dia" : " dias"}
                          </span>
                        </dd>
                      </div>
                    )}
                  </dl>
                )}

                <dl className="grid grid-cols-1 gap-2 lg:gap-6 text-lg md:gap-x-8 lg:col-span-6 lg:pl-8">
                  <div>
                    <dt className="text-center lg:text-left font-bold text-gray-900 mb-2">
                      Información de pago
                    </dt>
                    <div className="flex items-center justify-between  lg:pr-10">
                      <dt className="text-gray-700">Subtotal</dt>
                      <dd
                        className={`font-medium ${
                          orderTotalCoupon > 0
                            ? "line-through text-red-50"
                            : "text-gray-900"
                        } `}
                      >
                        ${formatTotals(orderTotal)}
                      </dd>
                    </div>
                    {coupons.length > 0 && (
                      <div className="flex items-center justify-between  lg:pr-10">
                        <dt className="text-gray-700">Cupones</dt>
                        <dd className="font-medium text-green-50">
                          - ${formatTotals(orderTotalCoupon)}
                        </dd>
                      </div>
                    )}
                    {products && ordersShopify && (
                      <div className="flex items-center justify-between  lg:pr-10">
                        <dt className="text-gray-700">Envío</dt>
                        <dd className="font-medium text-gray-900">
                          ${formatTotals(orderShipping)}
                        </dd>
                      </div>
                    )}

                    <div className="flex items-center justify-between lg:pr-10">
                      <dt className="text-gray-900">Total</dt>
                      <dd className="font-bold text-primary-200">
                        $
                        {formatTotals(
                          orderTotal + orderShipping - orderTotalCoupon
                        )}
                      </dd>
                    </div>
                  </div>
                  <div>
                    {ordersShopify && (
                      <dt className="text-center lg:text-left font-bold text-gray-900">
                        Persona que recibe el pedido:
                      </dt>
                    )}

                    <div className="flex items-center justify-center lg:justify-between ">
                      {ordersShopify && ordersShopify.shipping_address ? (
                        <dd className="lg:text-left text-center text-gray-700 mb-10 lg:mb-1">
                          <span className="block">
                            {ordersShopify.shipping_address.name}
                          </span>
                        </dd>
                      ) : (
                        products.length > 0 && "No disponible"
                      )}
                    </div>
                  </div>
                  {ordersShopify &&
                    ordersShopify.shipping_address &&
                    ordersShopify.shipping_address.phone && (
                      <div>
                        {ordersShopify && (
                          <dt className="text-center lg:text-left font-bold text-gray-900">
                            Teléfono
                          </dt>
                        )}

                        <div className="flex items-center justify-center lg:justify-between ">
                          {ordersShopify ? (
                            <dd className="lg:text-left text-center text-gray-700 mb-10 lg:mb-1">
                              <span className="block">
                                {ordersShopify.shipping_address.phone}
                              </span>
                            </dd>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    )}
                </dl>

                {products.length > 0 && (
                  <dl className="my-10 lg:my-1 text-center lg:text-left grid grid-cols-1 lg:grid-cols-2 gap-6 text-lg md:gap-x-8 lg:col-span-12 lg:pl-8">
                    <div className="lg:pl-10">
                      <dt className="font-bold text-gray-900">
                        Información de rastreo
                      </dt>
                      {orders && orders.status === "Send" ? (
                        <dd className="mt-3 text-gray-700">
                          <span className="block text-gray-700 ">
                            <b>Compañía: </b> {orders.shippingCarrier}
                          </span>
                          <span className="block">
                            <b>Numero de rastreo:</b> {orders.trackingNumber}{" "}
                          </span>
                        </dd>
                      ) : (
                        <div className="">Preparando envío</div>
                      )}
                    </div>
                    {orders.status === "Send" && (
                      <div className="mx-auto ">
                        <dt className="text-center font-bold text-gray-900">
                          Cambiar estatus
                        </dt>
                        {fillment ? (
                          <button
                            onClick={() => handleStatus()}
                            className=" bg-green-400 text-white font-bold p-2 mx-auto"
                          >
                            Marcar como recibido
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    {orders.status === "AwaitingPayment" &&
                      (loading ? (
                        <div className="xl:pl-10 font-bold text-primary-200">
                          <p className="">Subiendo evidencia... </p>
                          <div className=" lg:mr-16  mx-auto text-center">
                            <TableLoading />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="lg:pl-10 col-span-2">
                            <p className="text-center lg:text-left font-bold text-gray-900">
                              Información de pago
                            </p>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: replaceWithBr(
                                  orders.payment_information
                                ),
                              }}
                            />
                          </div>
                          {!ordersShopify.cancel_reason && (
                            <Formik
                              enableReinitialize={true}
                              initialValues={{}}
                              validationSchema={formSchema}
                              onSubmit={(values) => uploadEvidence(values)}
                            >
                              {({ setFieldValue }) => (
                                <Form className="col-span-2">
                                  <div className="mb-9 text-xl text-gray-550">
                                    <p className="mb-5 text-center">
                                      Subir evidencia de pago
                                    </p>
                                    <div>
                                      <FilesDragAndDropPayments
                                        onFileChange={(files) =>
                                          onFileChange(files, setFieldValue)
                                        }
                                        multiple={false}
                                      />
                                    </div>
                                    <ErrorMessage
                                      name="file"
                                      component="div"
                                      className="text-red-400 font-bold text-xl"
                                    />
                                  </div>

                                  <div className="w-full flex justify-center my-5">
                                    <button
                                      type="submit"
                                      className="w-full md:w-56 bg-primary-200 disabled:opacity-75 text-white text-xl lg:text-2xl py-3 px-5 disabled:opacity-75 mt-5"
                                      disabled={loadingEvidence}
                                    >
                                      {loadingEvidence ? (
                                        <FontAwesomeIcon
                                          icon="circle-notch"
                                          spin
                                        />
                                      ) : (
                                        <span>Subir</span>
                                      )}
                                    </button>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          )}
                        </>
                      ))}
                  </dl>
                )}
              </div>
            </section>

            {ordersShopify &&
              (ordersShopify.cancel_reason ? (
                <div className="text-red-600 text-center text-2xl lg:mt-10 font-bold">
                  Esta orden ha sido cancelada
                </div>
              ) : (
                <div className="border-t border-gray-200 py-6 px-4 sm:px-6 lg:p-8 bg-red">
                  <div className="mt-2" aria-hidden="true">
                    <div className="overflow-hidden rounded-full bg-gray-200">
                      <div
                        className={`h-2 rounded-full ${
                          setBar(orders.status) === 4
                            ? "bg-green-400"
                            : "bg-primary-200 "
                        } `}
                        style={{
                          width: `calc((${
                            fillment ? setBar(orders.status) : 1
                          } * 2 + 2) / 8 * 100%)`,
                        }}
                      />
                    </div>
                    <div className="mt-6 hidden grid-cols-3 text-base font-medium text-gray-600 sm:grid">
                      {/* Aqui queda pendiente esto de los steps, NO moverle aun */}

                      <div
                        className={classNames(
                          fillment
                            ? setBar(orders.status) === 1
                              ? "text-primary-200"
                              : ""
                            : "",
                          "text-center"
                        )}
                      >
                        En proceso
                      </div>
                      <div
                        className={classNames(
                          fillment
                            ? setBar(orders.status) === 1
                              ? "text-primary-200"
                              : ""
                            : "",
                          "text-center"
                        )}
                      >
                        Enviado
                      </div>
                      <div
                        className={classNames(
                          fillment
                            ? setBar(orders.status) === 3
                              ? "text-green-400"
                              : ""
                            : "",
                          "text-center"
                        )}
                      >
                        Completado
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {/* //Aqui va la version de servicios. */}

            {/* Billing */}
            <section
              aria-labelledby="summary-heading"
              className="mt-1 w-full md:w-1/2 md:ml-auto"
            >
              <h2 id="summary-heading" className="sr-only">
                Billing Summary
              </h2>
            </section>
          </>
        )}
      </main>
    </div>
  );
}
