export const memberships = {
  BASICA_EDUCATIVA: 1,
  BASICA_DROPSHIPPING: 2,
  PLUS_MENSUAL: 3,
  PLUS_TRIMESTRAL: 4,
  PLUS_ANUAL: 5,
  PREMIUM: 6,
  PLUS_SEMESTRAL: 7,
  ANUAL_DROPSHIPPING: 8,
  EMPRENDEDOR: 9,
};

export const EMPRENDEDOR_COURSES_WHITE_LIST = [
  {
    name: "Facebook Ads principiantes",
    uuid: "2462cc8c-18e6-4b08-944d-1097d1973087",
  },
  {
    name: "Facebook ADS Teorico",
    uuid: "cb5c586c-b7ea-482e-b7e3-d7a662f896e7",
  },
  {
    name: "Facebook Ads Avanzado",
    uuid: "69f81ebf-eead-49a4-8ebf-bb5aafeb379b",
  },
  {
    name: "Dropshipping Manager",
    uuid: "128f0057-9f5f-4a23-87ba-d92bedc46936",
  },
  {
    name: "Chat Selling",
    uuid: "38f4db64-e808-4811-96fa-9ce418a2cb74",
  },
  {
    name: "Aprende a crear tu tienda en línea con Shopify desde cero",
    uuid: "2e4cf728-95b9-460c-b439-4c68cfa06b45",
  },
  {
    name: "Cursos de Actualización en Vivo: Maximiza tu Conocimiento en Todos los Cursos",
    uuid: "d7d44101-5358-4ef0-ae55-920b6c7c840a",
  },
  {
    name: "Embudo o Funnels de Ventas",
    uuid: "1a60e3b3-5717-4d9f-956b-06dcc62d205b",
  },
  {
    name: "Crea tu propio contenido",
    uuid: "73291c95-5912-4aa6-b1ae-4899032ded2e",
  },
];

export const EMPRENDEDOR_VENDORS_WHITE_LIST = [
  "Bodega Global",
  "Bodega Puebla",
  "Bodega PlayMaket",
  "Bodega Ciudad de México",
  "Bodega Guadalajara",
];

export const COURSES_STATUS = {
  BUY: 1,
  TO_ACQUIRE: 2,
  START: 3,
  EXCLUSIVE_MEMBERSHIP: 4,
  EXCLUSIVE_PREMIUM: 5,
};
