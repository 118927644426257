import { useState, useEffect, useContext } from "react";
import * as _ from "lodash";
import TableLoading from "../../components/TableLoading";
import Context from "../../context/UserContext";
import { coursesServices } from "../../services/api/courses";
import { notifications } from "../../utils/notifications";
import CourseCard from "../../components/courses/CourseCard";

import { useNavigate } from "react-router-dom";
import CarrouselCourses from "../../components/courses/CarrouselCourses";

const WishList = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(Context);
  const [wishList, setWishList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fetchUserWishList = () => {
    setIsLoading(true);
    coursesServices
      .getUserWishList()
      .then((response) => {
        setWishList(response.data.courses);
      })
      .catch((err) => {})
      .finally(() => setIsLoading(false));

  }
  const handleWishList = (uuid) => {
    updateWishList(uuid);
  };

  const updateWishList = async (uuid) => {
    await coursesServices
      .updateCourseWishList({ uuid })
      .then((response) => {
        notifications.success(response.data.message);
        setWishList(wishList.filter((course) => course.uuid !== uuid));
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!currentUser) return;
    fetchUserWishList();
  }, [currentUser]);

  const handleAddCourse = async (id) => {
    if (currentUser) {
      await coursesServices.addCourseMembership(id)
        .then((response) => {
          notifications.success(response.data.message);
          fetchUserWishList();
        })
        .catch((err) => {
          /* eslint-disable jsx-a11y/anchor-is-valid */
          notifications.error(<div> {err.response.data.message}  <a href="/membresias/dropshipping" className="text-primary-200">Adquiere tu membresía. </a></div>);
        });

    } else {
      notifications.warn(<div> <a onClick={() => navigate({ pathname: '', search: '?login=true', })} className="text-primary-200">Inicie sesión  </a>para adquirir un curso</div>);
    }

  };
  return (
    <>
      {isLoading ? (
        <div className="w-full flex justify-center">
          <TableLoading size="5x" />
        </div>
      ) : !_.isEmpty(wishList) ? (<>
        <div className="lg:hidden"><CarrouselCourses courses={wishList} /></div>
        <div className="hidden lg:block">
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8">
            {wishList.map((course) => (
              <CourseCard key={course.uuid} course={course} handleAddCourse={(id) => handleAddCourse(id)} handleWishList={(uuid)=>handleWishList(uuid)} />
            ))}
          </div>
        </div>
      </>

      ) : (
        <div className="bg-gray-300 border-l-8 border-l-primary-200 p-4 my-8">
          <p className="text-center lg:text-left text-lg lg:text-xl text-gray-700 font-bold">
            Aún no tienes ningun curso en lista de deseados, ¡Vé a cursos y
            agrega uno!
          </p>
        </div>
      )}
    </>
  );
};

export default WishList;
