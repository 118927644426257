import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";

import useUser from "../../hooks/useUser";

const RegisterForm = ({ setActive, setEmail }) => {
  const formSchema = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
    email: Yup.string()
      .required("Campo requerido")
      .email("Correo electronico invalido")
      .max(255, `Máximo 255 caracteres`),
    phone: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    password: Yup.string()
      .required("Campo requerido")
      .min(8, `Mínimo 8 caracteres`),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Las contraseñas deben coincidir"
    ),
  });

  const { isLoading, hasError, errors, onSucess, register } = useUser();

  useEffect(() => {
    if (onSucess) {
      setActive("verify");
    }
  }, [onSucess, setActive]);

  const handleSubmit = async (values) => {
    setEmail(values.email);
    await register(values);
  };

  return (
    <>
      <Formik
        initialValues={{
          name: "",
          email: "",
          password: "",
          password_confirmation: "",
        }}
        validationSchema={formSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        <Form>
          <p className="text-gray-700 font-bold text-2xl pt-5 pb-2">Registro</p>

          <div className="mb-2">
            <p className="text-gray-700 font-bold text-xl">Nombre de usuario</p>
            <Field
              name="name"
              className="w-full bg-white text-gray-550 placeholder:font-italitc border border-slate-300 py-2 px-4 focus:outline-none"
              type="text"
            />
            <ErrorMessage
              name="name"
              component="div"
              className="text-red-400 font-bold font-xs"
            />
          </div>

          <div className="mb-2">
            <p className="text-gray-700 font-bold text-xl">
              Correo Electronico
            </p>
            <Field
              name="email"
              className="w-full bg-white text-gray-550 placeholder:font-italitc border border-slate-300 py-2 px-4 focus:outline-none"
              type="email"
            />
            <ErrorMessage
              name="email"
              component="div"
              className="text-red-400 font-bold font-xs"
            />
          </div>

          <div className="mb-2">
            <p className="text-gray-700 font-bold text-xl">Teléfono</p>
            <Field
              name="phone"
              className="w-full bg-white text-gray-550 placeholder:font-italitc border border-slate-300 py-2 px-4 focus:outline-none"
              type="text"
            />
            <ErrorMessage
              name="phone"
              component="div"
              className="text-red-400 font-bold font-xs"
            />
          </div>

          <div className="mb-2">
            <p className="text-gray-700 font-bold text-xl">Contraseña</p>
            <Field
              name="password"
              className="w-full bg-white text-gray-550 placeholder:font-italitc border border-slate-300 py-2 px-4 focus:outline-none"
              type="password"
            />
            <ErrorMessage
              name="password"
              component="div"
              className="text-red-400 font-bold font-xs"
            />
          </div>

          <div>
            <p className="text-gray-700 font-bold text-xl">Repite contraseña</p>
            <Field
              name="password_confirmation"
              className="w-full bg-white text-gray-550 placeholder:font-italitc border border-slate-300 py-2 px-4 focus:outline-none"
              type="password"
            />
            <ErrorMessage
              name="password_confirmation"
              component="div"
              className="text-red-400 font-bold font-xs"
            />
          </div>

          {hasError && errors.length > 0 && (
            <div className="bg-gray-300 border-l-4 border-l-tertiary p-2 mt-2">
              <ul className="text-gray-700 font-bold text-xs">
                {errors.map((err, i) => (
                  <li key={i}>{err}</li>
                ))}
              </ul>
            </div>
          )}

          <button
            type="submit"
            className="w-full bg-primary-200 text-white font-bold text-lg py-2 my-5 disabled:opacity-75"
            disabled={isLoading}
          >
            {isLoading ? (
              <FontAwesomeIcon icon="circle-notch" spin />
            ) : (
              <span>Registrarse</span>
            )}
          </button>
        </Form>
      </Formik>
    </>
  );
};

export default RegisterForm;
