/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Context from "../../../context/AdminUserContext";
import { notifications } from "../../../utils/notifications";
import { adminIncomesServices } from "../../../services/api/admin/contability";

import CreatableSelect from 'react-select/creatable';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import {  format } from "date-fns";

registerLocale("es", es);

const NewIncome = () => {
    const formSchema = Yup.object().shape({
        name: Yup.string().required("Campo requerido")
            .max(255, `Máximo 255 caracteres`),
        affiliate: Yup.string().email("Debe ser un correo electronico").max(255, `Máximo 255 caracteres`),
        amount: Yup.number()
            .required("Campo requerido")
            .when("type", {
                is: "Percent",
                then: Yup.number().max(
                    100,
                    "Cuando es porcentaje, el valor del importe debe ser menor o igual a 100"
                ),
                otherwise: Yup.number(),
            }),
        date: Yup.string().nullable().required('Campo requerido'),
        concept: Yup.string().required("Campo requerido").max(255, `Máximo 100 caracteres`),

    });

    const [items, setItems] = useState([]);
    const [itemsSelected, setItemsSelected] = useState();

    const [expirationDate, setExpirationDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const customStyles = {
        control: (provided) => ({
            ...provided,
            padding: "7px",
        }),
    };

    const { currentAdminUser } = useContext(Context);
    const navigate = useNavigate();

    const initialValues = {
        amount: 0,
        concept: "",
        name: "",
        affiliate: "",
        date: "",

    };

    const deleteDuplicates = (items) => {
        const duplicates = {};
        items.forEach((element) => {
            const existing = duplicates[element.name];
            if (existing) {
                existing.value += element.value;
                existing.id = existing.id || '';
                if (element.id && !existing.id.includes(element.id)) {
                    existing.id += ' ' + element.id;
                }
                existing.cost = (+existing.cost || 0) + (+element.cost || 0);
            } else {
                duplicates[element.name] = element;
            }
        });
        const mergedArray = Object.values(duplicates);
        return mergedArray;

    }
    const cleanConceptsList = (items) => {

        Object.keys(items).forEach(key => {
            if (items[key].concept) {
                items[key].name = items[key].concept;
                delete items[key].concept;
            }
            if (items[key].renew_cost && items[key].concept) {
                items.push({
                    name: "Renovacion " + items[key].name,
                    cost: items[key].renew_cost,
                });
                items[key].name = items[key].concept;
                delete items[key].concept;
            }

        });
        return deleteDuplicates(items);


    }

    const fetchIncomeItems = () => {
        setIsLoading(true);
        adminIncomesServices
            .getIncomeItems()
            .then((response) => {
                setItems(response.data.items);
            })
            .catch((err) => { })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const formatItems = (_items, setFieldValue) => {
        if (_items) {
            if (_items.id) {
                setFieldValue("concept", _items.id);
            } else {
                setFieldValue("concept", _items.name);
            }
            _items.cost && setFieldValue("amount", _items.cost);
            setItemsSelected(_items);
        } else {
            setFieldValue("concept", "");
            setFieldValue("amount", 0);
            setItemsSelected("");
        }

    };


    useEffect(() => {
        if (!currentAdminUser) return;
        fetchIncomeItems();
    }, [currentAdminUser]);

    const returnIdOrConcept = (concept) => {
        let value = concept;
        Object.keys(items).forEach(key => {
            if (items[key].name && typeof concept === 'string') {
                if (items[key].name.toLowerCase() === concept.toLowerCase() && items[key].id) {
                    value = items[key].id;
                }
            }

        });
        return value;
    }

    const handleSubmit = async (values) => {
        setIsLoading(true);
        values.concept = returnIdOrConcept(values.concept).toString();
        await adminIncomesServices
            .createIncome(values)
            .then((response) => {
                notifications.success(response.data.message);
                navigate("/admin/contabilidad");
            })
            .catch((err) => { })
            .finally(() => setIsLoading(false));


    };
    const createOption = (inputValue, setFieldValue, type) => {
        let newOption;
        switch (type) {
            case 'onCreateOption':
                newOption = {
                    name: inputValue,
                    value: inputValue
                };
                setFieldValue('concept', inputValue);
                setFieldValue("amount", 0);
                setItemsSelected(newOption);
                break;
            case 'onBlur':

                if (inputValue.target.value) {
                    newOption = {
                        name: inputValue.target.value,
                        value: inputValue.target.value
                    };
                    setFieldValue('concept', inputValue.target.value);
                    setFieldValue("amount", 0);
                    setItemsSelected(newOption);
                }

                break;
            default:
                formatItems(inputValue, setFieldValue)
                setFieldValue("amount", inputValue.cost);
                setItemsSelected(inputValue);
                break;

        }
        if (type) return newOption;
    };





    return (
        <div className="container mx-auto text-gray-700">
            <div className="flex flex-col md:flex-row justify-between items-center">
                <p className="text-4xl font-bold">Nuevo ingreso</p>
            </div>

            <div className="mt-12">
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={formSchema}
                    onSubmit={(values) => handleSubmit(values)}
                >
                    {({ setFieldValue }) => (
                        <Form>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                                <div className="mb-9 text-3xl text-gray-550">
                                    <p>Concepto</p>
                                    <CreatableSelect
                                        className="w-full bg-white text-gray-700 placeholder:font-italitc   focus:outline-none"
                                        styles={customStyles}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: 'hotpink',
                                                primary: 'white',
                                            },
                                        })}
                                        isClearable
                                        options={cleanConceptsList(items)}
                                        isLoading={isLoading}
                                        value={itemsSelected}
                                        getOptionLabel={(option) => option.name || option.label}
                                        getOptionValue={(option) => option.id || option.name}
                                        onChange={(items) => formatItems(items, setFieldValue)}
                                        placeholder="Seleccione un concepto"
                                        onCreateOption={(items) => createOption(items, setFieldValue, 'onCreateOption')}
                                        onBlur={(items) => createOption(items, setFieldValue, 'onBlur')}
                                        onBlurResetsInput={false}
                                        formatCreateLabel={(inputValue) => `Nuevo concepto  "${inputValue}"`}
                                    />
                                    <ErrorMessage
                                        name="concept"
                                        component="div"
                                        className="text-red-400 font-bold text-xl"
                                    />
                                </div>
                                <div className="mb-9 text-3xl text-gray-550">
                                    <p>Orden (Opcional)</p>
                                    <Field
                                        name="order"
                                        type="text"
                                        className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                    />
                                    <ErrorMessage
                                        name="order"
                                        component="div"
                                        className="text-red-400 font-bold text-xl"
                                    />
                                </div>

                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                                <div className="mb-9 text-3xl text-gray-550">
                                    <p>Nombre</p>
                                    <Field
                                        name="name"
                                        className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                    />
                                    <ErrorMessage
                                        name="name"
                                        component="div"
                                        className="text-red-400 font-bold text-xl"
                                    />
                                </div>
                                <div className="mb-9 text-3xl text-gray-550">
                                    <p>Afiliado por</p>
                                    <Field
                                        name="affiliate"
                                        className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                    />
                                    <ErrorMessage
                                        name="affiliate"
                                        component="div"
                                        className="text-red-400 font-bold text-xl"
                                    />
                                </div>


                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                                <div className="mb-9 text-3xl text-gray-550">
                                    <p>Monto</p>
                                    <Field
                                        name="amount"
                                        type="number"
                                        min="1"
                                        step="0.01"
                                        className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                    />
                                    <ErrorMessage
                                        name="amount"
                                        component="div"
                                        className="text-red-400 font-bold text-xl"
                                    />
                                </div>
                                <div className="mb-9 text-3xl text-gray-550">
                                    <p>Fecha</p>
                                    <DatePicker
                                        selected={expirationDate}
                                        onChange={(date) => {
                                            setFieldValue(
                                                "date",
                                                date ? format(date, "yyyy-MM-dd") : null
                                            );
                                            setExpirationDate(date);
                                        }}
                                        maxDate={new Date()}

                                        dateFormat="yyyy-MM-dd"
                                        isClearable={true}
                                        className="text-gray-700 placeholder:text-gray-700 border border-slate-300 focus:outline-none py-3 px-4 w-full"
                                        locale="es"

                                    />
                                    <ErrorMessage
                                        name="date"
                                        component="div"
                                        className="text-red-400 font-bold text-xl"
                                    />
                                </div>

                            </div>

                            <div className="w-full flex justify-end">
                                <button
                                    type="submit"
                                    className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75"
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <FontAwesomeIcon icon="circle-notch" spin />
                                    ) : (
                                        <span>Guardar</span>
                                    )}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default NewIncome;