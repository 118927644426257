import { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Context from "../context/AdminUserContext";

const OnlyAdminRevisorRoutes = () => {
  const { currentAdminUser, isVerifying } = useContext(Context);
  const content = () => {
    return currentAdminUser ? <Outlet /> : <Navigate to="/admin/login" />;
  };

  const verify = () => {
    return isVerifying ? (
      <div className="h-screen w-screen flex justify-center items-center">
        <FontAwesomeIcon
          icon={["fa", "circle-notch"]}
          size="6x"
          className="text-primary-200"
          spin
        />
      </div>
    ) : (
      content()
    );
  };
  return currentAdminUser.type === "Admin" ||
    currentAdminUser.type === "SuperAdmin" ||
    currentAdminUser.type === "Ventas" ||
    currentAdminUser.type === "Revisor" ? (
    verify()
  ) : (
    <Navigate to="/admin/login" />
  );
};

export default OnlyAdminRevisorRoutes;
