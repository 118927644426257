/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import { useNavigate, useParams } from "react-router-dom";
import * as _ from "lodash";
import { Skeleton } from "react-skeleton-generator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { shopifyService } from "../../services/api/shopify";

import ProductCard from "../../components/store/ProductCard";

const CategoryView = () => {
  const [loading, setLoading] = useState(true); // Estado de carga

  const navigate = useNavigate();
  const params = useParams();
  const [change, setChange] = useState(1);

  const [collection, setCollection] = useState({});
  const [categories, setCategories] = useState();

  const [products, setProducts] = useState([]);
  const [searchProducts, setSearchProducts] = useState();
  const [originalProducts, setOriginalProducts] = useState([]);
  const [comboValue, setComboValue] = useState("");

  const applyScrollPosition = () => {
    const scrollPosition = parseInt(
      localStorage.getItem("scrollPosition") || "0",
      10
    );
    window.scrollTo(0, scrollPosition);
  };

  const fetchCollectionById = (id) => {
    setCollection();
    setProducts();
    shopifyService
      .getCollection(id)
      .then((response) => {
        let data = response.data;
        setCollection(data.collection);
        setProducts(data.products);

        setOriginalProducts(data.products);
        setSearchProducts(data.products);
      })
      .catch((err) => {});
  };

  const fetchCollection = () => {
    shopifyService
      .getCollection(params.id)
      .then((response) => {
        let data = response.data;
        setCollection(data.collection);
        setProducts(data.products);
        setOriginalProducts(data.products);
        setSearchProducts(data.products);
      })
      .catch((err) => {});
  };
  const fetchCategories = () => {
    shopifyService
      .getAllCollections()
      .then((response) => {
        let data = response.data;
        setCategories(data.collects);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchCollection();
    fetchCategories();
  }, []);
  useEffect(() => {
    //setMostSell();
    sortData("old");
  }, [searchProducts]);

  useEffect(() => {
    if (categories && searchProducts) {
      setLoading(false);
    }
  }, [categories, searchProducts]);

  useEffect(() => {
    if (!loading) {
      applyScrollPosition();
    }
  }, [loading]);

  const navigateToDetails = (id, store_id) => {
    localStorage.setItem("scrollPosition", window.scrollY.toString());
    navigate(`/tienda/${store_id}/producto/${id}`);
  };

  const sortData = (value) => {
    setComboValue(value);
    if (searchProducts) {
      setChange(value);
      let sortedProducts = [...searchProducts];
      switch (value) {
        case "cheapest":
          sortedProducts.sort(
            (a, b) =>
              parseFloat(a.variants.edges[0].node.price.amount) -
              parseFloat(b.variants.edges[0].node.price.amount)
          );
          break;
        case "expensive":
          sortedProducts.sort(
            (a, b) =>
              parseFloat(b.variants.edges[0].node.price.amount) -
              parseFloat(a.variants.edges[0].node.price.amount)
          );
          break;
        case "new":
          sortedProducts.sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
          );
          break;
        case "old":
          sortedProducts.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          break;
        case "alfa":
          sortedProducts.sort((a, b) =>
            a.title < b.title ? -1 : a.title > b.title ? 1 : 0
          );
          break;
        case "alfz":
          sortedProducts.sort((a, b) =>
            a.title > b.title ? -1 : a.title < b.title ? 1 : 0
          );
          break;
        case "mostsell":
          sortedProducts.sort((a, b) =>
            a.sold > b.sold ? -1 : a.sold < b.sold ? 1 : 0
          );
          break;
        default:
          sortedProducts = originalProducts.slice();
      }
      setProducts(sortedProducts);
      return sortedProducts;
    }
  };
  return (
    <>
      <div className="container mx-auto w-full px-4 py-5 lg:px-0">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="w-full flex flex-row justify-between pb-5 md:pb-0 md:w-1/4">
            <select
              onChange={(event) => {
                let result = event.target.value.split("-");
                let store = result[0];
                let category = result[1];
                navigate(`/tienda/${store}/categoria/${category}`);
                fetchCollectionById(category);
              }}
              value={params.store_id + "-" + params.id}
              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
            >
              {categories
                ? categories.map((value, idx) => (
                    <option value={value.store.id + "-" + value.id} key={idx}>
                      {value.title}
                    </option>
                  ))
                : ""}
            </select>
          </div>
          <label className="relative block w-full md:w-1/2">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <FontAwesomeIcon
                icon={["fa", "magnifying-glass"]}
                className="text-[#A9A9A9]"
                size="sm"
              />
            </span>
            <input
              className="w-full bg-white text-gray-700 placeholder:font-italic border border-slate-300 py-3 pl-12 pr-4 focus:outline-none"
              type="text"
              placeholder="Buscar"
              onKeyDown={(event) => {
                let searchText = event.target.value.trim().toLowerCase();
                if (searchText === "") {
                  setProducts(searchProducts);
                } else {
                  let searchWords = searchText.split(" ");
                  let filteredProducts = searchProducts.filter((product) => {
                    return searchWords.every((word) => {
                      return product.title.toLowerCase().includes(word);
                    });
                  });
                  let sortedProducts = filteredProducts.sort((a, b) => {
                    return searchWords.reduce((acc, word) => {
                      let aIndex = a.title.toLowerCase().indexOf(word);
                      let bIndex = b.title.toLowerCase().indexOf(word);
                      if (aIndex !== -1 && bIndex !== -1) {
                        return acc + (aIndex - bIndex);
                      } else if (aIndex !== -1) {
                        return acc - 1;
                      } else if (bIndex !== -1) {
                        return acc + 1;
                      } else {
                        return acc;
                      }
                    }, 0);
                  });
                  setProducts(sortedProducts);
                }
              }}
            />
          </label>
        </div>
      </div>

      {!_.isEmpty(products) ? (
        ""
      ) : (
        <div className="container mx-auto w-full"> </div>
      )}
      {!_.isEmpty(collection) ? (
        <div>
          {/* HERO */}
          <div className="container mx-auto w-full">
            <div>
              <Swiper
                autoplay
                slidesPerView={1}
                spaceBetween={0}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper1 h-96"
              >
                {!_.isEmpty(collection) &&
                  collection.images.map((image, idx) => (
                    <SwiperSlide key={idx}>
                      <div className="w-full pb-16">
                        <div className="grid grid-cols-1">
                          <div className="px-4 lg:px-0">
                            <div className="absolute bottom-10 z-10 left-1/2 transform -translate-x-1/2 text-center">
                              <h5 className="text-3xl md:text-4xl lg:text-5xl font-bold uppercase text-white">
                                {collection.title}
                              </h5>
                            </div>

                            <img
                              src={image.url}
                              alt=""
                              className="h-96 w-full object-cover mx-auto select-none"
                            />
                            <div className="bg-black absolute top-0 w-[calc(100%-2rem)] md:w-full h-full opacity-[.25]" />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>

          {/* CONTENT */}
          {!_.isEmpty(products) ? (
            <div className="w-full">
              <div className="container mx-auto w-full pt-2 md:pt-6 px-4 lg:px-0">
                <div className="bg-white container mx-auto py-5 text-2xl md:flex md:justify-between my-5">
                  <div className="flex flex-row justify-between items-center">
                    <div className="md:mr-5 lg:pl-4 text-lg lg:text-2xl">
                      ORDENAR POR:
                    </div>
                    <div className="">
                      <select
                        value={comboValue}
                        className="text-center lg:text-left w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none text-lg lg:text-2xl"
                        onChange={(event) => sortData(event.target.value)}
                      >
                        <option value="mostsell">Más vendidos.</option>
                        <option value="alfa">Alfabeticamente A-Z .</option>
                        <option value="alfz">Alfabeticamente Z-A .</option>
                        <option value="cheapest">Precio: Menor A mayor.</option>
                        <option value="expensive">
                          Precio: Mayor A menor.
                        </option>
                        <option value="new">Fecha: Más antiguo.</option>
                        <option value="old">Fecha: Más reciente.</option>
                      </select>
                    </div>
                  </div>

                  {products ? (
                    <div className="text-center lg:text-left mt-10 md:my-auto lg:pr-4 text-2xl">
                      {" "}
                      {products.length} artículos
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* THE MOST SOLD */}
                <div className="pb-12 md:pb-24">
                  {/* PRODUCT LIST */}
                  <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 md:gap-5">
                    {change !== 1 && products.length > 0 ? (
                      products.map((product) => (
                        <ProductCard
                          key={product.id}
                          product={product}
                          onClick={navigateToDetails}
                        />
                      ))
                    ) : products.length === 0 ? (
                      <div>No se encontraron resultados</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : products.length === 0 ? (
            <div className="mx-auto container w-full text-2xl my-10">
              No se encontraron resultados
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <Skeleton.SkeletonThemeProvider animation="opacity">
          <div>
            <div className="container w-full mx-auto h-96 mb-16">
              <Skeleton height="100%" borderRadius="0px" />
            </div>
            <div className="">
              <div className="container mx-auto w-full pt-12 md:pt-24 px-4 md:px-0">
                <div className="pb-12 md:pb-24">
                  {/* PRODUCT LIST */}
                  <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5">
                    {[...Array(20)].map((e, idx) => (
                      <div key={idx} className="h-72">
                        <Skeleton height="100%" borderRadius="0px" />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Skeleton.SkeletonThemeProvider>
      )}
    </>
  );
};

export default CategoryView;
