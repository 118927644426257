export const labelsByMonth = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];

export const optionsBarWithCurrency = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += new Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(context.parsed.y);
          }
          return label;
        },
      },
    },
  },
};

export const optionsDoughnutWithCurrency = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          return " $" + context.formattedValue.toLocaleString("en-US");
        },
        title: function (context) {
          let title = context[0].label;
          return title;
        },
      },
    },
  },
};

export const optionsDoughnutSimple = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          return " " + context.formattedValue;
        },
        title: function (context) {
          let title = context[0].label;
          return title;
        },
      },
    },
  },
};

export const optionsDoughnutWithCurrencyNoAspectRatio = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          return " $" + context.formattedValue.toLocaleString("en-US");
        },
        title: function (context) {
          let title = context[0].label;
          return title;
        },
      },
    },
  },
};

export const optionsDoughnutSimpleNoAspectRatio = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          return " " + context.formattedValue;
        },
        title: function (context) {
          let title = context[0].label;
          return title;
        },
      },
    },
  },
};
