import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const options = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};
const optionsTimer = {
  position: "top-right",
  autoClose: 6000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
};

export const notifications = {
  success: (message) => toast.success(message, options),
  successBuy: (message) => toast.success(message, optionsTimer),
  error: (message) => toast.error(message, optionsTimer),
  warn: (message) => toast.warn(message, optionsTimer),
  info: (message) => toast.info(message, options),
};
