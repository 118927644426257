import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';
import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const RegistrationEventForm = ({ event, handleSubmit, clickEventInfo, loading }) => {
  const [initialValues] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    phone: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    email: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`)
      .email("Correo eléctronico inválido"),

  });
  return (
    <>
      <div>
        <div className="modal-header my-5 text-left flex flex-row cursor-pointer w-1/3"
          onClick={() => clickEventInfo(event)}
        >
          <FontAwesomeIcon
            icon={["fa", "chevron-left"]}
            size="xl"
            className="ml-5 mt-2 text-primary-200 text-2xl"
          />
          <div>
            <h5 className="modal-title font-bold  text-2xl 2xl:text-left text-center 2xl:text-3xl text-primary-200 ml-2 mt-1">Regresar</h5>
            <hr className=''></hr>
          </div>
        </div>
        <div className="px-12 text-3xl text-center" role="dialog">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={formSchema}
            onSubmit={(values) => handleSubmit(values, event.id)}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="modal-header my-5 text-left flex flex-row " >
                  <FontAwesomeIcon
                    icon={["far", "calendar"]}
                    size="sm"
                    className="mr-3 mt-1"
                  />
                  <h5 className="modal-title font-bold  text-2xl 2xl:text-left text-center 2xl:text-3xl">Agendar sesion en vivo</h5>
                  <hr className=''></hr>
                </div>
                <div className='flex flex-col-reverse 2xl:flex-row  text-xl 2xl:text-3xl '>

                  <div className='w-full 2xl:w-8/12 p'>

                    <div className='2xl:mb-4 capitalize text-center 2xl:text-left'>
                      <p className=''>{(moment(event.start).format('D')).toString() + " " + (moment(event.start).format('MMMM h:mm a')).toString()}</p>
                    </div>
                    <div className='2xl:pr-20 '>
                      <div className="mb-5 2xl:mb-4    text-gray-550">

                        <p className='text-center 2xl:text-left'>Nombre completo</p>
                        <Field
                          name="name"
                          className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                          type="text"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-red-400 font-bold text-xl"
                        />
                      </div>

                      <div className="mb-5 2xl:mb-4  text-gray-550 flex flex-row">
                        <div className='w-full'>
                          <p className='text-center 2xl:text-left'>Correo</p>
                          <Field
                            name="email"
                            className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                            type="text"

                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-red-400 font-bold text-xl"
                          />
                        </div>
                      </div>
                      <div className="mb-5 2xl:mb-4  text-gray-550">
                        <p className='text-center 2xl:text-left'>Celular</p>
                        <Field
                          name="phone"
                          className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                          type="text"

                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="text-red-400 font-bold text-xl"
                        />
                      </div>
                    </div>

                  </div>
                  <div className='w-full 2xl:w-4/12'>
                    {event.image && (
                      <img
                        src={event.image}
                        alt={event.name}
                        className="w-96  2xl:w-96  object-cover mx-auto"
                      />
                    )}
                  </div>
                </div>
                <div className="w-full flex justify-end px-6  ">
                  {loading ?
                    <div
                      className="w-full lg:w-56  bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75"
                    >
                      <FontAwesomeIcon icon="circle-notch" spin />

                    </div>
                    :
                    <button
                      type="submit"
                      className="w-full lg:w-56  bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75"
                    >
                      Agendar
                    </button>
                  }

                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};


export default RegistrationEventForm;