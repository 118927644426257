import { COURSES_STATUS } from "./memberships";

export const BUTTON_COURSES = {
  [COURSES_STATUS.BUY]: {
    color: "bg-primary-300",
    label: "Comprar",
    description: "",
  },
  [COURSES_STATUS.TO_ACQUIRE]: {
    color: "bg-primary-200",
    label: "Adquirir",
    description: "",
  },
  [COURSES_STATUS.START]: {
    color: "bg-primary-200",
    label: "Empezar",
    description: "",
  },
  [COURSES_STATUS.EXCLUSIVE_MEMBERSHIP]: {
    color: "bg-yellow-500",
    label: "Exclusivo para miembros",
    description: "Este curso es exclusivo para miembros",
  },
  [COURSES_STATUS.EXCLUSIVE_PREMIUM]: {
    color: "bg-yellow-600",
    label: "Exclusivo  miembros Premium",
    description: "Este curso es exclusivo para miembros Premium",
  },
  default: {
    color: "bg-primary-500",
    label: "En proceso...",
    description: "",
  },
};
