import { api } from "../../client";

export const blogServices = {
  getPosts(params) {
    return api.blog.get("/post", {
      params: params,
    });
  },

  getPostBySlug(slug) {
    return api.blog.get(`post/${slug}`);
  },
  getPostByTag(tag) {
    return api.blog.get(`post/tag/${tag}`);
  },
  getPostByAuthor(slug) {
    return api.blog.get(`post/author/${slug}`);
  },
  getPostById(id) {
    return api.blog.get(`post/preview/view/${id}`);
  },

  searchPosts(search) {
    return api.blog.get(`post/search/${search}`);
  },
};
