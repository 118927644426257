import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import useUser from "../hooks/useUser";

export default function Dropdown({ name }) {
  const { logout } = useUser();

  const formattedUserName = () => {
    return name.split(" ")[0];
  };

  return (
    <div className="text-right">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center items-center bg-gray-300 px-6 py-2 font-bold text-gray-700">
            <FontAwesomeIcon
              className="mr-2"
              icon={["far", "user"]}
              size="sm"
            />
            Hola, {formattedUserName()}
            <FontAwesomeIcon className="ml-2" icon="chevron-down" size="xs" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/mi-perfil"
                    className={`${
                      active ? "bg-primary-200 text-white" : "text-gray-700"
                    } group flex w-full items-center px-2 py-2`}
                  >
                    Mi Perfil
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/mi-escritorio"
                    className={`${
                      active ? "bg-primary-200 text-white" : "text-gray-700"
                    } group flex w-full items-center px-2 py-2`}
                  >
                    Mi Escritorio
                  </Link>
                )}
              </Menu.Item>
            </div>

            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-primary-200 text-white" : "text-gray-700"
                    } group flex w-full items-center px-2 py-2`}
                    onClick={() => logout()}
                  >
                    Cerrar Sesión
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
