import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
const BannersCarrousel = ({ banners,setSwiper }) => {
    return (
        <>
            <Swiper onSwiper={setSwiper}
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                    clickable: true,
                }}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 1,
                        spaceBetween: 50,
                    },
                }}
                modules={[Pagination]}
                className="mySwiper3">
                {
                    banners.map((option,index) => (
                        <SwiperSlide  id={option.id?option.id:option.name} key={index}>
                            <img alt="" className="mx-auto w-70" src={option.url || option.image}></img>
                        </SwiperSlide>
                    ))
                }

            </Swiper>
        </>
    );
};

export default BannersCarrousel;