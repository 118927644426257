import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useContext } from "react";
import { GoogleCalendar } from "../../components/Calendar/CalendarComponent";
import Context from "../../context/UserContext";
import 'moment-timezone';
const ScheduleEventComponent = ({ event, handleSubmit, RegistrationForm }) => {

  const { currentUser } = useContext(Context);
  const actualDate = moment(event.date).tz('America/Hermosillo', true);
  actualDate.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('ha ');
  
  const returnEventLink = (isEventMembership) => {
    let showLink = (<p className="text-primary-200 whitespace-pre-line 2xl:mb-5">
      <a href="/membresias">
        "Conviértete en miembro para poder acceder al enlace"{" "}
      </a>
    </p>)
    if (isEventMembership) {
      if (currentUser && currentUser.hasMembership) {
        showLink = (<p className="text-primary-200 whitespace-pre-line 2xl:mb-5 break-all">
          {event.link}
        </p>)
      }
    } else {
      showLink = (<p className="text-primary-200 whitespace-pre-line 2xl:mb-5 break-all">
        {event.link}
      </p>)
    }
    return showLink;
  }
  const returnButtonsEvents = (isEventMembership) => {
    let buttons = <div className="flex flex-col md:flex-row justify-between md:gap-10">
      <button
        onClick={() => GoogleCalendar(event)}
        className="text-xl mb-5 bg-white border-2 border-primary-200 px-6 py-2  text-primary-200 font-bold w-full"
      >
        Agregar a Google Calendar
      </button>
      <button
        type="button"
        className="text-xl mb-5 bg-primary-200 px-6 py-2 text-white font-bold w-full"
        onClick={() => {
          currentUser
            ? handleSubmit(event.element)
            : RegistrationForm(event);
        }}
      >
        Inscribirme
      </button>
    </div>;
    let showLink = "";
    if (isEventMembership) {
      if (currentUser && currentUser.hasMembership) {
        showLink = buttons;
      }
    } else {
      showLink = buttons;
    }
    return showLink;
  }



  return (
    <>
      <div
        className="px-8 md:px-12 text-2xl 2xl:text-3xl text-center"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="container mx-auto">
            <div className="modal-header my-5 text-left flex flex-row text-2xl 2xl:text-left text-center 2xl:text-3xl">
              <FontAwesomeIcon
                icon={["far", "calendar"]}
                size="sm"
                className="mr-3 mt-1"
              />
              <h5 className="modal-title font-bold ">{event.title}</h5>
              <hr className=""></hr>
            </div>
            <div className="modal-body text-2xl text-gray-600 text-center 2xl:text-left text-xl 2xl:text-3xl">
              <div className="flex flex-col-reverse 2xl:flex-row  text-xl 2xl:text-3xl ">
                <div className="w-full 2xl:w-8/12 whitespace-pre-line">
                  <div className=" 2xl:mb-5 capitalize">

                    <p className="">
                      {actualDate.format("D").toString() +
                        " " +
                        actualDate.format("MMMM h:mm a").toString()}
                    </p>
                  </div>
                  <p className=" 2xl:mb-5">{event.description}</p>
                  {event.membership_required ? returnEventLink(event.membership_required) : returnEventLink(false)}
                  {event.users === "Membresia" && (
                    <p className="font-bold ">
                      ¡No te pierdas este contenido exclusivo solo para miembros
                      PLUS!
                    </p>
                  )}
                  <p className="my-4 text-gray-500">
                    La liga de la sesión estara aqui en el calendario y tambien
                    se enviará un dia antes de la reunión a tu correo.
                  </p>
                </div>
                <div className="w-full 2xl:w-4/12 ">
                  {event.image && (
                    <img
                      src={event.image}
                      alt={event.name}
                      className="w-60 h-60 2xl:w-96 2xl:h-96 object-contain mx-auto "
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer text-right mt-5 lg:mt-10 ">
              {event.membership_required ? returnButtonsEvents(event.membership_required) : returnButtonsEvents(false)}

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduleEventComponent;
