import { memberships } from "./memberships";

export const MEMBERSHIPS_DICTIONARY = {
  [memberships.BASICA_EDUCATIVA]: {
    backgroundColor: "rgba(255, 99, 132, 0.7)",
    label: "Básica Educativa",
  },
  [memberships.BASICA_DROPSHIPPING]: {
    backgroundColor: "rgba(54, 162, 235, 0.7)",
    label: "Dropshipping",
  },
  [memberships.PLUS_MENSUAL]: {
    backgroundColor: "rgba(255, 206, 76, 0.7)",
    label: "Plus Mensual",
  },
  [memberships.PLUS_TRIMESTRAL]: {
    backgroundColor: "rgba(75, 192, 192, 0.7)",
    label: "Plus Trimestral",
  },
  [memberships.PLUS_ANUAL]: {
    backgroundColor: "rgba(153, 102, 255, 0.7)",
    label: "Plus Anual",
  },
  [memberships.EMPRENDEDOR]: {
    backgroundColor: "rgba(78, 200, 12, 0.7)",
    label: "Emprendedor",
  },
  [memberships.PREMIUM]: {
    backgroundColor: "rgba(255, 160, 47, 0.82)",
    label: "Premium",
  },
  [memberships.PLUS_SEMESTRAL]: {
    backgroundColor: "rgba(163, 147, 0, 0.8)",
    label: "Plus Semestral",
  },
  [memberships.ANUAL_DROPSHIPPING]: {
    backgroundColor: "rgba(255, 255, 255)",
    label: "",
  },
  [memberships.EMPRENDEDOR]: {
    backgroundColor: "rgba(78, 200, 12, 0.7)",
    label: "Emprendedor",
  },

  default: {
    backgroundColor: "rgba(50, 50, 255, 0.7)",
    label: "Cursos",
  },
};
