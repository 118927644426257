import noImageProduct from "../../assets/images/store/no-image-product.jpg";

const ProductCard = ({ product, onClick, viewedProduct }) => {
  const getPrice = () => {
    if (product.variants && product.variants.length > 0) {
      return product.variants[0].price;
    } else {
      let price = product.variants.edges[0].node.price;
      return price.amount ? price.amount : price;
    }
  };

  const getCompareAtPrice = () => {
    if (product.variants && product.variants.length > 0) {
      return product.variants[0].compare_at_price &&
        product.variants[0].compare_at_price > 0
        ? product.variants[0].compare_at_price
        : getPrice();
    } else {
      let productVariant = product.variants.edges[0].node;
      if (
        productVariant.compareAtPrice &&
        productVariant.compareAtPrice.amount
      ) {
        return productVariant.compareAtPrice.amount > 0
          ? productVariant.compareAtPrice.amount
          : getPrice();
      } else {
        return productVariant.compareAtPrice > 0
          ? productVariant.compareAtPrice
          : getPrice();
      }
    }
  };

  const getImage = () => {
    if (product.image && product.image.src) {
      return product.image.src;
    } else if (
      product.images &&
      product.images.edges &&
      product.images.edges.length > 0
    ) {
      return product.images.edges[0].node.originalSrc;
    } else {
      return noImageProduct;
    }
  };

  return (
    <div
      onClick={() => onClick(product.id, product.shopify_store_id)}
      className={`bg-white  ${
        !viewedProduct ? "h-60 lg:h-96 p-4 lg:p-8 flex flex-col" : "py-5"
      } justify-between cursor-pointer transition ease-in-out delay-50 hover:shadow-lg mb-5`}
    >
      <div className="w-full h-2/3">
        <img
          src={getImage()}
          alt=""
          className={` mx-auto  w-full ${
            viewedProduct ? "h-20" : "h-full"
          } object-contain`}
        />
      </div>
      {!viewedProduct && (
        <div className="text-sm lg:text-sm  leading-none">
          <p className="text-gray-700 text-sm xl:text-xl text-center lg:text-left">
            {product.title.length >= 25
              ? product.title.substring(0, 25) + "..."
              : product.title}
          </p>
          <p className="text-primary-200 text-sm xl:text-xl text-center">
            ${getCompareAtPrice()} MXN{" "}
          </p>

          <div className="text-green-50 text-[10px] xl:text-sm text-center font-bold">
            <p>Miembros:</p>
            <p>${getPrice()} MXN </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductCard;
