import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const CartModal = ({ isPublished, setVisible }) => {
  const navigate = useNavigate();

  const goToCart = () => {
    navigate("/carrito-de-compras");
  };
  const goToStore = () => {
    navigate(-1);
  };

  return (
    <div className="modal-dialog text-gray-700">
      <div className="px-10 py-3 md:py-10 md:px-20 flex flex-col">
        <p className="text-xl lg:text-2xl font-bold text-center ">¡Producto agregado con éxito!</p>
        <div className="flex flex-col md:flex-row justify-between text-lg ">
          <button
            type="button"
            className="text-center lg:py-2 px-8 text-primary-200 font-bold w-full md:w-auto mt-4 border border-primary-200"
            onClick={goToCart}
          >
            <FontAwesomeIcon icon={["far", "cart-shopping"]} /> Ir a pagar 
          </button>
          <button
            type="button"
            className="bg-primary-200 text-center lg:py-2 px-8 text-white font-bold w-full md:w-auto mt-4"
            onClick={goToStore}
          >
            Seguir comprando
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartModal;
