import * as _ from "lodash";
import { Doughnut } from "react-chartjs-2";
import {
  optionsDoughnutSimpleNoAspectRatio,
  optionsDoughnutWithCurrencyNoAspectRatio,
} from "../../../utils/chartsOptions";

const SalesAndCountsCommissions = ({
  comissionsChart,
  dataComissions,
  soldCountChart,
  dataSalesCount,
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-10 lg:gap-20 text-center mt-10">
      <div
        className={`${
          comissionsChart.every((item) => item === 0) ? "h-auto" : "h-96"
        }`}
      >
        <p className="text-3xl font-bold text-gray-700 mb-5">Comisiones</p>
        {comissionsChart.every((item) => item === 0) ? (
          <p className="text-primary-200 font-bold">SIN VENTAS</p>
        ) : (
          <Doughnut
            data={dataComissions}
            options={optionsDoughnutWithCurrencyNoAspectRatio}
          />
        )}
      </div>
      <div
        className={`${
          comissionsChart.every((value) => value === 0) ? "h-auto" : "h-96"
        }`}
      >
        <p className="text-3xl font-bold text-gray-700 mb-5">Vendidas</p>
        {soldCountChart.every((item) => item === 0) ? (
          <p className="text-primary-200 font-bold">SIN VENTAS</p>
        ) : (
          <Doughnut
            data={dataSalesCount}
            options={optionsDoughnutSimpleNoAspectRatio}
          />
        )}
      </div>
    </div>
  );
};

export default SalesAndCountsCommissions;
