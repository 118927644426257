import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import logo from "../assets/images/logo-globe-gbp.png";
import checkIcon from "../assets/icons/check-icon.svg";

const ShoppingCart = () => {
  return (
    <main className="container mx-auto flex-1 px-4 md:px-0 mb-16">
      <div className="">
        <div className="h-20 flex items-center">
          <img src={logo} alt="BeGlobalPro" className="h-9 mr-2" />
          <p className=" text-gray-700">
            <FontAwesomeIcon icon="fa-chevron-right" className="mr-1" />{" "}
            <span className="text-gray-400">Carrito de compras</span>
          </p>
        </div>
      </div>

      <div className="">
        <div className="bg-green-50 border-green-50 border-4 rounded-full w-16 h-16 lg:w-24 lg:h-24 mx-auto flex items-center justify-center mb-5 lg:mb-10">
          <img src={checkIcon} alt="" className="w-8/12 lg:w-10/12" />
        </div>

        <p className="pb-2 lg:pb-8 text-2xl lg:text-5xl text-center  text-green-50 font-bold">
          Pago exitoso
        </p>
        <p className="pb-8 text-xl lg:text-3xl text-center">
          Tu pago se ha completado exitosamente
        </p>
        <div className=" text-gray-700 text-xl lg:text-2xl text-center">
          <Link
            to="/"
            className="bg-primary-200 py-3 px-12 text-white font-bold border-primary-200 border-2 mt-5"
          >
            Continuar
          </Link>
        </div>
      </div>
    </main>
  );
};

export default ShoppingCart;
