import ProductCard from "../../components/store/ProductCard";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import { useNavigate } from "react-router-dom";

const ProductsHistory = ({ products, productsSkeleton, isMostSold }) => {
  const navigate = useNavigate();

  const navigateToDetails = (id, shopify_store_id) => {
    navigate(`/tienda/${shopify_store_id}/producto/${id}`);
  };

  return (
    <>
      <div className="hidden lg:block">
        {
          //version escritorio
        }
        {products && products.length > 5 && (
          <Swiper
            slidesPerView={5}
            spaceBetween={50}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            loop={true}
            breakpoints={{
              640: {
                slidesPerView: 5,
                spaceBetween: 50,
              },
              768: {
                slidesPerView: 5,
                spaceBetween: 50,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
            navigation={true}
            longSwipes={true}
            shortSwipes={false}
            modules={[Autoplay, Navigation]}
            className="mySwiperProducts"
          >
            {products.map((product, index) => (
              <>
                <SwiperSlide key={product.id}>
                  <ProductCard
                    viewedProduct
                    key={product.id}
                    product={product}
                    onClick={navigateToDetails}
                  />
                </SwiperSlide>
              </>
            ))}
          </Swiper>
        )}

        {products && products.length <= 5 && (
          <div className="grid grid-cols-5 gap-12">
            {products.map((product) => (
              <ProductCard
                viewedProduct
                key={product.id}
                product={product}
                onClick={navigateToDetails}
              />
            ))}
          </div>
        )}
      </div>

      <div className="block lg:hidden">
        {products && products.length > 2 && (
          <Swiper
            slidesPerView={2}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            loop={true}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
            navigation={true}
            longSwipes={true}
            shortSwipes={false}
            modules={[Autoplay, Navigation]}
            className="mySwiperProducts"
          >
            {products.map((product) => (
              <SwiperSlide key={product.id}>
                <ProductCard
                  viewedProduct
                  key={product.id}
                  product={product}
                  onClick={navigateToDetails}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        {products && products.length <= 2 && (
          <div className="grid grid-cols-2 gap-x-3">
            {products.map((product) => (
              <ProductCard
                viewedProduct
                key={product.id}
                product={product}
                onClick={navigateToDetails}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ProductsHistory;
