import { useEffect, useContext } from "react";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, useFormikContext } from "formik";
import * as _ from "lodash";

import Context from "../../context/AdminUserContext";

import FilesDragAndDrop from "../../components/FilesDragAndDrop";
import { adminProfileService } from "../../services/api/admin/profile";
import { notifications } from "../../utils/notifications";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const ChangeImageProfileModal = ({ visible, setVisible }) => {
  const formSchema = Yup.object().shape({
    image: Yup.mixed()
      .required("La imagen es requerida")
      .test(
        "fileFormat",
        "Formato no soportado",
        (value) =>
          !value || ((value) => value && SUPPORTED_FORMATS.includes(value.type))
      ),
  });

  const { setCurrentAdminUser } = useContext(Context);

  const onFileChange = (files, setFieldValue) => {
    if (!_.isEmpty(files)) {
      setFieldValue("image", files[0]);
    }
  };

  const handleSubmit = async (values) => {
    let bodyFormData = new FormData();

    bodyFormData.append("_method", "PUT");
    bodyFormData.append("image", values.image);

    await adminProfileService
      .updateImage(bodyFormData)
      .then((response) => {
        setCurrentAdminUser(response.data.admin);
        notifications.success("¡Imagen actualizada!");
        setVisible(false);
      })
      .catch((err) => {});
  };

  return (
    <div>
      <div>
        <Rodal
          visible={visible}
          onClose={() => setVisible(false)}
          customStyles={{
            width: "95%",
            maxWidth: 500,
            height: "85%",
            maxHeight: 510,
            padding: "0",
            overflowY: "scroll",
          }}
        >
          <div className="flex flex-col justify-center h-full px-8">
            <h2 className="text-center font-bold text-gray-700 text-2xl md:text-3xl mb-4">
              Cambiar imagen de perfil
            </h2>
            <Formik
              enableReinitialize={true}
              initialValues={{ image: null }}
              validationSchema={formSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ setFieldValue }) => (
                <Form>
                  <div>
                    <FilesDragAndDrop
                      onFileChange={(files) =>
                        onFileChange(files, setFieldValue)
                      }
                      multiple={false}
                      showFiles={false}
                    />
                    <ErrorMessage
                      name="image"
                      component="div"
                      className="text-red-400 font-bold text-xl"
                    />
                  </div>
                  <AutoSubmitImage />
                </Form>
              )}
            </Formik>
          </div>
        </Rodal>
      </div>
    </div>
  );
};

const AutoSubmitImage = () => {
  // Grab values and submitForm from context
  const { values, submitForm } = useFormikContext();
  useEffect(() => {
    // Submit the form imperatively as an effect as soon as form values.token are 6 digits long
    if (values.image !== null) {
      submitForm();
    }
  }, [values, submitForm]);
  return null;
};

export default ChangeImageProfileModal;
