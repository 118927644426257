import { useEffect, useState } from "react";
import PostComponent from "../../components/blog/PostComponent";
import { blogServices } from "../../services/api/blog";
import TableLoading from "../../components/TableLoading";
import { useNavigate, useParams } from "react-router-dom";
import NotFoundComponent from "../../components/blog/NotFoundComponent";

export default function PostByTag() {
  const params = useParams();

  const navigate = useNavigate();

  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchPostsByTag = () => {
    setIsLoading(true);

    blogServices
      .getPostByTag(params.tag)
      .then((response) => {
        setPosts((prevPosts) => prevPosts.concat(response.data.posts));
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchPostsByTag();
  }, []);

  return (
    <section className="bg-gray-300 h-auto">
      <div className="min-h-screen">
        <div className="container mx-auto px-4 py-5 pt-10 text-2xl md:text-4xl text-primary-200 font-bold text-center md:text-left">
          {" "}
          Articulos relacionados con{" "}
          <b className="text-primary-300">"{params.tag}"</b>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 container mx-auto px-4  py-4 md:py-10">
          {posts?.map(
            ({
              id,
              title,
              body,
              publish_date,
              featured_image,
              author,
              slug,
            }) => (
              <PostComponent
                key={id}
                title={title}
                body={body}
                publish_date={publish_date}
                featured_image={featured_image}
                author={author}
                slug={slug}
              />
            )
          )}
        </div>

        {isLoading && (
          <div className="container px-auto mx-auto text-center">
            <TableLoading size="10x" />
          </div>
        )}
        {posts.length === 0 && !isLoading && (
          <NotFoundComponent
            label={
              "Aún no se ha añadido ningún articulo relacionado a la etiqueta "
            }
            label2={params.tag}
            buttonText={"blog"}
            url={"/blog"}
          />
        )}
      </div>
    </section>
  );
}
