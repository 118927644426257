import ProgressBar from "@ramonak/react-progress-bar";
import TableLoading from "../../components/TableLoading";

const CoursesInProgress = ({ courses, isLoading, label }) => {
  return (
    <>
      <div className="text-center">
        <p className="text-center px-5 lg:text-left py-4 lg:py-8 text-2xl lg:text-3xl text-gray-700 font-bold border-b border-gray-350 lg:px-8 lg:self-start lg:flex flex-row">
          {label}
        </p>
      </div>
      {isLoading ? (
        <div className="w-full flex justify-center">
          <TableLoading size="3x" />
        </div>
      ) : (
        <div className="px-10 pt-8 space-y-8 mb-20 overflow-auto overflow-y-auto h-[480px] md:h-[590px] lg:h-[680px]">
          {courses.length === 0 && (
            <div className="text-xl text-center">
              No se ha iniciado ningun curso.
            </div>
          )}
          {courses.map((course, key) => (
            <div key={key} className="mb-10">
              <div className="flex justify-end w-full"></div>
              <div className="flex justify-between text-xl lg:text-2xl flex-wrap">
                <div className="font-bold text-center lg:text-left my-auto w-full lg:w-9/12">
                  {course.name}
                </div>
                <div className="mt-auto text-2xl font-bold text-primary-200 text-center lg:text-left w-full lg:w-auto">
                  {Number.isInteger(course.progress)
                    ? course.progress
                    : !isNaN(parseFloat(course.progress))
                    ? parseFloat(course.progress).toFixed(2)
                    : "0"}
                  %{" "}
                </div>
              </div>
              <div className="pt-5">
                <ProgressBar
                  bgColor="#60a5fa"
                  baseBgColor="#dadada"
                  isLabelVisible={false}
                  height="10px"
                  borderRadius="0px"
                  animateOnRender
                  completed={course.progress}
                  maxCompleted={100}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default CoursesInProgress;
