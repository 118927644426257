import { api } from "../../client";

export const cartServices = {
  getCart() {
    return api.base.get("/cart");
  },
  addCourseToCart(model) {
    return api.base.post("/cart", model);
  },
  removeCourseFromCart(model) {
    return api.base.post("/cart", model);
  },
  buyForLaterItemFromCart(model) {
    return api.base.post("/cart", model);
  },
  addProductToCart(model) {
    return api.base.post("/shopify/cart", model);
  },
  removeProductFromCart(model) {
    return api.base.post("/shopify/cart", model);
  },
  buyForLaterProductFromCart(model) {
    return api.base.post("/shopify/cart", model);
  },
  insertMembership() {
    return api.base.post("/cart/membership");
  },
};
