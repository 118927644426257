import { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import * as _ from "lodash";
import Context from "../../../context/AdminUserContext";
import TableLoading from "../../../components/TableLoading";
import TableNoData from "../../../components/TableNoData";
import { ShopifyAPIService } from "../../../services/api/admin/shopify";
import {
  getProductsForCollections,
  getProductsForCollectionsSM,
  customStyles,
} from "../../../utils/tableOptions";

const Index = () => {
  const navigate = useNavigate();
  const { currentAdminUser } = useContext(Context);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formattedTopUsersData, setFormattedTopUsersData] = useState([]);
  const inputHandler = (e) => setQuerySearch(e.target.value.toLowerCase());
  const [querySearch, setQuerySearch] = useState("");

  const filteredData = formattedTopUsersData.filter((product) => {
    if (querySearch === "") {
      return product;
    } else {
      return (
        product.title.toLowerCase().includes(querySearch) ||
        product.statusSearch.toLowerCase().includes(querySearch)
      );
    }
  });

  const fetchShopifyProducts = () => {
    ShopifyAPIService.getProducts()
      .then((response) => {
        setProducts(response.data.products);
        setLoading(false);
      })
      .catch((err) => {});
  };

  const formatCollectionsData = () => {
    let data = [];
    for (let index = 0; index < products.length; index++) {
      data.push({
        nameComponent: (
          <div>
            <span className="font-bold text-primary-200 ">
              {products[index].title}
            </span>
          </div>
        ),
        photoComponent: (
          <div>
            <span className="font-bold text-primary-200 text-3xl md:text-2xl">
              <img
                src={products[index].image?.src}
                alt=""
                className="w-20 h-20"
              />
            </span>
          </div>
        ),
        quantity: getFullInventory(products[index].variants),

        actionComponent: (
          <div className="text-primary-200 hover:text-primary-300 text-lg leading-none">
            <button
              onClick={() =>
                navigate(`/admin/productos/${products[index].id}/editar`)
              }
            >
              Editar
            </button>
          </div>
        ),
        status: (
          <div>
            {products[index].status === "draft" ? "Borrador" : "Activo"}
          </div>
        ),
        title: products[index].title,
        statusSearch: products[index].status,
      });
    }

    setFormattedTopUsersData(data);
    setLoading(false);
  };

  const getFullInventory = (variants) => {
    let inventory = 0;

    variants.forEach((variant) => {
      inventory += variant.inventory_quantity;
    });

    return inventory;
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchShopifyProducts();
  }, [currentAdminUser]);

  useEffect(() => {
    if (_.isEmpty(products)) return;
    formatCollectionsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <p className="text-4xl font-bold mb-5 md:mb-0">Productos</p>
        <Link
          to="/admin/productos/nuevo"
          className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75 text-center"
        >
          <FontAwesomeIcon icon={["fa", "plus"]} size="sm" className="mr-3" />
          Añadir nuevo
        </Link>
      </div>

      <div className="w-full  flex flex-col md:flex-row justify-center md:justify-left gap-8  py-3 mt-6 border-b border-b-gray-350">
        <div className="w-full ">
          <label className="relative block">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <FontAwesomeIcon
                icon={["fa", "magnifying-glass"]}
                className="text-[#A9A9A9]"
                size="sm"
              />
            </span>
            <input
              className="w-full  bg-white text-gray-700 placeholder:font-italic border border-slate-300 py-3 pl-12 pr-4 focus:outline-none"
              type="text"
              placeholder="Buscar"
              value={querySearch}
              onChange={inputHandler}
            />
          </label>
        </div>
      </div>

      <div className="border border-gray-350 mt-12 2xl:hidden">
        <DataTable
          columns={getProductsForCollections}
          data={filteredData}
          noDataComponent={<TableNoData />}
          progressPending={loading}
          progressComponent={<TableLoading />}
          customStyles={customStyles}
        />
      </div>
      <div className="border border-gray-350 mt-12 hidden 2xl:block">
        <DataTable
          columns={getProductsForCollections}
          data={filteredData}
          noDataComponent={<TableNoData />}
          progressPending={loading}
          progressComponent={<TableLoading />}
          customStyles={customStyles}
        />
      </div>
    </div>
  );
};

export default Index;
