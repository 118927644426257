/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as _ from "lodash";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DndProvider } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import Context from "../../../context/AdminUserContext";
import FilesDragAndDrop from "../../../components/FilesDragAndDrop";
import { notifications } from "../../../utils/notifications";
import { adminCoursesServices } from "../../../services/api/admin/courses";
import LessonCard from "../../../components/admin/LessonCard";

const MySwal = withReactContent(Swal);
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const EditCourse = () => {
  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    description: Yup.string().required("Campo requerido"),
    cost: Yup.number().required("Campo requerido"),
    image: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        "fileFormat",
        "Formato no soportado",
        (value) =>
          !value || ((value) => value && SUPPORTED_FORMATS.includes(value.type))
      ),
    level: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    teacher_id: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
  });

  const { currentAdminUser } = useContext(Context);
  const params = useParams();
  const navigate = useNavigate();

  const [course, setCourse] = useState({});
  const [teachers, setTeachers] = useState([]);
  const [initialLessonsOrder, setInitialLessonsOrder] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [openSection, setOpenSection] = useState(null);
  const [showSaveOrder, setShowSaveOrder] = useState(false);

  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    cost: 0,
    image: null,
    level: "",
    teacher_id: "",
  });

  useEffect(() => {
    if (!_.isEmpty(course)) {
      setInitialValues({
        name: course.name || "",
        description: course.description || "",
        cost: course.cost || 0,
        image: null,
        level: course.level || "Principiante",
        teacher_id: course.teacher_id || "",
        exclusive_members: course.exclusive_members || "0",
      });
    }
  }, [course]);

  const fetchCourse = () => {
    adminCoursesServices
      .courseDetails(params.uuid)
      .then((response) => {
        setInitialLessonsOrder(response.data.course.classes);
        setLessons(response.data.course.classes);
        setCourse(response.data.course);
      })
      .catch((err) => {});
  };
  const fetchTeachers = () => {
    adminCoursesServices
      .getTeachers()
      .then((response) => {
        setTeachers(response.data.teachers);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchCourse();
    fetchTeachers();
  }, [currentAdminUser]);

  const handleSubmit = async (values) => {
    let bodyFormData = new FormData();
    bodyFormData.append("_method", "PUT");
    bodyFormData.append("name", values.name);
    bodyFormData.append("description", values.description);
    bodyFormData.append("cost", values.cost);
    if (values.image !== null) bodyFormData.append("image", values.image);
    bodyFormData.append("level", values.level);
    if (values.teacher_id !== "0")
      bodyFormData.append("teacher_id", values.teacher_id);
    bodyFormData.append("exclusive_members", values.exclusive_members);

    await adminCoursesServices
      .updateCourse(params.uuid, bodyFormData)
      .then((response) => {
        notifications.success(response.data.message);
        navigate("/admin/cursos");
      })
      .catch((err) => {});
  };

  const handleDelete = () => {
    MySwal.fire({
      icon: "error",
      title: "¿Deseas eliminar este curso?",
      text: "Se eliminará todo el contenido asociado. Esta acción es irreversible",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#42a819",
      confirmButtonText: "Eliminar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await adminCoursesServices
          .deleteCourse(params.uuid)
          .then((response) => {
            notifications.success(response.data.message);
            navigate("/admin/cursos");
          })
          .catch((err) => {});
      }
    });
  };

  const onFileChange = (files, setFieldValue) => {
    if (!_.isEmpty(files)) {
      setFieldValue("image", files[0]);
    }
  };

  useEffect(() => {
    _.isEqual(initialLessonsOrder, lessons)
      ? setShowSaveOrder(false)
      : setShowSaveOrder(true);
  }, [lessons, initialLessonsOrder]);

  const moveLessonsListItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = lessons[dragIndex];
      const hoverItem = lessons[hoverIndex];

      let updatedLessons = [...lessons];
      updatedLessons[dragIndex] = hoverItem;
      updatedLessons[hoverIndex] = dragItem;

      setLessons(updatedLessons);
    },
    [lessons]
  );

  const getLessonsOrder = () => {
    return lessons.map((lesson, idx) => ({ id: lesson.id, order: idx }));
  };

  const updateLessonsOrder = async () => {
    let order = getLessonsOrder();
    await adminCoursesServices
      .updateLessonOrder({ classes: order })
      .then((response) => {
        notifications.success(response.data.message);
        setInitialLessonsOrder(lessons);
      })
      .catch((err) => {});
  };

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <p className="text-2xl lg:text-4xl font-bold">Editar curso</p>
      </div>

      <div className="mt-4 lg:mt-12">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-4 lg:mb-9 text-xl lg:text-3xl text-gray-550">
                  <p>Nombre del curso</p>
                  <Field
                    name="name"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-4 lg:mb-9 text-xl lg:text-3xl text-gray-550">
                  <p>Profesor</p>
                  <Field
                    as="select"
                    name="teacher_id"
                    className="w-full bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none"
                    disabled={currentAdminUser.type === "Profesor"}
                  >
                    <option value={"0"}>Seleccione un maestro</option>
                    {!_.isEmpty(teachers) &&
                      teachers.map((teacher) => (
                        <option
                          key={`teacher-${teacher.id}`}
                          value={teacher.id}
                        >
                          {teacher.name}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage
                    name="teacher_id"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>

              <div className="mb-4 lg:mb-9 text-xl lg:text-3xl text-gray-550">
                <p>Descripción del curso</p>
                <Field
                  as="textarea"
                  name="description"
                  className="w-full h-48 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-4 lg:mb-9 text-xl lg:text-3xl text-gray-550">
                  <p className="mb-5">Cambiar imagen del curso</p>
                  <div>
                    <FilesDragAndDrop
                      onFileChange={(files) =>
                        onFileChange(files, setFieldValue)
                      }
                      text={true}
                      multiple={false}
                    />
                  </div>
                  <ErrorMessage
                    name="image"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-4 lg:mb-9 text-xl lg:text-3xl text-gray-550 text-center lg:text-left">
                  <p className="mb-5">Imagen actual</p>
                  {course.image && (
                    <img
                      src={course.image}
                      alt={course.name}
                      className="w-full h-auto object-cover"
                    />
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-4 lg:mb-9 text-xl lg:text-3xl text-gray-550">
                  <p>Nivel</p>
                  <Field
                    as="select"
                    name="level"
                    className="w-full bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none"
                  >
                    <option value="Principiante">Principiante</option>
                    <option value="Intermedio">Intermedio</option>
                    <option value="Experto">Experto</option>
                  </Field>
                  <ErrorMessage
                    name="level"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-4 lg:mb-9 text-xl lg:text-3xl text-gray-550">
                  <p>Costo</p>
                  <Field
                    name="cost"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="number"
                    min="0"
                  />
                  <ErrorMessage
                    name="cost"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-4 lg:mb-9 text-xl lg:text-3xl text-gray-550">
                  <p>Exclusivad para miembros</p>

                  <Field
                    as="select"
                    name="exclusive_members"
                    className="w-full bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none"
                  >
                    <option value="0">Ninguna</option>
                    <option value="1">Membresia</option>
                    <option value="2">Dropshipping</option>
                    <option value="6">Membresia premium</option>
                  </Field>
                  <ErrorMessage
                    name="level"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>

              {/* SECTIONS & LESSONS */}
              <div className="grid grid-cols-1 mb-5 md:mb-0 mt-9">
                <div className="mb-4 lg:mb-9 text-xl lg:text-3xl text-gray-550">
                  <div className="flex flex-col gap-y-5 md:flex-row md:justify-end md:gap-x-5">
                    <Link
                      to={`/admin/cursos/${params.uuid}/seccion/nueva`}
                      className="w-full md:w-56 bg-primary-200 text-white text-xl lg:text-2xl py-3 px-5 disabled:opacity-75"
                    >
                      <FontAwesomeIcon
                        icon={["fa", "plus"]}
                        className="text-xl mr-3"
                      />
                      Añadir sección
                    </Link>
                    <Link
                      to={`/admin/cursos/${params.uuid}/leccion/nueva`}
                      className="w-full md:w-56 bg-primary-200 text-white text-xl lg:text-2xl py-3 px-5 disabled:opacity-75"
                    >
                      <FontAwesomeIcon
                        icon={["fa", "plus"]}
                        className="text-xl mr-3"
                      />
                      Añadir lección
                    </Link>
                  </div>
                </div>
              </div>

              {!_.isEmpty(course) && (
                <>
                  {course.sections.length > 0 && (
                    <>
                      <p className="mb-5 text-2xl lg:text-3xl text-gray-550 text-center lg:text-left">
                        Secciones
                      </p>
                      <div className="border border-gray-350 mb-4 lg:mb-9">
                        {course.sections.map((section, idx) => (
                          <div key={`section-${section.id}`}>
                            {/* DESPLIEGE DE SECCION */}
                            <div className="flex flex-row items-center md:gap-x-10 pl-4 pr-8 py-2 lg:py-5 border-b border-b-gray-350 w-full  bg-gray-200">
                              <div
                                className="w-full cursor-pointer flex flex-row items-center"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (section.id === openSection) {
                                    setOpenSection(null);
                                    return;
                                  }
                                  setOpenSection(section.id);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={[
                                    "fa",
                                    openSection === section.id
                                      ? "chevron-down"
                                      : "chevron-right",
                                  ]}
                                  className="pr-4 text-gray-700"
                                  size="sm"
                                />
                                <p className="text-xl lg:text-2xl ">
                                  Sección {idx + 1} -{" "}
                                  <span className="font-bold">
                                    {section.name}
                                  </span>
                                </p>
                              </div>

                              <div className="flex justify-between items-center md:flex-col text-[#AEAEAE]">
                                <button
                                  className="hidden md:block"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate(
                                      `/admin/cursos/${params.uuid}/seccion/${section.id}/editar`
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={["far", "pencil"]}
                                    className={`${
                                      true
                                        ? "text-primary-200"
                                        : "text-[#D8D8D8]"
                                    }`}
                                    size="sm"
                                  />
                                </button>
                                <button
                                  className="block md:hidden ml-auto"
                                  onClick={() =>
                                    navigate(
                                      `/admin/cursos/${params.uuid}/seccion/${section.id}/editar`
                                    )
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={["far", "pencil"]}
                                    className={`${
                                      true
                                        ? "text-primary-200"
                                        : "text-[#D8D8D8]"
                                    }`}
                                    size="sm"
                                  />
                                </button>
                              </div>
                            </div>
                            {/* DESPLIEGE DE LECCIONES DE SECCION */}
                            {openSection === section.id && (
                              <div className="flex flex-col pl-4 lg:pl-12 py-3 lg:py-5 border-b border-b-gray-350 w-full">
                                {section.classes.length > 0 ? (
                                  section.classes.map((lesson) => (
                                    <div className="flex flex-row items-center">
                                      <FontAwesomeIcon
                                        icon={["far", "circle-play"]}
                                        size="sm"
                                        className="mr-3 text-sm lg:text-xl"
                                      />
                                      <p
                                        key={`section-lesson-${lesson.id}`}
                                        className="text-sm lg:text-xl text-gray-700"
                                      >
                                        {lesson.name}
                                      </p>
                                    </div>
                                  ))
                                ) : (
                                  <div className="bg-gray-300 border-l-4 border-l-primary-200 p-4 mr-12">
                                    <p className="text-lg lg:text-xl text-gray-700 font-bold">
                                      Esta sección aun no contiene lecciones,
                                      ¡Añade una!
                                    </p>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {course.classes.length > 0 && (
                    <>
                      <div className="mb-5 leading-none">
                        <p className="text-center md:text-left text-2xl lg:text-3xl text-gray-550">
                          Lecciones sin sección
                        </p>
                        <p className="text-sm lg:text-xl text-center md:text-left">
                          Estas lecciones no pertenecen a ninguna sección
                        </p>
                      </div>
                      <DndProvider options={HTML5toTouch}>
                        <div className="border border-gray-350 mb-5">
                          {lessons.map((lesson, idx) => (
                            <LessonCard
                              key={`lesson-${lesson.id}`}
                              uuid={params.uuid}
                              lesson={lesson}
                              index={idx}
                              moveListItem={moveLessonsListItem}
                            />
                          ))}
                        </div>
                      </DndProvider>
                      {showSaveOrder && (
                        <div className="w-full mb-5">
                          <button
                            type="button"
                            className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75"
                            onClick={() => updateLessonsOrder()}
                          >
                            Actualizar orden
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}

              {/* RESOURCES */}
              {!_.isEmpty(course) && (
                <>
                  <div
                    className={` lg:text-left my-2 lg:my-5 text-2xl lg:text-3xl text-gray-550 flex ${
                      course.resources.length
                        ? "flex-row  justify-between"
                        : "flex-col"
                    }`}
                  >
                    <p className={`${!course.resources.length ? "mb-3" : ""}`}>
                      Recursos
                    </p>
                    <Link
                      to={`/admin/cursos/${params.uuid}/recurso/nuevo`}
                      className={`text-xl lg:text-2xl disabled:opacity-75 text-center ${
                        course.resources.length
                          ? "self-end text-primary-200"
                          : "w-full md:w-56 bg-primary-200 text-white py-3 px-5"
                      }`}
                    >
                      <FontAwesomeIcon
                        icon={["fa", "plus"]}
                        className="text-sm lg:text-xl mr-1 lg:mr-3"
                      />
                      Añadir recurso
                    </Link>
                  </div>
                  {course.resources.length > 0 && (
                    <div className="border border-gray-350">
                      {course.resources.map((resource) => (
                        <div
                          key={`resource-${resource.id}`}
                          className="flex flex-row justify-between items-center px-8 py-3 lg:py-5 border-b border-b-gray-350 w-full"
                        >
                          <div>
                            <p className="text-xl lg:text-2xl">
                              {resource.name}
                            </p>
                          </div>

                          <div className="flex justify-between items-center md:flex-col text-[#AEAEAE]">
                            <button
                              className="hidden md:block"
                              onClick={() =>
                                navigate(
                                  `/admin/cursos/${params.uuid}/recurso/${resource.id}/editar`
                                )
                              }
                            >
                              <FontAwesomeIcon
                                icon={["far", "pencil"]}
                                className={`${
                                  true ? "text-primary-200" : "text-[#D8D8D8]"
                                }`}
                                size="sm"
                              />
                            </button>
                            <button
                              className="block md:hidden ml-auto"
                              onClick={() =>
                                navigate(
                                  `/admin/cursos/${params.uuid}/recurso/${resource.id}/editar`
                                )
                              }
                            >
                              <FontAwesomeIcon
                                icon={["far", "pencil"]}
                                className={`${
                                  true ? "text-primary-200" : "text-[#D8D8D8]"
                                }`}
                                size="sm"
                              />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}

              {/* BUTTONS */}
              <div className="w-full flex justify-between space-x-6 mt-5">
                <button
                  type="button"
                  className="w-full md:w-56 bg-tertiary text-white text-2xl py-3 px-5 disabled:opacity-75 mt-5"
                  onClick={() => handleDelete()}
                >
                  Eliminar
                </button>
                <button
                  type="submit"
                  className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75 mt-5"
                >
                  Guardar
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditCourse;
