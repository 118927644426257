import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import CourseCard from "./CourseCard";
import { Navigation } from "swiper";

const CarrouselCourses = ({ courses, handleAddCourse }) => {
  return (
    <div>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 50,
          },
        }}
        navigation={true}
        modules={[Navigation, Pagination]}
        className="mySwiper3"
      >
        {courses?.map((course, index) => (
          <div key={index}>
            <SwiperSlide id={course.id} key={index}>
              <CourseCard
                key={course.uuid}
                course={course}
                handleAddCourse={(id) => handleAddCourse(id)}
              />
            </SwiperSlide>
          </div>
        ))}
      </Swiper>
    </div>
  );
};

export default CarrouselCourses;
