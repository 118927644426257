import { useState, useEffect, useContext } from "react";
import { Outlet, NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdminContext from "./../context/AdminUserContext";

import logo from "../assets/images/logo-bgp.svg";
import AdminDropdown from "../components/AdminDropdown";
import ReviewerSidebarLayout from "../components/admin/AdminLayout/ReviewerSidebarLayout";
import SellsSidebarLayout from "../components/admin/AdminLayout/SellsSidebarLayout.js";

const AdminLayout = () => {
  const { currentAdminUser } = useContext(AdminContext);
  const location = useLocation();
  const [showSidebar, setShowSidebar] = useState(false);
  const [showCoursesMenu, setShowCoursesMenu] = useState(false);
  const [showUsersSalesMenu, setShowUsersSalesMenu] = useState(false);
  const [showSalesMenu, setShowSalesMenu] = useState(false);
  const UrlPath = window.location.pathname;

  const validateProductsUrl = (isActive) => {
    if (
      isActive ||
      (UrlPath.match(/admin\/usuarios\/miembros\/productos\//i) &&
        !UrlPath.includes("administradores"))
    )
      return true;
  };

  const validateMembershipsUrl = (isActive) => {
    if (
      isActive ||
      (UrlPath.match(/admin\/usuarios\/miembros\/\d+\/ventas-membresias/) &&
        !UrlPath.includes("administradores"))
    )
      return true;
  };

  const validateAdminUsers = (isActive) => {
    if (
      isActive ||
      UrlPath.match(/admin\/usuarios\/administradores\/\d+\/editar/) ||
      UrlPath.includes("administradores/nuevo")
    )
      return true;
  };
  const validateUsersUrl = (isActive) => {
    if (
      ((isActive && UrlPath.match("/admin/usuarios")) ||
        UrlPath.includes("estadisticas") ||
        UrlPath.match(/admin\/usuarios\//i)) &&
      !UrlPath.includes("miembros") &&
      !UrlPath.includes("administradores")
    ) {
      return true;
    }
  };
  const validateCoursesUrl = (isActive) => {
    if (isActive || UrlPath.match("/admin/cursos")) {
      return true;
    }
  };
  const validateAdmissionsUrl = (isActive) => {
    if (isActive || UrlPath.includes("admisiones")) return true;
  };
  const validateOrdersUrl = (isActive) => {
    const pattern = /^\/admin\/ordenes(?:\/\d+)?$/;

    if (isActive && pattern.test(UrlPath)) {
      return true;
    }
  };
  const validateOrdersBGPUrl = (isActive) => {
    if (isActive || UrlPath.match("/admin/ordenes/bgp")) {
      return true;
    }
  };

  useEffect(() => {
    setShowSidebar(false);
  }, [location]);

  return (
    <div className="flex flex-row h-screen">
      {/* SIDEBAR LG+ */}
      <div
        className={`hidden lg:block w-1/3 2xl:w-1/4 text-gray-700 h-full fixed z-40 shadow-md ease-in-out duration-300 overflow-auto ${
          showSidebar ? "-translate-x-0 " : "-translate-x-full"
        }`}
        style={{ backgroundColor: "#FCFBFC" }}
      >
        <div className="px-12 mt-10">
          <img src={logo} alt="BeGlobalPro" className="w-full" />
        </div>

        <div className="mt-20 text-2xl">
          {(currentAdminUser.type === "Admin" ||
            currentAdminUser.type === "SuperAdmin" ||
            currentAdminUser.type === "Profesor") && (
            <>
              <NavLink end to="/admin/cursos">
                {({ isActive }) => (
                  <div
                    className={`flex items-center font-bold py-3 px-10 border-l-4 ${
                      validateCoursesUrl(isActive)
                        ? "text-primary-200 border-primary-200"
                        : "border-gray-200"
                    }`}
                  >
                    <div className="flex w-full">
                      <div className="w-1/6">
                        <FontAwesomeIcon icon="fa-book-blank" size="sm" />
                      </div>
                      <div className="w-full flex justify-between">
                        <p>Cursos</p>
                        <button
                          onClick={(e) => {
                            setShowCoursesMenu(!showCoursesMenu);
                            e.preventDefault();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={
                              showCoursesMenu
                                ? "fa-chevron-down"
                                : "fa-chevron-right"
                            }
                            size="sm"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </NavLink>
              {showCoursesMenu && (
                <div>
                  <NavLink end to="/admin/cursos/preguntas">
                    {({ isActive }) => (
                      <div
                        className={`flex items-center py-2 pl-24 pr-10 ${
                          isActive ? "text-primary-200" : null
                        }`}
                      >
                        <div className="w-full">
                          <p>Preguntas</p>
                        </div>
                      </div>
                    )}
                  </NavLink>
                  <NavLink end to="/admin/cursos/comentarios">
                    {({ isActive }) => (
                      <div
                        className={`flex items-center py-2 pl-24 pr-10 ${
                          isActive ? "text-primary-200" : null
                        }`}
                      >
                        <div className="w-full">
                          <p>Comentarios</p>
                        </div>
                      </div>
                    )}
                  </NavLink>
                </div>
              )}

              <NavLink end to="/admin/calendario">
                {({ isActive }) => (
                  <div
                    className={`flex items-center font-bold py-3 px-10 border-l-4 ${
                      isActive
                        ? "text-primary-200 border-primary-200"
                        : "border-gray-200"
                    }`}
                  >
                    <div className="flex w-full">
                      <div className="w-1/6">
                        <FontAwesomeIcon icon={["far", "calendar"]} size="sm" />
                      </div>
                      <div className="w-full">
                        <p className="leading-none">Calendario</p>
                      </div>
                    </div>
                  </div>
                )}
              </NavLink>
            </>
          )}

          {currentAdminUser.type === "Admin" ||
          currentAdminUser.type === "SuperAdmin" ? (
            <>
              <NavLink end to="/admin/noticias">
                {({ isActive }) => (
                  <div
                    className={`flex items-center font-bold py-3 px-10 border-l-4 ${
                      isActive
                        ? "text-primary-200 border-primary-200"
                        : "border-gray-200"
                    }`}
                  >
                    <div className="flex w-full">
                      <div className="w-1/6">
                        <FontAwesomeIcon icon="fa-bullhorn" size="sm" />
                      </div>
                      <div className="w-full">
                        <p>Noticias</p>
                      </div>
                    </div>
                  </div>
                )}
              </NavLink>
            </>
          ) : (
            ""
          )}

          {(currentAdminUser.type === "Admin" ||
            currentAdminUser.type === "SuperAdmin" ||
            currentAdminUser.type === "Proveedor") && (
            <>
              <NavLink to="/admin/productos">
                {({ isActive }) => (
                  <div
                    className={`flex items-center font-bold py-3 px-10 border-l-4 ${
                      isActive
                        ? "text-primary-200 border-primary-200"
                        : "border-gray-200"
                    }`}
                  >
                    <div className="flex w-full">
                      <div className="w-1/6">
                        <FontAwesomeIcon icon="fa-store" size="sm" />
                      </div>
                      <div className="w-full">
                        <p>Productos</p>
                      </div>
                    </div>
                  </div>
                )}
              </NavLink>
              <NavLink to="/admin/locacion">
                {({ isActive }) => (
                  <div
                    className={`flex items-center font-bold py-3 px-10 border-l-4 ${
                      isActive
                        ? "text-primary-200 border-primary-200"
                        : "border-gray-200"
                    }`}
                  >
                    <div className="flex w-full">
                      <div className="w-1/6">
                        <FontAwesomeIcon
                          icon={["far", "location-dot"]}
                          size="lg"
                        />
                      </div>
                      <div className="w-full">
                        <p>Locación</p>
                      </div>
                    </div>
                  </div>
                )}
              </NavLink>
              <NavLink to="/admin/envios">
                {({ isActive }) => (
                  <div
                    className={`flex items-center font-bold py-3 px-10 border-l-4 ${
                      isActive
                        ? "text-primary-200 border-primary-200"
                        : "border-gray-200"
                    }`}
                  >
                    <div className="flex w-full">
                      <div className="w-1/6">
                        <FontAwesomeIcon
                          icon={["far", "box-open"]}
                          className={"text-2xl"}
                        />
                      </div>
                      <div className="w-full">
                        <p>Envíos</p>
                      </div>
                    </div>
                  </div>
                )}
              </NavLink>
              <NavLink to="/admin/ordenes">
                {({ isActive }) => (
                  <div
                    className={`flex items-center font-bold py-3 px-10 border-l-4 ${
                      validateOrdersUrl(isActive)
                        ? "text-primary-200 border-primary-200"
                        : "border-gray-200"
                    }`}
                  >
                    <div className="flex w-full">
                      <div className="w-1/6">
                        <FontAwesomeIcon
                          icon={["far", "clipboard-list-check"]}
                          size="lg"
                        />
                      </div>
                      <div className="w-full">
                        <p>Ordenes Productos</p>
                      </div>
                    </div>
                  </div>
                )}
              </NavLink>
            </>
          )}

          {currentAdminUser.type === "Revisor" && <ReviewerSidebarLayout />}
          {currentAdminUser.type === "Ventas" && <SellsSidebarLayout />}

          {currentAdminUser.type === "Admin" ||
          currentAdminUser.type === "SuperAdmin" ? (
            <>
              <NavLink to="/admin/ordenes/bgp">
                {({ isActive }) => (
                  <div
                    className={`flex items-center font-bold py-3 px-10 border-l-4 ${
                      validateOrdersBGPUrl(isActive)
                        ? "text-primary-200 border-primary-200"
                        : "border-gray-200"
                    }`}
                  >
                    <div className="flex w-full">
                      <div className="w-1/6">
                        <FontAwesomeIcon
                          icon={["far", "file-invoice"]}
                          size="lg"
                        />
                      </div>
                      <div className="w-full">
                        <p>Ordenes BeGlobalPro</p>
                      </div>
                    </div>
                  </div>
                )}
              </NavLink>
              <NavLink end to="/admin/banners">
                {({ isActive }) => (
                  <div
                    className={`flex items-center font-bold py-3 px-10 border-l-4 ${
                      isActive
                        ? "text-primary-200 border-primary-200"
                        : "border-gray-200"
                    }`}
                  >
                    <div className="flex w-full">
                      <div className="w-1/6">
                        <FontAwesomeIcon icon={["far", "ad"]} size="sm" />
                      </div>
                      <div className="w-full">
                        <p className="leading-none">Banners</p>
                      </div>
                    </div>
                  </div>
                )}
              </NavLink>
              <NavLink to="/admin/cupones">
                {({ isActive }) => (
                  <div
                    className={`flex items-center font-bold py-3 px-10 border-l-4 ${
                      isActive
                        ? "text-primary-200 border-primary-200"
                        : "border-gray-200"
                    }`}
                  >
                    <div className="flex w-full">
                      <div className="w-1/6">
                        <FontAwesomeIcon icon={["far", "ticket"]} size="sm" />
                      </div>
                      <div className="w-full">
                        <p className="leading-none">Cupones</p>
                      </div>
                    </div>
                  </div>
                )}
              </NavLink>
              <NavLink end to="/admin/admisiones">
                {({ isActive }) => (
                  <div
                    className={`flex items-center font-bold py-3 px-10 border-l-4 ${
                      validateAdmissionsUrl(isActive)
                        ? "text-primary-200 border-primary-200"
                        : "border-gray-200"
                    }`}
                  >
                    <div className="flex w-full">
                      <div className="w-1/6">
                        <FontAwesomeIcon icon={["far", "memo-pad"]} size="sm" />
                      </div>
                      <div className="w-full">
                        <p className="leading-none">Solicitudes de admisión</p>
                      </div>
                    </div>
                  </div>
                )}
              </NavLink>
              <NavLink end to="/admin/usuarios/administradores">
                {({ isActive }) => (
                  <div
                    className={`flex items-center font-bold py-3 px-10 border-l-4 ${
                      validateAdminUsers(isActive)
                        ? "text-primary-200 border-primary-200"
                        : "border-gray-200"
                    }`}
                  >
                    <div className="flex w-full">
                      <div className="w-1/6">
                        <FontAwesomeIcon icon="fa-user-group" size="sm" />
                      </div>
                      <div className="w-full">
                        <p className="leading-none">Usuarios administradores</p>
                      </div>
                    </div>
                  </div>
                )}
              </NavLink>
              <NavLink to="/admin/usuarios/miembros">
                {({ isActive }) => (
                  <div
                    className={`flex items-center font-bold py-3 px-10 border-l-4 ${
                      isActive
                        ? "text-primary-200 border-primary-200"
                        : "border-gray-200"
                    }`}
                  >
                    <div className="flex w-full">
                      <div className="w-1/6">
                        <FontAwesomeIcon icon="fa-users" size="sm" />
                      </div>
                      <div className="w-full flex justify-between">
                        <p>Ventas de usuarios</p>
                        <button
                          onClick={(e) => {
                            setShowUsersSalesMenu(!showUsersSalesMenu);
                            e.preventDefault();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={
                              showUsersSalesMenu
                                ? "fa-chevron-down"
                                : "fa-chevron-right"
                            }
                            size="sm"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </NavLink>
            </>
          ) : (
            ""
          )}

          {showUsersSalesMenu && (
            <div>
              <NavLink end to="/admin/usuarios/miembros/top-ventas">
                {({ isActive }) => (
                  <div
                    className={`flex items-center py-2 pl-24 pr-10 ${
                      isActive ? "text-primary-200" : null
                    }`}
                  >
                    <div className="w-full">
                      <p>Top ventas</p>
                    </div>
                  </div>
                )}
              </NavLink>
              <NavLink end to="/admin/usuarios/miembros">
                {({ isActive }) => (
                  <div
                    className={`flex items-center py-2 pl-24 pr-10 ${
                      validateMembershipsUrl(isActive)
                        ? "text-primary-200"
                        : null
                    }`}
                  >
                    <div className="w-full">
                      <p>Ventas de membresias</p>
                    </div>
                  </div>
                )}
              </NavLink>
              <NavLink end to="/admin/usuarios/miembros/productos">
                {({ isActive }) => (
                  <div
                    className={`flex items-center py-2 pl-24 pr-10 ${
                      validateProductsUrl(isActive) ? "text-primary-200" : null
                    }`}
                  >
                    <div className="w-full">
                      <p>Ventas de productos</p>
                    </div>
                  </div>
                )}
              </NavLink>
            </div>
          )}

          {currentAdminUser.type === "Admin" ||
          currentAdminUser.type === "SuperAdmin" ? (
            <>
              <NavLink to="/admin/contabilidad">
                {({ isActive }) => (
                  <div
                    className={`flex items-center font-bold py-3 px-10 border-l-4 ${
                      isActive
                        ? "text-primary-200 border-primary-200"
                        : "border-gray-200"
                    }`}
                  >
                    <div className="flex w-full">
                      <div className="w-1/6">
                        <FontAwesomeIcon
                          icon={["far", "money-bill-transfer"]}
                          size="sm"
                        />
                      </div>
                      <div className="w-full flex justify-between">
                        <p>Contabilidad</p>
                        <button
                          onClick={(e) => {
                            setShowSalesMenu(!showSalesMenu);
                            e.preventDefault();
                          }}
                        ></button>
                      </div>
                    </div>
                  </div>
                )}
              </NavLink>

              <NavLink end to="/admin/retiros">
                {({ isActive }) => (
                  <div
                    className={`flex items-center font-bold py-3 px-10 border-l-4 ${
                      isActive
                        ? "text-primary-200 border-primary-200"
                        : "border-gray-200"
                    }`}
                  >
                    <div className="flex w-full">
                      <div className="w-1/6">
                        <FontAwesomeIcon
                          icon={["far", "money-check-pen"]}
                          size="sm"
                        />
                      </div>
                      <div className="w-full">
                        <p className="leading-none">Solicitudes de retiro</p>
                      </div>
                    </div>
                  </div>
                )}
              </NavLink>

              <NavLink end to="/admin/usuarios">
                {({ isActive }) => (
                  <div
                    className={`flex items-center font-bold py-3 px-10 border-l-4 ${
                      validateUsersUrl(isActive)
                        ? "text-primary-200 border-primary-200"
                        : "border-gray-200"
                    }`}
                  >
                    <div className="flex w-full">
                      <div className="w-1/6">
                        <FontAwesomeIcon icon="fa-user-group" size="sm" />
                      </div>
                      <div className="w-full">
                        <p className="leading-none">Miembros</p>
                      </div>
                    </div>
                  </div>
                )}
              </NavLink>
            </>
          ) : (
            ""
          )}
          {currentAdminUser.type === "SuperAdmin" ? (
            <>
              <NavLink to="/admin/tiendas">
                {({ isActive }) => (
                  <div
                    className={`flex items-center font-bold py-3 px-10 border-l-4 ${
                      isActive
                        ? "text-primary-200 border-primary-200"
                        : "border-gray-200"
                    }`}
                  >
                    <div className="flex w-full">
                      <div className="w-1/6">
                        <FontAwesomeIcon icon={["fab", "shopify"]} size="sm" />
                      </div>
                      <div className="w-full">
                        <p>Tiendas Shopify</p>
                      </div>
                    </div>
                  </div>
                )}
              </NavLink>
            </>
          ) : (
            ""
          )}
          <a
            href="https://blog.beglobalpro.org/admin/login"
            target="_blank"
            className={`flex items-center font-bold py-3 px-10 border-l-4 border-gray-200`}
          >
            <div className="flex w-full">
              <div className="w-1/6">
                <FontAwesomeIcon icon={["far", "memo-pad"]} size="sm" />
              </div>
              <div className="w-full">
                <p className="leading-none">Blog</p>
              </div>
            </div>
          </a>
        </div>
      </div>

      {/* MOBILE SIDEBAR */}
      {/* {showSidebar && ( */}
      <>
        <div
          className={`block lg:hidden w-5/6 md:w-1/2 text-gray-700 h-full fixed z-40 shadow-md ease-in-out duration-300 overflow-auto ${
            showSidebar ? "-translate-x-0 " : "-translate-x-full"
          }`}
          style={{ backgroundColor: "#FCFBFC" }}
        >
          <div className="px-10 mt-5">
            <img src={logo} alt="BeGlobalPro" className="w-full" />
          </div>

          <div className="mt-20 text-xl">
            {(currentAdminUser.type === "Admin" ||
              currentAdminUser.type === "SuperAdmin" ||
              currentAdminUser.type === "Profesor") && (
              <>
                <NavLink end to="/admin/cursos">
                  {({ isActive }) => (
                    <div
                      className={`flex items-center font-bold py-3 px-5 border-l-4 ${
                        validateCoursesUrl(isActive)
                          ? "text-primary-200 border-primary-200"
                          : "border-gray-200"
                      }`}
                    >
                      <div className="flex w-full">
                        <div className="w-1/6">
                          <FontAwesomeIcon icon="fa-book-blank" size="sm" />
                        </div>
                        <div className="w-full flex justify-between">
                          <p>Cursos</p>
                          <button
                            onClick={(e) => {
                              setShowCoursesMenu(!showCoursesMenu);
                              e.preventDefault();
                            }}
                          >
                            <FontAwesomeIcon
                              icon={
                                showCoursesMenu
                                  ? "fa-chevron-down"
                                  : "fa-chevron-right"
                              }
                              size="sm"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </NavLink>

                {showCoursesMenu && (
                  <div>
                    <NavLink end to="/admin/cursos/preguntas">
                      {({ isActive }) => (
                        <div
                          className={`flex items-center py-2 pl-16 pr-5 ${
                            isActive ? "text-primary-200" : null
                          }`}
                        >
                          <div className="w-full">
                            <p>Preguntas</p>
                          </div>
                        </div>
                      )}
                    </NavLink>
                    <NavLink end to="/admin/cursos/comentarios">
                      {({ isActive }) => (
                        <div
                          className={`flex items-center py-2 pl-16 pr-5 ${
                            isActive ? "text-primary-200" : null
                          }`}
                        >
                          <div className="w-full">
                            <p>Comentarios</p>
                          </div>
                        </div>
                      )}
                    </NavLink>
                  </div>
                )}

                <NavLink end to="/admin/calendario">
                  {({ isActive }) => (
                    <div
                      className={`flex items-center font-bold py-3 px-5 border-l-4 ${
                        isActive
                          ? "text-primary-200 border-primary-200"
                          : "border-gray-200"
                      }`}
                    >
                      <div className="flex w-full">
                        <div className="w-1/6">
                          <FontAwesomeIcon
                            icon={["far", "calendar"]}
                            size="sm"
                          />
                        </div>
                        <div className="w-full">
                          <p className="leading-none">Calendario</p>
                        </div>
                      </div>
                    </div>
                  )}
                </NavLink>
              </>
            )}

            {currentAdminUser.type === "Admin" ||
            currentAdminUser.type === "SuperAdmin" ? (
              <>
                <NavLink end to="/admin/noticias">
                  {({ isActive }) => (
                    <div
                      className={`flex items-center font-bold py-3 px-5 border-l-4 ${
                        isActive
                          ? "text-primary-200 border-primary-200"
                          : "border-gray-200"
                      }`}
                    >
                      <div className="flex w-full">
                        <div className="w-1/6">
                          <FontAwesomeIcon icon="fa-bullhorn" size="sm" />
                        </div>
                        <div className="w-full">
                          <p>Noticias</p>
                        </div>
                      </div>
                    </div>
                  )}
                </NavLink>
              </>
            ) : (
              ""
            )}

            {(currentAdminUser.type === "Admin" ||
              currentAdminUser.type === "SuperAdmin" ||
              currentAdminUser.type === "Proveedor") && (
              <>
                <NavLink to="/admin/productos">
                  {({ isActive }) => (
                    <div
                      className={`flex items-center font-bold py-3 px-5 border-l-4 ${
                        isActive
                          ? "text-primary-200 border-primary-200"
                          : "border-gray-200"
                      }`}
                    >
                      <div className="flex w-full">
                        <div className="w-1/6">
                          <FontAwesomeIcon icon="fa-store" size="sm" />
                        </div>
                        <div className="w-full">
                          <p>Productos</p>
                        </div>
                      </div>
                    </div>
                  )}
                </NavLink>
                <NavLink to="/admin/locacion">
                  {({ isActive }) => (
                    <div
                      className={`flex items-center font-bold py-3 px-5 border-l-4 ${
                        isActive
                          ? "text-primary-200 border-primary-200"
                          : "border-gray-200"
                      }`}
                    >
                      <div className="flex w-full">
                        <div className="w-1/6">
                          <FontAwesomeIcon
                            icon={["far", "location-dot"]}
                            size="lg"
                          />
                        </div>
                        <div className="w-full">
                          <p>Locación</p>
                        </div>
                      </div>
                    </div>
                  )}
                </NavLink>
                <NavLink to="/admin/envios">
                  {({ isActive }) => (
                    <div
                      className={`flex items-center font-bold py-3 px-5 border-l-4 ${
                        isActive
                          ? "text-primary-200 border-primary-200"
                          : "border-gray-200"
                      }`}
                    >
                      <div className="flex w-full">
                        <div className="w-1/6">
                          <FontAwesomeIcon
                            icon={["far", "box-open"]}
                            className={"text-2xl"}
                          />
                        </div>
                        <div className="w-full">
                          <p>Envíos</p>
                        </div>
                      </div>
                    </div>
                  )}
                </NavLink>
                <NavLink to="/admin/ordenes">
                  {({ isActive }) => (
                    <div
                      className={`flex items-center font-bold py-3 px-5 border-l-4 ${
                        validateOrdersUrl(isActive)
                          ? "text-primary-200 border-primary-200"
                          : "border-gray-200"
                      }`}
                    >
                      <div className="flex w-full">
                        <div className="w-1/6">
                          <FontAwesomeIcon
                            icon={["far", "clipboard-list-check"]}
                            size="lg"
                          />
                        </div>
                        <div className="w-full">
                          <p>Ordenes Productos</p>
                        </div>
                      </div>
                    </div>
                  )}
                </NavLink>
              </>
            )}

            {currentAdminUser.type === "Revisor" && <ReviewerSidebarLayout />}
            {currentAdminUser.type === "Ventas" && <SellsSidebarLayout />}

            {currentAdminUser.type === "Admin" ||
            currentAdminUser.type === "SuperAdmin" ? (
              <>
                <NavLink to="/admin/ordenes/bgp">
                  {({ isActive }) => (
                    <div
                      className={`flex items-center font-bold py-3 px-5 border-l-4 ${
                        validateOrdersBGPUrl(isActive)
                          ? "text-primary-200 border-primary-200"
                          : "border-gray-200"
                      }`}
                    >
                      <div className="flex w-full">
                        <div className="w-1/6">
                          <FontAwesomeIcon
                            icon={["far", "file-invoice"]}
                            size="lg"
                          />
                        </div>
                        <div className="w-full">
                          <p>Ordenes BeGlobalPro</p>
                        </div>
                      </div>
                    </div>
                  )}
                </NavLink>
                <NavLink end to="/admin/banners">
                  {({ isActive }) => (
                    <div
                      className={`flex items-center font-bold py-3 px-5 border-l-4 ${
                        isActive
                          ? "text-primary-200 border-primary-200"
                          : "border-gray-200"
                      }`}
                    >
                      <div className="flex w-full">
                        <div className="w-1/6">
                          <FontAwesomeIcon icon={["far", "ad"]} size="sm" />
                        </div>
                        <div className="w-full">
                          <p className="leading-none">Banners</p>
                        </div>
                      </div>
                    </div>
                  )}
                </NavLink>
                <NavLink to="/admin/cupones">
                  {({ isActive }) => (
                    <div
                      className={`flex items-center font-bold py-3 px-5 border-l-4 ${
                        isActive
                          ? "text-primary-200 border-primary-200"
                          : "border-gray-200"
                      }`}
                    >
                      <div className="flex w-full">
                        <div className="w-1/6">
                          <FontAwesomeIcon icon={["far", "ticket"]} size="sm" />
                        </div>
                        <div className="w-full">
                          <p className="leading-none">Cupones</p>
                        </div>
                      </div>
                    </div>
                  )}
                </NavLink>
                <NavLink end to="/admin/admisiones">
                  {({ isActive }) => (
                    <div
                      className={`flex items-center font-bold py-3 px-5 border-l-4 ${
                        validateAdmissionsUrl(isActive)
                          ? "text-primary-200 border-primary-200"
                          : "border-gray-200"
                      }`}
                    >
                      <div className="flex w-full">
                        <div className="w-1/6">
                          <FontAwesomeIcon
                            icon={["far", "memo-pad"]}
                            size="sm"
                          />
                        </div>
                        <div className="w-full">
                          <p className="leading-none">
                            Solicitudes de admisión
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </NavLink>
                <NavLink to="/admin/usuarios/administradores">
                  {({ isActive }) => (
                    <div
                      className={`flex items-center font-bold py-3 px-5 border-l-4 ${
                        isActive
                          ? "text-primary-200 border-primary-200"
                          : "border-gray-200"
                      }`}
                    >
                      <div className="flex w-full">
                        <div className="w-1/6">
                          <FontAwesomeIcon icon="fa-user-group" size="sm" />
                        </div>
                        <div className="w-full">
                          <p className="leading-none">
                            Usuarios administradores
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </NavLink>
                <NavLink to="/admin/usuarios/miembros">
                  {({ isActive }) => (
                    <div
                      className={`flex items-center font-bold py-3 px-5 border-l-4 ${
                        isActive
                          ? "text-primary-200 border-primary-200"
                          : "border-gray-200"
                      }`}
                    >
                      <div className="flex w-full">
                        <div className="w-1/6">
                          <FontAwesomeIcon icon="fa-users" size="sm" />
                        </div>
                        <div className="w-full flex justify-between">
                          <p>Ventas de usuarios</p>
                          <button
                            onClick={(e) => {
                              setShowUsersSalesMenu(!showUsersSalesMenu);
                              e.preventDefault();
                            }}
                          >
                            <FontAwesomeIcon
                              icon={
                                showUsersSalesMenu
                                  ? "fa-chevron-down"
                                  : "fa-chevron-right"
                              }
                              size="sm"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </NavLink>
              </>
            ) : (
              ""
            )}

            {showUsersSalesMenu && (
              <div>
                <NavLink end to="/admin/usuarios/miembros/top-ventas">
                  {({ isActive }) => (
                    <div
                      className={`flex items-center py-2 pl-16 pr-5 ${
                        isActive ? "text-primary-200" : null
                      }`}
                    >
                      <div className="w-full">
                        <p>Top ventas</p>
                      </div>
                    </div>
                  )}
                </NavLink>
                <NavLink end to="/admin/usuarios/miembros">
                  {({ isActive }) => (
                    <div
                      className={`flex items-center py-2 pl-16 pr-5 ${
                        validateMembershipsUrl(isActive)
                          ? "text-primary-200"
                          : null
                      }`}
                    >
                      <div className="w-full">
                        <p>Ventas de membresias</p>
                      </div>
                    </div>
                  )}
                </NavLink>
                <NavLink end to="/admin/usuarios/miembros/productos">
                  {({ isActive }) => (
                    <div
                      className={`flex items-center py-2 pl-16 pr-5 ${
                        validateProductsUrl(isActive)
                          ? "text-primary-200"
                          : null
                      }`}
                    >
                      <div className="w-full">
                        <p>Ventas de productos</p>
                      </div>
                    </div>
                  )}
                </NavLink>
              </div>
            )}

            {currentAdminUser.type === "Admin" ||
            currentAdminUser.type === "SuperAdmin" ? (
              <>
                <NavLink end to="/admin/contabilidad">
                  {({ isActive }) => (
                    <div
                      className={`flex items-center font-bold py-3 px-5 border-l-4 ${
                        isActive
                          ? "text-primary-200 border-primary-200"
                          : "border-gray-200"
                      }`}
                    >
                      <div className="flex w-full">
                        <div className="w-1/6">
                          <FontAwesomeIcon
                            icon={["far", "money-bill-transfer"]}
                            size="sm"
                          />
                        </div>
                        <div className="w-full">
                          <p className="leading-none">Contabilidad</p>
                        </div>
                      </div>
                    </div>
                  )}
                </NavLink>

                <NavLink end to="/admin/retiros">
                  {({ isActive }) => (
                    <div
                      className={`flex items-center font-bold py-3 px-5 border-l-4 ${
                        isActive
                          ? "text-primary-200 border-primary-200"
                          : "border-gray-200"
                      }`}
                    >
                      <div className="flex w-full">
                        <div className="w-1/6">
                          <FontAwesomeIcon
                            icon={["far", "money-check-pen"]}
                            size="sm"
                          />
                        </div>
                        <div className="w-full">
                          <p className="leading-none">Solicitudes de retiro</p>
                        </div>
                      </div>
                    </div>
                  )}
                </NavLink>
                <NavLink end to="/admin/usuarios">
                  {({ isActive }) => (
                    <div
                      className={`flex items-center font-bold py-3 px-5 border-l-4 ${
                        validateUsersUrl(isActive)
                          ? "text-primary-200 border-primary-200"
                          : "border-gray-200"
                      }`}
                    >
                      <div className="flex w-full">
                        <div className="w-1/6">
                          <FontAwesomeIcon icon="fa-user-group" size="sm" />
                        </div>
                        <div className="w-full">
                          <p className="leading-none">Miembros</p>
                        </div>
                      </div>
                    </div>
                  )}
                </NavLink>
              </>
            ) : (
              ""
            )}
            {currentAdminUser.type === "SuperAdmin" ? (
              <>
                <NavLink to="/admin/tiendas">
                  {({ isActive }) => (
                    <div
                      className={`flex items-center font-bold py-3 px-5 border-l-4 ${
                        isActive
                          ? "text-primary-200 border-primary-200"
                          : "border-gray-200"
                      }`}
                    >
                      <div className="flex w-full">
                        <div className="w-1/6">
                          <FontAwesomeIcon
                            icon={["fab", "shopify"]}
                            size="sm"
                          />
                        </div>
                        <div className="w-full">
                          <p>Tiendas Shopify</p>
                        </div>
                      </div>
                    </div>
                  )}
                </NavLink>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          role="button"
          className={`fixed z-10 inset-0 bg-black bg-opacity-25 ease-in-out duration-150 ${
            showSidebar
              ? "opacity-100 -translate-x-0"
              : "opacity-0 -translate-x-full"
          }`}
          onClick={() => setShowSidebar(false)}
        />
      </>

      {/* )} */}

      {/* CONTENT */}
      <div className="w-full bg-white">
        <div className="flex justify-between items-center border-b border-gray-300 py-5 px-9">
          <svg
            onClick={() => setShowSidebar(!showSidebar)}
            className="flex items-center cursor-pointer ml-5"
            fill="#9B9B9B"
            viewBox="0 0 100 80"
            width="30"
            height="30"
          >
            <rect width="100" height="10"></rect>
            <rect y="30" width="100" height="10"></rect>
            <rect y="60" width="100" height="10"></rect>
          </svg>
          <div className="flex items-center">
            <FontAwesomeIcon
              icon="fa-bell"
              size="xl"
              className="text-gray-600 mr-5"
            />
            <AdminDropdown />
          </div>
        </div>
        <div className="px-10 py-20 text-2xl">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
