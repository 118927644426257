/* eslint-disable import/no-anonymous-default-export */
import appConfig from "../app.config.js";

export default {
  createNamespaceKey(key) {
    return `_${appConfig.localStorage.namespace}_:${key}`;
  },

  getItem: (key) => localStorage.getItem(key),

  setItem: (key, value) => localStorage.setItem(key, value),

  removeItem: (key) => localStorage.removeItem(key),
};
