import { api } from "../../client";

export const eventServices = {
  getGuestEvents() {
    return api.base.get("guest/events");
  },
  getUserEvents() {
    return api.base.get("user/events");
  },
  getEvents() {
    return api.base.get("/event");
  },
  SetEvent(model) {
    return api.base.post("/event",model);
  },
  setEventGuest(model) {
    return api.base.post("/event/guest", model);;
  },
};
