/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as _ from "lodash";
import Context from "../../../context/AdminUserContext";
import { notifications } from "../../../utils/notifications";
import { adminShippingsServices } from "../../../services/api/admin/shippings";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

registerLocale("es", es);

const EditShipping = () => {
const MySwal = withReactContent(Swal);
  const formSchema = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
    days: Yup.number().required("Campo requerido").integer('Solo se permiten números enteros').max(15,"El tiempo de entrega no puede ser mayor a 15 dias"),
    cost: Yup.number().required("Campo requerido"),
  });

  const [shipping, setShipping] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    cost: "",
    days: "",
  });

  const { currentAdminUser } = useContext(Context);

  const params = useParams();
  const navigate = useNavigate();

  const fetchShipping = () => {
     adminShippingsServices
      .getShipping(params.id)
      .then((response) => {
        setShipping(response.data.shipping)
      })
      .catch((err) => { });
  };


  useEffect(() => {
    if (!currentAdminUser) return;
    fetchShipping();
  }, [currentAdminUser]);

  useEffect(() => {
    if (!_.isEmpty(shipping)) {
      setInitialValues({
        name: shipping.name || "",
        days: shipping.days || "",
        cost: shipping.cost || 0,
      });

    
    }
  }, [shipping]);

  const handleSubmit = async (values) => {
    if (!values.days) values.days = null;
    if (!values.cost) values.cost = null;
    if (!values.name) values.name = null;


    setIsLoading(true);

    await adminShippingsServices
      .updateShipping(params.id, values)
      .then((response) => {
        notifications.success(response.data.message);
        navigate("/admin/envios");
      })
      .catch((err) => { })
      .finally(() => setIsLoading(false));
  };

  const handleDelete = async () => {
    MySwal.fire({
      icon: "error",
      title: "¿Deseas eliminar este envio?",
      text: "Se eliminará todo el contenido asociado. Esta acción es irreversible",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#42a819",
      confirmButtonText: "Eliminar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
          await adminShippingsServices
          .deleteShipping(params.id)
          .then((response) => {
            notifications.success(response.data.message);
            navigate("/admin/envios");
          })
          .catch((err) => { }).finally();
      }
    });
  };

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <p className="text-4xl font-bold">Editar envio</p>
      </div>

      <div className="mt-12">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="mb-9 text-3xl text-gray-550">
                <p>Nombre</p>
                <Field
                  name="name"
                  className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>

              <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-3xl text-gray-550">


                  <p>Costo</p>
                  <Field
                    
                    name="cost"
                    type="number"
                    min="1"
                    step="0.01"
                    className={`${initialValues.disableOptions ? "bg-gray-300 cursor-not-allowed" : "bg-white text-gray-700"} w-full  placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none`}

                  />
                  <ErrorMessage
                    name="cost"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-9 text-2xl text-gray-550">
                  <p className="mb-1">Tiempo de entrega (Días)</p>
                  <Field
                    
                    name="days"
                    type="number"
                    min="1"
                    step="0.01"
                    className={`${initialValues.disableOptions ? "bg-gray-300 cursor-not-allowed" : "bg-white text-gray-700"} w-full  placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none`}

                  />
                  <ErrorMessage
                    name="days"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>







              <div className="flex flex-col md:flex-row justify-between w-full gap-5 mt-10 lg:mt-20">
                <button
                  type="button"
                  className="w-full md:w-1/3 lg:w-1/4 bg-tertiary text-white font-bold text-2xl py-3 disabled:opacity-75 order-2 md:order-1"
                  disabled={isLoading}
                  onClick={() => handleDelete()}
                >
                  {isLoading ? (
                    <FontAwesomeIcon icon="circle-notch" spin />
                  ) : (
                    <span>Eliminar</span>
                  )}
                </button>
                <button
                  type="submit"
                  className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75 order-1 md:order-2"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <FontAwesomeIcon icon="circle-notch" spin />
                  ) : (
                    <span>Guardar</span>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditShipping;
