import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notifications } from "../../utils/notifications";
import { adminCoursesServices } from "../../services/api/admin/courses";

const CourseCard = ({ course }) => {
  const navigate = useNavigate();
  const [visibility, setVisibility] = useState(course.visibility);

  const changeVisibility = async (_visibility) => {
    await adminCoursesServices
      .courseVisibility({ uuid: course.uuid, visibility: _visibility })
      .then((response) => {
        notifications.success(response.data.message);
        setVisibility(_visibility);
      })
      .catch((err) => {});
  };

  const handleVisibility = () => {
    changeVisibility(visibility === "Publico" ? "Privado" : "Publico");
  };

  return (
    <>
      <div className="text-xl lg:text-2xl flex flex-col md:flex-row justify-between px-8 py-5 border-b border-b-gray-350 w-full">
        <div className="w-full sm:w-9/12">
          <p>{course.name}</p>
        </div>

        <div className="flex justify-between items-center md:flex-col text-[#AEAEAE] pt-3 md:pt-0">
          <div className="hidden md:block ">
            <button
              onClick={() => navigate(`/admin/cursos/${course.uuid}/alumnos`)}
            >
              <FontAwesomeIcon
                icon="fa-user-group"
                size="sm"
                className="mr-5"
              />
            </button>

            <button onClick={() => handleVisibility()}>
              <FontAwesomeIcon
                icon={["far", visibility === "Publico" ? "eye" : "eye-slash"]}
                className={`mr-5 ${
                  visibility === "Publico"
                    ? "text-primary-200"
                    : "text-[#D8D8D8]"
                }`}
                size="sm"
              />
            </button>
            <button
              onClick={() => navigate(`/admin/cursos/editar/${course.uuid}`)}
            >
              <FontAwesomeIcon
                icon={["far", "pencil"]}
                className="text-primary-200"
                size="sm"
              />
            </button>
          </div>
          <div className="w-full flex md:hidden flex-row justify-end space-x-8">
            <button
              onClick={() => navigate(`/admin/cursos/${course.uuid}/alumnos`)}
            >
              <FontAwesomeIcon icon="fa-user-group" size="md" />
            </button>
            <button onClick={() => handleVisibility()}>
              <FontAwesomeIcon
                icon={["far", visibility === "Publico" ? "eye" : "eye-slash"]}
                className={`${
                  visibility === "Publico"
                    ? "text-primary-200"
                    : "text-[#D8D8D8]"
                }`}
                size="md"
              />
            </button>
            <button
              onClick={() => navigate(`/admin/cursos/editar/${course.uuid}`)}
            >
              <FontAwesomeIcon
                icon={["far", "pencil"]}
                className={`${true ? "text-primary-200" : "text-[#D8D8D8]"}`}
                size="md"
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseCard;
