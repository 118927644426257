import { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import * as _ from "lodash";

import Context from "../../../context/AdminUserContext";

import TableLoading from "../../../components/TableLoading";
import TableNoData from "../../../components/TableNoData";

import { adminUsersService } from "../../../services/api/admin/users";
import {
  getColumnsForTopUsersMemberships,
  customStyles,
} from "../../../utils/tableOptions";

const SalesTop = () => {
  const navigate = useNavigate();
  const { currentAdminUser } = useContext(Context);
  const firstRender = useRef(true);

  const [topUsers, setTopUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formattedTopUsersData, setFormattedTopUsersData] = useState([]);

  const fetchTopUsers = () => {
    adminUsersService
      .getUserTop()
      .then((response) => {
        setTopUsers(response.data.users);
      })
      .catch((err) => {});
  };

  const formatTopUserData = () => {
    let data = [];

    topUsers.forEach((user, idx) => {
      data.push({
        placeComponent: (
          <div>
            <span className="font-bold text-primary-200 text-lg md:text-xl">
              {idx + 1 === 1 ? (
                <FontAwesomeIcon
                  icon="fa-medal"
                  size="1x"
                  className="text-secondary"
                />
              ) : (
                `#${idx + 1}`
              )}
            </span>
          </div>
        ),
        photoComponent: (
          <div className="w-10 h-10 mx-2">
            <img
              src={user.image}
              alt=""
              className="rounded-full w-full h-full object-cover"
              style={{ backgroundColor: "#D8D8D8" }}
            />
          </div>
        ),
        name: user.name,
        memberships_total: user.memberships_total,
        memberships_count: user.memberships_count,
        actionComponent: (
          <div className="text-primary-200 hover:text-primary-300 text-lg leading-none">
            <button
              onClick={() =>
                navigate(
                  `/admin/usuarios/miembros/${user.id}/ventas-membresias`
                )
              }
            >
              Más Detalles
            </button>
          </div>
        ),
      });
    });

    setFormattedTopUsersData(data);
    setLoading(false);
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchTopUsers();
  }, [currentAdminUser]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (_.isEmpty(topUsers)) {
      setLoading(false);
      return;
    }
    formatTopUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topUsers]);

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <p className="text-4xl font-bold">
          <FontAwesomeIcon icon="fa-ranking-star" size="sm" className="mr-2" />
          Top de Ventas
        </p>
      </div>

      <div className="border border-gray-350 mt-12">
        <DataTable
          columns={getColumnsForTopUsersMemberships}
          data={formattedTopUsersData}
          noDataComponent={<TableNoData />}
          progressPending={loading}
          progressComponent={<TableLoading />}
          customStyles={customStyles}
        />
      </div>
    </div>
  );
};

export default SalesTop;
