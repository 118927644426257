import { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as _ from "lodash";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import useComissionsFilters from "../../../hooks/UseCommissionsFilters";
import { adminUsersService } from "../../../services/api/admin/users";
import Context from "../../../context/AdminUserContext";
import { optionsBarWithCurrency } from "../../../utils/chartsOptions";
import UserFund from "../../../components/Sales/Memberships/UserFund";
import CommissionsDetails from "../../../components/Sales/Memberships/CommissionsDetails";
import CommissionsHistory from "../../../components/Sales/Memberships/CommissionsHistory";
import SalesAndCountsCommissions from "../../../components/Sales/Memberships/SalesAndCountsCommissions";
import MembershipsGridsSales from "../../../components/Sales/Memberships/MembershipsGridsSales";

registerLocale("es", es);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const MembershipsSales = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { currentAdminUser } = useContext(Context);
  const [member, setMember] = useState({});

  const {
    setCommissions,
    setCommissionsRange,
    setYearBar,
    setBreakdownWeekSelect,
    setDateRange,
    setBreakdownSelect,
    comissionsChart,
    salesTotalChart,
    soldCountChart,
    dataComissions,
    dataSalesCount,
    commissions,
    commissionsRange,
    yearBar,
    data,
    breakdownWeekSelect,
    dateRangesCombined,
    arrayYears,
    breakdownSelect,
    startDate,
    endDate,
  } = useComissionsFilters();

  const fetchMembershipsSalesData = () => {
    adminUsersService
      .getUser(params.id)
      .then((response) => {
        setMember(response.data.user);
        let commissionsData = response.data.commissions;
        setCommissions(commissionsData);
        setCommissionsRange(commissionsData);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchMembershipsSalesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAdminUser]);

  return (
    <div className="container mx-auto">
      <div>
        {member && member.name && <UserFund member={member} />}
        <Bar options={optionsBarWithCurrency} data={data} />
        {commissions.length > 0 && (
          <CommissionsDetails
            commissions={commissions}
            breakdownSelect={breakdownSelect}
            setBreakdownSelect={(option) => setBreakdownSelect(option)}
            breakdownWeekSelect={breakdownWeekSelect}
            setBreakdownWeekSelect={(option) => setBreakdownWeekSelect(option)}
            dateRangesCombined={dateRangesCombined}
            yearBar={yearBar}
            setYearBar={(year) => setYearBar(year)}
            arrayYears={arrayYears}
          />
        )}
        <MembershipsGridsSales salesTotalChart={salesTotalChart} />
        <SalesAndCountsCommissions
          comissionsChart={comissionsChart}
          dataComissions={dataComissions}
          soldCountChart={soldCountChart}
          dataSalesCount={dataSalesCount}
        />
        <CommissionsHistory
          commissions={commissionsRange}
          startDate={startDate}
          endDate={endDate}
          setDateRange={(date) => setDateRange(date)}
        />
        <div className="mt-10">
          <button
            className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75"
            onClick={() => navigate(-1)}
          >
            Regresar
          </button>
        </div>
      </div>
    </div>
  );
};

export default MembershipsSales;
