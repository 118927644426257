import { api } from "../../../client";

export const adminCoursesServices = {
  getCourses() {
    return api.admin.get("admin/course");
  },
  getStudentsByCourse(uuid) {
    return api.admin.get(`/admin/course/${uuid}/users`);
  },
  courseDetails(uuid) {
    return api.admin.get(`/admin/course/${uuid}`);
  },
  courseVisibility(model) {
    return api.admin.post("/admin/course/visibility", model);
  },
  uploadCourse(model) {
    return api.admin.post("/admin/course", model, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  updateCourse(uuid, model) {
    return api.admin.post(`/admin/course/${uuid}`, model, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  deleteCourse(uuid) {
    return api.admin.delete(`/admin/course/${uuid}`);
  },
  // RESOURCES
  resourceDetails(id) {
    return api.admin.get(`admin/course/resource/${id}`);
  },
  uploadResourse(model) {
    return api.admin.post("admin/course/resource", model, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  updateResourse(id, model) {
    return api.admin.post(`admin/course/resource/${id}`, model, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  deleteResourse(id) {
    return api.admin.delete(`/admin/course/resource/${id}`);
  },
  // SECTIONS
  sectionDetails(id) {
    return api.admin.get(`/admin/course/section/${id}`);
  },
  createSection(model) {
    return api.admin.post("/admin/course/section", model, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  updateSection(id, model) {
    return api.admin.post(`/admin/course/section/${id}`, model, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  deleteSection(id) {
    return api.admin.delete(`/admin/course/section/${id}`);
  },
  // LESSONS
  lessonDetails(id) {
    return api.admin.get(`/admin/course/classe/${id}`);
  },
  uploadLesson(model) {
    return api.admin.post("admin/course/classe", model, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  updateLesson(id, model) {
    return api.admin.post(`admin/course/classe/${id}`, model, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  deleteLesson(id) {
    return api.admin.delete(`/admin/course/classe/${id}`);
  },
  // TEACHERS
  getTeachers() {
    return api.admin.get("admin/course/teacher");
  },
  // ORDER LESSONS
  updateLessonOrder(model) {
    return api.admin.post("admin/course/order", model, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  // Q&A
  getPendingQuestions() {
    return api.admin.get("admin/course/questions");
  },
  getAnsweredQuestions() {
    return api.admin.get("admin/course/questions/answered");
  },
  publishQuestion(model) {
    return api.admin.post("admin/course/questions/publish", model);
  },
  answerQuestion(model) {
    return api.admin.post("admin/course/questions/answer", model);
  },
  getComments() {
    return api.admin.get("admin/course/comments");
  },
  publishComment(model) {
    return api.admin.post("admin/course/comments/publish", model);
  },
  getStaticsByCourse(uuid, id) {
    return api.admin.get(`/admin/user/${id}/statistics/${uuid}`);
  },
  getStatics(id) {
    return api.admin.get(`/admin/user/${id}/statistics`);
  },
};
