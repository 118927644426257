import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const SellsSidebarLayout = () => {
  const UrlPath = window.location.pathname;
  const [showUsersSalesMenu, setShowUsersSalesMenu] = useState(false);
  const validateAdmissionsUrl = (isActive) => {
    if (isActive || UrlPath.includes("admisiones")) return true;
  };
  const validateProductsUrl = (isActive) => {
    if (
      isActive ||
      (UrlPath.match(/admin\/usuarios\/miembros\/productos\//i) &&
        !UrlPath.includes("administradores"))
    )
      return true;
  };

  const validateMembershipsUrl = (isActive) => {
    if (
      isActive ||
      (UrlPath.match(/admin\/usuarios\/miembros\/\d+\/ventas-membresias/) &&
        !UrlPath.includes("administradores"))
    )
      return true;
  };
  const validateUsersUrl = (isActive) => {
    if (
      ((isActive && UrlPath.match("/admin/usuarios")) ||
        UrlPath.includes("estadisticas") ||
        UrlPath.match(/admin\/usuarios\//i)) &&
      !UrlPath.includes("miembros") &&
      !UrlPath.includes("administradores")
    ) {
      return true;
    }
  };

  return (
    <>
      <NavLink end to="/admin/usuarios">
        {({ isActive }) => (
          <div
            className={`flex items-center font-bold py-3 px-10 border-l-4 ${
              validateUsersUrl(isActive)
                ? "text-primary-200 border-primary-200"
                : "border-gray-200"
            }`}
          >
            <div className="flex w-full">
              <div className="w-1/6">
                <FontAwesomeIcon icon="fa-user-group" size="sm" />
              </div>
              <div className="w-full">
                <p className="leading-none">Miembros</p>
              </div>
            </div>
          </div>
        )}
      </NavLink>

      <NavLink to="/admin/cupones">
        {({ isActive }) => (
          <div
            className={`flex items-center font-bold py-3 px-10 border-l-4 ${
              isActive
                ? "text-primary-200 border-primary-200"
                : "border-gray-200"
            }`}
          >
            <div className="flex w-full">
              <div className="w-1/6">
                <FontAwesomeIcon icon={["far", "ticket"]} size="sm" />
              </div>
              <div className="w-full">
                <p className="leading-none">Cupones</p>
              </div>
            </div>
          </div>
        )}
      </NavLink>
      <NavLink end to="/admin/admisiones">
        {({ isActive }) => (
          <div
            className={`flex items-center font-bold py-3 px-10 border-l-4 ${
              validateAdmissionsUrl(isActive)
                ? "text-primary-200 border-primary-200"
                : "border-gray-200"
            }`}
          >
            <div className="flex w-full">
              <div className="w-1/6">
                <FontAwesomeIcon icon={["far", "memo-pad"]} size="sm" />
              </div>
              <div className="w-full">
                <p className="leading-none">Solicitudes de admisión</p>
              </div>
            </div>
          </div>
        )}
      </NavLink>
      <NavLink to="/admin/usuarios/miembros">
        {({ isActive }) => (
          <div
            className={`flex items-center font-bold py-3 px-10 border-l-4 ${
              isActive
                ? "text-primary-200 border-primary-200"
                : "border-gray-200"
            }`}
          >
            <div className="flex w-full">
              <div className="w-1/6">
                <FontAwesomeIcon icon="fa-users" size="sm" />
              </div>
              <div className="w-full flex justify-between">
                <p>Ventas de usuarios</p>
                <button
                  onClick={(e) => {
                    setShowUsersSalesMenu(!showUsersSalesMenu);
                    e.preventDefault();
                  }}
                >
                  <FontAwesomeIcon
                    icon={
                      showUsersSalesMenu
                        ? "fa-chevron-down"
                        : "fa-chevron-right"
                    }
                    size="sm"
                  />
                </button>
              </div>
            </div>
          </div>
        )}
      </NavLink>
      {showUsersSalesMenu && (
        <div>
          <NavLink end to="/admin/usuarios/miembros/top-ventas">
            {({ isActive }) => (
              <div
                className={`flex items-center py-2 pl-24 pr-10 ${
                  isActive ? "text-primary-200" : null
                }`}
              >
                <div className="w-full">
                  <p>Top ventas</p>
                </div>
              </div>
            )}
          </NavLink>
          <NavLink end to="/admin/usuarios/miembros">
            {({ isActive }) => (
              <div
                className={`flex items-center py-2 pl-24 pr-10 ${
                  validateMembershipsUrl(isActive) ? "text-primary-200" : null
                }`}
              >
                <div className="w-full">
                  <p>Ventas de membresias</p>
                </div>
              </div>
            )}
          </NavLink>
          <NavLink end to="/admin/usuarios/miembros/productos">
            {({ isActive }) => (
              <div
                className={`flex items-center py-2 pl-24 pr-10 ${
                  validateProductsUrl(isActive) ? "text-primary-200" : null
                }`}
              >
                <div className="w-full">
                  <p>Ventas de productos</p>
                </div>
              </div>
            )}
          </NavLink>
        </div>
      )}
    </>
  );
};

export default SellsSidebarLayout;
