import { useState, useEffect, useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as _ from "lodash";
import { notifications } from "../../../utils/notifications";
import DataTable from "react-data-table-component";
import moment from "moment";
import WithdrawalModal from "../../../components/Modals/WithdrawalModal";
import WithdrawalBankModal from "../../../components/Modals/WithdrawalBankModal";

import Context from "../../../context/AdminUserContext";
import { adminWithdrawalsService } from "../../../services/api/admin/withdrawal";
import {
  getColumnsWhitdrawals,
  getColumnsWhitdrawalsAnswered,
  customStyles,
  paginationComponentOptions,
} from "../../../utils/tableOptions";
import TableLoading from "../../../components/TableLoading";
import TableNoData from "../../../components/TableNoData";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import fileDownload from "js-file-download";

const Index = () => {
  const { currentAdminUser } = useContext(Context);
  const [menuContent, setMenuContent] = useState("pending");
  const [pendings, setPendings] = useState([]);
  const [answered, setAnswered] = useState([]);
  const [querySearch, setQuerySearch] = useState("");
  const [formattedUsersData, setFormattedUsersData] = useState([]);
  const [formattedUsersDataAnswered, setFormattedUsersDataAnswered] = useState(
    []
  );
  const [loading, setLoading] = useState(true);
  const formikRef = useRef();

  const formatTopUserData = () => {
    let data = [];
    pendings.forEach((user, idx) => {
      data.push({
        photoComponent: (
          <div className="w-10 h-10 mx-2">
            <img
              src={user.user.image}
              alt=""
              className="rounded-full w-full h-full object-cover"
              style={{ backgroundColor: "#D8D8D8" }}
            />
          </div>
        ),
        userComponent: (
          <div className="">
            <a
              href={`/admin/usuarios/miembros/${user.user.id}/ventas-membresias`}
              className="text-primary-200"
            >
              {" "}
              {user.user.name}
            </a>
          </div>
        ),
        name: user.user.name,
        email: user.user.email,
        memberships_total: user.amount,
        memberships_count: user.memberships_count,
        created_at: moment(user.created_at).format("DD/MM/YYYY"),
        dataComponent: user.bank_information.holder_name ? (
          <div
            className="text-primary-300 font-bold cursor-pointer hover:text-primary-400"
            onClick={() =>
              checkWhidrawalInformation(
                user.bank_information,
                user.id,
                user.user.name
              )
            }
          >
            VER DATOS
          </div>
        ) : (
          <div className="text-red-50 cursor-not-allowed font-bold">
            NO DISPONIBLE
          </div>
        ),
        actionComponent:
          user.status !== "Denied" && user.status !== "Accepted" ? (
            <div className="flex justify-between items-center md:flex-col text-[#AEAEAE] pt-3 md:pt-0">
              <div className="block space-x-4 my-auto">
                <button
                  className="bg-primary-200 text-base uppercase text-white font-bold p-2 px-6 hover:bg-primary-300 text-base"
                  onClick={() => AcceptDeclineWithdrawalModal(user.id)}
                >
                  RESPONDER
                </button>
              </div>
            </div>
          ) : user.status === "Accepted" || user.status === "Denied" ? (
            <button
              className="bg-primary-200 text-base uppercase text-white font-bold p-2 px-6 hover:bg-primary-300 text-base"
              onClick={() => AcceptDeclineWithdrawalModal(user.id)}
            >
              RESPONDER
            </button>
          ) : (
            ""
          ),
      });
    });

    setFormattedUsersData(data);
    setLoading(false);
  };

  const formatTopUserDataAnswered = () => {
    let data = [];

    answered.forEach((user, idx) => {
      if (!user.message) {
        user.message = "";
      }
      data.push({
        photoComponent: (
          <div className="w-10 h-10 mx-2">
            <img
              src={user.user.image}
              alt=""
              className="rounded-full w-full h-full object-cover"
              style={{ backgroundColor: "#D8D8D8" }}
            />
          </div>
        ),
        userComponent: (
          <div className="">
            <a
              href={`/admin/usuarios/miembros/${user.user.id}/ventas-membresias`}
              className="text-primary-200"
            >
              {" "}
              {user.user.name}
            </a>
          </div>
        ),
        dataComponent: user.bank_information.holder_name ? (
          <div
            className="text-primary-300 font-bold cursor-pointer hover:text-primary-400"
            onClick={() =>
              checkWhidrawalInformation(
                user.bank_information,
                user.id,
                user.user.name
              )
            }
          >
            VER DATOS
          </div>
        ) : (
          <div className="text-red-50 cursor-not-allowed font-bold">
            NO DISPONIBLE
          </div>
        ),
        statusComponent:
          user.status === "Accepted" ? (
            <div className="text-green-50 font-bold">ACEPTADA</div>
          ) : (
            <div className="text-red-50 font-bold">RECHAZADA</div>
          ),
        name: user.user.name,
        email: user.user.email,
        memberships_total: user.amount,
        memberships_count: user.memberships_count,
        created_at: moment(user.created_at).format("DD/MM/YYYY"),
        actionComponent:
          user.status !== "Denied" && user.status !== "Accepted" ? (
            <div className="flex justify-between items-center md:flex-col text-[#AEAEAE] pt-3 md:pt-0">
              <div className="w-full flex md:hidden flex-row justify-end space-x-4 my-auto">
                <button className="text-primary-200 text-base uppercase">
                  ACEPTAR
                </button>
                <button className="text-red-50 text-base uppercase">
                  RECHAZAR
                </button>
              </div>
            </div>
          ) : user.message ? (
            <button
              className="bg-primary-200 text-base uppercase text-white font-bold p-2 px-6 hover:bg-primary-300 text-base"
              onClick={() => checkAnswerWithdrawal(user.message)}
            >
              VER RESPUESTA
            </button>
          ) : (
            "SIN RESPUESTA"
          ),
      });
    });
    setFormattedUsersDataAnswered(data);
  };

  function AcceptDeclineWithdrawalModal(id) {
    const initialValues = {
      message: "",
      option: "Accepted",
    };

    const formSchema = Yup.object().shape({
      option: Yup.string()
        .required("Campo requerido")
        .max(255, `Máximo 255 caracteres`),
    });

    setModalContent("");

    setModalContent(
      <div className="px-12 text-2xl text-center" role="dialog">
        <Formik
          innerRef={(p) => (formikRef.current = p)}
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={(values, { resetForm }) =>
            acceptDeniedWhithdrawal(id, values, resetForm)
          }
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="my-10 ml-0 font-bold lg:text-left text-center">
                {" "}
                Responder solicitud{" "}
              </div>
              <Field
                as="textarea"
                name="message"
                className="mb-5 w-full h-36 md:h-56 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                placeholder="Mensaje opcional"
              />
              <div className="text-center lg:text-left mb-5 leading-none">
                La respuesta será visible para el usuario.
              </div>
              <div className="flex-wrap flex flex-row justify-between">
                <div className="w-full flex flex-col md:flex-row justify-between">
                  <button
                    type="submit"
                    onClick={() => {
                      setFieldValue("option", "Denied");
                    }}
                    className="md:mb-10 w-full md:w-40 bg-white text-primary-200 border-2 border-primary-200 text-2xl py-3 px-5 disabled:opacity-75 mt-5"
                  >
                    Rechazar
                  </button>
                  <button
                    type="submit"
                    onClick={() => {
                      setFieldValue("option", "Accepted");
                    }}
                    className="md:mb-10 w-full md:w-40 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75 mt-5"
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );

    setShowModal(true);
  }

  const checkAnswerWithdrawal = (answer) => {
    setModalContent(
      <div className="px-12 text-2xl text-center" role="dialog">
        <div className="my-10 ml-0 font-bold lg:text-left text-center">
          {" "}
          Respuesta de solicitud{" "}
        </div>
        <textarea
          id="w3review"
          name="w3review"
          rows="4"
          cols="50"
          className="mb-5 w-full h-36 md:h-56 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
          placeholder="No se agrego ninguna respuesta"
          disabled={true}
          value={answer}
        ></textarea>
        <div className="flex-wrap flex flex-row justify-between">
          <div className="w-full text-center lg:text-left">
            La respuesta es visible para el usuario.
          </div>
        </div>
      </div>
    );
    setShowModal(true);
  };

  const downloadInvoice = (id, user_name) => {
    let extension = "";
    adminWithdrawalsService
      .downloadInvoice(id)
      .then((response) => {
        response.data.type === "application/pdf"
          ? (extension = "pdf")
          : (extension = "jpg");
        fileDownload(response.data, `Factura - ${user_name}.${extension}`);
      })
      .catch((err) => {});
  };

  const checkWhidrawalInformation = (bank_information, id, user_name) => {
    setModalContent(
      <div
        className="px-3 lg:px-12 text-xl lg:text-2xl text-center"
        role="dialog"
      >
        <div className="my-2 lg:my-5  font-bold  text-center text-xl lg:text-3xl">
          Datos para retirar
        </div>
        <>
          <button
            onClick={() => downloadInvoice(id, user_name)}
            className="my-5 lg:my-8 text-primary-200 hover:text-primary-300 cursor-pointer "
          >
            <FontAwesomeIcon
              className="mr-2 text-primary-200"
              icon={["fa", "download"]}
              size="sm"
            />
            Haz click para descargar la factura
          </button>
        </>
        <div className="space-y-6 text-center">
          <div className=" flex flex-row space-x-2">
            <div className="w-full font-bold">Titular de la tarjeta:</div>
            <div className="w-full">{bank_information.holder_name}</div>
          </div>
          <div className=" flex flex-row space-x-4">
            <div className="w-full  font-bold">Banco:</div>
            <div className="w-full ">{bank_information.bank}</div>
          </div>
          <div className=" flex flex-row space-x-4">
            <div className="w-full  font-bold">Número de tarjeta:</div>
            <div className="w-full ">{bank_information.card_number}</div>
          </div>
        </div>
      </div>
    );
    setShowBankModal(true);
  };

  const getPendingAdmissions = () => {
    adminWithdrawalsService
      .getPendingWithdrawals()
      .then((response) => {
        setPendings(response.data.withdrawals);
      })
      .catch((err) => {});
  };

  const acceptDeniedWhithdrawal = (id, values, resetForm) => {
    let bodyFormData = new FormData();
    bodyFormData.append("status", values.option);
    if (values.message) {
      bodyFormData.append("message", values.message);
    }
    bodyFormData.append("_method", "PUT");
    adminWithdrawalsService
      .setWithdrawal(id, bodyFormData)
      .then((response) => {
        notifications.success(response.data.message);
        getPendingAdmissions();
        getAnsweredAdmissions();
        setShowModal(false);
        resetForm();
      })
      .catch((err) => {});
  };

  const getAnsweredAdmissions = () => {
    adminWithdrawalsService
      .getAnsweredWithdrawals()
      .then((response) => {
        setAnswered(response.data.withdrawals);
      })
      .catch((err) => {});
  };

  const filteredData = formattedUsersData.filter((user) => {
    if (querySearch === "") {
      return user;
    } else {
      return (
        user.name.toLowerCase().includes(querySearch) ||
        user.email.toLowerCase().includes(querySearch)
      );
    }
  });

  const filteredDataAnswered = formattedUsersDataAnswered.filter((user) => {
    if (querySearch === "") {
      return user;
    } else {
      return (
        user.name.toLowerCase().includes(querySearch) ||
        user.email.toLowerCase().includes(querySearch)
      );
    }
  });

  useEffect(() => {
    if (!currentAdminUser) return;
    getPendingAdmissions();
    getAnsweredAdmissions();
  }, [currentAdminUser]);

  useEffect(() => {
    setLoading(false);
    // if (_.isEmpty(pendings)) return;
    formatTopUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendings]);

  useEffect(() => {
    if (_.isEmpty(answered)) return;
    formatTopUserDataAnswered();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answered]);

  useEffect(() => {
    setQuerySearch("");
  }, [menuContent]);

  const inputHandler = (e) => setQuerySearch(e.target.value.toLowerCase());
  const [showModal, setShowModal] = useState(false);
  const [showBankModal, setShowBankModal] = useState(false);

  const [modalContent, setModalContent] = useState();

  return (
    <div className="container mx-auto text-gray-700">
      <p className="text-3xl md:text-4xl font-bold text-center md:text-left">
        Todas las solicitudes
      </p>
      <WithdrawalModal
        visible={showModal}
        setVisible={setShowModal}
        modalContent={modalContent}
        resetForm={() =>
          formikRef.current ? formikRef.current.resetForm() : null
        }
      />
      <WithdrawalBankModal
        visible={showBankModal}
        setVisible={setShowBankModal}
        modalContent={modalContent}
        resetForm={() =>
          formikRef.current ? formikRef.current.resetForm() : null
        }
      />
      {/* MENU */}
      <div className="flex flex-col md:flex-row justify-center md:justify-between items-center gap-8 py-3 mt-6 border-b border-b-gray-350">
        <div className="flex flex-row">
          <button
            className="text-2xl mr-8"
            onClick={() => setMenuContent("pending")}
          >
            <p
              className={`${
                menuContent === "pending"
                  ? "text-primary-200 font-bold"
                  : "text-gray-700"
              }`}
            >
              Pendientes
            </p>
          </button>

          <button
            className="text-2xl"
            onClick={() => setMenuContent("answered")}
          >
            <p
              className={`${
                menuContent === "answered"
                  ? "text-primary-200 font-bold"
                  : "text-gray-700"
              }`}
            >
              Respondidas
            </p>
          </button>
        </div>

        <div className="flex flex-end">
          <label className="relative block">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <FontAwesomeIcon
                icon={["fa", "magnifying-glass"]}
                className="text-[#A9A9A9]"
                size="sm"
              />
            </span>
            <input
              className="w-full md:w-52 lg:w-64 bg-white text-gray-700 placeholder:font-italic border border-slate-300 py-3 pl-12 pr-4 focus:outline-none"
              type="text"
              placeholder="Buscar"
              value={querySearch}
              onChange={inputHandler}
            />
          </label>
        </div>
      </div>
      <DataTable
        columns={
          menuContent === "pending"
            ? getColumnsWhitdrawals
            : getColumnsWhitdrawalsAnswered
        }
        data={menuContent === "pending" ? filteredData : filteredDataAnswered}
        noDataComponent={<TableNoData />}
        progressPending={loading}
        progressComponent={<TableLoading />}
        customStyles={customStyles}
        pagination
        paginationComponentOptions={paginationComponentOptions}
      />
    </div>
  );
};

export default Index;
