/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import * as _ from "lodash";

import Context from "../../../context/AdminUserContext";
import { notifications } from "../../../utils/notifications";
import { adminCouponsServices } from "../../../services/api/admin/coupons";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { addDays, format, parseISO } from "date-fns";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
registerLocale("es", es);

const EditCoupon = () => {
  const formSchema = Yup.object().shape({
    code: Yup.string().required("Campo requerido"),
    description: Yup.string().notRequired().nullable(),
    type: Yup.string().required("Campo requerido"),
    amount: Yup.number()
      .required("Campo requerido")
      .when("type", {
        is: "Percent",
        then: Yup.number().max(
          100,
          "Cuando es porcentaje, el valor del importe debe ser menor o igual a 100"
        ),
        otherwise: Yup.number(),
      }),
    expired_at: Yup.string().notRequired().nullable(),
    usage_limit: Yup.number().notRequired().nullable(),
    items: Yup.array()
      .min(1, "Debe seleccionar al menos una opción")
      .required("Campo requerido"),
  });

  const [coupon, setCoupon] = useState({});
  const [items, setItems] = useState([]);
  const [itemsSelected, setItemsSelected] = useState([]);
  const [expirationDate, setExpirationDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    code: "",
    description: "",
    type: "Percent",
    amount: 0,
    expired_at: "",
    usage_limit: "",
    items: [],
  });

  const { currentAdminUser } = useContext(Context);

  const params = useParams();
  const navigate = useNavigate();

  const fetchCoupon = () => {
    adminCouponsServices
      .getCoupon(params.id)
      .then((response) => {
        setCoupon(response.data.coupon);
        setItemsSelected(response.data.items);
      })
      .catch((err) => {});
  };

  const fetchCouponItems = () => {
    setIsLoading(true);
    adminCouponsServices
      .getCouponsItems()
      .then((response) => {
        setItems(response.data.items);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchCoupon();
    fetchCouponItems();
  }, [currentAdminUser]);

  useEffect(() => {
    if (!_.isEmpty(coupon)) {
      setInitialValues({
        code: coupon.code || "",
        description: coupon.description || "",
        type: coupon.type || "Percent",
        amount: coupon.amount || 0,
        expired_at: coupon.expired_at ? coupon.expired_at.split(" ")[0] : "",
        usage_limit: coupon.usage_limit || "",
        items: itemsSelected || [],
      });

      if (coupon.expired_at) {
        setExpirationDate(parseISO(coupon.expired_at));
      }
    }
  }, [coupon, itemsSelected]);

  const handleSubmit = async (values) => {
    if (!values.description) values.description = null;
    if (!values.expired_at) values.expired_at = null;
    if (!values.usage_limit) values.usage_limit = null;
    if (coupon.code === values.code) delete values.code;

    values.items = values.items.map((item) => {
      return {
        type: item.type,
        uuid: item.uuid,
      };
    });

    setIsLoading(true);

    await adminCouponsServices
      .updateCoupon(params.id, values)
      .then((response) => {
        notifications.success(response.data.message);
        navigate("/admin/cupones");
      })
      .catch((err) => {})
      .finally(() => setIsLoading(false));
  };

  const handleDelete = async () => {
    MySwal.fire({
      icon: "error",
      title: "¿Deseas eliminar este cupon?",
      text: "Se eliminará todo el contenido asociado. Esta acción es irreversible",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#42a819",
      confirmButtonText: "Eliminar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);

        await adminCouponsServices
          .deleteCoupon(params.id)
          .then((response) => {
            notifications.success("Cupon eliminado!");
            navigate("/admin/cupones");
          })
          .catch((err) => {})
          .finally(() => {
            setIsLoading(false);
          });
      }
    });
  };

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <p className="text-4xl font-bold">Editar cupón</p>
      </div>

      <div className="mt-12">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ setFieldValue }) => (
            <Form>
              <p className="text-3xl text-gray-700 font-bold mb-5">General</p>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-3xl text-gray-550">
                  <p>Código</p>
                  <Field
                    name="code"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                  />
                  <ErrorMessage
                    name="code"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-3xl text-gray-550">
                  <p>Descripción</p>
                  <Field
                    as="textarea"
                    name="description"
                    className="w-full h-24 border border-slate-300 text-gray-700 placeholder:text-gray-700 py-3 px-4 resize-none focus:outline-none"
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-3xl text-gray-550">
                  <p>Tipo de Descuento</p>
                  <Field
                    as="select"
                    name="type"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                  >
                    <option value="Percent">Descuento en porcentaje</option>
                    <option value="Fixed">Descuento fijo</option>
                  </Field>
                  <ErrorMessage
                    name="type"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-3xl text-gray-550">
                  <p>Importe del cupón</p>
                  <Field
                    name="amount"
                    type="number"
                    min="1"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                  />
                  <ErrorMessage
                    name="amount"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-3xl text-gray-550">
                  <p>Expiración</p>
                  <DatePicker
                    selected={expirationDate}
                    onChange={(date) => {
                      setFieldValue(
                        "expired_at",
                        date ? format(date, "yyyy-MM-dd") : null
                      );
                      setExpirationDate(date);
                    }}
                    dateFormat="yyyy-MM-dd"
                    isClearable={true}
                    className="text-gray-700 placeholder:text-gray-700 border border-slate-300 focus:outline-none py-3 px-4 w-full"
                    locale="es"
                    minDate={addDays(new Date(), 1)}
                  />
                  <ErrorMessage
                    name="expired_at"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>

              <p className="text-3xl text-gray-700 font-bold mb-5">
                Restricción de uso
              </p>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-3xl text-gray-550">
                  <p>Membresías, servicios y cursos</p>
                  <Select
                    options={items}
                    value={itemsSelected}
                    isMulti
                    placeholder="Seleccione una opción"
                    noOptionsMessage={() => "No hay más opciones"}
                    isLoading={isLoading}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.uuid}
                    onChange={(items) => {
                      setFieldValue("items", items);
                      setItemsSelected(items);
                    }}
                  />
                  <ErrorMessage
                    name="items"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>

              <p className="text-3xl text-gray-700 font-bold mb-5">
                Límites de uso
              </p>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-3xl text-gray-550">
                  <p>Limite de uso por cupón</p>
                  <Field
                    name="usage_limit"
                    type="number"
                    min="1"
                    placeholder="Uso ilimitado"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                  />
                  <ErrorMessage
                    name="usage_limit"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>

              <div className="flex flex-col md:flex-row justify-between w-full gap-5 mt-10 lg:mt-20">
                <button
                  type="button"
                  className="w-full md:w-1/3 lg:w-1/4 bg-tertiary text-white font-bold text-2xl py-3 disabled:opacity-75 order-2 md:order-1"
                  disabled={isLoading}
                  onClick={() => handleDelete()}
                >
                  {isLoading ? (
                    <FontAwesomeIcon icon="circle-notch" spin />
                  ) : (
                    <span>Eliminar</span>
                  )}
                </button>
                <button
                  type="submit"
                  className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75 order-1 md:order-2"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <FontAwesomeIcon icon="circle-notch" spin />
                  ) : (
                    <span>Guardar</span>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditCoupon;
