import Rodal from "rodal";
import "rodal/lib/rodal.css";

const ReplyQuestionModal = ({ visible, setVisible, modalContent }) => {
  return (
    <div>
      <div>
        <Rodal
          visible={visible}
          onClose={() => setVisible(false)}
          customStyles={{
            width: "95%",
            maxWidth: 700,
            height: "85%",
            maxHeight: 650,
            padding: "0",
            overflowY: "scroll",
          }}
        >
          {modalContent}
        </Rodal>
      </div>
    </div>
  );
};

export default ReplyQuestionModal;
