import React, { useContext } from "react";
import UserContext from "../../context/UserContext";
import { validationPrice } from "../../utils";

const ProductCheckout = ({ product }) => {
  const { currentUser } = useContext(UserContext);

  const getPrice = () => {
    let variant = getVariant(product.variant_id);
    let variantPrice = showPrice()
      ? variant.price
      : variant.compare_at_price > 0
      ? variant.compare_at_price
      : variant.price;

    if (product.amount > 0) {
      return (product.amount * variantPrice).toLocaleString("en-US", {
        minimumFractionDigits: 2,
      });
    }

    return variantPrice.toLocaleString("en-US");
  };
  const getVariant = (variant_id) => {
    let variant;
    Object.keys(product.variants).forEach((key) => {
      if (variant_id === product.variants[key].id) {
        variant = product.variants[key];
      }
    });
    return variant;
  };

  const getVariantImage = (image_id) => {
    let image;
    Object.keys(product.images).forEach((key) => {
      if (image_id === product.images[key].id) {
        image = product.images[key];
      }
    });
    return image;
  };

  const showPrice = () => {
    return validationPrice(currentUser, product.vendor);
  };

  return (
    <div className="flex relative py-4 px-5 border-b border-gray-300">
      <div className="flex-shrink-0">
        <img
          src={
            product.image_id
              ? getVariantImage(product.image_id).src
              : product.image.src
          }
          alt={product.image && product.image.alt}
          className="h-20 w-20 rounded-md object-cover object-center"
        />
      </div>

      <div className="ml-5 md:ml-3 flex flex-1 flex-col">
        <div className="flex justify-between">
          <div className="my-auto w-5/12 lg:w-2/6 2xl:w-4/6">
            <p className=" text-sm lg:text-xl text-gray-700 font-bold text-base lg:text-xl text-left leading-none">
              {product.title}
            </p>
          </div>

          <div>
            <p className="pl-10 ml-4 font-bold text-sm lg:text-xl text-gray-900 leading-none text-right">
              ${getPrice()} <small> MXN</small>
            </p>
            <p className=" text-gray-700 font-bold text-sm lg:text-lg leading-none text-right mt-3">
              Cant. {product.amount}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCheckout;
