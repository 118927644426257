import React from "react";
import { Field, ErrorMessage } from "formik";

const DatePickerField = ({ ...props }) => {
  return (
    <div className="mb-9 text-xl text-gray-700 space-x-2 ">
      <p className="text-center md:text-left">
        {props.question && props.question}
      </p>
      {props.message && (
        <p className="text-gray-600 text-center md:text-left">
          En esta pregunta es súper importante que tomes en cuenta que se habla
          de UTILIDAD, es decir ganancias después de recuperar tu inversión, en
          TUS PRIMEROS 30 DÍAS. Muchas personas se confunden y ponen lo que
          quieren ganar proyectándose a mediano o largo plazo, la pregunta es EN
          TUS PRIMEROS 30 DÍAS
        </p>
      )}
      <div
        className={`flex justify-center lg:justify-start  mt-2 lg:mb-4 ${
          props.checkFull ? "flex-wrap" : " space-x-5 "
        }`}
      >
        {props.checkboxes.map((check, index) => (
          <div
            key={`multipleChk-${index}`}
            className={`space-x-2 flex items-center w-full ${
              props.checkFull ? "w-full mb-2" : ""
            }`}
          >
            <Field
              value={check.value}
              name={props.name}
              className="t-20 w-5 h-5  bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
              type={props.box ? "checkbox" : "radio"}
            />
            <span className="text-left">{check.text}</span>
          </div>
        ))}
        <ErrorMessage
          name={props.name}
          component="div"
          className="text-red-400 font-bold text-xl"
        />
      </div>
    </div>
  );
};
export default DatePickerField;
