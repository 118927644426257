import { api } from "../../../client";

export const adminEventServices = {
  getEvents() {
    return api.admin.get("admin/event");
  },
  uploadEvent(model) {
    return api.admin.post("/admin/event", model, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  updateEvent(uuid, model) {
    return api.admin.post(`/admin/event/${uuid}`, model, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  
  deleteEvent(uuid) {
    return api.admin.delete(`/admin/event/${uuid}`);
  },
  EventDetails(id) {
    return api.admin.get(`/admin/event/${id}`);
  },
};
