import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PublishQCContent = ({
  isQuestion = false,
  handlePublish,
  isPublished,
  setVisible,
}) => {
  const getTitle = () => {
    return isQuestion
      ? "¿Te gustaría ocultar esta pregunta? Ningún estudiante podrá verla"
      : "¿Te gustaría ocultar este comentario? Ningún estudiante podrá verlo";
  };

  const getMessage = () => {
    return isQuestion
      ? "¿Te gustaría publicar esta pregunta para que todos los estudiantes puedan verla?"
      : "¿Te gustaría publicar este comentario para que todos los estudiantes puedan verlo?";
  };

  return (
    <div className="text-gray-700">
      <div className="px-10 py-4 md:px-20 md:py-7 border-b border-b-gray-350 text-center md:text-left flex items-center">
        <FontAwesomeIcon icon="fa-flag" className="text-xl" />
        <p className="text-xl lg:text-3xl font-bold ml-5">
          {isPublished ? "Ocultar" : "Publicar"}{" "}
          {isQuestion ? "Pregunta" : "Comentario"}
        </p>
      </div>
      <div className="text-center lg:text-left px-10 py-3 md:py-10 md:px-20 flex flex-col">
        {isPublished ? (
          <p className="text-lg md:text-xl">{getTitle()}</p>
        ) : (
          <p className="text-lg md:text-xl">{getMessage()}</p>
        )}
        <div className="flex flex-col md:flex-row justify-between text-lg">
          <button
            type="button"
            className="text-sm lg:text-xl  text-center py-2 px-8 text-primary-200 font-bold w-full md:w-auto mt-4 border border-primary-200"
            onClick={() => setVisible(false)}
          >
            {isPublished ? "Cancelar" : "No, sin publicar"}
          </button>
          <button
            type="button"
            className="text-sm lg:text-xl bg-primary-200 text-center py-2 px-8 text-white font-bold w-full md:w-auto mt-4"
            onClick={() => handlePublish()}
          >
            Si, {isPublished ? "ocultar" : "publicar"}{" "}
            {isQuestion ? "pregunta" : "comentario"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PublishQCContent;
