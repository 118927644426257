import { api } from "../../client";

export const loggerServices = {
  addLogger(model) {
    return api.base.post("/frontend", model);
  },
  addNetworkHistoryLog(model) {
    return api.base.post("/network", model);
  },
};
