import CourseCard from "./courses/CourseCard";
import CarrouselCourses from "./courses/CarrouselCourses";
import { notifications } from "../utils/notifications";
import { coursesServices } from "../services/api/courses";

const CoursesList = ({ courses, handleAddCourse }) => {
  const handleWishList = (uuid) => {
    updateWishList(uuid);
  };

  const updateWishList = async (uuid) => {
    await coursesServices
      .updateCourseWishList({ uuid })
      .then((response) => {
        notifications.success(response.data.message);
      })
      .catch((err) => {});
  };

  return (
    <div>
      <div className=" ">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-2">
          {courses?.map((course) => (
            <CourseCard
              key={course.uuid}
              handleWishList={(uuid) => handleWishList(uuid)}
              course={course}
              handleAddCourse={(id) => handleAddCourse(id)}
            />
          ))}
        </div>
      </div>

      {/* <div className="block lg:hidden">
        <CarrouselCourses
          courses={courses}
          handleAddCourse={(id) => handleAddCourse(id)}
        />
      </div> */}

      <div>
        {courses?.length < 1 && (
          <div className="text-center lg:text-left">
            No se han encontrado resultados.
          </div>
        )}
      </div>
    </div>
  );
};

export default CoursesList;
