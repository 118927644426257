import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo-globe-gbp.png";

const ShoppingCart = () => {
  return (
    <main className="container mx-auto flex-1 px-4 md:px-0 mb-16">
      <div className="">
        <div className="h-20 flex items-center">
          <img src={logo} alt="BeGlobalPro" className="h-9 mr-2" />
          <p className=" text-gray-700">
            <FontAwesomeIcon icon="fa-chevron-right" className="mr-1" />{" "}
            <span className="text-gray-400">Carrito de compras</span>
          </p>
        </div>
      </div>

      <div className="">
        <div className="bg-red-50 border-red-50 border-4 rounded-full w-16 lg:w-24 h-16 lg:h-24 mx-auto flex items-center justify-center mb-5 lg:mb-10">
          <FontAwesomeIcon
            icon={["fa", "exclamation"]}
           
            className="fill-red-50   text-white text-4xl lg:text-6xl"
          />
        </div>

        <p className="pb-2 lg:pb-8 text-2xl lg:text-5xl text-center  text-red-50 font-bold">
          Pago declinado
        </p>
        <p className="pb-2 lg:pb-8 text-xl lg:text-3xl text-center">
          Algo salio mal, intentalo de nuevo
        </p>
        <div className="text-xl lg:text-2xl text-center mt-5">
          <Link
            to="/carrito-de-compras"
            className="bg-white py-3 px-12 text-primary-200 font-bold border-primary-200 border-2"
          >
            Intentar de nuevo
          </Link>
        </div>
      </div>
    </main>
  );
};

export default ShoppingCart;
