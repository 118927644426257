/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as _ from "lodash";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Context from "../../../../context/AdminUserContext";

import FilesDragAndDrop from "../../../../components/FilesDragAndDrop";

import { notifications } from "../../../../utils/notifications";
import { adminCoursesServices } from "../../../../services/api/admin/courses";

const MySwal = withReactContent(Swal);
const SUPPORTED_FORMATS = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
];

const EditResources = () => {
  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    file: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        "fileFormat",
        "Formato no soportado",
        (value) =>
          value === null || (value && SUPPORTED_FORMATS.includes(value.type))
      ),
  });

  const { currentAdminUser } = useContext(Context);

  const navigate = useNavigate();
  const params = useParams();

  const [resource, setResources] = useState({});

  const [initialValues, setInitialValues] = useState({
    name: "",
    file: null,
  });

  useEffect(() => {
    if (!_.isEmpty(resource)) {
      setInitialValues({
        name: resource.name || "",
        file: null,
      });
    }
  }, [resource]);

  const fetchResourse = () => {
    adminCoursesServices
      .resourceDetails(params.id)
      .then((response) => {
        setResources(response.data.resource);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchResourse();
  }, [currentAdminUser]);

  const handleSubmit = async (values) => {
    let bodyFormData = new FormData();

    bodyFormData.append("_method", "PUT");
    values.name && bodyFormData.append("name", values.name);
    values.file && bodyFormData.append("file", values.file);
    await adminCoursesServices
      .updateResourse(params.id, bodyFormData)
      .then((response) => {
        notifications.success(response.data.message);
        navigate(`/admin/cursos/editar/${params.uuid}`);
      })
      .catch((err) => {});
  };

  const handleDelete = () => {
    MySwal.fire({
      icon: "error",
      title: "¿Deseas eliminar este recurso?",
      text: "Se eliminará todo el contenido asociado. Esta acción es irreversible",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#42a819",
      confirmButtonText: "Eliminar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await adminCoursesServices
          .deleteResourse(params.id)
          .then((response) => {
            notifications.success(response.data.message);
            navigate(`/admin/cursos/editar/${params.uuid}`);
          })
          .catch((err) => {});
      }
    });
  };

  const onFileChange = (files, setFieldValue) => {
    if (!_.isEmpty(files)) {
      setFieldValue("file", files[0]);
    }
  };

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex-col">
        <button
          className="mr-auto text-primary-200 mb-5"
          onClick={() => navigate(-1)}
        >
          Regresar
        </button>
        <div className="flex flex-col md:flex-row justify-between items-center">
          <p className="text-2xl lg:text-4xl font-bold">Editar recurso</p>
        </div>
      </div>

      <div className="mt-4 lg:mt-12">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="text-center lg:text-left mb-4 lg:mb-9 text-xl lg:text-3xl text-gray-550">
                  <p>Nombre del recurso</p>
                  <Field
                    name="name"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>

              <div className="text-center lg:text-left mb-4 lg:mb-9 text-xl lg:text-3xl text-gray-550">
                <p className="mb-5">Cambiar archivo del recurso</p>
                <div>
                  <FilesDragAndDrop
                    onFileChange={(files) => onFileChange(files, setFieldValue)}
                    multiple={false}
                  />
                  <p className="text-gray-400 my-4 italic">
                    Formatos soportados: PDF, XLS, XLSX, DOC Y DOCX
                  </p>
                </div>
                <ErrorMessage
                  name="file"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>

              {/* BUTTONS */}
              <div className="w-full flex justify-between space-x-6 mt-5">
                <button
                  type="button"
                  className="w-full md:w-56 bg-tertiary text-white text-xl lg:text-2xl py-3 px-5 disabled:opacity-75 mt-5"
                  onClick={() => handleDelete()}
                >
                  Eliminar
                </button>
                <button
                  type="submit"
                  className="w-full md:w-56 bg-primary-200 text-white text-xl lg:text-2xl py-3 px-5 disabled:opacity-75 mt-5"
                >
                  Guardar
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditResources;
