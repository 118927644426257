import { useState, useEffect, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as _ from 'lodash'

import TableLoading from '../../components/TableLoading'
import Context from '../../context/UserContext'
import { coursesServices } from '../../services/api/courses'
import { notifications } from '../../utils/notifications'
import CarrouselClasse from '../../components/courses/classe/CarrouselClasse'

const Favorites = () => {
  const { currentUser } = useContext(Context)
  const [favorites, setFavorites] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!currentUser) return
    setIsLoading(true)

    coursesServices
      .getUserFavorites()
      .then(response => {
        setFavorites(response.data.favorites)
      })
      .catch(err => {})
      .finally(() => setIsLoading(false))
  }, [currentUser])

  const removeFromFavorites = async id => {
    await coursesServices
      .updateClassFavorites({ id })
      .then(response => {
        notifications.success(response.data.message)
        setFavorites(favorites.filter(favorite => favorite.classe.id !== id))
      })
      .catch(err => {})
  }

  return (
    <>
      {isLoading ? (
        <div className='w-full flex justify-center'>
          <TableLoading size='5x' />
        </div>
      ) : !_.isEmpty(favorites) ? (
        <>
          <div className='block lg:hidden'>
            <CarrouselClasse
              classe={favorites}
              removeFromFavorites={id => removeFromFavorites(id)}
            ></CarrouselClasse>
          </div>
          <div className='hidden lg:block'>
            <div className='grid md:grid-cols-2 gap-8'>
              {favorites.map(favorite => (
                <a 
                className="transition ease-in-out delay-50  hover:-translate-y-1 hover:scale-105 hover: duration-300" 
                href={`/cursos/contenido/${favorite.course.uuid}/${favorite.classe.id}`}>
                  <div
                    key={favorite.classe.id}
                    className='shadow-[2px_2px_8px_rgba(0,0,0,0.3)] h-44 flex items-center gap-3 lg:gap-6'
                  >
                    <div className='w-1/3 h-full'>
                      <img
                        src={favorite.course.image}
                        className='w-full h-full object-cover'
                        alt=''
                      />
                    </div>
                    <div className='flex items-center justify-between w-2/3 p-4'>
                      <div>
                        <h3 className='text-gray-700 font-bold text-xl lg:text-2xl mb-2'>
                          {favorite.course.name}
                        </h3>
                        {favorite.section && (
                          <p className='uppercase lg:text-lg'>
                            {favorite.section.name}
                          </p>
                        )}
                        <p className='lg:text-lg'>{favorite.classe.name}</p>
                      </div>

                      <div className='flex flex-col justify-center pl-2'>
                        <button
                          onClick={() =>
                            removeFromFavorites(favorite.classe.id)
                          }
                        >
                          <FontAwesomeIcon
                            icon='fa-star'
                            className='text-secondary'
                            size='xl'
                          />
                        </button>
                        <p className='mt-6 text-gray-500 text-center'>
                          {Math.floor(favorite.classe.time / 60) +
                            ':' +
                            (favorite.classe.time % 60)}{' '}
                          min
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className='bg-gray-300 border-l-8 border-l-primary-200 p-4 my-8'>
          <p className='text-lg lg:text-xl text-gray-700 font-bold'>
            Aún no tienes ninguna clase en favoritos, ¡Vé a tus cursos y agrega
            una!
          </p>
        </div>
      )}
    </>
  )
}

export default Favorites
