import { useEffect, useContext, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as _ from "lodash";
import { WithdrawalService } from "../../../services/api/withdrawal";
import { notifications } from "../../../utils/notifications";
import BasicModal from "../../../components/Modals/AdminModalEvent";
import PendingsAnsweredWhithdrawals from "../../../components/PendingsAnsweredWhithdrawals";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import FilesDragAndDrop from "../../../components/FilesDragAndDrop";
import Context from "../../../context/UserContext";
import Billing from "../../profile/Billing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { profileService } from "../../../services/api/profile";
import { optionsBarWithCurrency } from "../../../utils/chartsOptions";
import useComissionsFilters from "../../../hooks/UseCommissionsFilters";
import CommissionsDetails from "../../../components/Sales/Memberships/CommissionsDetails";
import SalesAndCountsCommissions from "../../../components/Sales/Memberships/SalesAndCountsCommissions";
import MembershipsGridsSales from "../../../components/Sales/Memberships/MembershipsGridsSales";
import CommissionsHistory from "../../../components/Sales/Memberships/CommissionsHistory";

registerLocale("es", es);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const SUPPORTED_FORMATS = ["file/pdf", "image/jpg", "image/jpeg", "image/png"];

const MembershipsSales = () => {
  const { currentUser } = useContext(Context);
  const {
    setCommissions,
    setCommissionsRange,
    setYearBar,
    setBreakdownWeekSelect,
    setDateRange,
    setBreakdownSelect,
    comissionsChart,
    salesTotalChart,
    soldCountChart,
    dataComissions,
    dataSalesCount,
    commissions,
    commissionsRange,
    yearBar,
    data,
    breakdownWeekSelect,
    dateRangesCombined,
    arrayYears,
    breakdownSelect,
    startDate,
    endDate,
  } = useComissionsFilters();

  const fetchMembershipsSalesData = () => {
    profileService
      .getUserMembershipsSales()
      .then((response) => {
        let commissionsData = response.data.commissions;
        setCommissions(commissionsData);
        setCommissionsRange(commissionsData);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!currentUser) return;
    fetchMembershipsSalesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <div className="flex flex-col md:flex-row gap-10">
      <div className="w-full md:w-1/2 lg:w-2/3">
        <div className="flex mb-10">
          <p className="text-xl lg:text-4xl font-bold text-gray-700 my-auto">
            Ventas
          </p>
        </div>

        {/* ANNUAL CHART */}
        <div className="mb-5">
          <Bar options={optionsBarWithCurrency} data={data} />
        </div>

        {/* BREAKDOWN */}
        {commissions.length > 0 && (
          <CommissionsDetails
            commissions={commissions}
            breakdownSelect={breakdownSelect}
            setBreakdownSelect={(option) => setBreakdownSelect(option)}
            breakdownWeekSelect={breakdownWeekSelect}
            setBreakdownWeekSelect={(option) => setBreakdownWeekSelect(option)}
            dateRangesCombined={dateRangesCombined}
            yearBar={yearBar}
            setYearBar={(year) => setYearBar(year)}
            arrayYears={arrayYears}
          />
        )}
        <MembershipsGridsSales salesTotalChart={salesTotalChart} />
        <SalesAndCountsCommissions
          comissionsChart={comissionsChart}
          dataComissions={dataComissions}
          soldCountChart={soldCountChart}
          dataSalesCount={dataSalesCount}
        />
        <CommissionsHistory
          commissions={commissionsRange}
          startDate={startDate}
          endDate={endDate}
          setDateRange={(date) => setDateRange(date)}
        />
      </div>
      <div className="w-full md:w-1/2 lg:w-1/3 border border-gray-350 px-8 pb-10 self-start">
        <ColumnB currentUser={currentUser} />
      </div>
    </div>
  );
};

export default MembershipsSales;

const ColumnB = ({ currentUser }) => {
  const [fund, setFund] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [bankInfo, setBankInfo] = useState(null);
  const [showFile, setShowFile] = useState(false);

  function OpenModal() {
    setModalContent(
      <div className="px-12 text-3xl text-center container" role="dialog">
        <PendingsAnsweredWhithdrawals></PendingsAnsweredWhithdrawals>
      </div>
    );
    setShowModal(true);
  }
  useEffect(() => {
    if (!currentUser) return;
    setFund(currentUser.fund);

    if (!bankInfo) {
      fethBankData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const withdrawMoney = async (values) => {
    let bodyFormData = new FormData();
    bodyFormData.append("amount", values.amount);
    bodyFormData.append("invoice", values.invoice);
    await WithdrawalService.withdrawal(bodyFormData)
      .then((response) => {
        notifications.success(response.data.message);
        setFund(fund - values.amount);
      })
      .catch((err) => {})
      .finally(() => {
        setShowFile(false);
      });
  };
  const formSchemaBankInfoWhithdrawal = Yup.object().shape({
    amount: Yup.number("Must be a number type")
      .typeError("Solo se pueden utilizar numeros enteros")
      .required("No se puede")
      .required("Campo requerido")
      .min(0, "El monto a retirar debe ser mayor a 0")
      .max(fund, "No puede ser mayor al fondo actual"),
    invoice: Yup.mixed()
      .required("La factura es requerida")
      .test(
        "fileFormat",
        "Formato no soportado",
        (value) =>
          !value || ((value) => value && SUPPORTED_FORMATS.includes(value.type))
      ),
  });
  const formSchemaBankInfo = Yup.object().shape({
    name: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    bank: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    card: Yup.string()
      .required("Campo requerido")
      .min(16, `Mínimo 16 digitos`)
      .max(30, `Máximo 30 digitos`),
  });

  const [initialValuesWhithdrawal] = useState({
    amount: "",
    invoice: null,
  });

  const [initialValues, setInitialValues] = useState({
    name: "",
    bank: "",
    card: "",
  });

  const [showInvoicing, setShowInvoicing] = useState(false);

  const handleSubmitBankInfo = async (values) => {
    let bodyFormData = new FormData();
    bodyFormData.append("holder_name", values.name);
    bodyFormData.append("bank", values.bank);
    bodyFormData.append("card_number", values.card);

    await WithdrawalService.sendBankInfo(bodyFormData)
      .then((response) => {
        notifications.success(response.data.message);
      })
      .catch((err) => {});
  };

  const onFileChange = (files, setFieldValue) => {
    if (!_.isEmpty(files)) {
      setFieldValue("invoice", files[0]);
      setShowFile(true);
    }
  };

  const fethBankData = () => {
    WithdrawalService.getBankInfo()
      .then((response) => {
        setBankInfo(response.data.withdrawal_information);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!_.isEmpty(bankInfo)) {
      setInitialValues({
        name: bankInfo.holder_name || "",
        bank: bankInfo.bank || "",
        card: bankInfo.card_number,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankInfo]);

  return (
    <div className="flex flex-col justify-center">
      <BasicModal
        visible={showModal}
        setVisible={setShowModal}
        modalContent={modalContent}
      />

      <div className="w-32 h-32 xl:w-64 xl:h-64 mx-auto mt-10">
        <img
          src={currentUser && currentUser.image}
          alt=""
          className="object-cover rounded-full h-full w-full"
          style={{ backgroundColor: "#D8D8D8" }}
        />
      </div>

      <Formik
        enableReinitialize={true}
        initialValues={initialValuesWhithdrawal}
        validationSchema={formSchemaBankInfoWhithdrawal}
        onSubmit={(values, { resetForm }) => {
          withdrawMoney(values);
          resetForm();
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <div className="text-center font-bold text-xl lg:text-2xl text-[#9F9F9F] mt-5">
              <p>Tu balance</p>
              <p className="text-2xl lg:text-5xl text-gray-700">
                ${fund.toLocaleString("en-US")}
              </p>

              <p className="mt-3">MXN</p>
            </div>
            <div className="mb-2 lg:mb-10">
              <p className=" text-xl lg:text-2xl text-gray-550">
                Escriba la cantidad
              </p>

              <Field
                type="text"
                className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
                name="amount"
              />
              <ErrorMessage
                name="amount"
                component="div"
                className="text-red-400 font-bold text-xl"
              />
            </div>

            <div>
              <FilesDragAndDrop
                onFileChange={(files) => onFileChange(files, setFieldValue)}
                multiple={false}
                showFiles={showFile}
              />

              <ErrorMessage
                name="invoice"
                component="div"
                className="text-red-400 font-bold text-xl"
              />
            </div>

            <div className="mt-10 mb-7">
              <button
                type="submit"
                className="w-full bg-primary-200 text-white font-bold text-xl lg:text-2xl py-3 disabled:opacity-75"
              >
                <span>Retirar</span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <div className="mt-2 mb-7">
        <button
          onClick={() => OpenModal()}
          className="w-full bg-primary-400 text-white font-bold text-xl lg:text-2xl py-3 disabled:opacity-75"
        >
          <span>Historial de retiros</span>
        </button>
      </div>
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={formSchemaBankInfo}
          onSubmit={(values) => handleSubmitBankInfo(values)}
        >
          <Form>
            <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
              <p>Nombre completo</p>
              <Field
                name="name"
                className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
                type="text"
              />
              <ErrorMessage
                name="name"
                component="div"
                className="text-red-400 font-bold text-xl"
              />
            </div>

            <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
              <p>Banco</p>
              <Field
                name="bank"
                className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
                type="text"
              />
              <ErrorMessage
                name="bank"
                component="div"
                className="text-red-400 font-bold text-xl"
              />
            </div>

            <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
              <p>No. Tarjeta</p>
              <Field
                name="card"
                className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
                type="text"
              />
              <ErrorMessage
                name="card"
                component="div"
                className="text-red-400 font-bold text-xl"
              />
            </div>

            <button
              type="submit"
              className="w-full bg-primary-200 text-white font-bold text-xl lg:text-2xl py-3 disabled:opacity-75"
              disabled={false}
            >
              {false ? (
                <FontAwesomeIcon icon="circle-notch" spin />
              ) : (
                <span>Actualizar</span>
              )}
            </button>
          </Form>
        </Formik>
      </div>
      <button
        className="font-bold text-xl xl:text-3xl text-gray-700 self-start mb-6"
        onClick={() => setShowInvoicing(!showInvoicing)}
      >
        <div className="my-2 lg:my-5">
          Datos de Facturación{" "}
          <FontAwesomeIcon
            icon={showInvoicing ? "fa-chevron-down" : "fa-chevron-right"}
            size="sm"
          />
        </div>
      </button>

      {showInvoicing && <Billing desktop />}
    </div>
  );
};
