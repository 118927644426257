import Rodal from "rodal";
import "rodal/lib/rodal.css";

export default function App({ visible, setVisible, modalContent }) {
  return (
    visible && (
      <div>
        <div>
          <Rodal
            visible={visible}
            onClose={() => setVisible(false)}
            customStyles={{
              width: "95%",
              maxWidth: 700,
              height: "85%",
              maxHeight: 750,
              padding: "0",
              overflowY: "auto",
            }}
          >
            {modalContent}
          </Rodal>
        </div>
      </div>
    )
  );
}
