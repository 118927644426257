import { api } from "../../../client";

export const adminAdmissionsService = {

  getPendings() {
    return api.admin.get("/admin/admission/pending");
  },
  getAnswered() {
    return api.admin.get("/admin/admission/answered");
  },
  getAdmissionApplication(id) {
    return api.admin.get(`admin/admission/${id}`);
  },
  answerAdmissionApplication(id,model) {
    return api.admin.post(`admin/admission/${id}`,model,{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
};
