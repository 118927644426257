import { api } from "../../client";

export const StatisticsService = {
  
  getStatistics() {
    return api.base.get("/user/statistics");
  },
 
 
};
