
const UploadImgVariantContent = ({
  files,
  currentVariant,
  setVisible,
  currentImageView,
  setCurrentImage,
  linkVariantToImg,
  setFieldValue,
}) => {

  const addImageToVariant = async (file) => {
    setCurrentImage(file.type);
    linkVariantToImg(currentVariant, file.type, setFieldValue);
    setVisible(false);
  };

  return (
    <div className="text-gray-700">
      <div className="px-10 py-4 md:px-20 md:py-7 border-b border-b-gray-350 text-center md:text-left flex items-center">
        <p className="text-3xl font-bold ml-5">Selecciona una imagen</p>
      </div>
      <div className="px-10 py-3 md:py-10 md:px-20 flex flex-row">
        {files?.length > 0 &&
          files.map((file, index) => (
            <div
              key={`variant-images-${index}`}
              className="w-1/5 ml-5 h-full text-center"
            >
              <button
                type="button"
                className={`rounded-lg hover:border-2 hover:border-black ${
                  currentImageView === file.type ? "border-2 border-black" : ""
                }`}
                onClick={() => addImageToVariant(file)}
              >
                <img
                  alt=""
                  className="rounded-lg object-cover h-20 md:h-40 w-full"
                  src={file.name}
                ></img>
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default UploadImgVariantContent;
