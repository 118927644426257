import { useState, useEffect } from "react";
import TableLoading from "../components/TableLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import * as _ from "lodash";
import moment from "moment";
import BasicModal from "../components/Modals/AdmissionModal";
import { WithdrawalService } from "../services/api/withdrawal";
import {
    getColumnsWhitdrawalsDesktop,
    customStyles,
    paginationComponentOptions
} from "../utils/tableOptions";


import TableNoData from "../components/TableNoData";
export default function PendingsAnsweredWhithdrawals(element) {

    const [pendings, setPendings] = useState();
    const [loading, setLoading] = useState(true);
    const [querySearch, setQuerySearch] = useState("");
    const [formattedWhithdrawal, setFormattedWhithdrawal] = useState([]);
    const inputHandler = (e) => setQuerySearch(e.target.value.toLowerCase());
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState();

    const filteredData = formattedWhithdrawal.filter((user) => {
        if (querySearch === "") {
            return user;
        } else {
            return (
                user.status.toLowerCase().includes(querySearch)
            );
        }
    });


    const getPendings = () => {
        WithdrawalService
            .getWithdrawalHistory()
            .then((response) => {
                setPendings(response.data.withdrawals);
            })
            .catch((err) => {});
    };



    const formatTopUserData = () => {
        const setStatus = (value) => {
            switch (value) {
                case 'Denied': return 'RECHAZADA';
                case 'Accepted': return 'ACEPTADA';
                default: return 'PENDIENTE';
            }
        }
        const setColor = (value) => {
            switch (value) {
                case 'Denied': return 'text-red-50';
                case 'Accepted': return 'text-green-50';
                default: return 'text-yellow-500';
            }
        }
        let data = [];
        pendings.forEach((whithdrawal, idx) => {
            if (!whithdrawal.message) {
                whithdrawal.message = ""
            }

            data.push({
                status: whithdrawal.status,
                memberships_total: whithdrawal.amount,
                memberships_count: whithdrawal.memberships_count,
                created_at: moment(whithdrawal.created_at).format('DD/MM/YYYY'),

                actionComponent: (
                    <div className="flex justify-between items-center md:flex-col text-[#AEAEAE] pt-3 md:pt-0">
                        <div className="hidden md:block space-x-4 my-auto">
                            <div className={setColor(whithdrawal.status) + " font-bold"}>{setStatus(whithdrawal.status)}</div>
                        </div>
                        <div className="w-full flex md:hidden flex-row justify-end space-x-4 my-auto">
                            <div className={setColor(whithdrawal.status) + " font-bold"}>{setStatus(whithdrawal.status)}</div>
                        </div>
                    </div>
                ),

                answerComponent: (
                     whithdrawal.message ? <div className="flex justify-between items-center md:flex-col text-[#AEAEAE] pt-3 md:pt-0">
                        <div className="hidden md:block space-x-4 my-auto">
                            <button
                                onClick={() => {
                                    setShowModal(true);
                                    setModalContent(<div className="px-12 text-2xl lg:text-3xl text-center" role="dialog">
                                        <div className="my-10 ml-0 font-bold lg:text-left text-center"> Comentario de solicitud </div>
                                        <textarea id="value" name="value" rows="4" cols="50"
                                            className="mb-5 w-full h-36 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                                            placeholder="Comentario predeterminado"
                                            value={whithdrawal.message}
                                            disabled={true}
                                        ></textarea>
                                    </div>)

                                }}
                                className="bg-primary-200  font-bold text-white p-2 px-2 lg:px-4">CONSULTAR</button>
                        </div>
                        <div className="w-full flex md:hidden flex-row justify-end space-x-4 my-auto">
                            <button className="bg-primary-200  text-sm lg:text-xl font-bold text-white p-2 px-2 lg:px-4">CONSULTAR</button>


                        </div>

                    </div> : <div className={`text-gray-700 my-auto`}>NO DISPONIBLE</div>
                ),
            });
        });

        setFormattedWhithdrawal(data);
        setLoading(false);
    };

    useEffect(() => {
        setLoading(false)
        if (_.isEmpty(pendings)) return;
        formatTopUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pendings]);

    useEffect(() => {
        getPendings()
    }, []);

    useEffect(() => {
        setQuerySearch("");
    }, [pendings]);


    return (
        <>
            <BasicModal
                visible={showModal}
                setVisible={setShowModal}
                modalContent={modalContent}
            />

            <div className="text-xl lg:text-4xl font-bold mt-5">Historial de retiros</div>
            <div className="flex flex-col md:flex-row justify-center md:justify-between items-center gap-8 px-4 lg:px-8 py-3 mt-6 border-b border-b-gray-350">
                <div className=" w-full">
                    <label className="relative block">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <FontAwesomeIcon
                                icon={["fa", "magnifying-glass"]}
                                className="text-[#A9A9A9]"
                                size="sm"
                            />
                        </span>
                        <input
                            className="w-full  text-xl lg:text-2xl bg-white text-gray-700 placeholder:font-italic border border-slate-300 py-1 lg:py-3 pl-12 pr-4 focus:outline-none"
                            type="text"
                            placeholder="Buscar"
                            value={querySearch}
                            onChange={inputHandler}
                        />
                    </label>
                </div>
            </div>
            <DataTable
                columns={getColumnsWhitdrawalsDesktop}
                data={filteredData}
                noDataComponent={<TableNoData />}
                progressPending={loading}
                progressComponent={<TableLoading />}
                customStyles={customStyles}
                pagination
                paginationComponentOptions={paginationComponentOptions}
            />
        </>
    );
}
