/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as _ from "lodash";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DndProvider } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch";

import Context from "../../../../context/AdminUserContext";

import { notifications } from "../../../../utils/notifications";
import { adminCoursesServices } from "../../../../services/api/admin/courses";
import LessonCard from "../../../../components/admin/LessonCard";

const MySwal = withReactContent(Swal);

const EditSection = () => {
  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
  });

  const { currentAdminUser } = useContext(Context);

  const navigate = useNavigate();
  const params = useParams();

  const [section, setSection] = useState({});
  const [lessons, setLessons] = useState([]);
  const [initialLessonsOrder, setInitialLessonsOrder] = useState([]);
  const [showSaveOrder, setShowSaveOrder] = useState(false);

  const [initialValues, setInitialValues] = useState({
    name: "",
  });

  useEffect(() => {
    if (!_.isEmpty(section)) {
      setInitialValues({
        name: section.name || "",
      });
    }
  }, [section]);

  const fetchSection = () => {
    adminCoursesServices
      .sectionDetails(params.id)
      .then((response) => {
        setSection(response.data.section);
        setLessons(response.data.section.classes);
        setInitialLessonsOrder(response.data.section.classes);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchSection();
  }, [currentAdminUser]);

  const handleSubmit = async (values) => {
    let bodyFormData = new FormData();

    bodyFormData.append("_method", "PUT");
    bodyFormData.append("name", values.name);

    await adminCoursesServices
      .updateSection(params.id, bodyFormData)
      .then((response) => {
        notifications.success(response.data.message);
        navigate(`/admin/cursos/editar/${params.uuid}`);
      })
      .catch((err) => {});
  };

  const handleDelete = () => {
    MySwal.fire({
      icon: "error",
      title: "¿Deseas eliminar está sección?",
      text: "Se eliminará todo el contenido asociado. Esta acción es irreversible",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#42a819",
      confirmButtonText: "Eliminar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await adminCoursesServices
          .deleteSection(section.id)
          .then((response) => {
            notifications.success(response.data.message);
            navigate(`/admin/cursos/editar/${params.uuid}`);
          })
          .catch((err) => {});
      }
    });
  };

  const moveLessonsListItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = lessons[dragIndex];
      const hoverItem = lessons[hoverIndex];

      let updatedLessons = [...lessons];
      updatedLessons[dragIndex] = hoverItem;
      updatedLessons[hoverIndex] = dragItem;

      setLessons(updatedLessons);
    },
    [lessons]
  );

  useEffect(() => {
    _.isEqual(initialLessonsOrder, lessons)
      ? setShowSaveOrder(false)
      : setShowSaveOrder(true);
  }, [lessons, initialLessonsOrder]);

  const getLessonsOrder = () => {
    return lessons.map((lesson, idx) => ({ id: lesson.id, order: idx }));
  };

  const updateLessonsOrder = async () => {
    let order = getLessonsOrder();
    await adminCoursesServices
      .updateLessonOrder({ classes: order })
      .then((response) => {
        notifications.success(response.data.message);
        setInitialLessonsOrder(lessons);
      })
      .catch((err) => {});
  };

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex-col">
        <button
          className="mr-auto text-primary-200 mb-5"
          onClick={() => navigate(-1)}
        >
          Regresar
        </button>
        <div className="flex flex-col md:flex-row justify-between items-center">
          <p className="text-2xl lg:text-4xl font-bold">Editar sección</p>
        </div>
      </div>

      <div className="mt-4 lg:mt-12">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="flex flex-col md:flex-row md:gap-x-5 xl:gap-x-10">
                <div className="text-center lg:text-left lg:mb-9 text-xl lg:text-3xl text-gray-550 md:col-span-2 w-full md:w-1/2 xl:w-2/3">
                  <p>Nombre de la sección</p>
                  <Field
                    name="name"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="w-full md:w-1/2 xl:w-1/3 flex flex-col gap-y-5 md:flex-row md:justify-between md:gap-x-5 xl:gap-x-10 mt-5 md:mt-0">
                  <button
                    type="submit"
                    className="w-full md:w-56 bg-primary-200 text-white text-xl lg:text-2xl py-3 px-5 disabled:opacity-75 disabled:cursor-not-allowed md:my-9"
                    disabled={section.name === values.name}
                  >
                    Guardar
                  </button>
                  <button
                    type="button"
                    className="w-full md:w-56 bg-tertiary text-white text-xl lg:text-2xl py-3 px-5 disabled:opacity-75 md:my-9"
                    onClick={() => handleDelete()}
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        {/* LESSONS */}
        <div className="text-center lg:text-left mb-5 text-xl lg:text-3xl text-gray-550">
          <p className="my-5 md:mb-3 md:mt-0">Lecciones</p>
        </div>
        {!_.isEmpty(lessons) ? (
          <>
            <DndProvider options={HTML5toTouch}>
              <div className="border border-gray-350">
                {lessons.map((lesson, idx) => (
                  <LessonCard
                    key={`lesson-${lesson.id}`}
                    uuid={params.uuid}
                    lesson={lesson}
                    index={idx}
                    moveListItem={moveLessonsListItem}
                  />
                ))}
              </div>
            </DndProvider>
            {showSaveOrder && (
              <button
                type="button"
                className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75 mt-9"
                onClick={() => updateLessonsOrder()}
              >
                Actualizar orden
              </button>
            )}
          </>
        ) : (
          <div className="bg-gray-300 border-l-4 border-l-primary-200 p-4">
            <p className="text-center lg:text-left text-lg lg:text-xl text-gray-700 font-bold">
              Esta sección aun no contiene lecciones, ¡Añade una!
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditSection;
