import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { notifications } from "../utils/notifications";

import Context from "../context/UserContext";
import appStorage from "../utils/appStorage";
import { tokenLocalStorageKey } from "../utils";
import { authService } from "../services/api/auth";

export default function useUser() {
  const navigate = useNavigate();

  const { jwt, setJWT, setCurrentUser } = useContext(Context);
  const [state, setState] = useState({
    loading: false,
    error: false,
    success: false,
  });
  const [errors, setErrors] = useState([]);

  const login = useCallback(
    ({ email, password }) => {
      setState({ loading: true, error: false, success: false });

      authService
        .login({ email, password })
        .then((response) => {
          setState({ loading: false, error: false, success: true });
          let token = response.data.token;
          appStorage.setItem(tokenLocalStorageKey, token);
          setJWT(token);
        })
        .catch((err) => {
          setState({ loading: false, error: true, success: false });
          appStorage.removeItem(tokenLocalStorageKey);

          if (err.response.data.errors) {
            setErrors([err.response.data.error]);
          } else if (err.response.data.message) {
            setErrors([err.response.data.message]);
          }
        });
    },
    [setJWT]
  );

  const register = useCallback(
    async ({ name, email, password, password_confirmation, phone }) => {
      setState({ loading: true, error: false, success: false });

      await authService
        .register({ name, email, password, password_confirmation, phone })
        .then((response) => {
          setState({ loading: false, error: false, success: true });
        })
        .catch((err) => {
          setState({ loading: false, error: true, success: false });
          if (err.response.data.errors) {
            setErrors(err.response.data.errors);
          }
        });
    },
    []
  );

  const verifyAccount = useCallback(async ({ email, code }) => {
    setState({ loading: true, error: false, success: false });

    await authService
      .verifyAccount({ email, code })
      .then((response) => {
        setState({ loading: false, error: false, success: true });
        notifications.success(response.data.message);
      })
      .catch((err) => {
        setState({ loading: false, error: true, success: false });
        if (err.response.data.error) {
          setErrors([err.response.data.error]);
        }
      });
  }, []);

  const resendVerificationCode = useCallback(async ({ email }) => {
    setState({ loading: true, error: false, success: false });

    await authService
      .resendVerificationCode({ email })
      .then((response) => {
        setState({ loading: false, error: false, success: true });
      })
      .catch((err) => {
        setState({ loading: false, error: true, success: false });
        if (err.response.data.error) {
          setErrors([err.response.data.error]);
        }
      });
  }, []);

  const sendPasswordReset = useCallback(async ({ email }) => {
    setState({ loading: true, error: false, success: false });

    await authService
      .sendPasswordReset({ email })
      .then((response) => {
        setState({ loading: false, error: false, success: true });
        notifications.success(response.data.message);
      })
      .catch((err) => {
        setState({ loading: false, error: true, success: false });
        if (err.response.data.error) {
          setErrors([err.response.data.error]);
        }
      });
  }, []);

  const passwordReset = useCallback(
    async ({ email, password, password_confirmation, code }) => {
      setState({ loading: true, error: false, success: false });

      await authService
        .passwordReset({ email, password, password_confirmation, code })
        .then((response) => {
          setState({ loading: false, error: false, success: true });
          notifications.success(response.data.message);
        })
        .catch((err) => {
          setState({ loading: false, error: true, success: false });
          if (err.response.data.error) {
            setErrors([err.response.data.error]);
          }
        });
    },
    []
  );

  const logout = useCallback(async () => {
    await authService
      .logout()
      .then((response) => {
        appStorage.removeItem(tokenLocalStorageKey);
        setJWT(null);
        setCurrentUser(null);
        navigate("/", { replace: true });
      })
      .catch((err) => {
        if (err.response.data.error) {
          setErrors([err.response.data.error]);
        }
      });
  }, [setJWT, setCurrentUser, navigate]);

  return {
    isLogged: Boolean(jwt),
    isLoading: state.loading,
    hasError: state.error,
    onSucess: state.success,
    login,
    register,
    verifyAccount,
    resendVerificationCode,
    sendPasswordReset,
    passwordReset,
    logout,
    errors,
  };
}
