import { Fragment, useContext } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

import Context from "../context/AdminUserContext";
import useAdminUser from "../hooks/useUserAdmin";

export default function AdminDropdown() {
  const { currentAdminUser } = useContext(Context);

  const { logout } = useAdminUser();

  return (
    <div className="text-right">
      <Menu as="div" className="relative inline-block text-left">
        <div className="w-12 h-12">
          <Menu.Button
            className="rounded-full w-12 h-12"
            style={{ backgroundColor: "#D8D8D8" }}
          >
            <img
              src={currentAdminUser && currentAdminUser.image}
              alt=""
              className="rounded-full w-full h-full object-cover"
              style={{ backgroundColor: "#D8D8D8" }}
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/admin/mi-perfil"
                    className={`${
                      active ? "bg-primary-200 text-white" : "text-gray-700"
                    } group flex w-full items-center px-2 py-2`}
                  >
                    Mi Perfil
                  </Link>
                )}
              </Menu.Item>
            </div>
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/admin/cambiar-password"
                    className={`${
                      active ? "bg-primary-200 text-white" : "text-gray-700"
                    } group flex w-full items-center px-2 py-2`}
                  >
                    Cambiar contraseña
                  </Link>
                )}
              </Menu.Item>
            </div>

            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-primary-200 text-white" : "text-gray-700"
                    } group flex w-full items-center px-2 py-2`}
                    onClick={() => logout()}
                  >
                    Cerrar Sesión
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
