/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressBar from "@ramonak/react-progress-bar";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as _ from "lodash";
import fileDownload from "js-file-download";
import { notifications } from "../utils/notifications";
import { coursesServices } from "../services/api/courses";
import Context from "../context/UserContext";
import logo from "../assets/images/logo-globe-gbp.png";
import VideoPlayer from "../components/VideoPlayer";
import { toHHMMSS } from "../utils";
import CompletedCourseModal from "../components/Modals/CompletedCourseModal";
import NetworkMonitorModal from "../components/Modals/NetworkMonitorModal";
import Ping from "ping.js";
import { loggerServices } from "../services/api/logger";

const CourseContent = () => {
  const formSchema = Yup.object().shape({
    qc_content: Yup.string().required("El campo es requerido"),
  });

  const initialValues = {
    qc_content: "",
  };

  const params = useParams();
  const navigate = useNavigate();
  const { currentUser } = useContext(Context);
  const p = new Ping();

  const [course, setCourse] = useState({});
  const [courseClass, setCourseClass] = useState(null);
  const [courseClassFavorite, setCourseClassFavorite] = useState(false);
  const [openSection, setOpenSection] = useState(null);
  const [menuContent, setMenuContent] = useState("questions");

  const [noteContent, setNoteContent] = useState("");
  const [notes, setNotes] = useState([]);
  const [duration, setDuration] = useState(null);
  const [allLessons, setAllLessons] = useState([]);
  const [showNextLessonBtn, setShowNextLessonBtn] = useState(true);
  const [isLessonCompleted, setIsLessonCompleted] = useState(false);
  const [isCourseCompleted, setIsCourseCompleted] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [isLoadingVideo, setIsLoadingVideo] = useState(true);
  const [resourceFound, setResourceFound] = useState(true);
  const [isObserving, setIsObserving] = useState(false);
  const [logs, setLogs] = useState([]);
  const [pingOneLogs, setPingOneLogs] = useState([]);
  const [pingTwoLogs, setPingTwoLogs] = useState([]);
  const [pingOneMetrics, setPingOneMetrics] = useState({});
  const [pingTwoMetrics, setPingTwoMetrics] = useState({});
  const [loadingNetworkMonitor, setLoadingNetworkMonitor] = useState(false);
  const [showNetworkMonitorModal, setShowNetworkMonitorModal] = useState(true);
  const [videoError, setVideoError] = useState(false);
  const [showNetworkMonitorButton, setShowNetworkMonitorButton] =
    useState(false);

  const combineAllLessons = (sections, lessons) => {
    let arr = [];
    sections.forEach((section) => {
      arr = [...arr, ...section.classes];
    });
    arr = [...arr, ...lessons];

    return arr;
  };

  const checkIfExistsMoreLessons = () => {
    let currentLessonIdx = allLessons.findIndex(
      (lesson) => lesson.id === courseClass
    );
    let nextLessonIdx = currentLessonIdx + 1;
    let nextLesson = allLessons[nextLessonIdx];

    if (nextLesson === undefined) {
      return { status: false, id: null };
    }

    return { status: true, id: nextLesson.id };
  };

  const goToNextLesson = () => {
    let { status, id } = checkIfExistsMoreLessons();

    if (!status) {
      setShowNextLessonBtn(false);
      return;
    }

    setShowNextLessonBtn(true);

    navigate(`/cursos/contenido/${params.uuid}/${id}`);
  };

  useEffect(() => {
    if (_.isEmpty(allLessons)) return;
    let { status } = checkIfExistsMoreLessons();
    if (!status) setShowNextLessonBtn(false);
    setIsLessonCompleted(lessonIsCompleted());
    checkCourseIsCompleted();
  }, [allLessons]);

  useEffect(() => {
    if (!currentUser) return;
    if (params.id) {
      startObservation();
      setCourseClass(parseInt(params.id));
    }
  }, [currentUser, params.id]);

  useEffect(() => {
    if (!currentUser) return;
    //
    async function fetchCourseView() {
      await coursesServices
        .getCourseView(params.uuid)
        .then((response) => {
          if (!params.id) return navigate(`${response.data.classe.id}`);
          if (params.id !== response.data.classe.id) return;
          setCourseClass(parseInt(response.data.classe.id));
        })
        .catch((err) => {
          navigate("/cursos");
        });
    }
    fetchCourseView();
  }, [currentUser, params.uuid]);

  useEffect(() => {
    if (courseClass === null) return;
    async function fetchCourseContent() {
      await coursesServices
        .getCourseContent(params.uuid, courseClass)
        .then((response) => {
          setCourseClassFavorite(false);
          if (response.data.course.classe.isFavorite) {
            setCourseClassFavorite(true);
          }
          let course = response.data.course;
          setCourse(course);
          setDuration(course.classe.time);
          setNotes(course.notes);
          setIsLoadingVideo(false);
          setLogs([]);
          setPingOneLogs([]);
          setPingTwoLogs([]);
          setPingOneMetrics({});
          setPingTwoMetrics({});
          setLoadingNetworkMonitor(false);
          setShowNetworkMonitorModal(false);
          setVideoError(false);
          setShowNetworkMonitorButton(false);
          if (course.progress === 100) setIsCourseCompleted(true);
          if (!_.isEmpty(allLessons)) {
            let { status } = checkIfExistsMoreLessons();
            if (status) setShowNextLessonBtn(true);
          }
        })
        .catch((err) => {});
    }

    setResourceFound(true);
    setIsLoadingVideo(true);
    fetchCourseContent();
  }, [courseClass, params.uuid]);

  useEffect(() => {
    const findInitialSection = () => {
      if (_.isEmpty(course) || _.isEmpty(course.sections)) return;

      course.sections.map((section) => {
        return section.classes.map((classe) =>
          classe.id === courseClass ? setOpenSection(section.id) : false
        );
      });
    };
    findInitialSection();
  }, [course, courseClass]);

  const sendQuestion = async (question, resetForm) => {
    await coursesServices
      .sendQuestion({ uuid: params.uuid, question })
      .then((response) => {
        notifications.success(response.data.message);
        resetForm();
      })
      .catch((err) => {});
  };

  const sendComment = async (comment, resetForm) => {
    await coursesServices
      .sendComment({ uuid: params.uuid, comment })
      .then((response) => {
        notifications.success(response.data.message);
        resetForm();
      })
      .catch((err) => {});
  };

  const handleFavorite = async () => {
    await coursesServices
      .updateClassFavorites({ id: course.classe.id })
      .then((response) => {
        notifications.success(response.data.message);
        setCourseClassFavorite(response.data.isFavorite);
      })
      .catch((err) => {});
  };

  const handleOpenSection = (id) => {
    id === openSection ? setOpenSection(null) : setOpenSection(id);
  };

  const handleSubmit = ({ qc_content }, resetForm) => {
    menuContent === "questions"
      ? sendQuestion(qc_content, resetForm)
      : sendComment(qc_content, resetForm);
  };

  const handleAddNote = async () => {
    if (_.isEmpty(noteContent)) {
      notifications.warn("¡No puedes agregar una nota vacía!");
      return;
    }
    await coursesServices
      .saveNote({ uuid: course.uuid, note: noteContent })
      .then((response) => {
        notifications.success(response.data.message);
        setNoteContent("");
        setNotes((state) => [...state, response.data.note]);
      })
      .catch((err) => {});
  };

  const handleDeleteNote = async (id) => {
    await coursesServices
      .deleteNote(id)
      .then((response) => {
        notifications.success(response.data.message);
        setNotes((state) => state.filter((note) => note.id !== id));
      })
      .catch((err) => {});
  };

  const findWhereLessonIs = () => {
    let idx = null;
    let _section = null;
    let _lesson = null;
    let _lessonId = null;

    if (_.isEmpty(course)) return;

    course.sections.forEach((section, sectionIdx) => {
      idx = section.classes.findIndex((lesson) => lesson.id === courseClass);
      if (idx !== -1) {
        _section = sectionIdx;
        _lessonId = section.classes[idx].id;
        return;
      }
    });

    if (_section !== null)
      return {
        where: "section",
        sectionIdx: _section,
        lessonId: _lessonId,
      };

    _lesson = course.classes.findIndex((classe) => classe.id === courseClass);
    _lessonId = course.classes[_lesson].id;
    return {
      where: "classes",
      sectionIdx: null,
      lessonId: _lessonId,
    };
  };

  const updateLessonsState = () => {
    let { where, sectionIdx, lessonId } = findWhereLessonIs();

    if (where === "section") {
      let arrLessons = course.sections[sectionIdx].classes.map((lesson) => {
        if (lesson.id === lessonId) {
          return { ...lesson, watched: true };
        }
        return lesson;
      });

      let newSection = { ...course.sections[sectionIdx], classes: arrLessons };

      let sections = course.sections;
      sections.splice(sectionIdx, 1, newSection);

      setCourse((state) => ({
        ...state,
        sections,
      }));
    } else {
      let arrLessons = course.classes.map((lesson) => {
        if (lesson.id === lessonId) {
          return { ...lesson, watched: true };
        }
        return lesson;
      });

      setCourse((state) => ({
        ...state,
        classes: arrLessons,
      }));
    }
  };

  useEffect(() => {
    if (_.isEmpty(course)) return;
    setAllLessons(combineAllLessons(course.sections, course.classes));
  }, [course]);

  const lessonIsCompleted = () => {
    let idx = allLessons.findIndex((lesson) => lesson.id === courseClass);
    if (idx !== -1) return allLessons[idx].watched;
  };

  const handleSaveLessonProgress = async (isDone) => {
    if (!isDone) return;
    if (lessonIsCompleted()) return;

    await coursesServices
      .saveLessonProgress({ id: courseClass })
      .then((response) => {
        updateLessonsState();
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!isCourseCompleted) return;
    setCourse((state) => ({
      ...state,
      progress: 100,
    }));
  }, [isCourseCompleted]);

  const checkCourseIsCompleted = () => {
    let completed = allLessons.every((lesson) => lesson.watched === true);
    if (completed) {
      setIsCourseCompleted(true);
    }
  };

  const handleGenerateDiploma = async () => {
    await coursesServices
      .generateDiploma({ uuid: params.uuid })
      .then((response) => {
        setShowModal(true);
        fileDownload(response.data, `${course.name} - Diploma.pdf`);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          notifications.warn(
            "Para generar el certificado debes puntuar y comentar el curso."
          );
        }
      });
  };

  const startObservation = () => {
    if (!isObserving) {
      const networkObserver = new PerformanceObserver((list) => {
        const entries = list.getEntries();

        entries.forEach(async (entry) => {
          if (entry instanceof PerformanceResourceTiming) {
            if (
              entry.name.includes(
                "https://service.beglobalpro.org/api/storage/courses/"
              )
            ) {
              const log = `Archivo: ${entry.name}, Tiempo de carga: ${entry.duration} ms`;
              setLogs((prevLogs) => [...prevLogs, log]);
            }
          }
        });
      });

      networkObserver.observe({ entryTypes: ["resource"] });

      setIsObserving(true);
    }
  };

  const stopObservation = () => {
    setIsObserving(false);
  };

  const generateLogs = async () => {
    stopObservation();
    setLoadingNetworkMonitor(true);
    setShowNetworkMonitorModal(true);

    let _logs = logs;

    if (_logs.length > 0) {
      for (const [idx, log] of _logs.entries()) {
        let matchLink = log.match(/Archivo:\s(https?:\/\/[^\s,]+)/);
        let matchMS = log.match(/Tiempo de carga:\s(\d+(\.\d+)?)\sms/);

        if (matchLink?.[1] && matchMS?.[1]) {
          let link = matchLink[1];
          let duration = matchMS[1];

          const response = await fetch(link);
          if (response.ok) {
            const transferSize = parseInt(
              response.headers.get("content-length") || 0
            );
            const transferRateKBps = ((transferSize / duration) * 1000) / 1024;
            const transferRateLog = `Tasa de transferencia: ${transferRateKBps.toFixed(
              2
            )} KB/s`;

            const finalLog = log + ", " + transferRateLog;
            _logs[idx] = finalLog;
          } else {
            const finalLog =
              log + ", Tasa de transferencia: 0 KB/s (Error de red)";
            _logs[idx] = finalLog;
          }
        }
      }
    }

    setLogs(_logs);

    await ping("http://beglobalpro.org", 1);
    await ping("http://www.google.com", 2);
  };

  const ping = async (url, save = 1) => {
    const resultados = [];
    const resultadosString = [];
    let fetchesExitosos = 0;
    let fetchesFallidos = 0;
    let sumaTotalTiempo = 0;
    const inicioTiempoGlobal = new Date();

    while (true) {
      try {
        const response = await new Promise((resolve, reject) => {
          p.ping(url, (err, data) => {
            if (err) {
              reject(err);
            } else {
              resolve({ data });
            }
          });
        });

        const data = response.data;
        resultadosString.push(`Respuesta exitosa en ${data} ms`);
        resultados.push(data);
        fetchesExitosos++;
        sumaTotalTiempo += data;
      } catch (err) {
        resultadosString.push(`Fallo en la respuesta`);
        fetchesFallidos++;
      }

      const tiempoTotalTranscurrido = new Date() - inicioTiempoGlobal;
      if (tiempoTotalTranscurrido >= 30000) {
        break;
      }

      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    // Calcular estadísticas
    const tiempoMaximo = Math.max(...resultados);
    const tiempoMinimo = Math.min(...resultados);
    const tiempoPromedio =
      resultados.length > 0 ? sumaTotalTiempo / resultados.length : 0;

    let metrics = {
      maxTime: tiempoMaximo + " ms",
      minTime: tiempoMinimo + " ms",
      avgTime: Math.round(tiempoPromedio) + " ms",
      totalTime: sumaTotalTiempo + " ms",
      totalPackages: fetchesExitosos + fetchesFallidos,
      successPackages: fetchesExitosos,
      failedPackages: fetchesFallidos,
    };

    if (save === 1) {
      setPingOneLogs(resultadosString);
      setPingOneMetrics(metrics);
    } else {
      setPingTwoLogs(resultadosString);
      setPingTwoMetrics(metrics);
    }
  };

  const saveNetworkMonitorData = async (data) => {
    await loggerServices
      .addNetworkHistoryLog({
        data,
      })
      .then((response) => {
        notifications.success(response.data.message);
      })
      .catch((err) => {})
      .finally(() => {
        setLoadingNetworkMonitor(false);
        setShowNetworkMonitorModal(false);
      });
  };

  useEffect(() => {
    if (
      !_.isEmpty(pingOneLogs) &&
      !_.isEmpty(pingTwoLogs) &&
      !_.isEmpty(pingOneMetrics) &&
      !_.isEmpty(pingTwoMetrics)
    ) {
      logs.pop();
      saveNetworkMonitorData(
        JSON.stringify({
          logs: logs,
          pingBGP: {
            logs: pingOneLogs,
            metrics: pingOneMetrics,
          },
          pingGoogle: {
            logs: pingTwoLogs,
            metrics: pingTwoMetrics,
          },
        })
      );
    }
  }, [pingOneLogs, pingTwoLogs, pingOneMetrics, pingTwoMetrics]);

  useEffect(() => {
    if (!videoError) return;
    // notifications.error(
    //   "Ocurrió un error al cargar el video, el botón del 'Enviar reporte de red' ha sido habilitado, lo puedes encontrar debajo del reproductor."
    // );
    setShowNetworkMonitorButton(true);
  }, [videoError]);

  if (Object.values(course).length === 0) return;

  return (
    <main className="container mx-auto px-4 xl:px-0 mb-16">
      <div className="border-b border-gray-200">
        <div className="h-20 flex items-center">
          <img src={logo} alt="Be Global Pro" className="h-6 mr-2" />
          <p className=" text-gray-700">
            <FontAwesomeIcon
              icon="fa-chevron-right"
              className="mr-1 text-sm hidden md:inline-block"
            />{" "}
            <Link to="../../cursos" className="mr-1">
              Cursos
            </Link>{" "}
            <FontAwesomeIcon icon="fa-chevron-right" className="mr-1 text-sm" />{" "}
            <Link to={`../../cursos/${course.uuid}`} className="mr-1">
              {course.name}{" "}
            </Link>{" "}
            <FontAwesomeIcon
              icon="fa-chevron-right"
              className="mx-1 text-sm hidden md:inline-block"
            />{" "}
            <br className="block md:hidden" />
            <span className="text-gray-600">{course.classe.name}</span>
          </p>
        </div>
      </div>

      <div className="container mx-auto px-2">
        <h1 className="font-bold text-center lg:text-left text-3xl xl:text-5xl text-gray-700 mt-12 mb-2">
          <Link to={`../../cursos/${course.uuid}`}>{course.name}</Link>{" "}
        </h1>

        <h2 className="border-b border-gray-400 text-gray-700 font-bold text-xl xl:text-3xl pb-4 mb-4 text-center lg:text-left">
          {course.classe.name}
        </h2>
        <div className="flex items-center text-gray-400 mb-6">
          <FontAwesomeIcon icon={["far", "clock"]} size="xl" />
          <p className="text-xl pl-2">{duration && toHHMMSS(duration)}</p>
        </div>

        <div className="flex flex-col md:flex-row gap-8">
          {/* FIRST COL */}
          <div className="md:w-1/2 lg:w-2/3">
            {!isLoadingVideo ? (
              <>
                {resourceFound ? (
                  <>
                    <VideoPlayer
                      url={course.classe.video}
                      poster={course.course_image}
                      handleSaveLessonProgress={handleSaveLessonProgress}
                      setResourceFound={setResourceFound}
                      setVideoError={setVideoError}
                    />

                    {courseClassFavorite ? (
                      <div className="flex justify-end items-center text-secondary mt-1">
                        <button onClick={() => handleFavorite()}>
                          <FontAwesomeIcon
                            icon={["fas", "star"]}
                            className="mx-1"
                          />
                        </button>
                        <p className="pl-1 text-lg">Añadido a favoritos</p>
                      </div>
                    ) : (
                      <div className="flex justify-end items-center text-gray-700 mt-1">
                        <button onClick={() => handleFavorite()}>
                          <FontAwesomeIcon
                            icon={["far", "star"]}
                            className="mx-1"
                          />
                        </button>
                        <p className="pl-1 text-lg">Añadir a favoritos</p>
                      </div>
                    )}

                    {showNetworkMonitorButton && (
                      <button
                        className=" bg-primary-200 text-center py-3 px-6 text-white font-bold w-1/2 md:w-auto disabled:cursor-not-allowed disabled:opacity-75 mt-3"
                        onClick={() => generateLogs()}
                        disabled={loadingNetworkMonitor}
                      >
                        {loadingNetworkMonitor ? (
                          <span>
                            Enviar reporte de red{" "}
                            <FontAwesomeIcon icon="circle-notch" spin />
                          </span>
                        ) : (
                          <span>Enviar reporte de red</span>
                        )}
                      </button>
                    )}
                  </>
                ) : (
                  <div className="bg-black h-48 md:h-[270px] lg:h-[366px] xl:h-[461px] 2xl:h-[558px] rounded-md">
                    <div className="flex flex-col h-full justify-center items-center text-white font-bold">
                      <p className="text-4xl lg:text-5xl xl:text-6xl">404</p>
                      <p className="text-3xl lg:text-4xl xl:text-5xl">
                        Recurso no encontrado
                      </p>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="bg-black h-48 md:h-[270px] lg:h-[366px] xl:h-[461px] 2xl:h-[558px] rounded-md" />
            )}

            {/* TODO: HIDE RESOURCES IF EMPTY AND ADD DOWNLOAD LINK TO BUTTON */}
            {!_.isEmpty(course.resources) && (
              <div className="border-t border-gray-400 mt-8 py-8">
                <h4 className=" text-gray-700 font-bold text-xl lg:text-4xl mb-2 lg:mb-4">
                  Recursos adicionales
                </h4>
                <div>
                  {course.resources.map((resource) => (
                    <div
                      key={resource.id}
                      className="flex items-center text-xl text-gray-700"
                    >
                      <FontAwesomeIcon
                        icon={["far", "file"]}
                        size="lg"
                        className="mr-1"
                      />
                      <p className="text-base lg:text-lg px-2">
                        {resource.name}
                      </p>
                      <a
                        href={resource.file}
                        className="text-primary-200"
                        download
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={["far", "circle-down"]}
                          className="mx-1"
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="border-t border-gray-400 lg:mt-8 py-8">
              <h4 className="text-center md:text-left text-gray-700 font-bold text-xl  lg:text-4xl mb-4">
                Mi progreso
              </h4>

              <ProgressBar
                bgColor="#60a5fa"
                baseBgColor="#dadada"
                isLabelVisible={false}
                height="10px"
                borderRadius="0px"
                animateOnRender
                completed={course.progress}
                maxCompleted={100}
              />

              <div className="flex items-center justify-center md:justify-end gap-4 mt-4 mb-16">
                {!isCourseCompleted && (
                  <button
                    className=" bg-primary-200 text-center py-3 px-6 text-white font-bold w-1/2 md:w-auto disabled:cursor-not-allowed disabled:opacity-75"
                    onClick={() => handleSaveLessonProgress(true)}
                    disabled={isLessonCompleted}
                  >
                    {isLessonCompleted ? "Completada" : "Completar"}
                  </button>
                )}

                {isCourseCompleted && (
                  <button
                    className="bg-primary-200 text-center py-3 px-6 text-white font-bold w-1/2 md:w-auto disabled:cursor-not-allowed disabled:opacity-75"
                    onClick={() => handleGenerateDiploma()}
                  >
                    Generar certificado
                  </button>
                )}

                {showNextLessonBtn && (
                  <button onClick={() => goToNextLesson()}>
                    Siguiente lección &rarr;
                  </button>
                )}
              </div>
            </div>

            {/* Questions & Comments menu */}
            <div className="flex justify-center md:justify-start items-center gap-8">
              <button
                className="text-xl"
                onClick={() => setMenuContent("questions")}
              >
                <p
                  className={`${
                    menuContent === "questions"
                      ? "text-primary-200 font-bold"
                      : "text-gray-700"
                  }`}
                >
                  Preguntas
                </p>
              </button>

              <div className="bg-gray-400 w-px h-8" />

              <button
                className="text-xl"
                onClick={() => setMenuContent("comments")}
              >
                <p
                  className={`${
                    menuContent === "comments"
                      ? "text-primary-200 font-bold"
                      : "text-gray-700"
                  }`}
                >
                  Comentarios
                </p>
              </button>
            </div>

            {/* Questions & Comments form */}
            <div className="my-8">
              <Formik
                initialValues={{
                  qc_content: "",
                }}
                validationSchema={formSchema}
                onSubmit={(values, { resetForm }) =>
                  handleSubmit(values, () => resetForm(initialValues))
                }
              >
                <Form>
                  <Field
                    name="qc_content"
                    className="w-full h-24 md:h-32 lg:h-44 border-2 border-gray-400 text-gray-700 placeholder:text-gray-700 text-lg p-4 resize-none focus:outline-none"
                    as="textarea"
                    placeholder={`${
                      menuContent === "questions"
                        ? "Escribe aquí tu pregunta"
                        : "Escribe aquí tu comentario"
                    }`}
                  />
                  <ErrorMessage
                    name="qc_content"
                    component="div"
                    className="text-red-400 font-bold font-xs"
                  />
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      className="bg-primary-200 text-center py-3 px-8 text-white font-bold w-1/2 md:w-auto mt-4"
                    >
                      Publicar
                    </button>
                  </div>
                </Form>
              </Formik>

              {/* Questions & Comments data */}
              {menuContent === "questions" ? (
                <Questions questions={course.questions} />
              ) : (
                <Comments comments={course.comments} />
              )}
            </div>
          </div>

          {/* SECOND COL */}
          <div className="md:w-1/2 lg:w-1/3">
            <h3 className="text-center lg:text-left text-gray-700 font-bold text-2xl xl:text-3xl pb-4 mb-3">
              {course.sections.length === 0
                ? "Lecciones del curso"
                : "Secciones del curso"}
            </h3>

            {course.sections.map((section, idx) => {
              return (
                <div key={section.id}>
                  <p
                    className="text-gray-600 text-xl md:text-lg xl:text-2xl pb-2 uppercase cursor-pointer"
                    onClick={() => handleOpenSection(section.id)}
                  >
                    Sección {idx + 1} - {section.name}
                    <FontAwesomeIcon
                      icon={
                        openSection === section.id
                          ? "fa-chevron-down"
                          : "fa-chevron-right"
                      }
                      className="pl-1 cursor-pointer"
                      size="xs"
                    />
                  </p>
                  {openSection === section.id &&
                    section.classes.map((classe, i) => (
                      <div key={classe.id} className="flex items-center mb-2">
                        <div>
                          <div
                            className={`${
                              classe.id === course.classe.id
                                ? "bg-primary-200 text-white"
                                : "bg-gray-300 text-gray-700"
                            } w-7 h-7 rounded-full flex items-center justify-center`}
                          >
                            {i + 1}
                          </div>
                        </div>
                        <button
                          className="flex"
                          onClick={() =>
                            navigate(
                              `/cursos/contenido/${params.uuid}/${classe.id}`
                            )
                          }
                        >
                          <p className="text-gray-400 px-2 text-sm lg:text-lg text-left leading-none">
                            {classe.name}
                          </p>
                          {classe.watched && (
                            <FontAwesomeIcon
                              icon="fa-check"
                              className="text-green-500 ml-2"
                            />
                          )}
                        </button>
                      </div>
                    ))}
                </div>
              );
            })}

            {course.sections.length > 0 && course.classes.length > 0 && (
              <hr className="my-5 border-gray-500" />
            )}

            {course.classes.map((classe, i) => (
              <div key={classe.id} className="flex items-center mb-2">
                <div>
                  <div
                    className={`${
                      classe.id === course.classe.id
                        ? "bg-primary-200 text-white"
                        : "bg-gray-300 text-gray-700"
                    } w-7 h-7 rounded-full flex items-center justify-center`}
                  >
                    {i + 1}
                  </div>
                </div>
                <button
                  className="flex"
                  onClick={() =>
                    navigate(`/cursos/contenido/${params.uuid}/${classe.id}`)
                  }
                >
                  <p className="w-full text-gray-400 pl-2 text-sm lg:text-lg text-left leading-none">
                    {classe.name}
                  </p>
                  {classe.watched && (
                    <FontAwesomeIcon
                      icon="fa-check"
                      className="text-green-500 ml-2"
                    />
                  )}
                </button>
              </div>
            ))}

            {/* NOTES */}
            <div className="bg-blue-50 p-8 rounded-md h-[300px] lg:h-[440px] mt-10 overflow-hidden">
              <button
                className="text-primary-200 font-bold mb-4"
                onClick={() => handleAddNote()}
              >
                + Agregar Nota
              </button>
              <textarea
                className="w-full h-1/4 bg-transparent text-gray-500 placeholder:text-gray-500 resize-none focus:outline-none"
                placeholder="Usa Notas para anotar información relevante y recordatorios."
                value={noteContent}
                onChange={(e) => setNoteContent(e.target.value)}
              />
              {!_.isEmpty(notes) && (
                <>
                  <div className="border-b border-b-blue-100" />
                  <div className="h-2/3 overflow-y-scroll py-5 pr-5 text-[#9F9F9F]">
                    {notes.map((note) => (
                      <div key={note.id} className="flex items-start mb-3">
                        <p className="text-justify w-full">● {note.note}</p>
                        <button
                          className="pl-2"
                          onClick={() => handleDeleteNote(note.id)}
                        >
                          <FontAwesomeIcon
                            icon="fa-xmark"
                            className="text-[#636363]"
                          />
                        </button>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <CompletedCourseModal visible={showModal} setVisible={setShowModal} />
      <NetworkMonitorModal
        visible={showNetworkMonitorModal}
        setVisible={setShowNetworkMonitorModal}
      />
    </main>
  );
};

export default CourseContent;

const Questions = ({ questions }) => {
  return (
    <>
      {questions.length ? (
        questions.map((q) => {
          return (
            <div key={`question-${q.id}`}>
              <div className="flex items-start my-8">
                <div className="pr-4 my-auto lg:my-0">
                  <div className="h-10 w-10 lg:w-16 lg:h-16 bg-gray-400 rounded-full">
                    <img
                      src={q.user.image}
                      alt=""
                      className="object-cover rounded-full h-full w-full"
                      style={{ backgroundColor: "#D8D8D8" }}
                    />
                  </div>
                </div>
                <div>
                  <h4 className="font-bold text-xl lg:text-2xl  text-gray-700">
                    {q.user.name}
                  </h4>
                  <p className="text-gray-700 text-sm">
                    <Moment format="DD/MM/YYYY">{q.createdAt}</Moment>
                  </p>
                  <p className="md:mr-40">{q.question}</p>
                </div>
              </div>

              {q.answer?.teacher && (
                <div className="ml-8">
                  <div className="flex items-start mb-8">
                    <div className="pr-4 my-auto lg:my-0">
                      <div className="h-10 w-10 lg:w-16 lg:h-16 bg-gray-400 rounded-full ">
                        <img
                          src={q.answer.teacher.image}
                          alt=""
                          className="object-cover rounded-full h-full w-full"
                          style={{ backgroundColor: "#D8D8D8" }}
                        />
                      </div>
                    </div>
                    <div>
                      <p className="text-primary-200">Respuesta</p>
                      <h4 className="font-bold text-xl lg:text-2xl  text-primary-200">
                        {q.answer.teacher.name}
                      </h4>
                      <p className="text-gray-700 text-sm">
                        <Moment format="DD/MM/YYYY">
                          {q.answer.createdAt}
                        </Moment>
                      </p>
                      <p className="md:mr-40">{q.answer.answer}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })
      ) : (
        <div className="bg-gray-300 border-l-4 border-l-primary-200 p-4 my-8">
          <p className="text-center lg:text-left text-sm lg:text-xl text-gray-700 font-bold">
            Aún no hay preguntas, ¡Sé el primero!
          </p>
        </div>
      )}
    </>
  );
};

const Comments = ({ comments }) => {
  return (
    <>
      {comments.length ? (
        comments.map((cmt) => {
          return (
            <div key={`comment-${cmt.id}`} className="flex items-start my-8">
              <div className="pr-4 my-auto lg:my-0">
                <div className="h-10 w-10 lg:w-16 lg:h-16 bg-gray-400 rounded-full">
                  <img
                    src={cmt.user.image}
                    alt=""
                    className="object-cover rounded-full h-full w-full"
                    style={{ backgroundColor: "#D8D8D8" }}
                  />
                </div>
              </div>
              <div>
                <h4 className="font-bold text-xl lg:text-2xl  text-gray-700">
                  {cmt.user.name}
                </h4>
                <p className="text-gray-700 text-sm">
                  <Moment format="DD/MM/YYYY">{cmt.createdAt}</Moment>
                </p>
                <p className="md:mr-40">{cmt.comment}</p>
              </div>
            </div>
          );
        })
      ) : (
        <div className="bg-gray-300 border-l-4 border-l-primary-200 p-4 my-8">
          <p className="text-center lg:text-left text-sm lg:text-xl text-gray-700 font-bold">
            Aún no hay comentarios, ¡Sé el primero!
          </p>
        </div>
      )}
    </>
  );
};
