import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import Context from "../../../context/AdminUserContext";
import DataTable from "react-data-table-component";
import * as _ from "lodash";

import TableLoading from "../../../components/TableLoading";
import TableNoData from "../../../components/TableNoData";
import { adminUsersAdministratorService } from "../../../services/api/admin/usersAdministrator";

import { getColumnsForUsers, customStyles } from "../../../utils/tableOptions";

const Index = () => {
  const { currentAdminUser } = useContext(Context);

  const [users, setUsers] = useState([]);
  const [querySearch, setQuerySearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [collectionsFormated, setCollectionsFormated] = useState([]);

  const inputHandler = (e) => setQuerySearch(e.target.value.toLowerCase());
  const navigate = useNavigate();

  const FormatCollectionsData = () => {
    let data = [];

    for (let index = 0; index < users.length; index++) {
      data.push({
        name: (
          <div>
            <span className="font-bold text-primary-200 text-xl md:text-2xl">
              {users[index].name}
            </span>
          </div>
        ),
        nameFilter: users[index].name,
        type: (
          <div>
            <span className="text-xl md:text-2xl">{users[index].type}</span>
          </div>
        ),
        actionComponent: (
          <div className="text-primary-200 hover:text-primary-300 leading-none text-xl md:text-2xl">
            <button
              onClick={() =>
                navigate(
                  `/admin/usuarios/administradores/${users[index].id}/editar`
                )
              }
            >
              Editar
            </button>
          </div>
        ),
      });
    }
    setCollectionsFormated(data);
    setLoading(false);
  };
  const filteredData = collectionsFormated.filter((user) => {
    if (querySearch === "") {
      return user;
    } else {
      return user.nameFilter.toLowerCase().includes(querySearch);
    }
  });

  useEffect(() => {
    if (_.isEmpty(users)) return;
    FormatCollectionsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const fetchUsers = () => {
    adminUsersAdministratorService
      .getUsers()
      .then((response) => {
        setUsers(response.data.admins);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchUsers();
  }, [currentAdminUser]);

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <p className="text-4xl font-bold">Todos los usuarios</p>
        <div className="flex flex-col md:flex-row items-center gap-5 mt-5 md:mt-0">
          <label className="relative block">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <FontAwesomeIcon
                icon={["fa", "magnifying-glass"]}
                className="text-[#A9A9A9]"
                size="sm"
              />
            </span>
            <input
              className="w-full md:w-52 lg:w-64 bg-white text-gray-700 placeholder:font-italic border border-slate-300 py-3 pl-12 pr-4 focus:outline-none"
              type="text"
              placeholder="Buscar"
              onChange={inputHandler}
            />
          </label>
          <Link
            to="/admin/usuarios/administradores/nuevo"
            className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75 text-center md:text-left"
          >
            <FontAwesomeIcon icon={["fa", "plus"]} size="sm" className="mr-3" />
            Añadir nuevo
          </Link>
        </div>
      </div>

      <div className="border border-gray-350 mt-12">
        <DataTable
          columns={getColumnsForUsers}
          data={filteredData}
          noDataComponent={<TableNoData />}
          progressPending={loading}
          progressComponent={<TableLoading />}
          customStyles={customStyles}
        />

        {/* ITEMS */}
      </div>
    </div>
  );
};

export default Index;
