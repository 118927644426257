import React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/es';

export default function Index() {
  return (<div>
    <div class="container mx-auto relative ">
      <div className='mt-20 lg:mt-40 px-10 text-center lg:text-left'>
        <p className='text-3xl lg:text-5xl text-gray-700 font-bold mb-5 text-center'>Aviso de privacidad</p>
        <div className='text-gray-700 text-xl '>
          <p className='mb-5'>
            SISTEMA BE GLOBAL PRO S.A. DE C.V. con domicilio en Hermosillo, Sonora, el cual hace uso de la página web <a href={'https://beglobalpro.org'} target={'_blank'} rel="noopener noreferrer"><b>beglobalpro.org</b></a> , es responsable del uso y protección de sus datos personales de conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y demás normatividad aplicable.
          </p>
          <p className='mb-5'>
            Nuestros clientes y usuarios pueden navegar por nuestro sitio web y medios electrónicos de difusión sin proporcionar datos personales. Nosotros podremos recabar datos personales tales como:
          </p>
          <div className=' pl-2 lg:pl-6 mb-5 lg:mb-10'>
            <ul class=" list-disc marker:text-primary-200 text-center lg:text-justify">
              <li className="mb-1">
                Datos de identificación: nombre, domicilio particular, fecha de nacimiento, número de teléfono fijo y/o móvil, fax, correo electrónico, imagen.
              </li>
              <li className="mb-1">
                Datos laborales: puesto, domicilio, correo electrónico, número de teléfono fijo o móvil y fax.
              </li>
              <li className="mb-1">
                Datos de facturación: entidad a la que se hará el cargo, domicilio fiscal, RFC.
              </li>
              <li className="mb-1">
                Datos financieros: información de cuentas bancarias, forma de pago, costo de inversión de su modelo de negocio, ventas mensuales entre otra información detallada sobre la rentabilidad de su empresa.
              </li>
            </ul>
          </div>
          <p className='mb-5'>
            Nosotros podremos recabar información personal al momento de iniciar una relación comercial, ya sea adquiriendo alguno de nuestros servicios, como proveedor de algún producto o servicio o adquiriendo alguna de nuestras membresías.
          </p>

          <p className='mb-5'>
            La empresa, como finalidad principal podrá hacer uso de sus datos personales para distintos fines siempre respetando la relación que tengamos con nuestros clientes, proveedores, colaboradores y usuarios.
          </p>

          <p className='mb-5'>
            Finalidades necesarias para el uso de información personal:

          </p>

          <div className=' pl-2 lg:pl-6 mb-5 lg:mb-10'>
            <ul class=" list-disc marker:text-primary-200 text-center lg:text-left">
              <li className="mb-1">
                Seguridad y control de acceso a nuestras instalaciones.
              </li>
              <li className="mb-1">
                Otorgar los productos y servicios contratados.

              </li>
              <li className="mb-1">
                Confirmar y/o asignar citas con nuestros clientes.
              </li>
              <li className="mb-1">
                Trámites de facturación.
              </li>
              <li className="mb-1">
                Actividades de Archivo y Respaldo de Información.
              </li>
              <li className="mb-1">
                Generar cotizaciones personalizadas.
              </li>
              <li className="mb-1">
                Llevar a cabo la inscripción en nuestro sistema de membresías.

              </li>
            </ul>
          </div>

          <p className='mb-5'>
            A su vez, podremos utilizar información personal al interactuar con nuestras redes sociales con el único fin de generar estadísticas relativas alcance y nivel de interacción con nuestro contenido y con ello elaborar estrategias de mercadotecnia.

          </p>
          <p className='mb-5'>
            La empresa como finalidad secundaria podrá utilizar los datos personales recabados para ofrecer ofertas y promociones de nuestros servicios, para hacer válidas las promociones vigentes, para agradecimientos por usar nuestros servicios, notificarle la actualización de precios de nuestros servicios, darle a conocer nuevas coberturas de nuestros servicios, así como más comunicaciones que pensamos que serán de su interés.

          </p>
          <p className='mb-5'>

          </p>
          <p className='mb-5'>
            En caso de que sus datos personales pretendan ser utilizados para una finalidad distinta a las anteriores, La empresa le notificará por correo electrónico o por teléfono, a fin de obtener su consentimiento para el tratamiento de sus datos personales de acuerdo a nuevas finalidades.

          </p>
          <p className='mb-5'>
            La empresa podrá transferir sus datos personales a terceros con los que mantenga una relación jurídica, únicamente con el fin de cumplir con las finalidades descritas en el Aviso de Privacidad, también podrá transferir sus datos personales en los casos específicamente previstos en la Ley.

          </p>
          <p className='mb-5'>
            Como titular de datos personales, usted puede ejercitar los derechos de acceso, rectificación, cancelación y oposición (derechos “ARCO”). Asimismo, puede revocar, en todo momento, el consentimiento que haya otorgado y que fuere necesario para el tratamiento de sus datos, así como limitar el uso o divulgación de los mismos.

          </p>
          <p className='mb-5'>
            Lo anterior, a través del envío de su solicitud en los términos establecidos por la Ley al correo electrónico: <b>info@academiabeglobalpro.com</b> o al domicilio especificado en el primer párrafo del presente.

          </p>
          <p className='mb-5'>
            Nos reservamos el derecho de cambiar este Aviso de Privacidad en cualquier momento. Toda modificación al presente se comunicará a través de nuestra página web <a href={'https://beglobalpro.org'} target={'_blank'} rel="noopener noreferrer"><b>beglobalpro.org</b></a> 

          </p>      <p className='mb-5'>
            Fecha de Actualización: Julio, 2022

          </p>









        </div>

      </div>

    </div>
  </div>)
};







