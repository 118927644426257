export const getFormattedDate = (_date) => {
  let date;
  if (_date.includes("T")) {
    date = _date.split("T")[0].split("-");
  } else {
    date = _date.split(" ")[0].split("-");
  }
  return date.reverse().join("/");
};

const getFormattedCurrency = (amount) => {
  return `$${amount.toLocaleString("en-US", {
    minimumFractionDigits: 0,
  })} MXN`;
};

export const paginationComponentOptions = {
  rowsPerPageText: "Filas por página",
  rangeSeparatorText: "de",
  selectAllRowsItem: true,
  selectAllRowsItemText: "Todos",
};

export const columnsMembershipsSales = [
  {
    name: "ORDEN",
    selector: (row) => row.order,
  },
  {
    name: "FECHA",
    selector: (row) => getFormattedDate(row.date),
  },
  {
    name: "CONCEPTO",
    selector: (row) => (row.isReferall ? row.label + "(5%)" : row.label),
  },
  {
    name: "TOTAL",
    selector: (row) => row.total,
  },
  {
    name: "COMISIÓN",
    selector: (row) => row.commission,
  },
  {
    name: "REFERIDO",
    selector: (row) => row.member,
  },
];

export const columnsMembershipsSalesBase = [
  {
    name: "ORDEN",
    selector: (row) => row.orden,
  },
  {
    name: "FECHA",
    selector: (row) => getFormattedDate(row.fecha),
  },
  {
    name: "CONCEPTO",
    selector: (row) => row.membresia || row.curso,
  },
  {
    name: "TOTAL",
    selector: (row) => row.total,
  },
  {
    name: "COMISIÓN",
    selector: (row) => row.comision,
  },
  {
    name: "REFERIDO",
    selector: (row) => row.miembro,
  },
];
export const columnsProducts = [
  {
    name: "ORDEN",
    width: "80px",
    selector: (row) => row.order_id,
  },
  {
    name: "FECHA",
    selector: (row) => getFormattedDate(row.created_at),
  },
  {
    name: "PRODUCTO",
    selector: (row) => row.name,
  },
  {
    name: "CANTIDAD",
    center: true,
    selector: (row) => row.amount,
  },
  {
    name: "COSTO",
    selector: (row) => "$" + parseFloat(row.cost).toFixed(2) + " MXN",
  },

  {
    name: "TOTAL",
    selector: (row) =>
      "$" + parseFloat(row.amount * row.cost).toFixed(2) + " MXN",
  },
];
export const customStyles = {
  rows: {
    style: {
      minHeight: "60px",
      fontSize: "18px",
    },
  },
  headCells: {
    style: {
      fontSize: "18px",
      fontWeight: 700,
    },
  },
};

export const customStylesLocations = {
  rows: {
    style: {
      minHeight: "80px",
      fontSize: "18px",
    },
  },
  headCells: {
    style: {
      fontSize: "18px",
      fontWeight: 700,
    },
  },
};

export const getColumnsForTopUsersMemberships = [
  {
    name: "",
    center: true,
    width: "70px",
    selector: (row) => row.placeComponent,
  },
  {
    name: "FOTO",
    center: true,
    width: "80px",
    selector: (row) => row.photoComponent,
  },
  {
    name: "NOMBRE",
    center: true,
    selector: (row) => row.name,
  },
  {
    name: "TOTAL",
    center: true,
    selector: (row) => getFormattedCurrency(row.memberships_total),
  },
  {
    name: "NRO. VENTAS",
    center: true,
    selector: (row) => row.memberships_count,
  },
  {
    name: "ACCIONES",
    center: true,
    selector: (row) => row.actionComponent,
  },
];
export const getColumnsWhitdrawals = [
  {
    name: "FOTO",
    center: true,
    selector: (row) => row.photoComponent,
  },
  {
    name: "NOMBRE",
    center: true,
    selector: (row) => row.userComponent,
  },
  {
    name: "TOTAL",
    center: true,
    selector: (row) => getFormattedCurrency(row.memberships_total),
  },
  {
    name: "FECHA",
    center: true,
    selector: (row) => row.created_at,
  },
  {
    name: "DATOS",
    center: true,
    selector: (row) => row.dataComponent,
  },
  {
    name: "ACCIONES",
    center: true,
    selector: (row) => row.actionComponent,
  },
];
export const getColumnsWhitdrawalsAnswered = [
  {
    name: "FOTO",
    center: true,
    selector: (row) => row.photoComponent,
  },
  {
    name: "NOMBRE",
    center: true,
    selector: (row) => row.userComponent,
  },
  {
    name: "TOTAL",
    center: true,
    selector: (row) => getFormattedCurrency(row.memberships_total),
  },
  {
    name: "FECHA",
    center: true,
    selector: (row) => row.created_at,
  },
  {
    name: "DATOS",
    center: true,
    selector: (row) => row.dataComponent,
  },
  {
    name: "ESTATUS",
    center: true,
    selector: (row) => row.statusComponent,
  },
  {
    name: "ACCIONES",
    center: true,
    selector: (row) => row.actionComponent,
  },
];
export const getColumnsWhitdrawalsDesktop = [
  {
    name: "MONTO",
    center: true,
    selector: (row) => getFormattedCurrency(row.memberships_total),
  },
  {
    name: "FECHA",
    center: true,
    selector: (row) => row.created_at,
  },
  {
    name: "ESTATUS",
    center: true,
    selector: (row) => row.actionComponent,
  },
  {
    name: "COMENTARIO",
    center: true,
    selector: (row) => row.answerComponent,
  },
];
export const getColumnsForMembershipsProductsSales = [
  {
    name: "FOTO",
    center: true,
    width: "80px",
    selector: (row) => row.photoComponent,
  },
  {
    name: "NOMBRE",
    center: true,
    selector: (row) => row.name,
  },
  {
    name: "EMAIL",
    center: true,
    omit: true,
    selector: (row) => row.email,
  },
  {
    name: "TOTAL",
    center: true,
    selector: (row) => getFormattedCurrency(row.memberships_total),
  },
  {
    name: "NRO. VENTAS",
    center: true,
    selector: (row) => row.memberships_count,
    sortable: true,
  },
  {
    name: "ACCIONES",
    center: true,
    selector: (row) => row.actionComponent,
  },
];

export const getColumnsForMemberships = [
  {
    name: "FOTO",
    center: true,
    selector: (row) => row.photoComponent,
  },
  {
    name: "NOMBRE",
    center: true,
    selector: (row) => row.name,
  },
  {
    name: "EMAIL",
    center: true,
    selector: (row) => row.email,
  },
  {
    name: "FONDOS",
    center: true,
    selector: (row) => row.fund,
  },
  {
    name: "EXPIRACIÓN",
    center: true,
    selector: (row) => row.membership_expired_at,
  },
  {
    name: "ACCIONES",
    center: true,
    selector: (row) => row.actionComponent,
  },
  {
    name: "CURSOS",
    center: true,
    selector: (row) => row.coursesComponent,
  },
];

export const getColumnsForExpirationMemberships = [
  {
    name: "NOMBRE",
    center: true,
    selector: (row) => row.name,
  },

  {
    name: "TELÉFONO",
    center: true,
    selector: (row) => row.phone,
  },
  {
    name: "EXPIRACIÓN",
    center: true,
    selector: (row) => row.membership_expired_at,
  },
  {
    name: "ACCIONES",
    center: true,
    selector: (row) => row.actionComponent,
  },
];

export const getColumnsForStudents = [
  {
    name: "FOTO",
    center: true,
    selector: (row) => row.photoComponent,
  },
  {
    name: "NOMBRE",
    center: true,
    selector: (row) => row.name,
  },
  {
    name: "EMAIL",
    center: true,
    selector: (row) => row.email,
  },

  {
    name: "ACCIONES",
    center: true,
    selector: (row) => row.actionComponent,
  },
];
export const getColumnsForCollections = [
  {
    name: "NOMBRE",
    center: true,
    selector: (row) => row.nameComponent,
  },

  {
    name: "ACCIONES",
    center: true,
    selector: (row) => row.actionComponent,
  },
];

export const getColumnsForUsers = [
  {
    name: "NOMBRE",
    center: true,
    selector: (row) => row.name,
  },

  {
    name: "TIPO",
    center: true,
    selector: (row) => row.type,
  },
  {
    name: "ACCIONES",
    center: true,
    selector: (row) => row.actionComponent,
  },
];

export const getProductsForCollections = [
  {
    name: "FOTO",
    center: true,
    selector: (row) => row.photoComponent,
  },
  {
    name: "NOMBRE",
    center: true,
    selector: (row) => row.nameComponent,
  },
  {
    name: "CANTIDAD",
    center: true,
    selector: (row) => row.quantity,
  },
  {
    name: "ESTATUS",
    center: true,
    selector: (row) => row.status,
  },
  {
    name: "ACCIONES",
    center: true,
    selector: (row) => row.actionComponent,
  },
];

export const getProductsForCollectionsSM = [
  {
    name: "FOTO",
    center: true,
    maxWidth: "120px",
    selector: (row) => row.photoComponent,
  },
  {
    name: "NOMBRE",
    center: true,
    maxWidth: "450px",
    selector: (row) => row.nameComponent,
  },
  {
    name: "CANTIDAD",
    center: true,
    maxWidth: "120px",
    style: {
      textAlign: "center",
    },
    selector: (row) => row.quantity,
  },
  {
    name: "ESTATUS",
    center: true,
    maxWidth: "120px",
    style: {
      textAlign: "center",
    },
    selector: (row) => row.status,
  },
  {
    name: "ACCIONES",
    center: true,
    maxWidth: "120px",
    style: {
      textAlign: "center",
    },
    selector: (row) => row.actionComponent,
  },
];

export const getColumnsForLocations = [
  {
    name: "NOMBRE",
    center: false,
    selector: (row) => row.nameComponent,
  },
  {
    name: "ACCIONES",
    maxWidth: "150px",
    center: true,
    style: {
      textAlign: "center",
    },
    selector: (row) => row.actionComponent,
  },
];

export const getColumnsForOrders = [
  {
    name: "ORIGEN",
    center: true,
    maxWidth: "130px",
    selector: (row) => row.tag,
  },
  {
    name: "ORDEN",
    center: true,
    maxWidth: "100px",
    selector: (row) => row.order,
  },
  {
    name: "FECHA",
    center: true,
    maxWidth: "165px",
    selector: (row) => row.date,
  },
  {
    name: "CLIENTE",
    center: true,
    selector: (row) => row.client,
  },
  {
    name: "ESTATUS",
    center: true,
    selector: (row) => row.status,
  },
  {
    name: "DETALLES",
    center: true,
    selector: (row) => row.detailsComponent,
  },
  {
    name: "ACCIONES",
    center: true,
    selector: (row) => row.actionComponent,
  },
];
export const getColumnsForSuperAdminOrders = [
  {
    name: "BODEGA",
    center: true,
    selector: (row) => row.vendor,
  },
  {
    name: "ORIGEN",
    center: true,
    maxWidth: "130px",
    selector: (row) => row.tag,
  },
  {
    name: "ORDEN",
    center: true,
    maxWidth: "100px",
    selector: (row) => row.order,
  },
  {
    name: "FECHA",
    center: true,
    maxWidth: "165px",
    selector: (row) => row.date,
  },
  {
    name: "CLIENTE",
    center: true,
    selector: (row) => row.client,
  },
  {
    name: "ESTATUS",
    center: true,
    maxWidth: "185px",
    selector: (row) => row.status,
  },
  {
    name: "DETALLES",
    center: true,
    maxWidth: "115px",
    selector: (row) => row.detailsComponent,
  },
  {
    name: "ACCIONES",
    center: true,
    maxWidth: "175px",
    selector: (row) => row.actionComponent,
  },
];

export const getColumnsForOrdersDesktop = [
  {
    name: "ORDEN",
    center: true,
    maxWidth: "165px",
    selector: (row) => row.order,
  },
  {
    name: "FECHA",
    center: true,
    maxWidth: "165px",
    selector: (row) => row.date,
  },
  {
    name: "TOTAL",
    center: true,
    selector: (row) => row.total,
  },
  {
    name: "ESTATUS",
    center: true,
    selector: (row) => row.status,
  },
  {
    name: "ACCIONES",
    center: true,
    selector: (row) => row.actionComponent,
  },
];

export const getColumnsForCoupons = [
  {
    name: "CUPON",
    center: true,
    maxWidth: "165px",
    selector: (row) => row.code,
  },
  {
    name: "TIPO",
    center: true,
    maxWidth: "250px",
    selector: (row) => row.type,
  },
  {
    name: "VALOR",
    center: true,
    maxWidth: "160px",
    selector: (row) => row.amount,
  },
  {
    name: "DESCRIPCIÓN",
    center: true,
    selector: (row) => row.description,
  },
  {
    name: "USOS",
    center: true,
    maxWidth: "165px",
    selector: (row) => row.use,
  },
  {
    name: "DETALLES",
    center: true,
    maxWidth: "165px",
    selector: (row) => row.detailsComponent,
  },
];

export const getColumnsForShippings = [
  {
    name: "ENVIO",
    center: true,
    selector: (row) => row.name,
  },
  {
    name: "COSTO",
    center: true,
    selector: (row) => row.cost,
  },
  {
    name: "TIEMPO DE ENTREGA (DIAS)",
    center: true,
    selector: (row) => row.days,
  },
  {
    name: "DETALLES",
    center: true,
    maxWidth: "165px",
    selector: (row) => row.detailsComponent,
  },
];

export const getColumnsForStores = [
  {
    name: "NOMBRE",
    center: true,
    selector: (row) => row.name,
  },
  {
    name: "URL",
    center: true,
    selector: (row) => row.store,
  },
  {
    name: "ID DE LOCACION",
    center: true,
    maxWidth: "165px",
    selector: (row) => row.location_id,
  },
  {
    name: "ACCIONES",
    center: true,
    maxWidth: "165px",
    selector: (row) => row.detailsComponent,
  },
];
