import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useState } from "react";

const gapi = window.gapi;
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const SCOPES =
  "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events";

export default function CalendarComponent({ getEvent, eventsCalendar }) {
  const [current, setCurrent] = useState("month");
  const localizer = momentLocalizer(moment);
  const tileDisabled = ({ activeStartDate, date, view }) => {
    return date < new Date();
  };

  const returnEventColor = (event) => {
    let color = "#63abe6";
    if (event.membership_required) {
      color = "#ffac04";
    }
    if (event.course) {
      color = "#5603fc";
    }
    if (moment(new Date()) > moment(event.date)) {
      color = "#ACACAC";
    }
    return color;
  };

  return (
    <>
      <Calendar
        tileDisabled={tileDisabled}
        className="relative text-gray-650 lg:text-left text-sm lg:text-lg pb-60 md:pb-10"
        selectable={true}
        events={eventsCalendar}
        onSelectEvent={(event) =>
          moment(new Date()) > moment(event.date) ? "" : getEvent(event)
        }
        defaultView={current}
        onView={(newView) => {
          setCurrent(newView);
        }}
        views={["month", "day"]}
        startAccessor="start"
        endAccessor="end"
        defaultDate={moment().toDate()}
        localizer={localizer}
        components={{}}
        eventPropGetter={(event) => ({
          style: {
            backgroundColor: returnEventColor(event),
          },
        })}
        messages={{
          next: "Siguiente",
          previous: "Antes",
          today: "Hoy",
          month: "Mes",
          week: "Semana",
          day: "Día",
          work_week: "Semana de trabajo",
          allDay: "Todo el día",
          yesterday: "Ayer",
          tomorrow: "Mañana",
          noEventsInRange: "No se han encontrado eventos",
          showMore: function showMore(total) {
            return "+" + total + "Eventos";
          },
        }}
      />
    </>
  );
}

export const changeEventFormat = (item) => {
  item.element = item.id;
  item.start = new Date(item.date);
  item.end = new Date(item.date);
  const newDateObj = moment(item.date).add(60, "m").toDate();
  item.end = new Date(newDateObj);
};

export const GoogleCalendar = (event) => {
  const date = {
    dateTime: new Date(event.start),
    timeZone: "America/Phoenix",
  };
  const googleEvent = {
    element: event.id,
    id: "",
    summary: event.title,
    location: event.link,
    start: date,
    end: date,
  };
  gapi.load("client:auth2", () => {
    gapi.client.init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      plugin_name: "React App Calendar",
      scope: SCOPES,
    });
    gapi.client.load("calendar", "v3", () => {});
    gapi.auth2
      .getAuthInstance()
      .signIn()
      .then(() => {
        const request = gapi.client.calendar.events.insert({
          calendarId: "primary",
          resource: googleEvent,
        });
        request.execute((event) => {
          window.open(event.htmlLink + "&authuser=" + event.creator.email);
        });
      })
      .catch((err) => {});
  });
};
