import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CommonTable from "../../../components/tables/CommonTable";
import { adminAccountingServices } from "../../../services/api/admin/contability";
import TableLoading from "../../../components/TableLoading";
import fileDownload from "js-file-download";
import Prices from "../../../components/admin/contability/Prices";

const Index = () => {
  const [incomes, setIncomes] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [selectDateFilter, setSelectDateFilter] = useState("monthAndYear");
  const [balance, setBalance] = useState(0);
  const [totalIncomes, setTotalIncomes] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [totalComplete, setTotalComplete] = useState(0);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(
    moment(new Date()).format("M")
  );
  const [currentLabelMonth, setCurrentLabelMonth] = useState(
    moment(new Date()).format("MMMM")
  );
  const [currentLabelRangeDate, setCurrentLabelRangeDate] = useState(
    moment(startDate).format("DD-MM-YYYY") +
      " a " +
      moment(endDate).format("DD-MM-YYYY")
  );
  const [currentYear, setCurrentYear] = useState(
    moment(new Date()).format("Y")
  );
  const [yearsByDates, setYearsByDates] = useState([]);

  const areRegisterValidated = (incomes, expenses) => {
    return incomes.concat(expenses).some((entry) => !entry.deleted_at);
  };
  const getAllYears = (year) => {
    const startYear = year;
    const years = [];
    let currentYear = startYear;
    while (currentYear <= new Date().getFullYear()) {
      years.push(currentYear);
      currentYear++;
    }
    return years;
  };

  const months = [
    {
      month: "Enero",
      value: 1,
    },
    {
      month: "Febrero",
      value: 2,
    },
    {
      month: "Marzo",
      value: 3,
    },
    {
      month: "Abril",
      value: 4,
    },
    {
      month: "Mayo",
      value: 5,
    },
    {
      month: "Junio",
      value: 6,
    },
    {
      month: "Julio",
      value: 7,
    },
    {
      month: "Agosto",
      value: 8,
    },
    {
      month: "Septiembre",
      value: 9,
    },
    {
      month: "Octubre",
      value: 10,
    },
    {
      month: "Noviembre",
      value: 11,
    },
    {
      month: "Diciembre",
      value: 12,
    },
  ];

  const createYearsInRange = () => {
    let dates = [];
    incomes.forEach((element) => {
      !dates.includes(new Date(element.date).getFullYear()) &&
        dates.push(new Date(element.date).getFullYear());
    });
    expenses.forEach((element) => {
      !dates.includes(new Date(element.date).getFullYear()) &&
        dates.push(new Date(element.date).getFullYear());
    });
    // setYearsByDates(dates);
    setYearsByDates(getAllYears(2022));
  };

  useEffect(() => {
    createYearsInRange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomes, expenses]);

  const getAccountingPerRange = () => {
    setLoading(true);
    let params;
    if (selectDateFilter === "monthAndYear") {
      params = {
        month: parseInt(currentMonth),
        year: parseInt(currentYear),
      };
    } else {
      params = {
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
    }
    if ((!startDate || !endDate) && selectDateFilter !== "monthAndYear") {
      setExpenses([]);
      setIncomes([]);
      setBalance(0);
      setTotalExpenses(0);
      setTotalIncomes(0);
      setTotalComplete(0);
      setLoading(false);
    } else {
      adminAccountingServices
        .getAccounting(params)
        .then((response) => {
          setExpenses(response.data.expenses);
          setIncomes(response.data.incomes);
          setBalance(response.data.balance);
          setTotalExpenses(response.data.total_expenses);
          setTotalIncomes(response.data.total_incomes);
          setTotalComplete(response.data.total);
        })
        .catch((err) => {})
        .finally(() => setLoading(false));
    }
  };

  const changeMonth = (value) => {
    setCurrentMonth(value);
  };
  const changeYear = (value) => {
    setCurrentYear(value);
  };
  const changeDates = (value) => {
    setStartDate(value[0]);
    setEndDate(value[1]);
    if (value[1]) {
      setCurrentLabelRangeDate(
        moment(value[0]).format("DD-MM-YYYY") +
          " a " +
          moment(value[1]).format("DD-MM-YYYY")
      );
    }
  };

  const downloadAccountingFile = () => {
    let extension = "xlsx";
    let params;
    let nameFile = "";
    if (selectDateFilter === "monthAndYear") {
      params = {
        month: parseInt(currentMonth),
        year: parseInt(currentYear),
      };
      nameFile = "Contabilidad " + currentLabelMonth + " " + currentYear;
    } else {
      params = {
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
      nameFile =
        "Contabilidad " +
        moment(startDate).format("YYYY-MM-DD") +
        " a " +
        moment(endDate).format("YYYY-MM-DD");
    }
    adminAccountingServices
      .downloadAccounting(params)
      .then((response) => {
        fileDownload(response.data, `${nameFile}.${extension}`);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getAccountingPerRange();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectDateFilter, startDate, endDate, currentMonth, currentYear]);

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex flex-col md:flex-row md:justify-between md:items-center my-10 md:space-x-12 lg:space-x-0">
        <div className="w-full md:w-auto lg:w-full">
          <p className="text-3xl 2xl:text-left font-bold text-center md:text-left mb-8 md:mb-0">
            Contabilidad
          </p>
        </div>

        <div className="w-full flex flex-col md:flex-row md:justify-end items-center space-y-4 md:space-y-0 md:space-x-4">
          <Link
            to="/admin/contabilidad/ingresos/nuevo"
            className="w-full lg:w-auto bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75 text-center lg:text-left"
          >
            <FontAwesomeIcon icon={["fa", "plus"]} size="sm" className="mr-3" />
            Nuevo Ingreso
          </Link>
          <Link
            to="/admin/contabilidad/egresos/nuevo"
            className="w-full lg:w-auto bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75 text-center lg:text-left"
          >
            <FontAwesomeIcon icon={["fa", "plus"]} size="sm" className="mr-3" />
            Nuevo Egreso
          </Link>
        </div>
      </div>

      {/* FILTERS */}
      <div className="flex flex-wrap md:flex-row justify-between items-center ">
        <div className=" flex flex-wrap 2xl:flex-row space-x-2  w-full justify-items-center lg:justify-between ">
          <div className="space-y-2  lg:space-y-2 flex justify-items-center text-2xl lg:text-3xl my-2 lg:my-auto  text-center lg:text-left w-full lg:w-auto flex-wrap lg:space-x-4 ">
            {selectDateFilter === "monthAndYear" ? (
              <div className="my-auto mx-auto lg:mx-0">
                <p>
                  Resultados <b className="capitalize">{currentLabelMonth} </b>{" "}
                  {currentYear}
                </p>
              </div>
            ) : (
              <div className="my-auto mx-auto lg:mx-0">
                <div className="lg:block hidden ">
                  Resultados{" "}
                  <b className="font-bold">{currentLabelRangeDate} </b>
                </div>
                <div className="block lg:hidden ">
                  Resultados
                  <p className="font-bold">{currentLabelRangeDate}</p>
                </div>
              </div>
            )}
            {(incomes.length > 0 || expenses.length > 0) &&
              areRegisterValidated(incomes, expenses) && (
                <button
                  onClick={downloadAccountingFile}
                  className="hidden lg:block w-full lg:w-auto bg-green-600 text-white text-2xl py-3 px-5 disabled:opacity-75 space-x-4 "
                >
                  <span className=" w-1/6 text-center">
                    <FontAwesomeIcon icon={["far", "file-excel"]} size="lg" />
                  </span>
                  <span className=" w-5/6 text-center">Descargar</span>
                </button>
              )}
          </div>

          <div className="w-full lg:w-auto my-5 flex flex-col items-center md:space-x-4 md:flex-row space-y-2 md:space-y-0 2xl:my-0 lg:space-x-4">
            <div className="w-full md:w-auto 2xl:mx-auto">
              <select
                name="breakdown"
                onChange={(value) => setSelectDateFilter(value.target.value)}
                className="w-full md:w-auto py-4 px-4 text-xl font-bold  bg-white text-gray-700 border border-slate-300 focus:outline-none 2xl:mx-1 2xl:text-xl"
              >
                <option value="monthAndYear">Mes y año</option>
                <option value="range">Rango</option>
              </select>
            </div>
            {selectDateFilter === "monthAndYear" ? (
              <div className="flex flex-col w-full space-y-2 lg:w-auto md:flex-row md:space-x-4 md:space-y-0">
                <select
                  name="breakdown"
                  value={currentMonth}
                  className="w-full mx-auto 2xl:mx-1 text-xl 2xl:text-xl font-bold  bg-white text-gray-700 border border-slate-300 py-4 px-4 focus:outline-none"
                  onChange={(event) => {
                    changeMonth(event.target.value);
                    setCurrentLabelMonth(
                      event.target.options[event.target.selectedIndex].text
                    );
                  }}
                >
                  {months.map((element, index) => (
                    <option key={index} value={element.value}>
                      {element.month}
                    </option>
                  ))}
                </select>
                <select
                  name="breakdown"
                  value={currentYear}
                  onChange={(event) => {
                    changeYear(event.target.value);
                  }}
                  className="mx-auto w-full text-xl font-bold bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none md:w-auto 2xl:mx-1 2xl:text-xl"
                >
                  {yearsByDates.map((element, index) => (
                    <option key={index} value={element}>
                      {element}
                    </option>
                  ))}
                </select>
                {/* DOWNLOAD BUTTON FOR SM, MD, hidden from LG+ */}
                {(incomes.length > 0 || expenses.length > 0) && (
                  <button
                    onClick={downloadAccountingFile}
                    className="block lg:hidden w-full bg-green-600 text-white text-2xl py-3 px-5 disabled:opacity-75 space-x-4 "
                  >
                    <span className="w-1/6 text-center">
                      <FontAwesomeIcon icon={["far", "file-excel"]} size="lg" />
                    </span>
                    <span className="w-5/6 text-center">Descargar</span>
                  </button>
                )}
              </div>
            ) : (
              <div className="flex flex-col w-full space-y-2 lg:w-auto md:flex-row md:justify-between md:space-x-4 md:space-y-0">
                <div className="2xl:my-0 w-full md:w-auto">
                  <DatePicker
                    maxDate={new Date()}
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(value) => {
                      changeDates(value);
                    }}
                    isClearable={true}
                    className="text-gray-700 placeholder:text-gray-700 border border-slate-300 focus:outline-none py-3 px-3 mr-6 w-full"
                    placeholderText="Buscar por rango de fechas..."
                    locale="es"
                  />
                </div>
                {/* DOWNLOAD BUTTON FOR SM, MD, hidden from LG+ */}
                {(incomes.length > 0 || expenses.length > 0) && (
                  <button
                    onClick={downloadAccountingFile}
                    className="block lg:hidden w-auto bg-green-600 text-white text-2xl py-3 px-5 disabled:opacity-75 space-x-4"
                  >
                    <span className="w-1/6 text-center">
                      <FontAwesomeIcon icon={["far", "file-excel"]} size="lg" />
                    </span>
                    <span className="w-5/6 text-center">Descargar</span>
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* AMOUNTS */}
      <div className="w-full md:w-fit text-center text-sm sm:text-md md:text-xl 2xl:text-left mt-10 my-5 font-bold border border-slate-300 p-4">
        <Prices text="Saldo" money={balance} color="text-primary-500" />
        <Prices
          text="Ingresos"
          money={totalIncomes}
          color="text-green-500"
          add
        />
        <Prices text="Egresos" money={totalExpenses} color="text-red-500" sub />
        <Prices text="Totales" money={totalComplete} color="" />
      </div>

      {/* TABLES */}
      <div className="flex mt-12 flex-wrap 2xl:flex-row 2xl:justify-between pr-4">
        <div className="mt-4 w-full 2xl:w-7/12 overflow-hidden">
          <p className="text-center font-bold my-4 text-3xl">Ingresos</p>
          {loading ? (
            <div className="mx-auto text-center ">
              <TableLoading />
            </div>
          ) : (
            <CommonTable
              array={incomes}
              content="incomes"
              headers={[
                "CONCEPTO",
                "NOMBRE",
                "AFILIADO POR",
                "MONTO",
                "FECHA",
                "DETALLE",
              ]}
            />
          )}
        </div>
        <div className="mt-4 w-full 2xl:w-4/12 overflow-hidden">
          <p className="text-center font-bold my-4 text-3xl">Egresos</p>

          {loading ? (
            <div className="mx-auto text-center ">
              <TableLoading />
            </div>
          ) : (
            <CommonTable
              array={expenses}
              content="expenses"
              headers={["CONCEPTO", "MONTO", "FECHA", "DETALLE"]}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Index;
