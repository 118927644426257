import { useEffect, useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";

import useProfile from "../../hooks/useProfile";

import Context from "../../context/UserContext";

import mexicoStates from "../../utils/mexicoStates";

const General = () => {
  const regex = /^(?:\+?\d+\s?)?(?:\(\d+\)|\d+)(?:-?\s?\d+)*$/;
  const formSchema = Yup.object().shape({
    phone: Yup.string()
      .max(10, `Máximo 10 caracteres`)
      .matches(new RegExp(regex), "Ingrese un número de teléfono válido"),
  });

  const { currentUser } = useContext(Context);
  const { isLoading, updateUser } = useProfile();

  const [initialValues, setInitialValues] = useState({
    name: "",
    phone: "",
    city: "",
    state: "",
    country: "",
    country_trigger: "",
  });

  useEffect(() => {
    if (currentUser) {
      setInitialValues({
        name: currentUser.name || "",
        phone: currentUser.phone || "",
        city: currentUser.city || "",
        state: currentUser.state || "",
        country: countryValidation(currentUser.country),
        country_trigger: countryTriggerValidation(currentUser.country),
      });
    }
  }, [currentUser]);

  const countryValidation = (country) => {
    if (!country) return "";
    return country !== "México" ? country : "";
  };

  const countryTriggerValidation = (country) => {
    if (!country) return "MX";
    return country === "México" ? "MX" : "OTHER";
  };

  const handleSubmit = async (values) => {
    let country = values.country === "" ? "México" : values.country;

    delete values.country_trigger;
    let newValues = { ...values, country };
    !newValues.phone && delete newValues.phone;
    !newValues.name && delete newValues.name;
    !newValues.city && delete newValues.city;
    !newValues.state && delete newValues.state;
    await updateUser(newValues);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={(values) => {
        if (values.country_trigger === "MX") values.country = "";
        handleSubmit(values);
      }}
    >
      {({ values }) => (
        <Form>
          <div className="mb-9 text-2xl text-gray-550">
            <p>Nombre completo</p>
            <Field
              name="name"
              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
              type="text"
            />
            <ErrorMessage
              name="name"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          <div className="mb-9 text-2xl text-gray-550">
            <p>Correo</p>
            <input
              className="w-full bg-gray-300 text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
              value={currentUser ? currentUser.email : ""}
              readOnly
            />
          </div>

          <div className="mb-9 text-2xl text-gray-550">
            <p>Teléfono</p>
            <Field
              name="phone"
              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
              type="text"
            />
            <ErrorMessage
              name="phone"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          <div className="mb-9 text-2xl text-gray-550">
            <p>País</p>
            <Field
              as="select"
              name="country_trigger"
              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
            >
              <option value="MX">México</option>
              <option value="OTHER">Otro país</option>
            </Field>
            <ErrorMessage
              name="country_trigger"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          {values.country_trigger === "OTHER" && (
            <div className="mb-9 text-2xl text-gray-550">
              <Field
                name="country"
                className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
                placeholder="Escriba el nombre de su país"
                type="text"
              />
              <ErrorMessage
                name="country"
                component="div"
                className="text-red-400 font-bold text-xl"
              />
            </div>
          )}

          <div className="mb-9 text-2xl text-gray-550">
            <p>Estado</p>
            {values.country_trigger === "OTHER" ? (
              <Field
                name="state"
                className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
                type="text"
              />
            ) : (
              <Field
                as="select"
                name="state"
                className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
              >
                {Object.keys(mexicoStates).map((key) => (
                  <option key={key} value={mexicoStates[key]}>
                    {mexicoStates[key]}
                  </option>
                ))}
              </Field>
            )}
            <ErrorMessage
              name="state"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          <div className="mb-9 text-2xl text-gray-550">
            <p>Ciudad</p>
            <Field
              name="city"
              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
              type="text"
            />
            <ErrorMessage
              name="city"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className="w-full md:w-1/2 lg:w-1/3 bg-primary-200 text-white font-bold text-2xl py-3 disabled:opacity-75"
              disabled={isLoading}
            >
              {isLoading ? (
                <FontAwesomeIcon icon="circle-notch" spin />
              ) : (
                <span>Actualizar</span>
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default General;
