import { useState, useEffect, useContext, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as _ from "lodash";
import { notifications } from "../../../utils/notifications";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Context from "../../../context/AdminUserContext";
import FilesDragAndDrop from "../../../components/FilesDragAndDropBanners";
import { shopifyService } from "../../../services/api/shopify";
import BannersCarrousel from "../../../components/admin/Banners/BannersCarrousel";

const MySwal = withReactContent(Swal);
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const Index = () => {
  const [submitAction, setSubmit] = useState();
  const [categories, setCategories] = useState();
  const [categoriesByStore, setCategoriesByStore] = useState([]);
  const [banner, setBanner] = useState();
  const [collection, setCollection] = useState();
  const [collectionPublicity, setCollectionPublicity] = useState();
  const [select, setSelect] = useState("none");
  const [name, setName] = useState(null);
  const [swiper, setSwiper] = useState(null);
  const [swiperPublicity, setSwiperPublicity] = useState(null);
  const logoRef = useRef();
  const [activateClean, setActivate] = useState(false);
  const [activatePublicity, setActivatePublicity] = useState(false);
  const [activateCategories, setActivateCategories] = useState(false);
  const [stores, setStores] = useState([]);
  const [selectStore, setSelectStore] = useState("none");

  const formSchema = () => {
    return Yup.object().shape({
      url: Yup.string()
        .nullable()
        .notRequired()
        .max(500, `Máximo 500 caracteres`),
      image: Yup.mixed()
        .nullable()
        .notRequired()
        .test(
          "fileFormat",
          "Formato no soportado",
          (value) =>
            !value ||
            ((value) => value && SUPPORTED_FORMATS.includes(value.type))
        ),
    });
  };

  const formSchemaMain = formSchema();
  const formSchemaCategory = Yup.object().shape({
    category: Yup.string()
      .required("Debe seleccionar un proveedor")
      .max(255, `Máximo 255 caracteres`),
    image: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        "fileFormat",
        "Formato no soportado",
        (value) =>
          !value || ((value) => value && SUPPORTED_FORMATS.includes(value.type))
      ),
  });
  const formSchemaAdvertising = formSchema();
  const { currentAdminUser } = useContext(Context);
  const [initialValuesMain] = useState({
    url: "",
    image: null,
  });

  const [initialValuesCategory] = useState({
    category: select,
    image: null,
  });

  const [initialValuesAdvertising] = useState({
    url: "",
    image: null,
  });

  const fetchBanners = () => {
    shopifyService
      .getBanners()
      .then((response) => {
        setBanner(response.data);
        setCollectionPublicity(response.data.bannerAdvertising);
      })
      .catch((err) => {});
  };

  const fetchCategories = () => {
    shopifyService
      .getCategories()
      .then((response) => {
        setCategories(response.data.collects);
        setCategoriesByStore(response.data.collects);
        let storeArrays = [];
        response.data.collects.forEach((collection) => {
          if (!storeArrays.includes(collection.store.name)) {
            storeArrays.push(collection.store.name);
          }
          setStores(storeArrays);
        });
      })
      .catch((err) => {});
  };
  useEffect(() => {
    if (!currentAdminUser) return;
    fetchBanners();
    fetchCategories();
  }, [currentAdminUser]);

  const returnImages = (value) => {
    setCollection(null);
    categories.forEach(function (image, i) {
      if (parseInt(value) === parseInt(image.id)) {
        setCollection(image.images);
      }
    });
  };

  const returnCategories = (value) => {
    setSelect("none");
    setName("");
    setCollection(null);
    if (value === "all") {
      return setCategoriesByStore(categories);
    }
    let newCategories = [];
    categories.forEach(function (categorie) {
      if (categorie.store.name === value) {
        newCategories.push(categorie);
      }
    });
    setCategoriesByStore(newCategories);
  };

  const onFileChange = (files, setFieldValue) => {
    if (!_.isEmpty(files)) {
      setFieldValue("image", files[0]);
    }
  };
  const onFilesChange = (files, setFieldValue) => {
    if (!_.isEmpty(files)) {
      setFieldValue("image", files);
    }
  };
  const handleSubmitMain = async (values) => {
    setActivate(true);
    let bodyFormData = new FormData();
    bodyFormData.append("_method", "PUT");
    if (values.image !== null) bodyFormData.append("image", values.image);
    bodyFormData.append("banner", "MAIN");
    bodyFormData.append("url", values.url);
    await shopifyService
      .uploadBanner(bodyFormData)
      .then((response) => {
        fetchBanners();
        notifications.success(response.data.message);
        setActivate(false);
      })
      .catch((err) => {});
  };

  const handleSubmitCategory = async (values) => {
    let bodyFormData = new FormData();
    values.image.forEach(function (image, i) {
      bodyFormData.append("images[]", values.image[i]);
    });
    setActivateCategories(true);
    bodyFormData.append("id", parseInt(values.category));
    await shopifyService
      .uploadCollection(bodyFormData)
      .then((response) => {
        fetchCategories();
        setSelect("none");
        setName("");
        setCollection(null);
        notifications.success(response.data.message);
        setActivateCategories(false);
      })
      .catch((err) => {});
  };

  const handleDeleteCategory = async (id) => {
    MySwal.fire({
      icon: "error",
      title: "¿Deseas eliminar esta imagen?",
      text: "Se eliminará la imagen de la categoria asociada. Esta acción es irreversible",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#42a819",
      confirmButtonText: "Eliminar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let bodyFormData = new FormData();
        bodyFormData.append("images[]", swiper.slides[swiper.activeIndex].id);
        bodyFormData.append("id", parseInt(id));
        await shopifyService
          .deleteCollection(bodyFormData)
          .then((response) => {
            fetchCategories();
            setSelect("none");
            setName("");
            setCollection(null);
            notifications.success(response.data.message);
          })
          .catch((err) => {});
      }
    });
  };
  const handleDeletePublicity = async (id) => {
    let bodyFormData = new FormData();
    bodyFormData.append("_method", "DELETE");
    MySwal.fire({
      icon: "error",
      title: "¿Deseas eliminar esta imagen?",
      text: "Se eliminará la imagen de los banners publicitarios. Esta acción es irreversible",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#42a819",
      confirmButtonText: "Eliminar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await shopifyService
          .deleteAdvertising(id, bodyFormData)
          .then((response) => {
            fetchBanners();
            notifications.success(response.data.message);
          })
          .catch((err) => {});
      }
    });
  };

  const handleUpdateAdvertising = async (values) => {
    let bodyFormData = new FormData();
    bodyFormData.append("_method", "PUT");
    bodyFormData.append("url", values.url);
    if (values.image !== null) bodyFormData.append("image", values.image);
    bodyFormData.append("banner", "ADVERTISING");
    setActivatePublicity(true);

    await shopifyService
      .updateBannerAdvertising(
        swiperPublicity.slides[swiperPublicity.activeIndex].id,
        bodyFormData
      )
      .then((response) => {
        fetchBanners();
        notifications.success(response.data.message);
        setActivatePublicity(false);
      })
      .catch((err) => {});
  };

  const handleSubmitAdvertising = async (values) => {
    setActivatePublicity(true);
    let bodyFormData = new FormData();
    bodyFormData.append("url", values.url);
    if (values.image !== null) bodyFormData.append("image", values.image);
    bodyFormData.append("banner", "ADVERTISING");
    await shopifyService
      .uploadBannerAdvertising(bodyFormData)
      .then((response) => {
        fetchBanners();
        notifications.success(response.data.message);
        setActivatePublicity(false);
      })
      .catch((err) => {});
  };

  const handleReset = (values, formikBag) => {
    logoRef.current.value = null; //THIS RESETS THE FILE FIELD
  };

  return (
    <div className="container mx-auto text-gray-700">
      <div>
        <p className="text-4xl font-bold">Banners</p>
      </div>
      <div className="mt-12">
        {/* Main banner */}
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={initialValuesMain}
            validationSchema={formSchemaMain}
            onReset={handleReset}
            onSubmit={(values) => handleSubmitMain(values)}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="grid grid-cols-1 gap-5">
                  <div className="mb-9 text-3xl text-gray-550">
                    <p className="mb-3 text-gray-700">
                      Banner Principal de la tienda
                    </p>
                    <div>
                      <FilesDragAndDrop
                        onFileChange={(files) => {
                          setActivate(false);
                          onFileChange(files, setFieldValue);
                        }}
                        activateClean={activateClean}
                        multiple={false}
                      />
                    </div>
                    <ErrorMessage
                      name="image"
                      component="div"
                      className="text-red-400 font-bold text-xl"
                    />
                  </div>
                  <div className="text-3xl text-gray-550">
                    <p className="">Banner actual</p>
                  </div>
                  <div className="mb-9 text-3xl text-gray-550 w-11/12 mx-auto">
                    {banner && (
                      <img
                        src={banner.bannerMain.image}
                        alt={banner.bannerMain.name}
                        className="w-full h-auto object-cover "
                      />
                    )}
                  </div>
                </div>
                <div className="mb-5 text-3xl text-gray-700">
                  <p className="mb-3">Vinculo URL</p>
                  <Field
                    name="url"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="url"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="w-full flex justify-end">
                  <button
                    type="submit"
                    className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75 mt-5"
                  >
                    Guardar
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        {/* Category banner */}
        <div className="mt-20">
          <Formik
            enableReinitialize={true}
            initialValues={initialValuesCategory}
            validationSchema={formSchemaCategory}
            onSubmit={(values) => handleSubmitCategory(values)}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                  <div className="mb-9 text-3xl text-gray-550">
                    <p className="mb-2">Tienda de Shopify</p>
                    <Field
                      as="select"
                      value={selectStore}
                      name="store"
                      onChange={(value) => {
                        returnCategories(value.target.value);
                        setFieldValue("store", value.target.value);
                        setSelectStore(value.target.value);
                      }}
                      className="w-full bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none"
                    >
                      <option value={"all"}>Todas las tiendas</option>
                      {stores
                        ? stores.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))
                        : ""}
                    </Field>
                    <ErrorMessage
                      name="store"
                      component="div"
                      className="text-red-400 font-bold text-xl"
                    />
                  </div>

                  <div className="mb-9 text-3xl text-gray-550">
                    <p className="mb-2">Categoría</p>
                    <Field
                      as="select"
                      value={select}
                      name="category"
                      onChange={(value) => {
                        returnImages(value.target.value);
                        setSelect(value.target.value);
                        setFieldValue("category", value.target.value);
                        setName(
                          value.target.options[value.target.selectedIndex].text
                        );
                      }}
                      className="w-full bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none"
                    >
                      <option value={"none"}>Seleccionar</option>
                      {categoriesByStore
                        ? categoriesByStore.map((option, index) => (
                            <option key={index} value={option.id}>
                              {option.title}
                            </option>
                          ))
                        : ""}
                    </Field>
                    <ErrorMessage
                      name="category"
                      component="div"
                      className="text-red-400 font-bold text-xl"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-10">
                  <div className="mb-9 text-3xl text-gray-550">
                    <p className="mb-3 text-gray-700">Banner {name}</p>
                    <div className=" w-full mx-auto">
                      <FilesDragAndDrop
                        activateClean={activateCategories}
                        onFileChange={(files) => {
                          setActivateCategories(false);
                          onFilesChange(files, setFieldValue);
                        }}
                        multiple={true}
                      />
                    </div>
                    <ErrorMessage
                      name="image"
                      component="div"
                      className="text-red-400 font-bold text-xl"
                    />
                  </div>
                </div>
                <div className="w-11/12 mx-auto">
                  {collection && collection.length > 0 ? (
                    <BannersCarrousel
                      banners={collection}
                      setSwiper={setSwiper}
                    ></BannersCarrousel>
                  ) : (
                    ""
                  )}
                </div>
                {/* BUTTONS */}
                <div className="w-full flex justify-end space-x-4">
                  {swiper ? (
                    swiper.slides ? (
                      <button
                        type="button"
                        onClick={() => handleDeleteCategory(select)}
                        className="w-full md:w-56 bg-red-50 text-white text-2xl py-3 px-5 disabled:opacity-75 mt-5"
                      >
                        Eliminar imagen
                      </button>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  <button
                    type="submit"
                    className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75 mt-5"
                  >
                    Guardar
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        {/* Advertising banner */}
        <div className="mt-14">
          <Formik
            enableReinitialize={true}
            initialValues={initialValuesAdvertising}
            validationSchema={formSchemaAdvertising}
            onSubmit={(values) => {
              parseInt(submitAction) === 1
                ? handleSubmitAdvertising(values)
                : handleUpdateAdvertising(values);
            }}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="grid grid-cols-1 gap-10">
                  <div className="mb-9 text-3xl text-gray-550">
                    <p className="mb-3 text-gray-700">Banner Publicitario</p>
                    <div>
                      <FilesDragAndDrop
                        onFileChange={(files) => {
                          setActivatePublicity(false);
                          onFileChange(files, setFieldValue);
                        }}
                        activateClean={activatePublicity}
                        multiple={false}
                      />
                    </div>
                    <ErrorMessage
                      name="image"
                      component="div"
                      className="text-red-400 font-bold text-xl"
                    />
                  </div>

                  <div className="mb-9 text-3xl text-gray-550">
                    <p>Banner actual</p>
                    {banner && (
                      <img
                        src={banner.bannerAdvertising.image}
                        alt={banner.bannerAdvertising.name}
                        className="w-full h-auto object-cover"
                      />
                    )}
                  </div>

                  <div className=" w-11/12 mx-auto">
                    {collectionPublicity ? (
                      <BannersCarrousel
                        banners={collectionPublicity}
                        setSwiper={setSwiperPublicity}
                      ></BannersCarrousel>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="mb-5 text-3xl text-gray-700">
                  <p className="mb-3">Vinculo URL</p>
                  <Field
                    name="url"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="url"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>

                {/* BUTTONS */}
                <div className="w-full flex flex-col md:flex-row justify-end md:space-x-4">
                  {swiperPublicity && swiperPublicity.slides && (
                    <div className="flex flex-col md:flex-row md:space-x-4 order-2 md:order-1">
                      {banner.bannerAdvertising[0].id !== null && (
                        <button
                          type="button"
                          onClick={() =>
                            handleDeletePublicity(
                              swiperPublicity.slides[
                                swiperPublicity.activeIndex
                              ].id
                            )
                          }
                          className="w-full md:w-56 bg-red-50 text-white text-2xl py-3 px-5 disabled:opacity-75 mt-5 order-2 md:order-1"
                        >
                          Eliminar imagen
                        </button>
                      )}

                      {banner.bannerAdvertising[0].id !== null && (
                        <button
                          type="submit"
                          onClick={() => {
                            setSubmit("2");
                          }}
                          className="w-full md:w-56 bg-primary-300 text-white text-2xl py-3 px-5 disabled:opacity-75 mt-5 order-1 md:order-2"
                        >
                          Actualizar imagen
                        </button>
                      )}
                    </div>
                  )}

                  <button
                    type="submit"
                    onClick={() => {
                      setSubmit("1");
                    }}
                    className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75 mt-5 order-1 md:order-2"
                  >
                    Guardar
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Index;
