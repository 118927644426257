import { api } from "../../../client";

export const adminUsersAdministratorService = {
  getUsers() {
    return api.admin.get("/admin/administrator");
  },
  getUser(id) {
    return api.admin.get(`/admin/administrator/${id}`);
  },
  createUser(model) {
    return api.admin.post("/admin/administrator", model);
  },
  updateUser(id, model) {
    return api.admin.put(`/admin/administrator/${id}`, model);
  },
};
