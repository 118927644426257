import { useFormikContext } from "formik";
import { React } from "react";
const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  return (
    <div className="mb-9 text-xl text-gray-700 space-x-2 text-center md:text-left">
      <p>
        {props.question}{" "}
        <b>
          {props.link ? (
            <a href={props.link} target="_blank" rel="noopener noreferrer">
              {props.linkTitle}
            </a>
          ) : (
            ""
          )}{" "}
        </b>
      </p>
      <div className="space-x-5 flex justify-center md:justify-start  mt-2 lg:mb-4">
        <div className="space-x-2 flex items-center">
          <input
            name={props.name}
            value="unooo"
            checked={props.check === 1}
            className="t-20 w-5 h-5  bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
            onChange={(value) => {
              setFieldValue(props.name, true);
            }}
            type="radio"
          />
          <span className="">Si</span>
        </div>
        <div className="space-x-2 flex items-center">
          <input
            name={props.name}
            checked={props.check === 0}
            value="dooos"
            className="w-5 h-5  bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
            onChange={(value) => {
              setFieldValue(props.name, false);
            }}
            type="radio"
          />
          <span className="">No</span>
        </div>
      </div>
    </div>
  );
};
export default DatePickerField;
