/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as _ from "lodash";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Context from "../../../context/AdminUserContext";
import { notifications } from "../../../utils/notifications";
import { adminExpenseServices } from "../../../services/api/admin/contability";
import CreatableSelect from 'react-select/creatable';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import {  format, parseISO } from "date-fns";
import CommonTable from "../../../components/tables/CommonTable";
import moment from "moment";

registerLocale("es", es);

const EditExpense = () => {
    const formikRef = useRef();
    const MySwal = withReactContent(Swal);

    const formSchema = Yup.object().shape({
        amount: Yup.number().required("Campo requerido"),
        date: Yup.string().nullable().required('Campo requerido'),
        concept: Yup.string().required("Campo requerido").max(255, `Máximo 100 caracteres`),
    });

    const [items, setItems] = useState([]);
    const [itemsSelected, setItemsSelected] = useState();
    const [expirationDate, setExpirationDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [expense, setExpense] = useState(null);
    const [expenseHistory, setExpenseHistory] = useState([]);

    const { currentAdminUser } = useContext(Context);
    const navigate = useNavigate();
    const params = useParams();

    const [initialValues, setInitialValues] = useState({
        amount: 0,
        concept: "",
        date: "",
    });

    useEffect(() => {
        if (!currentAdminUser) return;
        fetchExpense();
    }, [currentAdminUser, items]);
    useEffect(() => {
        if (!currentAdminUser) return;

        fetchExpenseItems();
    }, [currentAdminUser]);

    const deleteDuplicates = (items) => {
        const duplicates = {};
        items.forEach((element) => {
            const existing = duplicates[element.name];
            if (existing) {
                existing.value += element.value;
                existing.id = existing.id || '';
                if (element.id && !existing.id.includes(element.id)) {
                    existing.id += ' ' + element.id;
                }
                existing.cost = (+existing.cost || 0) + (+element.cost || 0);
            } else {
                duplicates[element.name] = element;
            }
        });
        const mergedArray = Object.values(duplicates);
        return mergedArray;

    }

    const cleanConceptsList = (items) => {
        Object.keys(items).forEach(key => {
            if (items[key].concept) {
                items[key].name = items[key].concept;
                delete items[key].concept;
            }
            if (items[key].renew_cost && items[key].concept) {
                items.push({
                    name: "Renovacion " + items[key].name,
                    cost: items[key].renew_cost,
                });
                items[key].name = items[key].concept;
                delete items[key].concept;
            }

        });
        return deleteDuplicates(items);


    }

    const fetchExpenseItems = () => {
        setIsLoading(true);
        adminExpenseServices
            .getExpenseItems()
            .then((response) => {
                setItems(response.data.items);
            })
            .catch((err) => { })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const formatItems = (_items, setFieldValue) => {
        setItemsSelected(_items);

        if (_items.id) {
            setFieldValue("concept", _items.id);
        } else {
            setFieldValue("concept", _items.name);
        }
        _items.cost && setFieldValue("amount", _items.cost);

    };

    useEffect(() => {
        if (!currentAdminUser) return;
        fetchExpenseItems();
    }, [currentAdminUser]);

    const getConceptName = (id) => {
        let name = '';
        items.forEach(item => {
            if (item.id === id) {
                name = item.name
            }

        });

        return name;
    }


    const fetchExpense = () => {
        adminExpenseServices
            .getExpense(params.id)
            .then((response) => {
                setExpense(response.data.data);
                setExpenseHistory(response.data.data.expenses_history);
                setItemsSelected({ name: getConceptName(response.data.data.concept_id), value: response.data.data.concept_id });
                formikRef.current.setFieldValue(
                    "concept",
                    response.data.data.concept_id
                );
            })
            .catch((err) => { });
    };


    useEffect(() => {
        if (!_.isEmpty(expense)) {
            setInitialValues({
                amount: expense.amount || "",
                concept: expense.concept || "",
                date: expense.date ? expense.date.split(" ")[0] : "",
                disableOptions: (expense.deleted_at || currentAdminUser.type === 'Admin'),//Este campo es para cuando este implementado lo de eliminar
            });

            if (expense.date) {
                setExpirationDate(parseISO(expense.date));
            }

        }
    }, [expense, itemsSelected]);


    const handleSubmit = async (values) => {
        setIsLoading(true);
        values.concept = returnIdOrConcept(values.concept).toString();
        delete values.disableOptions;
        await adminExpenseServices
            .updateExpense(params.id, values)
            .then((response) => {
                notifications.success(response.data.message);
                navigate("/admin/contabilidad");
            })
            .catch((err) => { })
            .finally(() => setIsLoading(false));
    };
    const createOption = (inputValue, setFieldValue, type) => {
        let newOption;
        switch (type) {
            case 'onCreateOption':
                newOption = {
                    name: inputValue,
                    value: inputValue
                };
                setFieldValue('concept', inputValue);
                setFieldValue("amount", 0);
                setItemsSelected(newOption);
                break;
            case 'onBlur':
                if (inputValue.target.value) {
                    newOption = {
                        name: inputValue.target.value,
                        value: inputValue.target.value
                    };
                    setFieldValue('concept', inputValue.target.value);
                    setFieldValue("amount", 0);
                    setItemsSelected(newOption);
                }

                break;
            default:
                formatItems(inputValue, setFieldValue)
                setFieldValue("amount", inputValue.cost);
                setItemsSelected(inputValue);
                break;

        }
        if (type) return newOption;
    };

    const handleDelete = async () => {

        MySwal.fire({
            icon: "error",
            title: "¿Deseas eliminar este egreso?",
            text: "Se eliminará todo el contenido asociado. Esta acción es irreversible",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonText: "Cancelar",
            cancelButtonColor: "#42a819",
            confirmButtonText: "Eliminar",
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                setIsLoading(true);
                await adminExpenseServices
                    .deleteExpense(params.id)
                    .then((response) => {
                        notifications.success(response.data.message);
                        navigate("/admin/contabilidad");
                    })
                    .catch((err) => {
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        });



    };


    const customStyles = {
        control: (provided) => ({
            ...provided,
            padding: "7px",
            cursor: initialValues.disableOptions ? 'not-allowed' : 'default',
        }),
    };
    
    const returnIdOrConcept = (concept) => {
        let value = concept;
        Object.keys(items).forEach(key => {
          if(items[key].name && typeof concept === 'string'){
            if (items[key].name.toLowerCase() === concept.toLowerCase() && items[key].id ) {
                value = items[key].id;
        }
          }
           
        });
        return value;
    }

    return (
        <div className="container mx-auto text-gray-700">
            <div className="flex flex-col md:flex-row justify-between items-center">
                <p className="text-4xl font-bold"> {currentAdminUser.type === 'SuperAdmin' && !initialValues.disableOptions ? "Editar egreso" : "Visualizar egreso"}</p>
            </div>

            <div className="mt-12">
                <Formik
                    innerRef={formikRef}
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={formSchema}
                    onSubmit={(values) => handleSubmit(values)}
                >
                    {({ setFieldValue }) => (
                        <Form>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                                <div className="mb-9 text-3xl text-gray-550">
                                    <p>Concepto</p>
                                    <CreatableSelect
                                        className="w-full bg-white text-gray-700 placeholder:font-italitc   focus:outline-none"
                                        styles={customStyles}

                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: 'hotpink',
                                                primary: 'white',
                                            },
                                        })}
                                        isClearable
                                        options={cleanConceptsList(items)}
                                        isDisabled={initialValues.disableOptions}
                                        isLoading={isLoading}
                                        value={itemsSelected}
                                        getOptionLabel={(option) => option.name || option.label}
                                        getOptionValue={(option) => option.id || option.name}
                                        onChange={(items) => formatItems(items, setFieldValue)}
                                        placeholder="Seleccione un concepto"
                                        onCreateOption={(items) => createOption(items, setFieldValue, 'onCreateOption')}
                                        onBlur={(items) => createOption(items, setFieldValue, 'onBlur')}
                                        onBlurResetsInput={false}
                                        formatCreateLabel={(inputValue) => `Nuevo concepto  "${inputValue}"`}
                                    />
                                    <ErrorMessage
                                        name="concept"
                                        component="div"
                                        className="text-red-400 font-bold text-xl"
                                    />
                                </div>
                                <div className="mb-9 text-3xl text-gray-550">
                                    <p>Monto</p>
                                    <Field
                                        name="amount"
                                        type="number"
                                        min="1"
                                        step="0.01"
                                        disabled={initialValues.disableOptions}
                                        className={`${initialValues.disableOptions ? "bg-gray-300 cursor-not-allowed" : "bg-white text-gray-700"} w-full  placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none`}

                                    />
                                    <ErrorMessage
                                        name="amount"
                                        component="div"
                                        className="text-red-400 font-bold text-xl"
                                    />
                                </div>

                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                                <div className="mb-9 text-3xl text-gray-550">
                                    <p>Fecha</p>
                                    {initialValues.disableOptions ?
                                        <Field
                                            disabled={initialValues.disableOptions}
                                            type="text"
                                            className={`${initialValues.disableOptions ? "bg-gray-300 cursor-not-allowed" : "bg-white text-gray-700"} w-full  placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none`}
                                            value={moment(expirationDate).format('YYYY-MM-DD')}

                                        />
                                        :
                                        <DatePicker
                                            maxDate={new Date()}
                                            selected={expirationDate}
                                            disabled={initialValues.disableOptions}

                                            onChange={(date) => {
                                                setFieldValue(
                                                    "date",
                                                    date ? format(date, "yyyy-MM-dd") : null
                                                );
                                                setExpirationDate(date);
                                            }}
                                            dateFormat="yyyy-MM-dd"
                                            isClearable={true}
                                            className={`${initialValues.disableOptions ? "bg-gray-300 cursor-not-allowed" : "bg-white text-gray-700"} w-full  placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none`}
                                            locale="es"
                                        />
                                    }

                                    <ErrorMessage
                                        name="date"
                                        component="div"
                                        className="text-red-400 font-bold text-xl"
                                    />
                                </div>

                            </div>
                            {!initialValues.disableOptions && currentAdminUser.type === "SuperAdmin" &&
                                <div className="w-full flex justify-between">
                                    <button
                                        type="button"
                                        className="w-full md:w-56 bg-tertiary text-white text-2xl py-3 px-5 disabled:opacity-75"
                                        disabled={isLoading}
                                        onClick={() => handleDelete()}
                                    >
                                        {isLoading ? (
                                            <FontAwesomeIcon icon="circle-notch" spin />
                                        ) : (
                                            <span>Eliminar</span>
                                        )}
                                    </button>

                                    <button
                                        type="submit"
                                        className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75"
                                        disabled={isLoading}

                                    >
                                        {isLoading ? (
                                            <FontAwesomeIcon icon="circle-notch" spin />
                                        ) : (
                                            <span>Guardar</span>
                                        )}
                                    </button>
                                </div>

                            }

                        </Form>
                    )}
                </Formik>
                {currentAdminUser.type === 'SuperAdmin' &&

                    <div className="my-10">
                        <div className="flex flex-col md:flex-row justify-between items-center my-10">
                            <p className="text-4xl font-bold">Historial de cambios</p>
                        </div>
                        <CommonTable array={expenseHistory} content='expensesHistory' headers={['CONCEPTO', 'MONTO', 'FECHA ', 'TIPO', 'FECHA DE MODIFICACIÓN', 'MODIFICADO POR']} />

                    </div>
                }

            </div>
        </div>
    );
};

export default EditExpense;