import { api } from '../../../client'

export const ShopifyAPIService = {
  getCollections () {
    return api.admin.get('admin/shopify/collection', {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    })
  },
  uploadCollection (model) {
    return api.admin.post('/admin/shopify/collection', model, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
  },
  getCollectionById (id) {
    return api.admin.get(`/admin/shopify/collection/${id}`)
  },
  updateCollection (id, model) {
    return api.admin.post(`/admin/shopify/collection/${id}`, model, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
  },
  getProducts () {
    return api.admin.get('admin/shopify/product')
  },
  getProductById (id) {
    return api.admin.get(`admin/shopify/product/${id}`)
  },
  uploadProduct (model) {
    return api.admin.post('/admin/shopify/product', model, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
  },
  updateProduct (id, model) {
    return api.admin.post(`/admin/shopify/product/${id}`, model, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
  },
  deleteProduct (id) {
    return api.admin.delete(`/admin/shopify/product/${id}`)
  },
  getOrders () {
    return api.admin.get('admin/shopify/order')
  },
  updateInventory (id, model) {
    return api.admin.put(`/admin/shopify/inventory/${id}`, model, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
  },
  updateOptions (id, model) {
    return api.admin.post(`/admin/shopify/options/${id}`, model, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
  },
  uploadImage (id, model) {
    return api.admin.post(`/admin/shopify/product/${id}/images`, model, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
  },
  deleteImagen (id, imageId) {
    return api.admin.delete(`/admin/shopify/product/${id}/image/${imageId}`)
  },

  getStores () {
    return api.admin.get('admin/shopify/store')
  },

  createStore (model) {
    return api.admin.post('admin/shopify/store',model)
  },

  deleteStore(id){
    return api.admin.delete(`/admin/shopify/store/${id}`)
  }}
