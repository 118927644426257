import { useContext } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Context from "../../context/AdminUserContext";

const CommonTable = ({ headers, array, content }) => {
  const { currentAdminUser } = useContext(Context);

  const typeText = {
    Created: "Registro creado",
    Modified: "Modificacion",
    Deleted: "Registro eliminado",
  };
  const returnTableContent = (item, content, index) => {
    let deleteAt;
    switch (content) {
      case "expenses":
        deleteAt = item.deleted_at;
        return (
          <>
            <td
              className={`${
                deleteAt && "line-through text-red-50"
              } whitespace-nowrap px-6 py-4 font-medium dark:border-neutral-500`}
            >
              {item.concept ? item.concept.concept : "NO DEFINIDO"}
            </td>
            <td
              className={`${
                deleteAt && "line-through text-red-50"
              } whitespace-nowrap  px-6 py-4 dark:border-neutral-500`}
            >
              $
              {item.amount.toLocaleString("en-US", {
                minimumFractionDigits: 2,
              })}{" "}
              MXN
            </td>
            <td
              className={`${
                deleteAt && "line-through text-red-50"
              } whitespace-nowrap px-6 py-4 dark:border-neutral-500`}
            >
              {moment(item.date).format("DD/MM/YYYY")}
            </td>

            <td
              className={`${
                deleteAt
                  ? "text-red-50 hover:text-red-600"
                  : "text-primary-200 hover:text-primary-300"
              } whitespace-nowrap px-6 py-4   text-lg leading-none`}
            >
              <Link to={`/admin/contabilidad/egresos/editar/${item.id}`}>
                {!deleteAt && currentAdminUser.type === "SuperAdmin"
                  ? "Editar"
                  : "Visualizar"}
              </Link>
            </td>
          </>
        );
      case "incomes":
        deleteAt = item.deleted_at;
        return (
          <>
            <td
              className={`${
                deleteAt && "line-through text-red-50"
              } whitespace-nowrap px-6 py-4 font-medium dark:border-neutral-500`}
            >
              {item.concept ? item.concept.concept : "NO DEFINIDO"}
            </td>
            <td
              className={`${
                deleteAt && "line-through text-red-50"
              } whitespace-nowrap  px-6 py-4 dark:border-neutral-500`}
            >
              {item.name}
            </td>
            <td
              className={`${
                deleteAt && "line-through text-red-50"
              } whitespace-nowrap  px-6 py-4 dark:border-neutral-500`}
            >
              {item.affiliate ? item.affiliate : "NO DEFINIDO"}
            </td>
            <td
              className={`${
                deleteAt && "line-through text-red-50"
              } whitespace-nowrap  px-6 py-4 dark:border-neutral-500`}
            >
              {item.amount.toLocaleString("en-US", {
                minimumFractionDigits: 2,
              })}{" "}
              MXN
            </td>
            <td
              className={`${
                deleteAt && "line-through text-red-50"
              } whitespace-nowrap px-6 py-4 dark:border-neutral-500`}
            >
              {moment(item.date).format("DD/MM/YYYY")}
            </td>

            <td
              className={`${
                deleteAt
                  ? "text-red-50 hover:text-red-600"
                  : "text-primary-200 hover:text-primary-300"
              } whitespace-nowrap px-6 py-4   text-lg leading-none`}
            >
              <Link to={`/admin/contabilidad/ingresos/editar/${item.id}`}>
                {/*
                                    Deleted ad es verdadero, entonces pon el visualizar y ademas si no es super admin
                                    */}
                {!deleteAt && currentAdminUser.type === "SuperAdmin"
                  ? "Editar"
                  : "Visualizar"}
              </Link>
            </td>
          </>
        );
      case "expensesHistory":
        if (item.type === "Deleted") deleteAt = true;
        return (
          <>
            <td
              className={`${
                deleteAt && "line-through text-red-50"
              } whitespace-nowrap px-6 py-4 font-medium dark:border-neutral-500`}
            >
              {item.concept ? item.concept.concept : "NO DEFINIDO"}
            </td>
            <td
              className={`${
                deleteAt && "line-through text-red-50"
              } whitespace-nowrap  px-6 py-4 dark:border-neutral-500`}
            >
              ${item.amount} MXN
            </td>
            <td
              className={`${
                deleteAt && "line-through text-red-50"
              } whitespace-nowrap px-6 py-4 dark:border-neutral-500`}
            >
              {moment(item.date).format("DD/MM/YYYY")}
            </td>
            <td
              className={`${
                deleteAt && "text-red-50 font-bold"
              } whitespace-nowrap px-6 py-4 dark:border-neutral-500`}
            >
              {!deleteAt ? typeText[item.type] : "Registro eliminado"}
            </td>
            <td
              className={`${
                deleteAt && " text-red-50"
              } whitespace-nowrap px-6 py-4 dark:border-neutral-500`}
            >
              {moment(item.created_at).format("DD/MM/YYYY")}
            </td>

            <td className="whitespace-nowrap px-6 py-4 text-primary-200  text-lg leading-none">
              {item.administrator ? item.administrator.name : "NO DISPONIBLE"}
            </td>
          </>
        );

      case "incomesHistory":
        if (item.type === "Deleted") deleteAt = true;
        return (
          <>
            <td
              className={`${
                deleteAt && "line-through text-red-50"
              } whitespace-nowrap px-6 py-4 font-medium dark:border-neutral-500`}
            >
              {item.concept ? item.concept.concept : "NO DEFINIDO"}
            </td>
            <td
              className={`${
                deleteAt && "line-through text-red-50"
              } whitespace-nowrap  px-6 py-4 dark:border-neutral-500`}
            >
              {item.name}
            </td>
            <td
              className={`${
                deleteAt && "line-through text-red-50"
              } whitespace-nowrap  px-6 py-4 dark:border-neutral-500`}
            >
              {item.affiliate ? item.affiliate : "NO DEFINIDO"}
            </td>
            <td
              className={`${
                deleteAt && "line-through text-red-50"
              } whitespace-nowrap  px-6 py-4 dark:border-neutral-500`}
            >
              ${item.amount} MXN
            </td>
            <td
              className={`${
                deleteAt && "line-through text-red-50"
              } whitespace-nowrap px-6 py-4 dark:border-neutral-500`}
            >
              {moment(item.date).format("DD/MM/YYYY")}
            </td>
            <td
              className={`${
                deleteAt && "text-red-50 font-bold"
              } whitespace-nowrap px-6 py-4 dark:border-neutral-500`}
            >
              {!deleteAt ? typeText[item.type] : "Registro eliminado"}
            </td>
            <td
              className={`${
                deleteAt && " text-red-50"
              } whitespace-nowrap px-6 py-4 dark:border-neutral-500`}
            >
              {moment(item.created_at).format("DD/MM/YYYY")}
            </td>

            <td className="whitespace-nowrap px-6 py-4 text-primary-200  text-lg leading-none">
              {item.administrator ? item.administrator.name : "NO DISPONIBLE"}
            </td>
          </>
        );
      default:
        break;
    }
  };

  return (
    <div className="flex flex-col">
      <div className="overflow-x-scroll sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full border text-center text-sm font-light dark:border-gray-350">
              <thead className="border border-gray-350">
                <tr>
                  {headers.map((header, index) => (
                    <th
                      scope="col"
                      key={index}
                      className={`${
                        index === headers.length
                          ? "px-6 py-4"
                          : "px-6 py-4 dark:border-neutral-500"
                      } `}
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {array && array.length > 0 ? (
                  array.map((item, index) => (
                    <tr key={index} className="border-b dark:border-gray-350">
                      {returnTableContent(item, content, index)}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan={"100%"}>
                      No se han encontrado datos
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonTable;
