import { NavLink, Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const DesktopLayout = () => {
  return (
    <div className="flex flex-col h-screen justify-between">
      <div>
        <NavBar />

        <main>
          <div className="w-full h-full relative md:px-6 lg:px-10">
            <div className="w-full">
              <div className="py-8 lg:py-10">
                <div className="container mx-auto text-center">
                  <h1 className="text-gray-700 font-bold text-2xl md:text-3xl lg:text-4xl mb-6 text-center md:text-left">
                    Conoce nuestras membresías
                  </h1>
                  {/* NAV */}
                  <div className="flex flex-col md:flex-row items-center md:gap-5 lg:gap-20 text-2xl text-gray-600">
                    {/* <NavLink end to="/membresias/basica">
                      {({ isActive }) => (
                        <span
                          className={`text-xl lg:text-2xl ${
                            isActive || window.location.pathname === "/membresias" ? "text-primary-200 font-bold" : undefined
                          }`}
                        >
                          <FontAwesomeIcon
                            icon={["far", "book"]}
                            className="mr-2"
                          />
                          Básica Educativa
                        </span>
                      )}
                    </NavLink> */}
                    {/* <NavLink end to="/membresias/dropshipping">
                      {({ isActive }) => (
                        <span
                          className={`text-xl lg:text-2xl ${
                            isActive ? "text-primary-200 font-bold" : undefined
                          }`}
                        >
                          <FontAwesomeIcon
                            icon={["far", "box-open"]}
                            className="mr-2"
                          />
                          Básica Dropshipping
                        </span>
                      )}
                    </NavLink> */}
                    <NavLink end to="/membresias/plus">
                      {({ isActive }) => (
                        <span
                          className={`text-xl lg:text-2xl ${
                            isActive ||
                            window.location.pathname === "/membresias"
                              ? "text-primary-200 font-bold"
                              : undefined
                          }`}
                        >
                          <FontAwesomeIcon
                            icon={["far", "shield-plus"]}
                            className="mr-2"
                          />
                          Franquicia PLUS
                        </span>
                      )}
                    </NavLink>
                    {/* <NavLink end to="/membresias/premium">
                      {({ isActive }) => (
                        <span
                          className={`text-xl lg:text-2xl ${
                            isActive ? "text-primary-200 font-bold" : undefined
                          }`}
                        >
                          <FontAwesomeIcon
                            icon={["far", "crown"]}
                            className="mr-2"
                          />
                          Franquicia Premium
                        </span>
                      )}
                    </NavLink> */}
                    <NavLink end to="/membresias/emprendedor">
                      {({ isActive }) => (
                        <span
                          className={`text-xl lg:text-2xl ${
                            isActive ? "text-primary-200 font-bold" : undefined
                          }`}
                        >
                          <FontAwesomeIcon
                            icon={["fa", "business-time"]}
                            className="mr-2"
                          />
                          Franquicia Emprendedor
                        </span>
                      )}
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="bg-white w-full container mx-auto px-5 md:px-0">
                <Outlet />
              </div>
            </div>
          </div>
        </main>
      </div>

      <Footer />
    </div>
  );
};

export default DesktopLayout;
