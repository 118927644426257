/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { api } from "../services/client";
import appStorage from "../utils/appStorage";
import { adminTokenLocalStorageKey } from "../utils";
import { adminAuthService } from "../services/api/admin/auth";

const Context = React.createContext({});

export function AdminUserContextProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [jwt, setJWT] = useState(() =>
    appStorage.getItem(adminTokenLocalStorageKey)
  );
  const [currentAdminUser, setCurrentAdminUser] = useState(null);
  const [isVerifying, setIsVerifying] = useState(true);

  const setDefaultHeaders = (token) => {
    let authorization = token ? `Bearer ${token}` : undefined;
    api.admin.defaults.headers.common.Authorization = authorization;
  };

  const verify = useCallback(
    (token) => {
      if (!token) return Promise.resolve(null);

      adminAuthService
        .verify(token)
        .then((response) => {
          setCurrentAdminUser(response.data.admin);
        })
        .catch((err) => {
          setCurrentAdminUser(null);
        })
        .finally(() => {
          setIsVerifying(false);
        });
    },
    [setCurrentAdminUser]
  );

  useEffect(() => {
    if (!jwt || !location.pathname.includes("admin")) return;
    setDefaultHeaders(jwt);
    verify(jwt);
  }, [jwt, verify]);

  useEffect(() => {
    window.addEventListener("storage", (e) => {
      if (e.key === adminTokenLocalStorageKey && e.oldValue && !e.newValue) {
        setCurrentAdminUser(null);
      }
    });

    return () => {
      window.removeEventListener("storage", null);
    };
  }, [navigate]);

  return (
    <Context.Provider
      value={{
        jwt,
        setJWT,
        currentAdminUser,
        setCurrentAdminUser,
        isVerifying,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default Context;
