import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TableNoData = () => {
  return (
    <div className="flex flex-col md:flex-row items-center gap-2 py-10 px-4">
      <FontAwesomeIcon
        icon={["fa", "message-exclamation"]}
        size="1x"
        className="text-primary-200"
      />
      <p className="text-xl lg:2xl font-bold">
        No hay información que mostrar.
      </p>
    </div>
  );
};

export default TableNoData;
