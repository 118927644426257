import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DatePickerFieldEdit from "../../components/admin/PickersComponentEdit";
import CoursesComboComponentEdit from "../../components/admin/CoursesComboComponentEdit";
import FilesDragAndDrop from "../../components/FilesDragAndDrop";
import * as _ from "lodash";

const EventEditForm = ({ event, handleSubmit, handleDelete }) => {
  const formSchemaEdit = Yup.object().shape({
    nameEdit: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    descriptionEdit: Yup.string().required("Campo requerido"),
    linkEdit: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    hourEdit: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    dateEdit: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
  });

  const onFileChange = (files, setFieldValue) => {
    if (!_.isEmpty(files)) {
      setFieldValue("image", files[0]);
    }
  };

  return (
    <>
      <div className="px-12 text-3xl text-center" role="dialog">
        <Formik
          enableReinitialize={true}
          initialValues={{
            nameEdit: event.title,
            linkEdit: event.link,
            descriptionEdit: event.description,
            dateEdit: event.date,
            hourEdit: event.date,
            usersEdit: event.users,
            courseEdit: event.course ? event.course.uuid : "ninguno",
          }}
          validationSchema={formSchemaEdit}
          onSubmit={(values) => handleSubmit(values, event.id)}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="modal-header my-5 text-left flex flex-row ">
                <FontAwesomeIcon
                  icon={["far", "calendar"]}
                  size="sm"
                  className="mr-3 mt-1"
                />
                <h5 className="modal-title font-bold">Editar evento</h5>
                <hr className=""></hr>
              </div>
              <div className="mb-9 text-3xl text-gray-550">
                <p className="text-center lg:text-left">Titulo del evento</p>
                <Field
                  name="nameEdit"
                  className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                  type="text"
                />
                <ErrorMessage
                  name="nameEdit"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>
              <div className="mb-9 text-3xl text-gray-550">
                <p className="text-center lg:text-left">
                  Seleccione el tipo de usuario
                </p>
                <Field
                  as="select"
                  className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                  name="usersEdit"
                >
                  <option value="Todos">Todos</option>
                  <option value="Membresia">Membresia</option>
                </Field>
                <ErrorMessage
                  name="usersEdit"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>
              <div className="mb-9 text-3xl text-gray-550">
                <p className="text-center lg:text-left">
                  Seleccione el curso (opcional)
                </p>
                <CoursesComboComponentEdit
                  uuid={event.course ? event.course.uuid : "ninguno"}
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-3xl text-gray-550">
                  <p className="mb-5">Cambiar imagen </p>
                  <div>
                    <FilesDragAndDrop
                      onFileChange={(files) =>
                        onFileChange(files, setFieldValue)
                      }
                      multiple={false}
                    />
                  </div>
                  <ErrorMessage
                    name="image"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-9 text-3xl text-gray-550">
                  <p className="mb-5">Imagen actual</p>
                  {event.image && (
                    <img
                      src={event.image}
                      alt={event.name}
                      className="w-full h-auto object-cover"
                    />
                  )}
                </div>
              </div>
              <DatePickerFieldEdit
                date={event.date}
                hour={event.date}
                id={event.id}
              />
              <div className="mb-9 text-3xl text-gray-550">
                <p className="text-center lg:text-left">
                  Liga del evento de Zoom
                </p>
                <Field
                  name="linkEdit"
                  className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                  type="text"
                />
                <ErrorMessage
                  name="linkEdit"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>

              <div className="mb-9 text-3xl text-gray-550">
                <p className="text-center lg:text-left">Descripción</p>
                <Field
                  as="textarea"
                  name="descriptionEdit"
                  className="w-full h-48 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                />
                <ErrorMessage
                  name="descriptionEdit"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>
              <div className="w-full flex  my-5 flex flex-row flex-wrap">
                <div className="w-full lg:w-1/2">
                  <button
                    type="button"
                    className="w-full md:w-56 bg-tertiary text-white text-2xl py-3 px-5 disabled:opacity-75 mt-5"
                    onClick={() => handleDelete(event.id)}
                  >
                    Eliminar
                  </button>
                </div>
                <div className="w-full lg:w-1/2">
                  <button
                    type="submit"
                    className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75 mt-5"
                  >
                    Actualizar
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default EventEditForm;
