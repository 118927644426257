import React from "react";
import { loggerServices } from "../services/api/logger";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, sentError: false };
  }

  async sendLoggerRequest() {
    const tiempoActual = Math.floor(new Date().getTime() / 1000);

    if (!localStorage.getItem("loggerRequest")) {
      const tiempoExpiracion = tiempoActual + 300; // 5 minutos
      localStorage.setItem("loggerRequest", tiempoExpiracion);
      return true;
    } else {
      const tiempoAlmacenado = parseInt(
        localStorage.getItem("loggerRequest"),
        10
      );

      if (tiempoActual > tiempoAlmacenado) {
        const nuevoTiempoExpiracion = tiempoActual + 300;
        localStorage.setItem("loggerRequest", nuevoTiempoExpiracion);
        return true;
      } else {
        return false;
      }
    }
  }

  async componentDidCatch(error, errorInfo) {
    if (error) {
      this.setState({ hasError: true });

      if (await this.sendLoggerRequest()) {
        await loggerServices
          .addLogger({
            error: [String(error)],
            info: errorInfo,
            url: [window.location.href],
            resolution: [
              window.screen.width + "w X " + window.screen.height + "h",
            ],
          })
          .then(() => {
            this.setState({ sentError: true });
          })
          .catch(() => {})
          .finally(() => window.location.replace("/"));
      } else {
        this.setState({ sentError: true });
        window.location.replace("/");
      }
    }
  }

  render() {
    if (!this.state.hasError && !this.state.sentError) {
      return this.props.children;
    }
  }
}
export default ErrorBoundary;
