import NewCard from "../../components/admin/News/NewCard";
import FilesDragAndDrop from "../../components/FilesDragAndDrop";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import { useState, useEffect, useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as _ from "lodash";
import BasicModal from "../../components/Modals/AdminModalEvent";
import * as Yup from "yup";
import { adminNewsService } from "../../services/api/admin/news";

import Context from "../../context/AdminUserContext";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { notifications } from "../../utils/notifications";

const Index = () => {
  const { currentAdminUser } = useContext(Context);

  const [menuContent, setMenuContent] = useState("releases");
  const [newsRelease, setNewsRelease] = useState([]);
  const [news, setNews] = useState([]);

  const [newsOffer, setNewsOffer] = useState([]);
  const [querySearch, setQuerySearch] = useState("");

  const fetchNews = () => {
    adminNewsService
      .getNews()
      .then((response) => {
        setNews(response.data.news);
        setNewsOffer(response.data.rebates);
        setNewsRelease(response.data.newReleases);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchNews();
  }, [currentAdminUser]);

  useEffect(() => {
    setQuerySearch("");
  }, [menuContent]);

  const inputHandler = (e) => setQuerySearch(e.target.value.toLowerCase());
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState();
  const formikRef = useRef();
  const [initialValues] = useState({
    title: "",
    description: "",
    type: "Nuevos Lanzamientos",
    image: "",
    link: "",
  });

  const formSchema = Yup.object().shape({
    title: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    description: Yup.string().required("Campo requerido"),
    link: Yup.string().nullable().optional().max(255, `Máximo 255 caracteres`),
  });

  const handleSubmit = async (values, resetForm) => {
    let bodyFormData = new FormData();
    bodyFormData.append("title", values.title);
    bodyFormData.append("description", values.description);
    values.link && bodyFormData.append("link", values.link);
    bodyFormData.append("type", values.type);
    values.image && bodyFormData.append("image", values.image);
    await adminNewsService
      .uploadNew(bodyFormData)
      .then((response) => {
        notifications.success(response.data.message);
        setShowModal(false);
        resetForm();
        fetchNews();
      })
      .catch((err) => {});
  };

  const handleSubmitEdit = async (values, id) => {
    let bodyFormData = new FormData();
    bodyFormData.append("_method", "PUT");
    bodyFormData.append("title", values.title);
    bodyFormData.append("description", values.description);
    values.link && bodyFormData.append("link", values.link);
    bodyFormData.append("type", values.type);
    values.image && bodyFormData.append("image", values.image);
    await adminNewsService
      .updateNew(id, bodyFormData)
      .then((response) => {
        notifications.success(response.data.message);
        setShowModal(false);
        fetchNews();
      })
      .catch((err) => {});
  };

  const deleteNew = async (id) => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      icon: "error",
      title: "¿Deseas eliminar esta noticia?",
      text: "Se eliminará todo el contenido asociado. Esta acción es irreversible",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#42a819",
      confirmButtonText: "Eliminar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await adminNewsService
          .deleteNew(id)
          .then((response) => {
            notifications.success(response.data.message);
            fetchNews();
          })
          .catch((err) => {});
      }
    });
  };

  const editNew = async (event) => {
    setModalContent(
      <div className="px-8 lg:px-12 text-3xl text-center" role="dialog">
        <Formik
          enableReinitialize={true}
          initialValues={{
            title: event.title,
            link: event.link || "",
            description: event.description,
            type: event.type,
          }}
          validationSchema={formSchema}
          onSubmit={(values) => handleSubmitEdit(values, event.id)}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="modal-header my-5 text-left flex flex-row ">
                <FontAwesomeIcon
                  icon="fa-bullhorn"
                  size="sm"
                  className="mr-3 mt-1"
                />
                <h5 className="modal-title font-bold">Publicar noticia</h5>
                <hr className=""></hr>
              </div>
              <div className="mb-9 text-3xl text-gray-550">
                <Field
                  name="title"
                  placeholder="Titulo de la noticia"
                  className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                  type="text"
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>
              <div className="mb-9 text-3xl text-gray-550">
                <p className="text-center lg:text-left">
                  Seleccione el tipo de noticia
                </p>
                <Field
                  as="select"
                  className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                  name="type"
                >
                  <option value="Nuevos Lanzamientos">Nuevo lanzamiento</option>
                  <option value="Rebajas">Rebaja</option>
                  <option value="Anuncios">Anuncios</option>
                </Field>
                <ErrorMessage
                  name="users"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-3xl text-gray-550">
                  <p className="mb-5">Cambiar imagen </p>
                  <div>
                    <FilesDragAndDrop
                      onFileChange={(files) =>
                        onFileChange(files, setFieldValue)
                      }
                      multiple={false}
                      text={true}
                    />
                  </div>
                  <ErrorMessage
                    name="image"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-9 text-3xl text-gray-550">
                  <p className="mb-5">Imagen actual</p>
                  {event.image && (
                    <img
                      src={event.image}
                      alt={event.name}
                      className="w-full h-auto object-cover"
                    />
                  )}
                </div>
              </div>
              <div className="mb-9 text-3xl text-gray-550">
                <p className="text-center lg:text-left">Escriba el enlace</p>
                <Field
                  name="link"
                  className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                  type="text"
                />
                <ErrorMessage
                  name="link"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>

              <div className="mb-9 text-3xl text-gray-550">
                <p className="text-center lg:text-left">Descripción</p>
                <Field
                  as="textarea"
                  name="description"
                  className="w-full h-48 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>

              <div className="w-full flex justify-end my-5">
                <button
                  type="submit"
                  className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75 mt-5"
                >
                  + Publicar
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
    setShowModal(true);
  };

  const onFileChange = (files, setFieldValue) => {
    if (!_.isEmpty(files)) {
      setFieldValue("image", files[0]);
    }
  };

  function OpenModal(item) {
    setModalContent(
      <div className="px-8 lg:px-12 container" role="dialog">
        <div className="text-3xl text-center" role="dialog">
          <Formik
            innerRef={(p) => (formikRef.current = p)}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={formSchema}
            onSubmit={(values, { resetForm }) =>
              handleSubmit(values, resetForm)
            }
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="modal-header my-5 text-left flex flex-row ">
                  <FontAwesomeIcon
                    icon="fa-bullhorn"
                    size="sm"
                    className="mr-3 mt-1"
                  />
                  <h5 className="modal-title font-bold">Publicar noticia</h5>
                  <hr className=""></hr>
                </div>
                <div className="mb-9 text-3xl text-gray-550">
                  <Field
                    name="title"
                    placeholder="Titulo de la noticia"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-9 text-3xl text-gray-550">
                  <p className="text-center lg:text-left">
                    Seleccione el tipo de noticia
                  </p>
                  <Field
                    as="select"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    name="type"
                  >
                    <option value="Nuevos Lanzamientos">
                      Nuevo lanzamiento
                    </option>
                    <option value="Rebajas">Rebaja</option>
                    <option value="Anuncios">Anuncios</option>
                  </Field>
                  <ErrorMessage
                    name="users"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-9 text-3xl text-gray-550">
                  <p className="mb-5">Imagen de la noticia</p>
                  <div>
                    <FilesDragAndDrop
                      onFileChange={(files) =>
                        onFileChange(files, setFieldValue)
                      }
                      multiple={false}
                      text={true}
                    />
                  </div>
                  <ErrorMessage
                    name="image"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-9 text-3xl text-gray-550">
                  <p className="text-center lg:text-left">Escriba el enlace</p>
                  <Field
                    name="link"
                    className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    type="text"
                  />
                  <ErrorMessage
                    name="link"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>

                <div className="mb-9 text-3xl text-gray-550">
                  <p className="text-center lg:text-left">Descripción</p>
                  <Field
                    as="textarea"
                    name="description"
                    className="w-full h-48 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>

                <div className="w-full flex justify-end my-5">
                  <button
                    type="submit"
                    className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75 mt-5"
                  >
                    + Publicar
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
    setShowModal(true);
  }

  const filteredData = () => {
    let dataToFilter = newsRelease;
    switch (menuContent) {
      case "news":
        dataToFilter = news;
        break;
      case "releases":
        dataToFilter = newsRelease;
        break;
      case "rebates":
        dataToFilter = newsOffer;

        break;
      default:
        dataToFilter = newsOffer;
        break;
    }

    return dataToFilter.filter((admission) => {
      if (querySearch === "") {
        return admission;
      } else {
        return admission.title.toLowerCase().includes(querySearch);
      }
    });
  };

  return (
    <div className="container mx-auto text-gray-700">
      <BasicModal
        visible={showModal}
        setVisible={setShowModal}
        modalContent={modalContent}
        resetForm={() =>
          formikRef.current ? formikRef.current.resetForm() : null
        }
      />
      <div className="flex justify-between items-center">
        <p className="text-xl md:text-4xl font-bold">Todas las noticias</p>
        <button
          onClick={() => OpenModal()}
          className="text-lg md:text-2xl font-bold text-white bg-primary-200 px-4 py-2 md:px-12"
        >
          Crear noticia
        </button>
      </div>

      {/* MENU */}
      <div className="flex flex-col md:flex-row justify-center md:justify-between items-center gap-8 py-3 mt-6 border-b border-b-gray-350">
        <div className="flex flex-row">
          <button
            className="text-lg md:text-xl lg:text-2xl mr-8"
            onClick={() => setMenuContent("releases")}
          >
            <p
              className={`${
                menuContent === "releases"
                  ? "text-primary-200 font-bold"
                  : "text-gray-700"
              }`}
            >
              Nuevos lanzamientos
            </p>
          </button>

          <button
            className="text-lg md:text-xl lg:text-2xl mr-8"
            onClick={() => setMenuContent("rebates")}
          >
            <p
              className={`${
                menuContent === "rebates"
                  ? "text-primary-200 font-bold"
                  : "text-gray-700"
              }`}
            >
              Rebajas
            </p>
          </button>

          <button
            className="text-lg md:text-xl lg:text-2xl"
            onClick={() => setMenuContent("news")}
          >
            <p
              className={`${
                menuContent === "news"
                  ? "text-primary-200 font-bold"
                  : "text-gray-700"
              }`}
            >
              Anuncios
            </p>
          </button>
        </div>

        <div className="flex flex-end">
          <label className="relative block">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <FontAwesomeIcon
                icon={["fa", "magnifying-glass"]}
                className="text-[#A9A9A9]"
                size="sm"
              />
            </span>
            <input
              className="w-full md:w-52 lg:w-64 bg-white text-gray-700 placeholder:font-italic border border-slate-300 py-3 pl-12 pr-4 focus:outline-none"
              type="text"
              placeholder="Buscar"
              value={querySearch}
              onChange={inputHandler}
            />
          </label>
        </div>
      </div>

      <div className="border border-gray-350 mt-12">
        {/* CARD */}
        {setContent(menuContent, filteredData, deleteNew, editNew)}
      </div>
    </div>
  );
};

const setContent = (menuContent, filteredData, deleteNew, editNew) => {
  switch (menuContent) {
    case "releases":
      return (
        <Answered
          admissions={filteredData()}
          deleteNew={deleteNew}
          editNew={editNew}
          label={"No hay nuevos lanzamientos registrados."}
        />
      );
    case "rebates":
      return (
        <Answered
          admissions={filteredData()}
          deleteNew={deleteNew}
          editNew={editNew}
          label={"No hay rebajas registradas."}
        />
      );
    case "news":
      return (
        <Answered
          admissions={filteredData()}
          deleteNew={deleteNew}
          editNew={editNew}
          label={"No hay anuncios registrados."}
        />
      );
    default:
      return (
        <Answered
          admissions={filteredData()}
          deleteNew={deleteNew}
          editNew={editNew}
        />
      );
  }
};

const Answered = ({ label, admissions, deleteNew, editNew }) => {
  return (
    <>
      {!_.isEmpty(admissions) ? (
        admissions.map((admission) => (
          <NewCard
            key={admission.id}
            item={admission}
            deleteNew={(id) => deleteNew(id)}
            editNew={(event) => editNew(event)}
          />
        ))
      ) : (
        <div className="bg-gray-300 border-l-4 border-l-primary-200 p-4 m-8">
          <div className="text-lg md:text-xl lg:text-2xl text-gray-700 font-bold">
            {label}
          </div>
        </div>
      )}
    </>
  );
};

export default Index;
