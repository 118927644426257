import React from "react";
import { Field, ErrorMessage } from "formik";

const DatePickerField = ({ ...props }) => {
  const numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  return (
    <div className="mb-9 text-xl text-gray-700 space-x-2 ">
      <p className="text-center md:text-left">
        {props.question && props.question}
      </p>

      <div
        className={`flex justify-center md:justify-start  mt-2 lg:mb-4 ${
          props.checkFull ? "flex-wrap" : " space-x-2"
        }`}
      >
        {numbers.map((check, index) => (
          <div
            key={`rateChk-${index}`}
            className={`space-x-2 flex items-center  flex-wrap  ${
              props.checkFull ? "w-full mb-2" : " "
            }`}
          >
            <Field
              value={check}
              name={props.name}
              className=" mx-auto t-20 w-5 h-5  bg-white text-gray-700 border border-slate-300 py-3  focus:outline-none resize-none"
              type="radio"
            />
            <span className="w-1/2 lg:w-full  lg:pl-3">{check}</span>
          </div>
        ))}
        <ErrorMessage
          name={props.name}
          component="div"
          className="text-red-400 font-bold text-xl"
        />
      </div>
    </div>
  );
};
export default DatePickerField;
