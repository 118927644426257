/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notifications } from "../../../utils/notifications";
import { adminShippingsServices } from "../../../services/api/admin/shippings";


const NewShipping = () => {
  const formSchema = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
    days: Yup.number().required("Campo requerido").integer('Solo se permiten numeros enteros').max(15,"El tiempo de entrega no puede ser mayor a 15 dias"),
    cost: Yup.number().required("Campo requerido"),
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const initialValues = {
    name: "",
    cost: "",
    days: "",
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    await adminShippingsServices
      .createShipping(values)
      .then((response) => {
        notifications.success(response.data.message);
        navigate("/admin/envios");
      })
      .catch((err) => { })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <p className="text-4xl font-bold">Nuevo envio</p>
      </div>

      <div className="mt-12">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="mb-9 text-3xl text-gray-550">
                <p>Nombre</p>
                <Field
                  name="name"
                  className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>

              <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-x-10">
                <div className="mb-9 text-3xl text-gray-550">


                  <p>Costo</p>
                  <Field
                    
                    name="cost"
                    type="number"
                    min="1"
                    step="0.01"
                    className={`${initialValues.disableOptions ? "bg-gray-300 cursor-not-allowed" : "bg-white text-gray-700"} w-full  placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none`}

                  />
                  <ErrorMessage
                    name="cost"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
                <div className="mb-9 text-2xl text-gray-550">
                  <p className="mb-1">Tiempo de entrega (Días)</p>
                  <Field
                    name="days"
                    type="number"
                    min="1"
                    step="0.01"
                    className={`${initialValues.disableOptions ? "bg-gray-300 cursor-not-allowed" : "bg-white text-gray-700"} w-full  placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none`}

                  />
                  <ErrorMessage
                    name="days"
                    component="div"
                    className="text-red-400 font-bold text-xl"
                  />
                </div>
              </div>


              <div className="w-full flex justify-end">
                <button
                  type="submit"
                  className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <FontAwesomeIcon icon="circle-notch" spin />
                  ) : (
                    <span>Guardar</span>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default NewShipping;
