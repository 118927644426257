import { api } from "../../../client";

export const adminAuthService = {
  login(model) {
    return api.admin.post("/auth/admin/login", model);
  },
  logout() {
    return api.admin.post("/auth/admin/logout");
  },
  verify() {
    return api.admin.get("/admin");
  },
};
