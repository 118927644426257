const NewCard = ({ item, deleteNew, editNew }) => {
  return (
    <>
      <div className="flex flex-col md:flex-row justify-between px-8 py-5 border-b border-b-gray-350 w-full">
        <div>
          <p className="font-bold text-lg md:text-xl lg:text-2xl">
            {item.title}
          </p>
        </div>

        <div className="flex justify-between items-center md:flex-col text-[#AEAEAE] pt-3 md:pt-0">
          <div className="hidden md:block space-x-4">
            <button
              className="text-primary-200 hover:text-primary-300 text-base uppercase"
              onClick={() => editNew(item)}
            >
              Editar
            </button>
            <button
              className="text-red-50 hover:text-red-500 text-base uppercase"
              onClick={() => deleteNew(item.id)}
            >
              Eliminar
            </button>
          </div>
          <div className="w-full flex md:hidden flex-row justify-end space-x-4">
            <button
              className="text-primary-200 text-base uppercase"
              onClick={() => editNew(item)}
            >
              Editar
            </button>
            <button
              className="text-red-50 hover:text-red-500 text-base uppercase"
              onClick={() => deleteNew(item.id)}
            >
              Eliminar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewCard;
