import { useEffect, useContext, useState } from "react";
import TableLoading from "../../../components/TableLoading";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Context from "../../../context/UserContext";
import { profileService } from "../../../services/api/profile";
import {
  labelsByMonth,
  optionsBarWithCurrency,
} from "../../../utils/chartsOptions";
import {
  paginationComponentOptions,
  columnsProducts,
} from "../../../utils/tableOptions";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);



const ProductsSales = () => {
  const { currentUser } = useContext(Context);
  const [products, setProducts] = useState([]);
  const [endDate, setEndDate] = useState(null);;
  const [startDate, setStartDate] = useState(null);;
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);;
  const [loading, setLoading] = useState(true);
  const data = {
    labels: labelsByMonth,
    datasets: [
      {
        data: labelsByMonth.map((el, idx) => {
          let amount = 0;
          let mFilter = products.filter((m) => {
            let date = m.created_at.split(" ")[0];
            let month = parseInt(date.split("-")[1]);
            if (month === idx + 1) return true;
            return false;
          });

          mFilter.forEach((product) => (amount += product.cost));

          return amount;
        }),
        backgroundColor: "#003159",
      },
    ],
  };

  useEffect(() => {
    let filteredData = tableData.filter((product) => {
      if (!endDate && !startDate) {
        return true;
      }
      return moment(product.created_at).isBetween(startDate, endDate);
    });
    setFilteredData(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, endDate])

  const fetchProductSalesData = () => {
    profileService
      .getUserProductsSales
      ()
      .then((response) => {
        setProducts(response.data.products)
        setTableData(response.data.products);
      })
      .catch((err) => { }).finally(() => setLoading(false));
  };

  const changeDates = (value) => {
    setStartDate(value[0])
    setEndDate(value[1])
  }

  useEffect(() => {
    if (!currentUser) return;
    fetchProductSalesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);



  return (
    <>
      {loading ?
        <div className=" w-full mx-auto text-center">
          <TableLoading size="3x" />
        </div>
        :
        <div className="flex flex-col md:flex-row gap-10">
          <div className="w-full ">
            <div className="flex mb-5 lg:mb-10">
              <p className="text-center lg:text-left text-xl lg:text-3xl font-bold text-gray-700">Ventas</p>
            </div>
            <div>
              <Bar options={optionsBarWithCurrency} data={data} />
            </div>
            <div className="flex my-5 lg:my-10">
              <p className="text-center lg:text-left text-xl lg:text-3xl font-bold text-gray-700">
                Historial de Ventas
              </p>
            </div>
            <div className="border border-gray-350 p-5">
              <div>
                <div className="w-full lg:w-1/3 ml-auto text-lg mb-5">
                  <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(value) => {
                      changeDates(value);

                    }}
                    dateFormat="dd/MM/yyyy"
                    isClearable={true}
                    className="text-gray-700 placeholder:text-gray-700 border border-slate-300 focus:outline-none py-1 px-3 w-full"
                    placeholderText="Buscar por rango de fechas..."
                    locale="es"
                  />
                </div>
                <div>
                  <DataTable
                    columns={columnsProducts}
                    data={filteredData}
                    noDataComponent="No hay información que mostrar."
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      }

    </>

  );
};

export default ProductsSales;

