import { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Rating } from "react-simple-star-rating";

import { notifications } from "../utils/notifications";
import { coursesServices } from "../services/api/courses";
import logo from "../assets/images/logo-globe-gbp.png";

import Context from "../context/UserContext";
import _ from "lodash";
import { BUTTON_COURSES } from "../utils/coursesButtonDictionary";
import CourseButton from "../components/courses/CourseButton";
import { COURSES_STATUS } from "../utils/memberships";

export default function Course() {
  const navigate = useNavigate();
  const { currentUser } = useContext(Context);

  const [openTemario, setOpenTemario] = useState(true);
  const [course, setCourse] = useState({});
  const [inWishList, setInWishList] = useState(false);

  const [inscribeds, setInscribeds] = useState();
  const [percent, setPercent] = useState();
  const [classesCount, setClassesCount] = useState();

  const params = useParams();
  const fetchCourse = () => {
    coursesServices
      .courseDetails(params.uuid)
      .then((response) => {
        setCourse(response.data.course);
        setInWishList(response.data.course.inWishList);
        setInscribeds(response.data.course.inscribeds);
        setPercent(response.data.course.percent);
        setClassesCount(response.data.course.classesCount);
      })
      .catch((err) => {
        err.response.status === 404 && navigate("/curso-no-encontrado");
      });
  };

  const handleRating = (rate) => {
    let qualification = rate;
    coursesServices
      .qualifyCourse({ uuid: course.uuid, qualification })
      .then((response) => {
        fetchCourse();
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchCourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);
  const handleWishList = async () => {
    await coursesServices
      .updateCourseWishList({ uuid: course.uuid })
      .then((response) => {
        notifications.success(response.data.message);
        setInWishList(!inWishList);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          /* eslint-disable jsx-a11y/anchor-is-valid */
          notifications.warn(
            <div>
              {" "}
              <a
                onClick={() =>
                  navigate({ pathname: "", search: "?login=true" })
                }
                className="text-primary-200"
              >
                Inicie sesión{" "}
              </a>
              para agregar un curso a su lista de deseos
            </div>
          );
        }
      });
  };

  const returnPrice = (status) => {
    const optionsButton = BUTTON_COURSES[status] || BUTTON_COURSES.default;
    return (
      <p
        className={`text-gray-700 font-bold my-4 ${
          status === COURSES_STATUS.BUY
            ? "text-4xl lg:text-5xl xl:text-6xl "
            : "text-xl lg:text-2xl xl:text-3xl"
        }`}
      >
        {status === COURSES_STATUS.BUY
          ? "$" + course.cost.toLocaleString("en-US") + " MXN."
          : optionsButton.description}
      </p>
    );
  };

  if (Object.values(course).length === 0) return;

  return (
    <main className="mx-6">
      <div className="container mx-auto px-4 md:px-0 mb-16 ">
        <div className="border-b border-gray-200">
          <div className="h-20 flex items-center">
            <img src={logo} alt="Be Global Pro" className="h-6 mr-2" />
            <p className=" text-gray-700">
              <FontAwesomeIcon icon="fa-chevron-right" className="mr-1" />{" "}
              <Link to="../../cursos" className="mr-1">
                Cursos
              </Link>{" "}
              <FontAwesomeIcon icon="fa-chevron-right" className="mr-1" />{" "}
              <span className="text-gray-400">{course.name}</span>
            </p>
          </div>
        </div>

        <div>
          <h1 className="font-bold text-xl md:text-4xl xl:text-5xl text-gray-700 mt-12 mb-2 text-center lg:text-left">
            {course.name}
          </h1>
          <p className="text-gray-500 text-base lg:text-xl mb-4 text-center lg:text-left">
            Impartido por {course.teacher.name}
          </p>

          <div className="flex justify-between items-center mb-4">
            <p className="bg-secondary inline uppercase text-white font-bold px-2 py-1 text-sm">
              más popular
            </p>

            <div className="flex items-center pr-4 text-gray-700">
              <button onClick={() => handleWishList()}>
                <FontAwesomeIcon
                  icon={inWishList ? ["fa", "heart"] : ["far", "heart"]}
                  className={`${inWishList ? "text-tertiary" : null}`}
                  size="lg"
                />
              </button>
              <p className="pl-1 hidden md:block">
                {inWishList
                  ? "Añadido a lista de deseos"
                  : "Añadir a lista de deseos"}
              </p>
            </div>
          </div>
          <img
            src={course.image}
            alt="Imagen del curso"
            className="block md:hidden w-full object-contain mb-3"
          />

          <div className="flex flex-col md:flex-row">
            {/* FIRST COL */}
            <div className="w-full md:w-2/3 md:mr-8">
              <img
                src={course.image}
                alt="Imagen del curso"
                className="w-full hidden md:block mb-6 object-contain"
              />
              <p className="whitespace-pre-wrap text-gray-700 text-xl lg:text-2xl my-2 lg:my-8 md:mb-8 md:mt-0 text-justify lg:text-left">
                {course.description}
              </p>

              <div className="flex items-center text-gray-700">
                <h3 className="font-bold text-xl lg:text-3xl my-4">
                  Temario Completo
                </h3>
                <FontAwesomeIcon
                  icon={openTemario ? "fa-chevron-up" : "fa-chevron-down"}
                  className="text-xl ml-3 cursor-pointer"
                  onClick={() => setOpenTemario(!openTemario)}
                />
              </div>

              {/* Temario */}
              {openTemario && (
                <>
                  {course.sections.map((section, idx) => (
                    <div key={section.id}>
                      <p className="text-gray-650 font-bold text-xl lg:text-2xl pb-2 uppercase">
                        <FontAwesomeIcon
                          icon={["fa", "chevron-right"]}
                          size="sm"
                          className="text-gray-700 pr-4"
                        />
                        Sección {idx + 1} - {section.name}
                      </p>
                      <ul className="mb-8">
                        {section.classes.map((classe, i) => (
                          <li key={i} className="flex items-center mb-4">
                            <FontAwesomeIcon
                              icon={["far", "lock-keyhole"]}
                              size="lg"
                              className="text-gray-400"
                            />
                            <p className="pl-4 text-gray-700 text-sm lg:text-xl">
                              {classe.name}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}

                  {course.sections.length > 0 && course.classes.length > 0 && (
                    <hr className="my-5 border-gray-500" />
                  )}

                  <ul className="mb-8">
                    {course.classes.map((_class, i) => (
                      <li key={i} className="flex items-center mb-4">
                        <FontAwesomeIcon
                          icon={["far", "lock-keyhole"]}
                          size="lg"
                          className="text-gray-400"
                        />
                        <p className="pl-4 text-gray-700 text-sm lg:text-xl">
                          {_class.name}
                        </p>
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {!_.isEmpty(course.comments) && (
                <>
                  <h3 className="text-center lg:text-left font-bold text-xl lg:text-3xl my-4 text-gray-700 pb-4">
                    Comentarios
                  </h3>
                  {course.comments.map((comment) => (
                    <div key={comment.id} className="flex items-start mb-8">
                      <div className="pr-4 my-auto lg:my-0">
                        <div className="h-10 w-10 lg:w-16 lg:h-16 bg-gray-400 rounded-full">
                          <img
                            src={comment.user.image}
                            alt=""
                            className="object-cover rounded-full h-full w-full"
                            style={{ backgroundColor: "#D8D8D8" }}
                          />
                        </div>
                      </div>
                      <div>
                        <h4 className="font-bold text-xl lg:text-2xl text-gray-700">
                          {comment.user.name}
                        </h4>
                        <p className="text-gray-700 text-sm">
                          {comment.created_at}
                        </p>
                        <p className="md:mr-40">{comment.comment}</p>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>

            {/* SECOND COL */}
            <div className="w-full md:w-1/3 order-first md:order-2 ">
              <div className="flex items-center ">
                <Rating
                  size={30}
                  emptyColor="#e2e2e2"
                  fillColor="#ffc107"
                  transition
                  ratingValue={currentUser ? course.myRating : course.rating}
                  initialValue={currentUser ? course.myRating : 0}
                  showTooltip={false}
                  readonly={
                    currentUser ? course.status !== COURSES_STATUS.START : true
                  }
                  onClick={handleRating}
                />
                {currentUser && (
                  <div className="ml-2 text-lg lg:text-xl text-gray-700 font-bold">
                    ({parseFloat(course.rating).toFixed(2)}{" "}
                    <FontAwesomeIcon
                      className="text-secondary"
                      icon="fa-star"
                      size="xs"
                    />
                    )
                  </div>
                )}
              </div>
              {returnPrice(course.status, course.membership_id)}
              <CourseButton course={course} detail fetchCourse={fetchCourse} />

              {/* Iconos y caracteristicas del curso */}
              <div className="py-5 border-b border-gray-400 mb-4">
                <div className="flex items-center text-gray-700 mb-4">
                  <FontAwesomeIcon icon={["far", "user"]} size="xl" />
                  <p className="text-sm lg:text-xl pl-2">
                    Inscritos: {inscribeds} estudiantes
                  </p>
                </div>
                <div className="flex items-center text-gray-700 mb-4">
                  <FontAwesomeIcon icon={["far", "thumbs-up"]} size="xl" />
                  <p className="text-sm lg:text-xl pl-2">
                    {percent > 0
                      ? "" +
                        parseFloat(percent).toFixed(2) +
                        "% valoraciones positivas"
                      : "Sin valoraciones"}{" "}
                  </p>
                </div>
                <div className="flex items-center text-gray-700 mb-4">
                  <FontAwesomeIcon icon={["far", "book-blank"]} size="xl" />
                  <p className="text-sm lg:text-xl pl-2">
                    Conferencias: {classesCount}
                  </p>
                </div>

                <div className="flex items-center text-gray-700 mb-4">
                  <FontAwesomeIcon icon="fa-infinity" size="xl" />
                  <p className="text-sm lg:text-xl pl-2">Acceso de por vida</p>
                </div>
                <div className="flex items-center text-gray-700 mb-4">
                  <FontAwesomeIcon
                    icon={["far", "file-certificate"]}
                    size="xl"
                  />
                  <p className="text-sm lg:text-xl pl-2">
                    Diploma al finalizar
                  </p>
                </div>
              </div>

              {/* Datos de instructor(a) */}
              <div className="flex pb-4 mt-8">
                <div className="w-1/5">
                  <img
                    src={course.teacher.image}
                    className="rounded-full"
                    alt=""
                  />
                </div>

                <div className="w-4/5 pl-4 text-sm lg:text-xl">
                  <p className="text-gray-500">Impartido por</p>
                  <p className="mb-2">{course.teacher.name}</p>
                  <p className="text-gray-500">{course.teacher.semblance}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
