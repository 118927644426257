import React, { useState, useEffect, useRef } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "moment/locale/es";
import BasicModal from "../../components/Modals/AdminModalEvent";
import { adminEventServices } from "../../services/api/admin/events";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { notifications } from "../../utils/notifications";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FilesDragAndDrop from "../../components/FilesDragAndDrop";
import DatePickerField from "../../components/admin/PickersComponent";

import CoursesComboComponent from "../../components/admin/CoursesComboComponent";
import * as _ from "lodash";
import "../styles.css"; // Tell webpack that Button.js uses these styles
import EventEditForm from "../../components/Calendar/EventEditForm";

export let navigate = {
  PREVIOUS: "PREV",
  NEXT: "NEXT",
  TODAY: "TODAY",
  DATE: "DATE",
};

const MySwal = withReactContent(Swal);

export default function Index() {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [eventsCalendar, setEventsCalendar] = useState([]);
  const formikRef = useRef();
  const [loading] = useState(false);

  const returnEventColor = (event) => {
    let color = "#63abe6";
    if (event.membership_required) {
      color = "#ffac04";
    }
    if (event.course) {
      color = "#5603fc";
    }
    if (moment(new Date()) > moment(event.date)) {
      color = "#ACACAC";
    }
    return color;
  };

  const fetchEvents = () => {
    adminEventServices
      .getEvents()
      .then((response) => {
        setEventsCalendar(response.data.events);
      })
      .catch((err) => {});
  };

  const onFileChange = (files, setFieldValue) => {
    if (!_.isEmpty(files)) {
      setFieldValue("image", files[0]);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  function foreach(item) {
    item.element = item.id;
    item.start = new Date(item.date);
    item.end = new Date(item.date);
    const newDateObj = moment(item.date).add(60, "m").toDate();
    item.end = new Date(newDateObj);
  }

  const [selectedDate] = useState(new Date());

  const [initialValues] = useState({
    name: "",
    link: "",
    description: "",
    date: selectedDate,
    hour: selectedDate,
    users: "Todos",
    image: "",
    course: "ninguno",
  });

  function OpenModal() {
    setModalContent(
      <div className="px-12 text-3xl text-center" role="dialog">
        <Formik
          innerRef={(p) => (formikRef.current = p)}
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="modal-header my-5 text-left flex flex-row ">
                <FontAwesomeIcon
                  icon={["far", "calendar"]}
                  size="sm"
                  className="mr-3 mt-1"
                />
                <h5 className="modal-title font-bold">Publicar evento</h5>
                <hr className=""></hr>
              </div>
              <div className="mb-9 text-3xl text-gray-550">
                <Field
                  name="name"
                  placeholder="Titulo del evento"
                  className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                  type="text"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>
              <div className="mb-9 text-3xl text-gray-550">
                <p className="text-center lg:text-left">
                  Seleccione el tipo de usuario
                </p>
                <Field
                  as="select"
                  className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                  name="users"
                >
                  <option value="Todos">Todos</option>
                  <option value="Membresia">Membresia</option>
                </Field>
                <ErrorMessage
                  name="users"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>
              <div className="mb-9 text-3xl text-gray-550">
                <p className="text-center lg:text-left">
                  Seleccione el curso (opcional)
                </p>
                <CoursesComboComponent />
              </div>

              <div className="mb-9 text-3xl text-gray-550">
                <p className="mb-5">Imagen del evento</p>
                <div>
                  <FilesDragAndDrop
                    onFileChange={(files) => onFileChange(files, setFieldValue)}
                    multiple={false}
                    text={true}
                  />
                </div>
                <ErrorMessage
                  name="image"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>

              <DatePickerField name="date" />

              <div className="mb-9 text-3xl text-gray-550">
                <p className="text-center lg:text-left">
                  Liga del evento de Zoom
                </p>
                <Field
                  name="link"
                  className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                  type="text"
                />
                <ErrorMessage
                  name="link"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>

              <div className="mb-9 text-3xl text-gray-550">
                <p className="text-center lg:text-left">Descripción</p>
                <Field
                  as="textarea"
                  name="description"
                  className="w-full h-48 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="text-red-400 font-bold text-xl"
                />
              </div>

              <div className="w-full flex justify-end my-5">
                <button
                  type="submit"
                  className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75 mt-5"
                  disabled={loading}
                  id="btn-submit"
                >
                  {loading ? (
                    <FontAwesomeIcon icon="circle-notch" spin />
                  ) : (
                    <span>+ Publicar</span>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );

    setShowModal(true);
  }

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    description: Yup.string().required("Campo requerido"),
    link: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
  });

  const handleSubmit = async (values, resetForm) => {
    if (values.users === "Todos") {
      values.users = 0;
    } else {
      values.users = 1;
    }
    let bodyFormData = new FormData();
    bodyFormData.append(
      "date",
      moment(values.date).tz("America/Hermosillo").format("YYYY-MM-DD") +
        " " +
        moment(values.hour).tz("America/Hermosillo").format("HH:mm")
    );
    bodyFormData.append("title", values.name);
    bodyFormData.append("description", values.description);
    bodyFormData.append("link", values.link);
    bodyFormData.append("membership_required", values.users);
    if (values.course !== "ninguno") {
      bodyFormData.append("uuid", values.course);
    }
    values.image && bodyFormData.append("image", values.image);

    document.getElementById("btn-submit").disabled = true;
    await adminEventServices
      .uploadEvent(bodyFormData)
      .then((response) => {
        notifications.success(response.data.message);
        resetForm();
        document.getElementById("btn-submit").disabled = false;
        setShowModal(false);
        fetchEvents();
      })
      .catch((err) => {
        document.getElementById("btn-submit").disabled = false;
      });
  };

  const handleDelete = (id) => {
    MySwal.fire({
      icon: "error",
      title: "¿Deseas eliminar este evento?",
      text: "Se eliminará todo el contenido asociado. Esta acción es irreversible",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#42a819",
      confirmButtonText: "Eliminar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await adminEventServices
          .deleteEvent(id)
          .then((response) => {
            notifications.success(response.data.message);
            setShowModal(false);
            fetchEvents();
          })
          .catch((err) => {});
      }
    });
  };

  function openEvent(event) {
    if (event.membership_required === false) {
      event.users = "Todos";
    } else {
      event.users = "Membresia";
    }
    const areHourAndDateTimeZoneChanging = (event, date, hour) => {
      let serverTimeZone = "America/Hermosillo";
      let dateTimeZone = moment(event.date)
        .tz(serverTimeZone)
        .format("YYYY-MM-DD");
      let hourTimeZone = moment(event.date).tz(serverTimeZone).format("HH:mm");
      let adminDateTimeZone = moment(date)
        .tz(serverTimeZone)
        .format("YYYY-MM-DD");
      let adminHourTimeZone = moment(hour).tz(serverTimeZone).format("HH:mm");

      if (
        dateTimeZone !== adminDateTimeZone ||
        hourTimeZone !== adminHourTimeZone
      ) {
        return true;
      }
      return false;
    };

    const handleSubmit = async (values, id) => {
      if (values.usersEdit === "Todos") {
        values.usersEdit = 0;
      } else {
        values.usersEdit = 1;
      }
      let bodyFormData = new FormData();
      bodyFormData.append("_method", "PUT");
      bodyFormData.append("title", values.nameEdit);
      bodyFormData.append("description", values.descriptionEdit);
      areHourAndDateTimeZoneChanging(event, values.dateEdit, values.hourEdit) &&
        bodyFormData.append(
          "date",
          moment(values.dateEdit)
            .tz("America/Hermosillo")
            .format("YYYY-MM-DD") +
            " " +
            moment(values.hourEdit).tz("America/Hermosillo").format("HH:mm")
        );
      bodyFormData.append("link", values.linkEdit);
      bodyFormData.append("membership_required", values.usersEdit);
      const courseEdit = values.courseEdit;
      if (courseEdit.toString() !== "ninguno") {
        bodyFormData.append("uuid", values.courseEdit);
      } else {
        bodyFormData.append("uuid", "");
      }
      values.image && bodyFormData.append("image", values.image);
      await adminEventServices
        .updateEvent(id, bodyFormData)
        .then((response) => {
          notifications.success(response.data.message);
          setShowModal(false);
          fetchEvents();
        })
        .catch((err) => {});
    };

    setModalContent(
      <EventEditForm
        event={event}
        handleDelete={(id) => handleDelete(id)}
        handleSubmit={(values, id) => handleSubmit(values, id)}
      ></EventEditForm>
    );

    setShowModal(true);
  }

  const [current, setCurrent] = useState("month");

  return (
    <div className="container mx-auto">
      <div className="flex flex-wrap text-center lg:text-left text-xl">
        <p className="text-3xl font-bold pb-4 text-center lg:text-left">
          Información de eventos:
        </p>
        <div className="w-full flex flex-row">
          <div className="w-10 bg-event-50 mb-2 mr-2 mt-2"></div>
          Evento para público general.
        </div>
        <div className="w-full flex flex-row">
          <div className="w-10 bg-event-100 mb-2 mr-2 mt-2"></div>
          Evento para miembros.
        </div>
        <div className="w-full flex flex-row">
          <div className="w-10 bg-event-150 mb-2 mr-2 mt-2"></div>
          Evento con curso asignado.
        </div>
        <div className="w-full flex flex-row">
          <div className="w-10 bg-event-200 mb-2 mr-2 mt-2"></div>
          Evento caducado.
        </div>
      </div>

      <div className=" flex flex-row-reverse flex-wrap">
        <button
          type="submit"
          onClick={OpenModal}
          className="bg-primary-200 text-white text-xl md:text-2xl py-2 px-5 disabled:opacity-75 mt-5 w-full md:w-1/3 xl:w-1/4 2xl:w-1/5 lg:ml-auto"
        >
          + Crear evento
        </button>
      </div>

      {eventsCalendar && eventsCalendar.forEach(foreach)}
      <BasicModal
        visible={showModal}
        setVisible={setShowModal}
        modalContent={modalContent}
        resetForm={() =>
          formikRef.current ? formikRef.current.resetForm() : null
        }
      />

      <div
        className="mx-auto my-20 capitalize max-w-7xl"
        style={{ height: "500pt" }}
      >
        <div className="text-gray-650 normal-case text-center lg:text-left text-2xl mb-5">
          Agenda tus eventos en vivo
        </div>
        <Calendar
          className="text-gray-650 lg:text-left text-sm"
          selectable={true}
          events={eventsCalendar}
          eventPropGetter={(event) => ({
            style: {
              backgroundColor: returnEventColor(event),
            },
          })}
          defaultView={current}
          onView={(newView) => {
            setCurrent(newView);
          }}
          views={["month", "day"]}
          onSelectEvent={(event) => openEvent(event)}
          startAccessor="start"
          endAccessor="end"
          defaultDate={moment().toDate()}
          localizer={localizer}
          components={{
            eventWrapper: ({ event, children }) => (
              <div
                onContextMenu={(e) => {
                  handleDelete(event.id);
                  e.preventDefault();
                }}
              >
                {children}
              </div>
            ),
          }}
          onSelectSlot={(slotInfo) => {}}
          messages={{
            next: "Siguiente",
            previous: "Antes",
            today: "Hoy",
            month: "Mes",
            week: "Semana",
            day: "Día",
            work_week: "Semana de trabajo",
            allDay: "Todo el día",
            yesterday: "Ayer",
            tomorrow: "Mañana",
            noEventsInRange: "No se han encontrado eventos",
            showMore: function showMore(total) {
              return "+" + total + "Eventos";
            },
          }}
        />
      </div>
    </div>
  );
}

const localizer = momentLocalizer(moment);
