import { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field, FieldArray } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ShopifyAPIService } from "../../../services/api/admin/shopify";
import * as _ from "lodash";
import Context from "../../../context/AdminUserContext";
import { notifications } from "../../../utils/notifications";

import FilesDragAndDrop from "../../../components/FilesDragAndDropProducts";

import UploadImgVariantModal from "../../../components/Modals/admin/UploadImgVariantModal";
import UploadImgVariantContent from "../../../components/Modals/admin/UploadImgVariantContent";

const EditProduct = () => {
  const navigate = useNavigate();
  const params = useParams();
  const formikRef = useRef();
  const [showPublish, setShowPublish] = useState(false);
  const [currentVariant, setCurrentVariant] = useState();
  // eslint-disable-next-line no-unused-vars
  const [currentImage, setCurrentImage] = useState();
  const [currentImageView, setCurrentImageView] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const { currentAdminUser } = useContext(Context);
  const [product, setProduct] = useState();
  const [initialValues, setInitialValues] = useState({
    title: "",
    body_html: "",
    image: [],
    product_type: "",
    tags: "",
    status: "active",
    variants: [
      {
        option1: "",
        price: "",
        compare_at_price: "",
        inventory_quantity: "",
      },
    ],
    options: [],
  });

  const [oldFiles, setOldFiles] = useState([]);
  const [allFiles, setAllFiles] = useState([]);

  const fetchProduct = () => {
    ShopifyAPIService.getProductById(params.id)
      .then(async (response) => {
        setProduct(response.data.product);

        let oldImages = [];
        for (const image of response.data.product.images) {
          let oldimg = await setOldImage(image);
          oldImages.push(oldimg);
        }
        setOldFiles(oldImages);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const setOldImage = (image) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(image.src);

        const contentType = response.headers.get("content-type");
        const blob = await response.blob();
        const file = new File([blob], image.src, {
          contentType,
          type: image.id,
        });

        resolve(file);
      } catch (e) {
        reject(e);
      }
    });

  const formSchema = Yup.object().shape({
    title: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    body_html: Yup.string().required("Campo requerido"),
    product_type: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    tags: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    let bodyFormData = new FormData();

    bodyFormData.append("_method", "PUT");
    if (values.image?.length > 0) {
      values.image.forEach(function (image, i) {
        bodyFormData.append("images[]", values.image[i]);
      });
    }

    bodyFormData.append("title", values.title);
    bodyFormData.append("body_html", values.body_html);
    bodyFormData.append("product_type", values.product_type);
    bodyFormData.append("tags", values.tags);
    bodyFormData.append("status", values.status);
    values.variants.forEach((variant) => {
      variant.old_inventory_quantity = variant.inventory_quantity;
    });
    bodyFormData.append("variants", JSON.stringify(values.variants));

    let arrayOptions = [];
    values.options.forEach((option) => {
      arrayOptions.push({
        name: option.name,
        values: [],
      });
    });

    values.variants.forEach((variant) => {
      if (
        variant.option1 !== null &&
        variant.option1 !== "" &&
        !arrayOptions[0].values.includes(variant.option1)
      ) {
        arrayOptions[0].values.push(variant.option1);
      }
      if (
        variant.option2 !== null &&
        variant.option2 !== "" &&
        !arrayOptions[1].values.includes(variant.option2)
      ) {
        arrayOptions[1].values.push(variant.option2);
      }
      if (variant.option3 !== null && variant.option3 !== "") {
        arrayOptions[2].values.push(variant.option3);
      }
    });

    bodyFormData.append("options", JSON.stringify(arrayOptions));

    await ShopifyAPIService.updateProduct(params.id, bodyFormData)
      .then((response) => {
        notifications.success(response.data.message);
        navigate("/admin/productos");
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilesChange = (files, setFieldValue) => {
    if (!_.isEmpty(files)) {
      setAllFiles(files);
    }
  };

  const linkVariantToImg = (id, idImage, setFieldValue) => {
    setFieldValue("variants[" + id + "].image_id", idImage);
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAdminUser]);

  useEffect(() => {
    if (!_.isEmpty(product)) {
      setInitialValues({
        title: product.title || "",
        body_html: product.body_html || "",
        product_type: product.product_type || "",
        tags: product.tags || "",
        status: product.status || "",
        variants: product.variants || [],
        options: product.options || [],
      });
    }
  }, [product]);

  useEffect(() => {
    if (showPublish) return;
    setCurrentImageView(null);
  }, [showPublish]);

  const updateInventory = async (variant) => {
    if (!variant.inventory_item_id) return;

    await ShopifyAPIService.updateInventory(variant.inventory_item_id, {
      available: parseInt(variant.inventory_quantity),
    })
      .then((response) => {
        notifications.success("Inventario actualizado!");
      })
      .catch((err) => {});
  };

  const updateOption = async (options, index) => {
    if (!options[index].values) return;
    let bodyFormData = new FormData();
    bodyFormData.append("_method", "PUT");
    options.forEach((option) => {});
    bodyFormData.append("options", JSON.stringify(options));
    await ShopifyAPIService.updateOptions(params.id, bodyFormData)
      .then((response) => {
        notifications.success("Opciones actualizadas!");
      })
      .catch((err) => {});
  };

  const handleDelete = async () => {
    setLoadingDelete(true);

    await ShopifyAPIService.deleteProduct(product.id)
      .then((response) => {
        notifications.success(response.data.message);
        navigate("/admin/productos");
      })
      .catch((err) => {})
      .finally(() => {
        setLoadingDelete(false);
      });
  };

  return (
    <>
      <div className="container mx-auto text-gray-700">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <p className="text-4xl font-bold">Editar Producto</p>
        </div>

        <div className="flex flex-col md:flex-row gap-10 mt-10">
          <div className="w-full">
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={formSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ values, setFieldValue }) => (
                <>
                  <Form>
                    <div className="mb-9 text-2xl text-gray-700 w-full lg:w-2/3">
                      <p>Título</p>
                      <Field
                        name="title"
                        className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                        type="text"
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="text-red-400 font-bold text-xl"
                      />
                    </div>

                    <div className="mb-9 text-2xl text-gray-700 w-full lg:w-2/3">
                      <p>Descripción</p>
                      <Field
                        as="textarea"
                        name="body_html"
                        className="w-full h-24 md:h-32 lg:h-44 border border-slate-300 text-gray-700 placeholder:text-gray-700 py-3 px-4 resize-none focus:outline-none"
                      />
                      <ErrorMessage
                        name="body_html"
                        component="div"
                        className="text-red-400 font-bold text-xl"
                      />
                    </div>

                    <div className="mb-9 text-2xl text-gray-700 w-full lg:w-2/3">
                      <p>Media</p>
                      <div>
                        <FilesDragAndDrop
                          text={true}
                          onFileChange={(files) =>
                            onFilesChange(files, setFieldValue)
                          }
                          multiple={true}
                          showFiles={true}
                          oldFiles={oldFiles}
                        />
                      </div>
                      <ErrorMessage
                        name="image"
                        component="div"
                        className="text-red-400 font-bold text-xl"
                      />
                    </div>

                    <div className="mb-9 text-2xl text-gray-700 w-full lg:w-2/3">
                      <div className="mt-5">
                        <div className="grid grid-cols-1">
                          <div>
                            <p>Tipo de producto</p>
                            <Field
                              name="product_type"
                              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                              type="text"
                            />
                            <ErrorMessage
                              name="product_type"
                              component="div"
                              className="text-red-400 font-bold text-xl"
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-1 mt-5">
                          <div>
                            <p>Etiquetas (Separar por comas)</p>
                            <Field
                              name="tags"
                              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                              type="text"
                            />
                            <ErrorMessage
                              name="tags"
                              component="div"
                              className="text-red-400 font-bold text-xl"
                            />
                          </div>
                        </div>

                        <p className="my-5 text-3xl font-bold">Opciones</p>

                        {
                          <FieldArray name="options">
                            {({ insert, remove, push }) => (
                              <div>
                                {values?.options.length > 0 &&
                                  values.options.map((option, index) => (
                                    <div
                                      className="flex flex-row items-center"
                                      key={index}
                                    >
                                      <div className="text-left mt-3">
                                        <p>Nombre</p>
                                        <div className="flex justify-center items-center space-x-4">
                                          <Field
                                            name={`options.${index}.name`}
                                            className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                            type="text"
                                            onBlur={() =>
                                              updateOption(
                                                values.options,
                                                index
                                              )
                                            }
                                          />
                                          <div className="flex justify-center items-center h-full">
                                            {values.options.length > 1 && (
                                              <button
                                                type="button"
                                                className=""
                                                onClick={() => {
                                                  remove(index);
                                                  values.variants.forEach(
                                                    (variant, variantIndex) => {
                                                      switch (index) {
                                                        case 0:
                                                          values.variants[
                                                            variantIndex
                                                          ].option1 = "";
                                                          break;
                                                        case 1:
                                                          values.variants[
                                                            variantIndex
                                                          ].option2 = "";
                                                          break;
                                                        case 2:
                                                          values.variants[
                                                            variantIndex
                                                          ].option3 = "";
                                                          break;

                                                        default:
                                                          break;
                                                      }
                                                    }
                                                  );
                                                }}
                                              >
                                                X
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                        <ErrorMessage
                                          name={`options.${index}.name`}
                                          component="div"
                                          className="text-red-400 font-bold text-xl"
                                        />
                                      </div>
                                    </div>
                                  ))}
                                {values?.options.length < 3 && (
                                  <button
                                    type="button"
                                    className="w-full md:w-1/3 bg-primary-200 text-white font-bold text-2xl py-3 disabled:opacity-75 mt-5 md:mt-10"
                                    onClick={() => push({})}
                                  >
                                    Añadir opción
                                  </button>
                                )}
                              </div>
                            )}
                          </FieldArray>
                        }

                        <p className="mt-10 mb-5 text-3xl font-bold">
                          Variantes
                        </p>

                        <FieldArray name="variants">
                          {({ insert, remove, push }) => (
                            <div>
                              {values?.variants.length > 0 &&
                                values.variants.map((variant, index) => (
                                  <div
                                    className="grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-[1fr_1fr_1fr_1fr_auto] mt-10 gap-5 text-center"
                                    key={index}
                                  >
                                    {values.options.length > 0 &&
                                      values.options.map(
                                        (option, indexOption) => (
                                          <>
                                            {option.name ? (
                                              <div>
                                                <p>{option.name}</p>
                                                <Field
                                                  name={`variants.${index}.option${
                                                    indexOption + 1
                                                  }`}
                                                  className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                                  type="text"
                                                />
                                                <ErrorMessage
                                                  name={`variants.${index}.option${
                                                    indexOption + 1
                                                  }`}
                                                  component="div"
                                                  className="text-red-400 font-bold text-xl"
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        )
                                      )}

                                    <div>
                                      <p className="text-2xl  md:text-xl md:mt-1 lg:text-xl lg:mt-1    3xl:text-2xl 3xl:mt-0">
                                        Precio
                                      </p>
                                      <Field
                                        name={`variants.${index}.price`}
                                        className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                        type="number"
                                      />
                                      <ErrorMessage
                                        name={`variants.${index}.price`}
                                        component="div"
                                        className="text-red-400 font-bold text-xl"
                                      />
                                    </div>
                                    <div>
                                      <p className="text-2xl  md:text-xl md:mt-1 lg:text-xl lg:mt-1    3xl:text-2xl 3xl:mt-0">
                                        Precio (No Miembros)
                                      </p>
                                      <Field
                                        name={`variants.${index}.compare_at_price`}
                                        className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                        type="number"
                                      />
                                      <ErrorMessage
                                        name={`variants.${index}.compare_at_price`}
                                        component="div"
                                        className="text-red-400 font-bold text-xl"
                                      />
                                    </div>
                                    <div>
                                      <p className="text-2xl  md:text-xl md:mt-1 lg:text-xl lg:mt-1    3xl:text-2xl 3xl:mt-0">
                                        Cantidad
                                      </p>
                                      <Field
                                        name={`variants.${index}.inventory_quantity`}
                                        className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                        type="text"
                                        onBlur={() => updateInventory(variant)}
                                      />
                                      <ErrorMessage
                                        name={`variants.${index}.inventory_quantity`}
                                        component="div"
                                        className="text-red-400 font-bold text-xl"
                                      />
                                    </div>
                                    <div className="h-full">
                                      <p className="text-2xl  md:text-xl md:mt-1 lg:text-xl lg:mt-1    3xl:text-2xl 3xl:mt-0">
                                        Imagen
                                      </p>
                                      <button
                                        type="button"
                                        className="mt-3"
                                        onClick={() => {
                                          setShowPublish(true);
                                          setCurrentImageView(
                                            variant.image_id
                                              ? variant.image_id.toString()
                                              : null
                                          );
                                          setCurrentVariant(index);
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon="fa-solid fa-plus"
                                          className="text-primary-200"
                                          size="lg"
                                        />
                                      </button>
                                    </div>
                                    <div className="flex justify-center items-center md:pt-8">
                                      {values.variants.length > 1 && (
                                        <button
                                          type="button"
                                          className=""
                                          onClick={() => remove(index)}
                                        >
                                          X
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              <button
                                type="button"
                                className="w-full md:w-1/3 bg-primary-200 text-white font-bold text-2xl py-3 disabled:opacity-75 mt-5 md:mt-10"
                                onClick={() =>
                                  push({
                                    option1: "",
                                    option2: "",
                                    option3: "",
                                    price: "",
                                    inventory_quantity: "",
                                  })
                                }
                              >
                                Añadir variante
                              </button>
                            </div>
                          )}
                        </FieldArray>
                      </div>
                    </div>

                    <div className="text-2xl mt-10 w-full md:w-1/2 lg:w-3/12">
                      <p className="pb-2">Estatus</p>
                      <Field
                        as="select"
                        name="status"
                        className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                      >
                        <option value="active">Activo</option>
                        <option value="draft">Borrador</option>
                      </Field>
                      <ErrorMessage
                        name="status"
                        component="div"
                        className="text-red-400 font-bold text-xl"
                      />
                    </div>

                    <div className="flex flex-col md:flex-row justify-between w-full gap-5 mt-10 lg:mt-20">
                      <button
                        type="button"
                        className="w-full md:w-1/3 lg:w-1/4 bg-tertiary text-white font-bold text-2xl py-3 disabled:opacity-75 order-2 md:order-1"
                        disabled={loading || loadingDelete}
                        onClick={() => handleDelete()}
                      >
                        {loadingDelete ? (
                          <FontAwesomeIcon icon="circle-notch" spin />
                        ) : (
                          <span>Eliminar</span>
                        )}
                      </button>
                      <button
                        type="submit"
                        className="w-full md:w-1/3 lg:w-1/4 bg-primary-200 text-white font-bold text-2xl py-3 disabled:opacity-75 order-1 md:order-2"
                        disabled={loading || loadingDelete}
                      >
                        {loading ? (
                          <FontAwesomeIcon icon="circle-notch" spin />
                        ) : (
                          <span>Guardar</span>
                        )}
                      </button>
                    </div>
                  </Form>
                  <UploadImgVariantModal
                    visible={showPublish}
                    setVisible={setShowPublish}
                    modalContent={
                      <UploadImgVariantContent
                        files={allFiles}
                        currentVariant={currentVariant}
                        setVisible={setShowPublish}
                        currentImageView={currentImageView}
                        setCurrentImage={setCurrentImage}
                        linkVariantToImg={linkVariantToImg}
                        setFieldValue={setFieldValue}
                      />
                    }
                  />
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProduct;
