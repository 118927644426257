import { api } from "../../../client";

export const adminIncomesServices = {
  getIncomes() {
    return api.admin.get("/admin/income");
  },
  getIncome(id) {
    return api.admin.get(`/admin/income/${id}`);
  },
  createIncome(model) {
    return api.admin.post("/admin/income", model, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  updateIncome(id, model) {
    return api.admin.put(`/admin/income/${id}`, model, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  deleteIncome(id) {
    return api.admin.delete(`/admin/income/${id}`);
  },

  getIncomeItems() {
    return api.admin.get("/admin/income/items");
  },
};

export const adminExpenseServices = {
  getExpenses() {
    return api.admin.get("/admin/expense");
  },
  getExpense(id) {
    return api.admin.get(`/admin/expense/${id}`);
  },
  createExpense(model) {
    return api.admin.post("/admin/expense", model, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  updateExpense(id, model) {
    return api.admin.put(`/admin/expense/${id}`, model, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  deleteExpense(id) {
    return api.admin.delete(`/admin/expense/${id}`);
  },

  getExpenseItems() {
    return api.admin.get("/admin/expense/items");
  },
};
export const adminAccountingServices = {
  downloadAccounting(model) {
    return api.admin.post(`/admin/accounting/download`,model, { responseType: "blob" });
  },
  getAccounting(params) {
    return api.admin.get('/admin/accounting', {
      params: params
    });
  }
}