import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";

import { useNavigate } from "react-router-dom";

import { profileService } from "../../../services/api/profile";

import mexicoStates from "../../../utils/mexicoStates";
import { notifications } from "../../../utils/notifications";

const NewAddress = () => {
  const regex = /^(?:\+?\d+\s?)?(?:\(\d+\)|\d+)(?:-?\s?\d+)*$/;
  const formSchema = Yup.object().shape({
    receives: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    street_and_number: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    colony: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    city: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    state: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    country_trigger: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    country: Yup.string().when("country_trigger", {
      is: (val) => val === "OTHER",
      then: Yup.string().required("Campo requerido"),
    }),
    postal_code: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    phone: Yup.string()
      .required("Campo requerido")
      .max(10, `Máximo 10 caracteres`)
      .matches(new RegExp(regex), "Ingrese un número de teléfono válido"),
  });

  const initialValues = {
    receives: "",
    street_and_number: "",
    colony: "",
    city: "",
    state: mexicoStates["AGU"],
    country: "",
    country_trigger: "MX",
    postal_code: "",
    phone: "",
  };

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    let country = values.country === "" ? "México" : values.country;

    delete values.country_trigger;
    let newValues = { ...values, country };

    await profileService
      .addAddress(newValues)
      .then((response) => {
        notifications.success(response.data.message);
        navigate("/mi-perfil/direcciones");
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={(values) => {
        if (values.country_trigger === "MX") values.country = "";
        handleSubmit(values);
      }}
    >
      {({ values }) => (
        <Form>
          <p className="text-gray-700 font-bold text-center lg:text-left text-xl lg:text-2xl pb-2 lg:pb-5">
            Nueva dirección
          </p>
          <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
            <p>Persona que recibe</p>
            <Field
              name="receives"
              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
              type="text"
            />
            <ErrorMessage
              name="receives"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>
          <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
            <p>Calle y número</p>
            <Field
              name="street_and_number"
              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
              type="text"
            />
            <ErrorMessage
              name="street_and_number"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
            <p>Colonia</p>
            <Field
              name="colony"
              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
              type="text"
            />
            <ErrorMessage
              name="colony"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
            <p>País</p>
            <Field
              as="select"
              name="country_trigger"
              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
            >
              <option value="MX">México</option>
              <option value="OTHER">Otro país</option>
            </Field>
            <ErrorMessage
              name="country_trigger"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          {values.country_trigger === "OTHER" && (
            <div className="mb-9 text-xl lg:text-2xl text-gray-550">
              <Field
                name="country"
                className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
                placeholder="Escriba el nombre de su país"
                type="text"
              />
              <ErrorMessage
                name="country"
                component="div"
                className="text-red-400 font-bold text-xl"
              />
            </div>
          )}

          <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
            <p>Estado</p>
            {values.country_trigger === "OTHER" ? (
              <Field
                name="state"
                className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
                type="text"
              />
            ) : (
              <Field
                as="select"
                name="state"
                className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
              >
                {Object.keys(mexicoStates).map((key) => (
                  <option key={key} value={mexicoStates[key]}>
                    {mexicoStates[key]}
                  </option>
                ))}
              </Field>
            )}
            <ErrorMessage
              name="state"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
            <p>Ciudad</p>
            <Field
              name="city"
              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
              type="text"
            />
            <ErrorMessage
              name="city"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
            <p>Código postal</p>
            <Field
              name="postal_code"
              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
              type="text"
            />
            <ErrorMessage
              name="postal_code"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
            <p>Teléfono</p>
            <Field
              name="phone"
              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
              type="text"
            />
            <ErrorMessage
              name="phone"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className="w-full md:w-1/2 lg:w-1/3 bg-primary-200 text-white font-bold text-xl py-2 lg:py-3 disabled:opacity-75"
              disabled={isLoading}
            >
              {isLoading ? (
                <FontAwesomeIcon icon="circle-notch" spin />
              ) : (
                <span>Agregar</span>
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default NewAddress;
